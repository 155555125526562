import { useEffect } from 'react';
import { useAppSelector } from 'hooks/useSelector';
import i18n from 'i18next';

const useSetupLanguage = () => {
  const userLang = useAppSelector((store) => store.user.language);
  const isAuthorized = sessionStorage.getItem('isAuthorized') === 'true';

  useEffect(() => {
    const sessionLang = sessionStorage.getItem('lang');
    const localLang = localStorage.getItem('lang')?.replace(/"/g, '');

    const lang = isAuthorized
      ? userLang
      : sessionLang && sessionLang !== 'null'
      ? sessionLang.replace(/"/g, '')
      : localLang || 'en';

    i18n.changeLanguage(lang);
  }, [userLang, isAuthorized]); // Update language when Redux state changes
};

export default useSetupLanguage;
