import {
  ADD_JOB_TO_LIST,
  GET_JOBS,
  JOB_SEARCH_LOADING,
  SEARCH_DATA_ON_CHANGE,
  UPDATE_JOB_VISIBILITY,
  SORT_BY,
  CLEAR_JOBS,
  UPDATE_JOB_PERCENTAGE_PROGRESS,
  SET_LAST_JOB_RESULT_ID,
  UPDATE_JOB_COVERAGE_POLYGON_DATA,
  UPDATE_JOB_HIGH_PRECISION_USED_AT_DATA,
  FIRE_ALERT,
} from 'constants/actionTypes';
import api from 'api/appApi';
import { notification } from 'antd';

export const setJobSearchLoading = (status) => (dispatch) =>
  dispatch({
    type: JOB_SEARCH_LOADING,
    payload: status,
  });

export const searchDataOnChange = (data) => (dispatch) =>
  dispatch({
    type: SEARCH_DATA_ON_CHANGE,
    payload: data,
  });

export const getSearchJobs = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .getSearchJobs()
      .then((res) => {
        dispatch({
          type: GET_JOBS,
          payload: res.data.data,
        });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const addJobToList = (data) => (dispatch) =>
  dispatch({
    type: ADD_JOB_TO_LIST,
    payload: data,
  });

export const setJobVisibility = (status, id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .setJobVisibility(status, id)
      .then((res) => {
        dispatch({
          type: UPDATE_JOB_VISIBILITY,
          payload: res.data.data,
        });
      })
      .catch((err) => reject(err)),
  );

export const sortJobsBy = (sort) => (dispatch) =>
  dispatch({
    type: SORT_BY,
    payload: sort,
  });

export const clearJobs = (jobs, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .clearJobs(jobs)
      .then((res) => {
        dispatch({
          type: CLEAR_JOBS,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        notification.error({
          message: t('error'),
          description: t('locationsClearForbidden'),
        });
        return reject(err);
      }),
  );

export const updateJobPercentageProgress = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_JOB_PERCENTAGE_PROGRESS,
    payload: data,
  });

export const scrollToTop = (data) => (dispatch) => {
  dispatch({
    type: SET_LAST_JOB_RESULT_ID,
    payload: data,
  });
};

export const cancelJobSearch = (id, source) => () =>
  new Promise((resolve, reject) =>
    api.search
      .cancelJob(id, source)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        reject(err);
      }),
  );

export const getCoveragePolygon = (id, percentile, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .getCoveragePolygon(id, percentile)
      .then((res) => {
        const payloadData = { jobId: id, data: res.data.data };

        dispatch({
          type: UPDATE_JOB_COVERAGE_POLYGON_DATA,
          payload: payloadData,
        });

        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err?.response?.data?.error || err?.response?.data?.data?.error,
            message: t('noResult'),
          },
        });
        return reject(err);
      }),
  );

export const setLocationHighPrecisionUsed = (id, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .setLocationHighPrecisionUsed(id)
      .then(() => {
        dispatch({
          type: UPDATE_JOB_HIGH_PRECISION_USED_AT_DATA,
          payload: id,
        });

        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err.response.data.data.error,
            message: t('error'),
          },
        });
        return reject(err);
      }),
  );
