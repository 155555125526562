import React, { memo, useState, useEffect, useRef, useCallback, FC } from 'react';

import { Polyline } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import moment, { Moment } from 'moment';

import PlotRouteInterface from 'components/map/controls/plotRoute/plotRouteInterface';
import { ILocation } from 'types/storeTypes';
import { ICustomLeafletMap, OFFSET_PADDING } from 'components/map/map';
import PlotPoint from 'components/map/controls/plotRoute/plotPoint';

const filterAsc = (locations: ILocation[] | null | undefined) => {
  if (!locations) return [];

  return locations
    .filter((location) => location.lat && location.lng)
    .sort((a, b) => moment(a.location_time).diff(b.location_time));
};

interface IPlotMap {
  locations: ILocation[] | null | undefined;
  map: ICustomLeafletMap;
}
const PlotMap: FC<IPlotMap> = ({ locations, map }) => {
  const initialMarkers = useRef(filterAsc(locations));
  const [filteredMarkers, setFilteredMarkers] = useState(initialMarkers.current);
  const [groupedMarkers, setGroupedMarkers] = useState(initialMarkers.current);
  const [isGrouped, setIsGrouped] = useState(false);
  const [rangePickerDates, setRangePickerDates] = useState<[Moment, Moment] | null>(null);

  const [playState, setPlayState] = useState(false);
  const [playItemPosition, setPlayItemPosition] = useState(0);
  const markers = isGrouped ? groupedMarkers : filteredMarkers;

  const root = markers.map(({ lat, lng }) => [lat, lng]) as LatLngExpression[];

  const groupLocations = (_locations: ILocation[]) =>
    _locations.filter((v, i, a) => a.findIndex((t) => t.lat === v.lat && t.lng === v.lng) === i);

  const groupSameLocationsToggle = (isChecked: boolean) => {
    setIsGrouped(isChecked);
    setPlayItemPosition(0);
    setPlayState(false);
    if (isChecked) {
      setGroupedMarkers(groupLocations([...filteredMarkers]));
    }
  };

  const filterByDateAndTime = (dates: [Moment | null, Moment | null] | null) => {
    setPlayItemPosition(0);
    if (dates && dates.length === 2) {
      setRangePickerDates(dates as [Moment, Moment]);

      const data = initialMarkers.current.filter(({ location_time }) => {
        const time = moment(location_time).utc();
        return time.isBetween(
          moment(dates?.[0]?.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').utc(),
          moment(dates?.[1]?.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').utc(),
        );
      });
      setGroupedMarkers(groupLocations([...data]));
      setFilteredMarkers(data);
    } else if (dates === null) {
      setRangePickerDates(null);
      setGroupedMarkers(groupLocations([...initialMarkers.current]));
      setFilteredMarkers(initialMarkers.current);
    }
  };

  const fitBounds = useCallback(() => {
    if (markers.length) {
      const bounds = L.latLngBounds(markers as LatLngExpression[]);
      map.fitBounds(bounds, OFFSET_PADDING);
    }
  }, [map, markers]);

  const onPlayStart = () => {
    if (markers?.length) {
      const { lat, lng } = markers[playItemPosition];
      if (lat && lng) map.setView([lat, lng], 17);
    }
  };

  const onPlayDirectionAction = (direction: string) => {
    const newForwardPosition = playItemPosition + 1;
    const newBackwardPosition = playItemPosition - 1;

    if (direction === 'forward' && markers.length > newForwardPosition) {
      const { lat, lng } = markers[newForwardPosition];
      if (lat && lng) map.setView([lat, lng], 17);
      setPlayItemPosition(newForwardPosition);
    } else if (direction === 'backward' && newBackwardPosition >= 0) {
      const { lat, lng } = markers[newBackwardPosition];
      if (lat && lng) map.setView([lat, lng], 17);
      setPlayItemPosition(newBackwardPosition);
    }
  };

  const onPlayStop = () => {
    fitBounds();
    setPlayItemPosition(0);
  };

  useEffect(() => {
    setFilteredMarkers(filterAsc(locations));
  }, [locations]);

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  return (
    <>
      <PlotRouteInterface
        groupSameLocationsToggle={groupSameLocationsToggle}
        filterByDateAndTime={filterByDateAndTime}
        isGrouped={isGrouped}
        rangePickerDates={rangePickerDates}
        onPlayStart={onPlayStart}
        onPlayStop={onPlayStop}
        onPlayDirectionAction={onPlayDirectionAction}
        playItemPosition={playItemPosition}
        playState={playState}
        setPlayState={setPlayState}
        filteredMarkersCount={markers.length}
        currentActiveMarker={markers?.[playItemPosition]}
      />

      <Polyline pathOptions={{ color: '#3da0ea' }} positions={root} />
      {markers.map(({ lat, lng, id, location_time }, index) =>
        lat && lng ? <PlotPoint key={id} position={[lat, lng]} index={index} locationTime={location_time} /> : null,
      )}
    </>
  );
};

export default memo(PlotMap);
