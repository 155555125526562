import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/index';
import { TeamIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

import { Tooltip } from 'antd';

import TeamSwitch from 'components/menu/teamItem/teamSwitch';
import MenuPopover from 'components/menu/menuPopover';
import MenuStyles from 'components/menu/menuStyles';
import ExpandedContent from 'components/menu/expandedContent';

import { ROLE_USER } from 'constants/userRoleType';
import useCanView from 'utils/canUserView';

const { MenuItemWrapper, MinimizedContent, NameText } = MenuStyles;

const MenuTeamItem = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const canView = useCanView();

  const [popoverVisible, setPopoverVisible] = useState(false);

  if (!canView([ROLE_USER])) return null;

  const handlePopoverVisibility = (open: boolean) => setPopoverVisible(open);

  return (
    <MenuPopover
      content={<TeamSwitch closePopover={() => setPopoverVisible(false)} />}
      open={popoverVisible}
      onVisibleChange={handlePopoverVisibility}
    >
      <MenuItemWrapper marginBottom={theme['marginMd']}>
        {isExpanded ? (
          <MinimizedContent>
            <Icon color="darkGrayColor" icon={TeamIcon} />
          </MinimizedContent>
        ) : (
          <Tooltip placement="right" title={t('teams')} mouseEnterDelay={0.5}>
            <MinimizedContent>
              <Icon color="darkGrayColor" icon={TeamIcon} />
            </MinimizedContent>
          </Tooltip>
        )}
        <ExpandedContent isExpanded={isExpanded}>
          <NameText>{t('teams')}</NameText>
        </ExpandedContent>
      </MenuItemWrapper>
    </MenuPopover>
  );
};

export default MenuTeamItem;
