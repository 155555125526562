import React from 'react';

import styled from 'styled-components';

import { Popconfirm, PopconfirmProps } from 'antd';

const PopConfirm = (props: PopconfirmProps) => (
  <StyledPopConfirm
    overlayClassName="custom-popover"
    getPopupContainer={() => {
      const parent = document.querySelector('#main-layout');
      return parent instanceof HTMLElement ? parent : document.body;
    }}
    {...props}
  />
);

const StyledPopConfirm = styled(Popconfirm)``;

export default PopConfirm;
