import React from 'react';
import styled from 'styled-components';

const PulseDot = ({ ...rest }) => {
  return (
    <Wrapper {...rest}>
      <AnimatedDot />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 10px;
  height: 10px;
`;

const AnimatedDot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryColor};
    animation: pulse-ring 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    transition: background 0.3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.primaryColor};
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
    transition: background 0.3s ease-in-out;
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.3);
    }
    80%,
    100% {
      opacity: 0;
      transform: scale(0.7);
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

export default PulseDot;
