import {
  GET_GROUPS,
  GET_SINGLE_GROUP,
  CREATE_GROUP,
  ADD_CONTACT_TO_GROUP,
  LOCATE_CONTACT_GROUP,
  DELETE_GROUP,
  REMOVE_CONTACT_FROM_GROUP,
  DELETE_ROBOT_GROUP,
  SEARCH_CONTACT_GROUP_RESULT,
  EDIT_CONTACT_GROUP,
  LOGOUT,
} from 'constants/actionTypes';
import initialState from './initState';

export default function groups(state = initialState, action = {}) {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case GET_SINGLE_GROUP:
      return {
        ...state,
        singleGroup: action.payload,
      };
    case CREATE_GROUP:
      return {
        ...state,
        allGroups: {
          ...state.allGroups,
          [action.payload.id]: action.payload,
        },
      };
    case ADD_CONTACT_TO_GROUP:
      return {
        ...state,
        singleGroup: {
          ...state.singleGroup,
          contacts: action.payload,
        },
      };
    case LOCATE_CONTACT_GROUP: {
      return {
        ...state,
        singleGroup: {
          ...state.singleGroup,
          contacts: {
            ...state.singleGroup.contacts,
            [action.payload.contact_id]: {
              ...state.singleGroup.contacts[action.payload.contact_id],
              last_location: action.payload,
            },
          },
        },
      };
    }
    case DELETE_GROUP: {
      const groupObject = state.allGroups;
      delete groupObject[action.payload.id];
      return {
        ...state,
        allGroups: groupObject,
      };
    }
    case REMOVE_CONTACT_FROM_GROUP: {
      return {
        ...state,
        singleGroup: {
          ...state.singleGroup,
          contacts: action.payload,
        },
      };
    }
    case DELETE_ROBOT_GROUP: {
      const robotsObj = state.singleGroup.group_robots;
      delete robotsObj[action.payload.id];
      return {
        ...state,
        singleGroup: {
          ...state.singleGroup,
          group_robots: { ...robotsObj },
        },
      };
    }
    case SEARCH_CONTACT_GROUP_RESULT:
      return {
        ...state,
        allGroups: action.payload,
      };
    case EDIT_CONTACT_GROUP:
      return {
        ...state,
        singleGroup: {
          ...state.singleGroup,
          ...action.payload,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
