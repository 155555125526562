import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';

import { ICustomLeafletMap } from 'components/map/map';
import { Button } from 'components/index';
import { Tooltip } from 'antd';
import { RulerIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

import { initRuler } from './ruler';
import './ruler.css';

interface IRulerComponent {
  map: ICustomLeafletMap;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const RulerComponent: FC<IRulerComponent> = ({ map, tooltipPosition }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  const [isActive, setIsActive] = useState(false);
  const rulerRef = useRef<any>();

  const onToggleCallback = (value: boolean) => setIsActive(value);

  const onToggle = () => {
    rulerRef.current._toggleMeasure();
  };

  useEffect(() => {
    if (!map) return;

    initRuler(onToggleCallback, theme, L);

    const ruler = (L.control as any).ruler();
    ruler.addTo(map);
    rulerRef.current = ruler;

    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip
      title={t('ruler')}
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
    >
      <Button
        ref={ref}
        shape="circle"
        icon={RulerIcon}
        onClick={onToggle}
        // @ts-ignore
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
          background: isActive ? theme.primaryColor : theme.backgroundWhiteColor,
        }}
        customIconColor={isActive ? 'backgroundWhiteColor' : undefined}
      />
    </Tooltip>
  );
};

export default RulerComponent;
