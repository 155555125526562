import { useKeycloak } from '@react-keycloak/web';

export const useLogoutApp = () => {
  const { keycloak } = useKeycloak();
  const loginMethod = process.env.REACT_APP_LOGIN_METHOD;

  if (loginMethod === 'keycloak') {
    sessionStorage.setItem('logout-was-done', 'true');
    keycloak.logout();
  } else {
    return;
  }
};
