import { GET_SERVICE_STATUS } from 'constants/actionTypes';

export default function serviceStatus(state = { serviceStatus: 1 }, action = {}) {
  switch (action.type) {
    case GET_SERVICE_STATUS:
      return { ...action.payload };
    default:
      return state;
  }
}
