import React from 'react';
import { Route, Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

import { setUserToAuthorized } from 'actions/user/auth';

import rootReducer from 'reducers/rootReducer';

import { isIOS } from 'utils/notificationAndSound';
import ErrorBoundaries from 'utils/errorBoundaries';
import history from './utils/history';

import ThemeProvider from './theme/themeProvider';

import i18n from './utils/i18n'; // required to be imported before App.
import App from './App';

// Enable HOT-Reload for development
const storeConf = () => createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// Browser Notification and Audio - CONFIG
export const notificationPermissionResult = isIOS() ? '' : Notification.permission;

const store = storeConf();
export default store;

if (sessionStorage.getItem('access_token')) {
  store.dispatch(setUserToAuthorized());
}
const loginMethod = process.env.REACT_APP_LOGIN_METHOD;
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ErrorBoundaries>
    <Provider store={store}>
      {loginMethod === 'keycloak' ? (
        <ReactKeycloakProvider authClient={keycloak}>
          <ThemeProvider>
            <Router history={history}>
              <Route component={App} />
            </Router>
          </ThemeProvider>
        </ReactKeycloakProvider>
      ) : (
        <ThemeProvider>
          <Router history={history}>
            <Route component={App} />
          </Router>
        </ThemeProvider>
      )}
    </Provider>
  </ErrorBoundaries>,
);
