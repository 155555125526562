import React, { FC, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';
import L from 'leaflet';

import { PlusIcon, MinusIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

import { Icon } from 'components';
import { ICustomLeafletMap } from 'components/map/map';

interface IZoomControl {
  map: ICustomLeafletMap;
  isResultsMenuExpanded: boolean;
  isMenuExpanded: boolean;
}

const ZoomControl: FC<IZoomControl> = ({ map, isResultsMenuExpanded, isMenuExpanded }) => {
  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  const zoomIn = () => map.zoomIn();
  const zoomOut = () => map.zoomOut();

  // Stop propagation
  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <ZoomWrapper
      ref={ref as any}
      onClick={(e) => e.stopPropagation()}
      $isResultsMenuExpanded={isResultsMenuExpanded}
      $isMenuExpanded={isMenuExpanded}
    >
      <Icon icon={PlusIcon} size={22} hover={{ svg: { fill: theme.primaryColor } }} onClick={zoomIn} />
      <Line $isResultsMenuExpanded={isResultsMenuExpanded} $isMenuExpanded={isMenuExpanded} />
      <Icon icon={MinusIcon} size={22} hover={{ svg: { path: { fill: theme.primaryColor } } }} onClick={zoomOut} />
    </ZoomWrapper>
  );
};

const ZoomWrapper = styled.div<{ $isResultsMenuExpanded: boolean; $isMenuExpanded: boolean }>`
  position: relative;
  width: 32px;
  background: ${({ theme }) => theme.backgroundWhiteColor};
  border-radius: ${({ theme }) => theme.borderRadiusX1};

  padding: 7px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  z-index: 100;

  @media only screen and (max-width: 1800px) {
    ${({ $isResultsMenuExpanded, $isMenuExpanded }) =>
      $isResultsMenuExpanded &&
      $isMenuExpanded &&
      css`
        flex-direction: row-reverse;
        width: auto;
      `}
  }

  @media only screen and (max-width: 1600px) {
    ${({ $isResultsMenuExpanded }) =>
      $isResultsMenuExpanded &&
      css`
        flex-direction: row-reverse;
        width: auto;
      `}
  }

  @media only screen and (max-width: 1350px) {
    ${({ $isMenuExpanded }) =>
      $isMenuExpanded &&
      css`
        flex-direction: row-reverse;
        width: auto;
      `}
  }

  @media only screen and (max-width: 1180px) {
    flex-direction: row-reverse;
    width: auto;
  }
`;

const Line = styled.div<{ $isResultsMenuExpanded: boolean; $isMenuExpanded: boolean }>`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.grayShade8Color};

  @media only screen and (max-width: 1800px) {
    ${({ $isResultsMenuExpanded, $isMenuExpanded }) =>
      $isResultsMenuExpanded &&
      $isMenuExpanded &&
      css`
        height: 20px;
        width: 1px;
      `}
  }

  @media only screen and (max-width: 1600px) {
    ${({ $isResultsMenuExpanded }) =>
      $isResultsMenuExpanded &&
      css`
        height: 20px;
        width: 1px;
      `}
  }

  @media only screen and (max-width: 1350px) {
    ${({ $isMenuExpanded }) =>
      $isMenuExpanded &&
      css`
        height: 20px;
        width: 1px;
      `}
  }

  @media only screen and (max-width: 1180px) {
    height: 20px;
    width: 1px;
  }
`;

export default ZoomControl;
