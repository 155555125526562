import { GET_SURVEYS, DELETE_SURVEY, UPLOAD_SURVEY, UPDATE_SURVEY, LOGOUT } from 'constants/actionTypes';

const initialState = {
  data: null,
};

export default function survey(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SURVEYS:
      return {
        ...state,
        data: action.payload,
      };
    case UPLOAD_SURVEY:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case UPDATE_SURVEY: {
      if (state.data) {
        const newArray = [...state.data];
        const index = newArray.findIndex((survey) => survey.id === action.payload.id);
        newArray[index] = { ...newArray[index], ...action.payload };

        return {
          ...state,
          data: newArray,
        };
      }
      return { ...state };
    }
    case DELETE_SURVEY: {
      const newArray = [...state.data];
      const index = newArray.findIndex((survey) => survey.id === action.payload);
      newArray.splice(index, 1);

      return {
        ...state,
        data: newArray,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
