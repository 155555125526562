import { SET_FILTER_AUDIT, UPDATE_AUDIT_LOGS_REPORT } from 'constants/actionTypes';

export default function audit(state = {}, action = {}) {
  switch (action.type) {
    case SET_FILTER_AUDIT:
      return action.payload;

    case UPDATE_AUDIT_LOGS_REPORT:
      const newLog = {
        ...action.payload,
        isNew: true,
      };

      const newData = state.data ? [...state?.data] : [];

      const isIdMissing = !newData.some((item) => item.id === action.payload.id);
      if (isIdMissing) {
        newData.unshift(newLog);
      }

      return {
        ...state,
        data: newData,
      };

    default:
      return state;
  }
}
