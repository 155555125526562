import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { Table as TableAntd, TableProps } from 'antd';

interface ITable extends TableProps<any> {}

const Table: FC<ITable> = (props) => {
  const { t } = useTranslation();

  const locale = {
    emptyText: t('noData'),
    triggerAsc: t('clickToSortAscending'),
    triggerDesc: t('clickToSortDescending'),
    cancelSort: t('clickToCancelSorting'),
  };

  return <StyledTable {...props} locale={locale} />;
};

const StyledTable = styled(TableAntd)`
  .ant-table {
    background: transparent;
  }

  .ant-table-container {
    border-radius: ${({ theme }) => theme.borderRadiusBg};
    overflow: hidden;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: ${({ theme }) => theme.textBlackColor};
    }
    td {
      white-space: nowrap;
      background: ${({ theme }) => theme.mainShade7Color} !important;
    }
    tr {
      th {
        white-space: nowrap;
        background: ${({ theme }) => (theme.isDarkMode ? theme.backgroundWhiteColor : theme.mainShade7Color)};
      }
    }

    .ant-table-column-sorter {
      color: ${({ theme }) => theme.textBlackColor};
    }

    .ant-table-column-has-sorters {
      background: ${({ theme }) => (theme.isDarkMode ? theme.backgroundWhiteColor : theme.mainShade7Color)} !important;

      &:hover {
        background: ${({ theme }) => (theme.isDarkMode ? theme.grayShade10Color : theme.mainShade7Color)} !important;
      }
    }
  }

  // remove last row bottom border
  .ant-table-tbody {
    .ant-table-column-sort {
      background: ${({ theme }) => theme.mainShade6Color} !important;
    }

    .ant-table-row {
      .ant-table-cell {
        background: ${({ theme }) =>
          theme.isDarkMode ? theme.mainShade7Color : theme.backgroundWhiteColor} !important;
        border-top: ${({ theme }) =>
          `1px solid ${theme.isDarkMode ? theme.grayShade9Color : theme.mainShade7Color}`} !important;
      }
      color: ${({ theme }) => theme.textBlackColor};
    }

    .ant-table-placeholder {
      .ant-table-cell {
        color: ${({ theme }) => theme.textBlackColor};
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: none !important;
        }
      }

      &:hover {
        td {
          background: ${({ theme }) => (theme.isDarkMode ? theme.grayShade9Color : theme.mainShade7Color)} !important;
        }
      }
      // Expanded row // next level of table
      &.ant-table-expanded-row {
        & > td {
          background: ${({ theme }) => theme.mainShade8Color};

          .ant-table {
            margin: 0 !important;
            border-color: ${({ theme }) => theme.mainShade6Color};

            .ant-table-thead {
              tr {
                th {
                  border-color: ${({ theme }) => theme.mainShade6Color};
                  &:before {
                    background-color: ${({ theme }) => theme.mainShade6Color};
                  }
                }
              }
            }
            .ant-table-tbody {
              tr {
                td {
                  border-color: ${({ theme }) => theme.mainShade6Color};
                }
              }
            }
          }
          .ant-pagination {
            .ant-select-selector {
              border-color: ${({ theme }) => theme.mainShade6Color};
            }
          }
        }
        .ant-pagination {
          margin-top: ${({ theme }) => theme.marginSm};
          margin-bottom: 0;
          .ant-select-selector {
            border-radius: ${({ theme }) => theme.borderRadiusMd};
          }
        }
      }
    }

    .ant-table-column-sort {
      background: ${({ theme }) => theme.backgroundWhiteColor} !important;
    }
  }
`;

export default Table;
