import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Tabs, TabsProps } from 'antd';

const TabsComponent = (props: TabsProps) => {
  const tabListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const tabNavList = tabListRef.current?.querySelector('.ant-tabs-nav-list');
      const tabNavWrap = tabListRef.current?.querySelector('.ant-tabs-nav-wrap');

      if (tabNavList) {
        (tabNavList as HTMLElement).style.transform = 'translate(0px, 0px)';
      }

      if (tabNavWrap) {
        if (tabNavWrap.classList.contains('ant-tabs-nav-wrap-ping-left')) {
          tabNavWrap.classList.remove('ant-tabs-nav-wrap-ping-left');
          tabNavWrap.classList.add('ant-tabs-nav-wrap-ping-right');
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={tabListRef}>
      <StyledTabs {...props} />
    </div>
  );
};

const StyledTabs = styled(Tabs)``;

export default TabsComponent;
