import React, { FC, CSSProperties } from 'react';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import styled from 'styled-components';

interface IFlagComponent {
  code?: string;
  style?: CSSProperties;
}

const FlagComponent: FC<IFlagComponent> = ({ code, style }) => {
  if (!code) return null;
  return (
    <div style={{ display: 'flex', height: '12px', ...style }}>
      {code ? <StyledIcon className={`fi fi-${code}`}></StyledIcon> : null}
    </div>
  );
};

export const StyledIcon = styled.span`
  &::before {
    content: '' !important;
  }
`;

export default FlagComponent;
