import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import history from '../../utils/history';
import LoadingComponent from 'utils/suspenseLoading';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER } from '../../constants/userRoleType';

class LeaderTeamMembersRoute extends Component {
  state = {};

  shouldComponentUpdate(nextProps) {
    if (nextProps.user.isAuthorized === false) {
      history.push('/login');
      return false;
    }
    return true;
  }
  render() {
    const { user, component: Components, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (user.isAuthorized && user.role.name === ROLE_USER)
            return (
              <Suspense fallback={<LoadingComponent />}>
                <Components {...props} />
              </Suspense>
            );
          else if (user.isAuthorized && (user.role.name === ROLE_SUPER_ADMIN || user.role.name === ROLE_ADMIN))
            history.goBack();
          else if (!user.isAuthorized) history.push('/login');
          return null;
        }}
      />
    );
  }
}

LeaderTeamMembersRoute.propTypes = {
  component: PropTypes.shape({}),
  user: PropTypes.shape({
    isAuthorized: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(LeaderTeamMembersRoute);
