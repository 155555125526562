import React from 'react';
import { withTranslation } from 'react-i18next';

import { Result } from 'antd';
import { Button } from 'components';
import history from 'utils/history';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div
          style={{ width: '100wh', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Result
            status="500"
            title={`${t('somethingWentWrongUpdateFailed')}!`}
            subTitle={t('tryToRefreshThePageOrContactAdministrator')}
            extra={
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <Button type="primary" onClick={() => history.go(0)}>
                  {t('refresh')}
                </Button>
              </div>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
