import React from 'react';
import {
  GET_ALL_CONTACTS,
  GET_CONTACT_INFO,
  ERASE_CONTACT_INFO,
  SET_PRIMARY,
  SAVE_TERMINAL,
  FIRE_ALERT,
  SAVE_CONTACT,
  ADD_TERMINAL,
  ARCHIVE_TERMINAL,
  DELETE_TERMINALS,
  CREATE_CONTACT,
  LOCATE_CONTACT,
  DASHBOARD_INFO,
  DELETE_CONTACT,
  GET_COMMENT,
  CREATE_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  GET_PLOTS,
  SEARCH_CONTACT_RESULT,
  SEARCH_UNSAVED_CONTACT_RESULT,
  ADD_TO_CANCELLED_LIST,
  REMOVE_FROM_CANCELLED_LIST,
} from 'constants/actionTypes';
import api from 'api/appApi';
import store from '../../index';

export const getAllContacts = () => (dispatch) =>
  api.contact.getContacts().then((res) => {
    dispatch({
      type: GET_ALL_CONTACTS,
      payload: res.data.data,
    });
    return res.data.data;
  });

export const getContactInfo = (contactId, filter) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.contact
      .getContact(contactId, filter)
      .then((res) => {
        dispatch({
          type: GET_CONTACT_INFO,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const searchContact = (page, perPage, searchText, groupId, column, sort) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .searchContact(page, perPage, searchText, groupId, column, sort)
      .then((res) => {
        dispatch({
          type: SEARCH_CONTACT_RESULT,
          payload: {
            page: page,
            per_page: perPage,
            contacts: res.data?.contacts,
            contactGroups: res.data?.contactGroups?.data,
          },
        });
        return resolve({ contacts: res.data?.contacts, contactGroups: res.data?.contactGroups?.data });
      })
      .catch((err) => {
        reject(err);
      }),
  );

export const createContact = (contactData) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .createContact(contactData)
      .then((res) => {
        dispatch({
          type: CREATE_CONTACT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        const name = !err.response.data.errors.name ? '' : err.response.data.errors.name[0];
        const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${phone} ${imsi} ${name} ${limit}`,
            message: 'Error!',
          },
        });
        reject(err);
      }),
  );

export const eraseContactInfo = () => (dispatch) => dispatch({ type: ERASE_CONTACT_INFO });

export const setPrimary = (terminalId) => (dispatch) =>
  api.contact.setPrimary(terminalId).then((res) => {
    dispatch({
      type: SET_PRIMARY,
      payload: res.data.data,
    });
    return res.data.data;
  });

export const saveTerminal = (terminalId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .saveTerminal(terminalId, data)
      .then((res) => {
        dispatch({
          type: SAVE_TERMINAL,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${phone} ${imsi}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      }),
  );

export const saveContact = (contactId, data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .saveContact(contactId, data)
      .then((res) => {
        dispatch({
          type: SAVE_CONTACT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('requestAccessFromAdministrator'),
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const addTerminal = (terminalId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .addTerminal(terminalId, data)
      .then((res) => {
        dispatch({
          type: ADD_TERMINAL,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${phone} ${imsi} ${limit}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      }),
  );

export const archiveTerminal = (terminalId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .archiveTerminal(terminalId)
      .then((res) => {
        dispatch({
          type: ARCHIVE_TERMINAL,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteTerminal = (terminalId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .deleteTerminal(terminalId)
      .then((res) => {
        dispatch({
          type: DELETE_TERMINALS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const locateContact = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .locateContact(data)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        const errors = err.response?.data?.errors || {};
        const errorMessage = err.response?.data?.message || err.message || 'Unknown error';
        const errorPoints = errors.user ? errors.user[0] : '';

        if (!errors.note) {
          dispatch({
            type: FIRE_ALERT,
            payload: {
              message: t('error'),
              specificMessage: `${errorMessage} ${errorPoints}`,
            },
          });
        } else {
          dispatch({
            type: FIRE_ALERT,
            payload: {
              message: t('error'),
              specificMessage: errorMessage,
            },
          });
        }
        reject(err);
      }),
  );

export const getDashboardData = () => (dispatch) =>
  api.contact.getDashboardData().then((res) => {
    dispatch({
      type: DASHBOARD_INFO,
      payload: res.data.data,
    });
  });

export const saveLocationFroTerminal = (terminalId, locationId) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.contact
      .saveLocationFroTerminal(terminalId, locationId)
      .then((res) => resolve(res.data.data))
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${err.response.data.message}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      });
  });

export const deleteContact = (contactId, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .deleteContact(contactId)
      .then((res) => {
        dispatch({
          type: DELETE_CONTACT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('requestAccessFromAdministrator'),
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const getComments = (contactId) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.contact
      .getComment(contactId)
      .then((res) => {
        dispatch({
          type: GET_COMMENT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const createComment = (contactId, text) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.contact
      .createComment(contactId, text)
      .then((res) => {
        dispatch({
          type: CREATE_COMMENT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const editComment = (commentId, text) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.contact
      .editComment(commentId, text)
      .then((res) => {
        dispatch({
          type: EDIT_COMMENT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const deleteComment = (commentId) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.contact
      .deleteComment(commentId)
      .then((res) => {
        dispatch({
          type: DELETE_COMMENT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const uploadContacts = (file, config) => () =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    api.contact
      .uploadContacts(formData, config)
      .then((res) => {
        if (res.data.new.length) {
          store.dispatch(getAllContacts());
        }
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

export const getPlots = (mcc, mnc, lac, cid) => (dispatch) =>
  new Promise((resolve, reject) => {
    const objChecked = {};
    if (mcc !== 'null' && mcc !== '') objChecked.mcc = mcc;
    if (mnc !== 'null' && mnc !== '') objChecked.mnc = mnc;
    if (lac !== 'null' && lac !== '') objChecked.lac = lac;
    if (cid !== 'null' && cid !== '') objChecked.cid = cid;
    return api.contact
      .getPlots(objChecked)
      .then((res) => {
        dispatch({
          type: GET_PLOTS,
          payload: { id: cid, data: res.data?.response?.data },
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

export const locateContactResult = (data) => (dispatch) =>
  dispatch({
    type: LOCATE_CONTACT,
    payload: data,
  });

export const getHeatPoints = (string, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getHeatPoints(string)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err?.message || t('serviceUnavailable'),
            message: t('empty'),
          },
        });
        return reject(err);
      }),
  );
export const searchUnsavedContacts = (page, perPage, searchText) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .searchUnsavedContacts(page, perPage, searchText)
      .then((res) => {
        dispatch({
          type: SEARCH_UNSAVED_CONTACT_RESULT,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      }),
  );

export const addToCancelledList = (id) => (dispatch) => {
  dispatch({
    type: ADD_TO_CANCELLED_LIST,
    payload: id,
  });
};

export const removeFromCancelledList = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CANCELLED_LIST,
    payload: id,
  });
};
