import React from 'react';

import styled, { css } from 'styled-components';

import { Collapse, CollapseProps } from 'antd';

const CollapseComponent = (props: CollapseProps) => <StyledCollapse {...props} />;

const StyledCollapse = styled(Collapse)`
  border: ${({ theme }) => `1px solid ${theme.grayShade9Color}`};
  border-radius: ${({ theme }) => theme.borderRadiusBg};

  .ant-collapse-item {
    border-bottom: 0;

    .ant-collapse-header {
      border-radius: 9px !important;
      background: ${({ theme }) => theme.mainShade7Color};
    }

    .ant-collapse-content {
      border-top: ${({ theme }) => `1px solid ${theme.grayShade9Color}`};

      &-box {
        @media only screen and (max-width: 640px) {
          padding: ${({ theme }) => theme.paddingMd};
        }
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        border-radius: 9px 9px 0 0 !important;
      }
    }
  }

  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      .ant-collapse-item {
        .ant-collapse-header {
          .ant-collapse-header-text {
            color: ${theme.textBlackColor};
          }
        }

        .ant-collapse-content {
          background: ${theme.grayShade9Color};
        }
      }
    `}
`;

export default CollapseComponent;
