import { createGlobalStyle } from 'styled-components';
import { KeysOfDarkTheme } from 'theme/types';
import { css } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ theme: Record<KeysOfDarkTheme, string> }>`

  html {
    background: ${({ theme }) => (theme.isDarkMode ? theme.backgroundLightGrayColor : theme.backgroundWhiteColor)};

  }

  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      ::selection {
        color: initial;
        background: rgba(255, 255, 255, 0.61);
      }
    `}

  .ant-tooltip {
    z-index: 10000;
  }

  // Select Component
  .ant-select {
    .ant-select-selection-search-input {
      background: transparent !important;
    }
  }

  .ant-select-dropdown {
    z-index: 10000;
    background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade6Color : theme.backgroundWhiteColor)};
    box-shadow: ${({ theme }) => theme.boxShadowBase};

    .ant-select-item {
      &:not(.ant-select-item-option-disabled) {
        color: ${({ theme }) => theme.textBlackColor};
          
        &:hover {
          background: ${({ theme }) => (theme.isDarkMode ? theme.grayShade9Color : theme.mainShade7Color)}
        }
          
        &.ant-select-item-option-selected {
          background: ${({ theme }) => (theme.isDarkMode ? theme.grayShade8Color : theme.mainShade6Color)};

          &:hover {
            background: ${({ theme }) => (theme.isDarkMode ? theme.grayShade7Color : theme.mainShade5Color)}
          }
        }
      }
        
      &.ant-select-item-option-disabled {
        .ant-select-item-option-content {
          ${({ theme }) =>
            theme.isDarkMode &&
            css`
              color: ${({ theme }) => theme.grayShade4Color};
            `}
            
        }
      }
    }
  }

  .ant-select-multiple {
    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.textGrayColor}
    }
    
    .ant-select-selection-item-remove:hover {
      color: ${({ theme }) => theme.redColor}
    }

    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .ant-select-selection-item {
          background: ${({ theme }) => theme.grayShade5Color};
        }

        .ant-select-item-option-selected {
          background-color: ${theme.grayShade5Color};
        }

        .ant-select-item-option-selected:hover {
          background-color: ${theme.grayShade3Color};
        }
      `}
  } 

  // PopOver Component
  .ant-popover {
    .ant-popover-inner {
      padding: 0;
      background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade6Color : theme.backgroundWhiteColor)};
    }

    .ant-popover-arrow {
        &:before,
        &:after {
            background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade6Color : theme.backgroundWhiteColor)};
        }
    }

    .ant-popover-title {
      padding: ${({ theme }) => `${theme.paddingMd} ${theme.paddingBg} 0px ${theme.paddingBg}`};
      color: ${({ theme }) => theme.textBlackColor}
    }

    .ant-popconfirm-message {
      color: ${({ theme }) => theme.textBlackColor}

    }
  }

  // Popup Component
  .leaflet-popup {
    .leaflet-popup-content-wrapper {
      background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade6Color : theme.backgroundWhiteColor)};    
    }

    .leaflet-popup-tip {
      background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade6Color : theme.backgroundWhiteColor)};
    }
  }

  // Table Component Pagination
  .ant-pagination {
    .ant-pagination-prev, .ant-pagination-next {
      button {
        color: ${({ theme }) => theme.textBlackColor};
      }
    }

    .ant-pagination-item-active {
      background: ${({ theme }) => theme.backgroundWhiteColor};

      a {
        color: ${({ theme }) => theme.primaryColor} !important;
      }
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
          color: ${({ theme }) => theme.textBlackColor};
        }
      }   
    }

    .ant-pagination-item {
      a {
        color: ${({ theme }) => theme.textBlackColor};
        @media only screen and (max-width: 640px) {
          padding-right: ${({ theme }) => theme.paddingXs};
          padding-left: ${({ theme }) => theme.paddingXs};
        }
      }
    }

    .ant-pagination-options {
      .ant-select-selector {
        border-radius: 5px;
        background: ${({ theme }) => theme.grayShade11Color};
        border-color: ${({ theme }) => theme.lightGrayColor};
      }

      &:hover {
        .ant-select-selector {
          border-color: ${({ theme }) => theme.primaryColor} !important;
        }
      }

      .ant-select-arrow {
        color: ${({ theme }) => theme.textBlackColor};
      }

      .ant-select-selection-item {
        color: ${({ theme }) => theme.textBlackColor} ;
      }
    }
      
    &.ant-pagination-disabled {
      ${({ theme }) =>
        theme.isDarkMode &&
        css`
          .ant-pagination-prev,
          .ant-pagination-next {
            button.ant-pagination-item-link {
              color: ${({ theme }) => theme.grayShade4Color};
            }
          }

          .ant-pagination-item {
            button.ant-pagination-item-link,
            a {
              color: ${({ theme }) => theme.grayShade4Color};
            }
          }

          .ant-pagination-jump-prev,
          .ant-pagination-jump-next {
            .ant-pagination-item-container {
              .ant-pagination-item-ellipsis {
                color: ${({ theme }) => theme.grayShade4Color};
              }
            }
          }

          .ant-pagination-options {
            .ant-select-selector {
              border-color: ${({ theme }) => theme.grayShade4Color};
            }

            .ant-select-arrow {
              color: ${({ theme }) => theme.grayShade4Color};
            }
          }
          .ant-select-selection-item {
            color: ${({ theme }) => theme.grayShade4Color};
          }
        `}
    }
  }

  .ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 30%);
  }

  .ant-dropdown {
    z-index: 10000 !important;
  }

  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      .ant-table-filter-dropdown {
        background: ${theme.mainShade6Color} !important;

        form {
          background: transparent !important;
        }
      }

      .ant-dropdown-menu {
        background: ${theme.mainShade6Color} !important;

        &::-webkit-scrollbar {
          width: 6px;
          background: ${({ theme }) => theme.mainShade6Color};
        }

        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.mainShade5Color};
          cursor: pointer;
        }

        .ant-dropdown-menu-item,
        .ant-dropdown-menu-submenu {
          color: ${theme.textBlackColor} !important;

          .ant-dropdown-menu-submenu-title,
          .ant-dropdown-menu-submenu-expand-icon {
            span {
              color: ${theme.textBlackColor} !important;
            }
          }

          &:hover {
            background: ${theme.mainShade7Color} !important;
          }

          &.ant-dropdown-menu-item-disabled {
            color: ${theme.grayShade4Color} !important;
            background: transparent !important;
          }
        }
      }
    `}
 
  .ant-empty-description {
    color: ${({ theme }) => theme.textBlackColor};
  }

  .ant-table {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .ant-table-filter-column {
          .ant-dropdown-trigger {
            color: ${theme.textBlackColor};

            &:hover {
              color: ${theme.primaryColor};
            }
          }
        }

        .ant-table-row-expand-icon {
          color: ${theme.textBlackColor};
          background: ${theme.mainShade7Color};
        }
      `}
  }

  .ant-table-filter-dropdown {
    border-radius: 5px;
  }

  .ant-dropdown-menu-sub {
    border-radius: 5px;
    overflow: hidden;
  }

  .custom-popover {
    z-index: 10000 !important;

    .ant-popover-inner {
      border-radius: 10px;

      .ant-popover-inner-content {
        padding: 20px;
        border-radius: 20px;
        color: ${({ theme }) => theme.textBlackColor}
      }
    }
  }

  .ant-picker-panel-container {
    border-radius: 10px;
  }

  .ant-dropdown-menu-submenu {
    z-index: 10000 !important;
      
    .ant-dropdown-menu {
      max-height: 400px;
      overflow: auto;
    }
  }

  .ant-notification {
    z-index: 100000;

    .ant-notification-notice {
      border-radius: 10px;
        
      ${({ theme }) =>
        theme.isDarkMode &&
        css`
          color: ${({ theme }) => theme.textBlackColor};
          background: ${({ theme }) => theme.backgroundWhiteColor};
          border: ${`1px solid ${theme.grayShade9Color}`} !important;

          .ant-notification-notice-message {
            color: ${({ theme }) => theme.textBlackColor};
          }
        `}
    }
  }

  .ant-modal .ant-modal-content {

    .ant-modal-body {
      color: ${({ theme }) => theme.textBlackColor};
    }
      
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        color: ${({ theme }) => theme.textBlackColor};
        background-color: ${({ theme }) => theme.backgroundWhiteColor};
        border: ${`1px solid ${theme.grayShade9Color}`} !important;

        .ant-modal-body {
          color: ${({ theme }) => theme.textBlackColor};
        }
      `}
  }
  
  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 10002 !important;
  }

  .ant-modal-content .ant-modal-title {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        color: ${({ theme }) => theme.textBlackColor};
        background-color: ${({ theme }) => theme.backgroundWhiteColor};
      `}
  }
  
  .modal-details-row {
      color: ${({ theme }) => theme.textBlackColor};
      
      .ant-typography {
          color: ${({ theme }) => theme.textBlackColor};
      }
  }
  
  .ant-notification-notice-close {
      color: ${({ theme }) => theme.textGrayColor} !important;
  }

  .ant-dropdown-menu {
    border-radius: 10px;
  }

  .react-tel-input {
    height: 32px !important;

    .form-control {
      height: 100% !important;
    }

    .country-list {
      border-radius: ${({ theme }) => theme.borderRadiusBg} !important;
    }

    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .form-control {
          color: ${theme.textBlackColor};
          background: ${theme.backgroundWhiteColor} !important;
        }

        .flag-dropdown {
          background: ${theme.backgroundLightGrayColor} !important;
        }

        .open,
        .selected-flag,
        &:hover {
          background: ${theme.grayShade4Color};
        }

        .country-list {
          background: ${theme.backgroundLightGrayColor} !important;

          li {
            color: ${theme.textBlackColor};
          }

          .country {
            &:hover {
              background: ${theme.primaryColor} !important;
              color: ${theme.textBlackColor};
            }
          }

          .highlight {
            background-color: ${theme.mainShade7Color};
          }
        }
      `}
  }

  // Transfer Component
  .ant-transfer {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .ant-transfer-list-header {
          background: ${theme.mainShade6Color};
          color: ${theme.textBlackColor};
        }

        .ant-transfer-list-body {
          color: ${theme.textBlackColor};
        }

        .ant-transfer-list-content-item-disabled {
          color: ${theme.grayShade4Color};
        }

        .ant-transfer-operation {
          .ant-btn {
            color: ${theme.textBlackColor};
          }
        }

        .ant-transfer-list-content-item-checked {
          background-color: ${theme.grayShade5Color};
        }

        .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
          background-color: ${theme.grayShade3Color};
        }
      `}
  }

  .ant-transfer {
      .ant-transfer-list {
          min-width: 280px !important;
      }
  }

  // Ant card
  .ant-card {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        background: ${theme.grayShade9Color};
        border: ${`1px solid ${theme.mainShade7Color}`} !important;

        .ant-card-head {
          background: ${theme.grayShade10Color} !important;
        }
      `}
  }

  // Switch Component
  .ant-switch {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        background: ${theme.grayShade7Color};
      `}
  }

  // Radio Button
  .ant-radio-wrapper {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        span {
          color: ${theme.textBlackColor};
        }
      `}
  }

  // Result Component
  .ant-result {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .ant-result-title,
        .ant-result-subtitle {
          color: ${theme.textBlackColor};
        }
      `}
  }


  // Tabs Component
  .ant-tabs {
    .ant-tabs-tab-btn {
      ${({ theme }) =>
        theme.isDarkMode &&
        css`
          color: ${theme.textLightGrayColor};

          &:hover {
            color: ${theme.primaryColor};
          }
        `}
    }
    .ant-tabs-nav {
      overflow: hidden;
      
      .ant-tabs-nav-operations {
        z-index: 1000;
        background: ${({ theme }) => theme.grayShade11Color};
      }
    }
  }

  // Calendar Dropdown Component
  .ant-picker {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .ant-picker-input {
          input {
            color: ${theme.textBlackColor};

            &::placeholder {
              color: ${theme.grayShade7Color};
            }
          }
        }

        .anticon {
          svg {
            path {
              fill: ${({ theme }) => theme.textBlackColor} !important;
            }
          }
        }

        .ant-picker-clear {
          background: ${({ theme }) => theme.backgroundWhiteColor};
        }
      `}
  }

  .ant-picker {
    border-radius: 5px;
    background: ${({ theme }) => theme.backgroundWhiteColor};
    border-color: ${({ theme }) => theme.lightGrayColor};

    &:hover {
      border-color: ${({ theme }) => theme.primaryColor};
    }
  }

  .ant-picker-dropdown {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        .ant-picker-panel-container {
          background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade7Color : theme.backgroundWhiteColor)};
        }

        .ant-picker-range-arrow {
          &:before {
            background: ${({ theme }) => (theme.isDarkMode ? theme.mainShade7Color : theme.backgroundWhiteColor)};
          }
        }

        .ant-picker-header {
          color: ${theme.textBlackColor};
          button {
            color: ${theme.grayShade3Color};
          }
        }

        .ant-picker-content {
          thead {
            tr {
              th {
                color: ${theme.textBlackColor};
              }
            }
          }

          tbody {
            tr {
              .ant-picker-cell-disabled {
                color: ${theme.grayShade1Color};

                &:before {
                  background: ${theme.grayShade9Color};
                }
              }

              .ant-picker-cell {
                color: ${theme.grayShade2Color};
                &.ant-picker-cell-in-view {
                  color: ${theme.textBlackColor};
                }
              }

              .ant-picker-cell-in-range,
              .ant-picker-cell-range-hover-end,
              .ant-picker-cell-range-hover-start,
              .ant-picker-cell-range-hover,
              .ant-picker-cell-range-start,
              .ant-picker-cell-range-end {
                &:before {
                  background: ${theme.grayShade5Color} !important;
                }
              }
            }
          }
        }

        .ant-picker-time-panel-cell {
          .ant-picker-time-panel-cell-inner {
            color: ${theme.textGrayColor} !important;
          }
        }

        .ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            background: ${theme.grayShade11Color} !important;
            color: ${theme.textBlackColor} !important;
          }
        }

        .ant-picker-footer {
          .ant-picker-ok {
            button:disabled {
              background: ${theme.grayShade9Color};
              border-color: ${theme.grayShade4Color};
              color: ${theme.textBlackColor};
              opacity: 0.5;
            }
          }
        }
      `}
  }

  .ant-empty-image {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        svg {
          filter: invert(80%);
        }
      `}
  }
  
  .ant-progress {
    .ant-progress-inner {
      background-color: ${({ theme }) => theme.grayShade8Color} ;
    }
     
      &.ant-progress-show-info {
          .ant-progress-outer {
              margin-inline-end: calc(-2.7em - 8px);
              padding-inline-end: calc(2.7em + 8px);
          }  
      }
 

    .ant-progress-text {
      color: ${({ theme }) => theme.textBlackColor};
      width: 2.7em;
    }
  }
  
  .ant-list {
      .ant-list-item {
          color: ${({ theme }) => theme.textBlackColor};
          border-color: ${({ theme }) => theme.grayShade8Color};
      }
  }

`;

export default GlobalStyle;
