import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';

import { KeysOfColorPalette } from 'theme/types';

import { textStyles } from 'components/text/text';

type KeysOfTextStyles = keyof typeof textStyles;

interface IParagraphProps extends React.ForwardRefExoticComponent<ParagraphProps & React.RefAttributes<HTMLElement>> {}

interface IParagraph extends IParagraphProps {
  variant?: KeysOfTextStyles;
  color?: KeysOfColorPalette;
  hover?: any;
  children?: ReactNode;
}

const { Paragraph: ParagraphAntd } = Typography;

const Paragraph: FC<Omit<IParagraph, '$$typeof'>> = ({
  children,
  variant = 'bodyRegular',
  color = 'textBlackColor',
  hover,
  ...rest
}) => {
  return (
    <StyledParagraph variant={variant} color={color} $customHover={hover} {...rest}>
      {children}
    </StyledParagraph>
  );
};

const StyledParagraph = styled(ParagraphAntd)<{
  variant: KeysOfTextStyles;
  color: KeysOfColorPalette;
  $customHover?: any;
  disabled?: boolean;
}>`
  ${({ variant }) => (variant ? textStyles[variant] : undefined)};
  color: ${({ theme, color }) => (color ? theme[color] : undefined)};

  ${({ $customHover, disabled }) =>
    $customHover &&
    !disabled &&
    css`
      &:hover {
        ${$customHover}
      }
    `}
`;

export default Paragraph;
