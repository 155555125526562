import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Checkbox } from 'antd';

import { ILocation } from 'types/storeTypes';
import { RangePicker, Button, PopOver } from 'components';
import { LeftArrow01Icon, RightArrow01Icon } from 'assets/icons';
import { useMap } from 'react-leaflet';

interface IPlayItemDescription {
  data: ILocation | null;
}

const PlayItemDescription: FC<IPlayItemDescription> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;
  return (
    <div style={{ maxWidth: '240px' }}>
      <div>
        <span style={{ fontWeight: 600 }}>{t('name')}: </span>
        <span>{data.name}</span>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>{t('network')}: </span>
        <span>{data.network}</span>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>
          {t('lat')}/{t('lng')}:{' '}
        </span>
        <span>
          {data.lat}/{data.lng}
        </span>
      </div>
    </div>
  );
};

interface IPlotRouteInterface {
  groupSameLocationsToggle: (isChecked: boolean) => void;
  filterByDateAndTime: (values: [any | null, any | null] | null) => void;
  isGrouped: boolean;
  rangePickerDates: [any, any] | null;
  onPlayStart: () => void;
  onPlayStop: () => void;
  onPlayDirectionAction: (direction: string) => void;
  playItemPosition: number;
  playState: boolean;
  setPlayState: React.Dispatch<React.SetStateAction<boolean>>;
  filteredMarkersCount: number;
  currentActiveMarker: ILocation;
}

const PlotRouteInterface: FC<IPlotRouteInterface> = ({
  groupSameLocationsToggle,
  filterByDateAndTime,
  isGrouped,
  rangePickerDates,
  onPlayStart,
  onPlayStop,
  onPlayDirectionAction,
  playItemPosition,
  playState,
  setPlayState,
  filteredMarkersCount,
  currentActiveMarker,
}) => {
  const { t } = useTranslation();

  const playRoutes = () => {
    setPlayState(true);
    onPlayStart();
  };
  const map = useMap();

  const stopPlayRoutes = () => {
    setPlayState(false);
    onPlayStop();
  };

  const forwardAction = () => onPlayDirectionAction('forward');
  const backwardAction = () => onPlayDirectionAction('backward');

  function on() {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) map.tap.enable();
  }
  function off() {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }
  return (
    <Wrapper onMouseOver={off} onMouseLeave={on}>
      <PlotRouteWrapper>
        <RangePicker
          placeholder={[t('startTime'), t('endTime')]}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as HTMLElement;
          }}
          value={rangePickerDates}
          onChange={filterByDateAndTime}
          showTime
          format="YYYY-MM-DD HH:mm"
          allowEmpty={[true, true]}
        />
        <Checkbox checked={isGrouped} onChange={(e) => groupSameLocationsToggle(e.target.checked)}>
          {t('groupSameLocations')}
        </Checkbox>
      </PlotRouteWrapper>

      <PlotRoutePlayWrapper>
        {playState ? (
          <Button size="small" onClick={stopPlayRoutes}>
            {t('stop')}
          </Button>
        ) : (
          <Button size="small" onClick={playRoutes}>
            {t('playRoutePath')}
          </Button>
        )}
        {playState && (
          <RouteControlsWrapper>
            <Button
              shape="circle"
              size="small"
              disabled={playItemPosition === 0}
              onClick={backwardAction}
              icon={LeftArrow01Icon}
            />
            <Button
              shape="circle"
              size="small"
              disabled={filteredMarkersCount === playItemPosition + 1 || filteredMarkersCount === 0}
              onClick={forwardAction}
              icon={RightArrow01Icon}
            />
            {filteredMarkersCount > 0 && (
              <PopOver content={<PlayItemDescription data={currentActiveMarker} />} title={t('markerInfo')}>
                <PositionNumber>{playItemPosition + 1}</PositionNumber>
              </PopOver>
            )}
          </RouteControlsWrapper>
        )}
      </PlotRoutePlayWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;

  width: fit-content;
  min-width: 586px;

  top: 10px;
  right: 50%;
  transform: translateX(50%);

  background: ${({ theme }) => theme.backgroundWhiteColor};
  padding: ${({ theme }) => theme.paddingBg};
  border-radius: ${({ theme }) => theme.borderRadiusBg};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gapMd};
`;

const PlotRouteWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.gapBg};
`;

const PlotRoutePlayWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.gapBg};
`;

const RouteControlsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapSm};

  align-items: center;
`;

const PositionNumber = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: #92c453;
  cursor: pointer;
`;

export default memo(PlotRouteInterface);
