import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { Tooltip } from 'antd';
import { switchTeam } from 'actions/teams/teams';
import { useAppSelector } from 'hooks/useSelector';
import { useAppDispatch } from 'hooks/useDispatch';
import { Text } from 'components/index';
import { useTheme } from 'theme/useTheme';
import history from 'utils/history';

const TeamSwitch = ({ closePopover }: { closePopover: () => void }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const user = useAppSelector((store) => store.user);
  const teams = user?.teams?.data;
  const currentTeam = user?.team;

  const switchTeamAction = (id: number) => {
    dispatch(switchTeam(id));
  };

  const redirectAction = (e: MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/team/${id}`);
    closePopover();
  };

  if (!teams) return <Text>{t('noTeamsFound')}</Text>;

  return (
    <TeamWrapper>
      <HeaderWrapper>
        <Text variant="subtitleBold">{t('teams')}</Text>
      </HeaderWrapper>
      <ContentWrapper>
        <Team key="team-personal-view" isActive={!currentTeam?.id} onClick={() => switchTeamAction(0)}>
          <Text ellipsis>{t('personalView')}</Text>
        </Team>
        {Object.values(teams).map((team) => (
          <>
            <Tooltip key={`team-${team.id}`} title={team.name} mouseEnterDelay={0.8}>
              <div style={{ width: '100%' }}>
                <Team
                  key={`team-${team.id}`}
                  isActive={currentTeam?.id === team.id}
                  onClick={() => switchTeamAction(team.id)}
                >
                  <Text ellipsis>{team.name}</Text>
                  {user.leader === 1 && (
                    <Text
                      underline
                      variant="bodyRegularSm"
                      style={{ cursor: 'pointer', minWidth: 60, color: theme['greenColor'] }}
                      hover={{ cursor: 'pointer' }}
                      onClick={(e) => redirectAction(e as any, team.id)}
                    >
                      {t('viewTeam')}
                    </Text>
                  )}
                </Team>
              </div>
            </Tooltip>
          </>
        ))}
      </ContentWrapper>
    </TeamWrapper>
  );
};

const TeamWrapper = styled.div`
  width: 300px;
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 10px 24px 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.textLightGrayColor};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-height: 355px;
  height: calc(100% - 52px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.gapSm};
  padding: ${({ theme }) => theme.paddingMd};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`;

const Team = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  width: 100%;
  padding: ${({ theme }) => `${theme.paddingMd}`};
  border-radius: ${({ theme }) => theme.borderRadiusBg};

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
    `}

  &:hover {
    background: ${({ theme }) => theme.mainShade7Color};
  }
`;

export default TeamSwitch;
