import React from 'react';

import { useTheme } from 'styled-components';
import { Popover } from 'antd';

const MenuPopover = ({ children, content, open, onVisibleChange }) => {
  const theme = useTheme();
  return (
    <Popover
      overlayClassName="menu-popover"
      content={content}
      trigger="click"
      placement="right"
      overlayInnerStyle={{ overflow: 'hidden', lineHeight: '0px' }}
      popupAlign={{ offset: [19] }}
      destroyTooltipOnHide
      open={open}
      onOpenChange={onVisibleChange}
    >
      {children}
    </Popover>
  );
};

MenuPopover.defaultProps = {
  open: undefined,
  onVisibleChange: undefined,
};

export default MenuPopover;
