import React, { FC } from 'react';

import ExpandedContent from 'components/menu/expandedContent';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';

import MenuStyles from 'components/menu/menuStyles';
import { useTheme } from 'theme/useTheme';
import IStore from 'types/storeTypes';

const { MenuItemWrapper, MinimizedContent, NameText, Avatar, NameWrapper, EmailText, AvatarText, ArrowIconStyled } =
  MenuStyles;

interface IUserInfoItem {
  isExpanded: boolean;
  toggle: () => void;
  user: IStore['user'];
}

const UserInfoItem: FC<IUserInfoItem> = ({ isExpanded, toggle, user }) => {
  const theme = useTheme();
  return (
    <MenuItemWrapper marginBottom={theme['marginX1']} disableSelection>
      <MinimizedContent>
        <Avatar onClick={toggle}>
          <AvatarText>{user.username.slice(0, 2).toUpperCase()}</AvatarText>
        </Avatar>
      </MinimizedContent>
      <ExpandedContent isExpanded={isExpanded}>
        <NameWrapper>
          <NameText>{user.username}</NameText>
          <EmailText>{user.email}</EmailText>
        </NameWrapper>
      </ExpandedContent>
      <ArrowIconStyled
        icon={isExpanded ? ArrowLeftIcon : ArrowRightIcon}
        color="mainShade2Color"
        size={24}
        $isExpanded={isExpanded}
        onClick={toggle}
      />
    </MenuItemWrapper>
  );
};

export default UserInfoItem;
