import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import L, { PointExpression } from 'leaflet';
import { Circle, Popup } from 'react-leaflet';
import styled from 'styled-components';

import Marker from 'components/map/marker';

// @ts-ignore
import markerBlackNone from 'assets/markers/marker-black-none.png';
// @ts-ignore
import markerRed from 'assets/markers/marker-red.png';
// @ts-ignore
import markerRedNone from 'assets/markers/marker-red-none.png';
// @ts-ignore
import markerBlue from 'assets/markers/marker-blue.png';
// @ts-ignore
import markerBlueNone from 'assets/markers/marker-blue-none.png';
// @ts-ignore
import markerPurple from 'assets/markers/marker-purple.png';
// @ts-ignore
import markerPurpleNone from 'assets/markers/marker-purple-none.png';
// @ts-ignore
import markerYellow from 'assets/markers/marker-yellow.png';
// @ts-ignore
import markerYellowNone from 'assets/markers/marker-yellow-none.png';
// @ts-ignore
import markerBlack from 'assets/markers/marker-black.png';

import { ICustomLeafletMap, OFFSET_PADDING, PlotResponse } from 'components/map/map';

const CIRCLE_COLORS = ['red', 'blue', 'yellow', 'black', 'purple'];
const getMarker = (color: string, lacf: boolean) => {
  const myIcon = L.icon({
    iconUrl: markerBlackNone,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
  });

  if (color === 'red') {
    if (lacf) myIcon.options.iconUrl = markerRed;
    else myIcon.options.iconUrl = markerRedNone;
  } else if (color === 'blue') {
    if (lacf) myIcon.options.iconUrl = markerBlue;
    else myIcon.options.iconUrl = markerBlueNone;
  } else if (color === 'purple') {
    if (lacf) myIcon.options.iconUrl = markerPurple;
    else myIcon.options.iconUrl = markerPurpleNone;
  } else if (color === 'black') {
    if (lacf) myIcon.options.iconUrl = markerPurple;
    else myIcon.options.iconUrl = markerPurpleNone;
  } else if (color === 'yellow') {
    if (lacf) myIcon.options.iconUrl = markerYellow;
    else myIcon.options.iconUrl = markerYellowNone;
  } else if (color === 'black') {
    if (lacf) myIcon.options.iconUrl = markerBlack;
  }

  return myIcon;
};
const PlotsView: FC<{
  map: ICustomLeafletMap;
  plotMarkers?: PlotResponse;
  customWithOffset?: { paddingTopLeft: PointExpression; paddingBottomRight: PointExpression };
  withOffset?: boolean;
}> = ({ map, plotMarkers, customWithOffset, withOffset }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (plotMarkers?.response?.data.object.length) {
      const locationsGroup: L.LatLngBounds[] = [];
      plotMarkers?.response?.data.object.forEach(({ accuracy, location: { lat, lng } }) => {
        if (lat && lng) {
          const circle = L.circle([lat, lng], accuracy);
          // Adding then removing the circle from the map due to some bug
          circle.addTo(map);
          locationsGroup.push(circle.getBounds());
          circle.removeFrom(map);
        }
      });

      if (locationsGroup.length)
        map.fitBounds(
          locationsGroup as any,
          customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined,
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotMarkers]);

  return plotMarkers?.response?.data?.object?.length ? (
    <>
      {plotMarkers.response.data.object.map(({ location, accuracy, lacf }, index) => {
        return (
          <div key={`plot-index-${index}`}>
            <Marker
              position={[location.lat as number, location.lng as number]}
              icon={getMarker(CIRCLE_COLORS[index], lacf)}
            >
              <StyledPopup>
                <div>
                  <div>
                    {t('option')}-{index + 1}
                  </div>
                  <div style={{ fontWeight: 600 }}>Lacf: {`${lacf}`}</div>
                </div>
              </StyledPopup>
            </Marker>
            <Circle
              center={[location.lat as number, location.lng as number]}
              pathOptions={{ color: CIRCLE_COLORS[index], weight: 1 }}
              radius={accuracy}
            />
          </div>
        );
      })}
    </>
  ) : null;
};

const StyledPopup = styled(Popup)`
  span {
    font-weight: 600;
  }
`;

export default PlotsView;
