import { LOGOUT, SINGLE_SEARCH } from 'constants/actionTypes';

export default function users(state = {}, action = {}) {
  switch (action.type) {
    case SINGLE_SEARCH:
      return {
        ...action.payload,
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
