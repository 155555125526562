import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimatePresence, motion } from 'framer-motion';

import { useTheme } from 'theme/useTheme';
import { Icon, Text } from 'components/index';
import { CardIcon } from 'assets/icons';
import { Tooltip } from 'antd';

import ExpandedContent from 'components/menu/expandedContent';
import MenuStyles from 'components/menu/menuStyles';
import { useAppSelector } from 'hooks/useSelector';
import MenuPopover from 'components/menu/menuPopover';
import PopUpCreditInfo from 'components/menu/creditsItem/popUpCreditInfo';
import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER } from 'constants/userRoleType';

const { MiniLineBreak, MenuItemWrapper, MinimizedContent, NameText, CreditWrapper } = MenuStyles;

const CREDITS_UNAVAILABLE_TEXT = 'Credits unavailable';

const MenuCreditsItem = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const user = useAppSelector((store) => store.user);

  if (!user) return null;

  const { day_credit_row, allocated_credits, daily_credits } = user;

  const getUserCredits = () => {
    if (day_credit_row) {
      return (
        <div
          style={{ display: 'flex', flexDirection: isExpanded ? 'row' : 'column', gap: isExpanded ? theme.gapXs : 0 }}
        >
          <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
            {day_credit_row?.used_credits ?? '∞'}
          </Text>
          {!isExpanded && <MiniLineBreak />}
          {isExpanded && (
            <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
              /
            </Text>
          )}
          <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
            {allocated_credits ?? '∞'}
          </Text>
          {!isExpanded && <MiniLineBreak />}
          {isExpanded && (
            <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
              /
            </Text>
          )}
          <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
            {daily_credits ?? '∞'}
          </Text>
        </div>
      );
    }

    return CREDITS_UNAVAILABLE_TEXT;
  };

  const getAdminCredits = () => {
    if (day_credit_row) {
      return (
        <div
          style={{ display: 'flex', flexDirection: isExpanded ? 'row' : 'column', gap: isExpanded ? theme.gapXs : 0 }}
        >
          <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
            {day_credit_row?.used_credits ?? '∞'}
          </Text>
          {!isExpanded && <MiniLineBreak />}
          {isExpanded && (
            <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
              /
            </Text>
          )}
          <Text variant="bodyLightSx" style={{ textAlign: 'center' }}>
            {allocated_credits ?? '∞'}
          </Text>
        </div>
      );
    }

    return CREDITS_UNAVAILABLE_TEXT;
  };

  const getAvailableCredits = () => {
    if (user.role.name === ROLE_USER) return getUserCredits();
    else if (user.role.name === ROLE_ADMIN || user.role.name === ROLE_SUPER_ADMIN) return getAdminCredits();

    return CREDITS_UNAVAILABLE_TEXT;
  };

  return (
    <MenuPopover content={<PopUpCreditInfo />}>
      <MenuItemWrapper
        marginBottom={theme['marginMd']}
        style={{ height: '48px', cursor: 'pointer' }}
        disableSelection={!isExpanded}
      >
        <AnimatePresence initial={false}>
          {isExpanded ? (
            <motion.div
              key="credits-1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ display: 'flex', alignItems: 'center' }}
              transition={{ duration: 0.15 }}
            >
              <MinimizedContent>
                <Icon color="darkGrayColor" icon={CardIcon} />
              </MinimizedContent>
              <ExpandedContent isExpanded={isExpanded}>
                <NameText style={{ lineHeight: '24px' }}>{t('credits')}</NameText>
                <CreditWrapper>
                  <Text variant="bodyRegularSm">{getAvailableCredits()}</Text>
                </CreditWrapper>
              </ExpandedContent>
            </motion.div>
          ) : (
            <motion.div
              key="credits-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ position: 'absolute' }}
              transition={{ duration: 0.15 }}
            >
              <MinimizedContent>
                <Tooltip placement="right" title={t('credits')} mouseEnterDelay={0.5}>
                  <CreditWrapper>
                    <Text variant="bodyRegularSx">{getAvailableCredits()}</Text>
                  </CreditWrapper>
                </Tooltip>
              </MinimizedContent>
            </motion.div>
          )}
        </AnimatePresence>
      </MenuItemWrapper>
    </MenuPopover>
  );
};

export default MenuCreditsItem;
