import {
  GET_ALL_CONTACTS,
  GET_CONTACT_INFO,
  ERASE_CONTACT_INFO,
  SET_PRIMARY,
  SAVE_TERMINAL,
  SAVE_CONTACT,
  ADD_TERMINAL,
  ARCHIVE_TERMINAL,
  DELETE_TERMINALS,
  CREATE_CONTACT,
  LOCATE_CONTACT,
  DELETE_CONTACT,
  GET_COMMENT,
  CREATE_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  GET_PLOTS,
  SEARCH_CONTACT_RESULT,
  UPDATE_JOB_PERCENTAGE_PROGRESS,
  ADD_TO_CANCELLED_LIST,
  REMOVE_FROM_CANCELLED_LIST,
  LOGOUT,
} from 'constants/actionTypes';
import initialState from './initState';

export default function contact(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_CONTACTS:
      return {
        ...state,
        allContacts: {
          ...state.allContacts,
          contacts: action.payload,
        },
      };
    case CREATE_CONTACT:
      return {
        ...state,
        allContacts: {
          ...state.allContacts,
          contacts: {
            ...state.allContacts.contacts,
            [action.payload.id]: action.payload,
          },
        },
      };
    case GET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: {
          ...action.payload,
        },
      };
    case SEARCH_CONTACT_RESULT:
      return {
        ...state,
        allContacts: action.payload,
      };
    case ERASE_CONTACT_INFO:
      return {
        ...state,
        contactInfo: {},
      };
    case SET_PRIMARY:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminals: action.payload,
        },
      };
    case SAVE_TERMINAL:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminals: action.payload,
        },
      };
    case ADD_TERMINAL:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminals: action.payload,
        },
      };
    case SAVE_CONTACT:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ...action.payload,
        },
      };
    case ARCHIVE_TERMINAL:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminals: action.payload,
        },
      };
    case DELETE_TERMINALS:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminals: action.payload,
        },
      };
    case LOCATE_CONTACT: {
      const location = action.payload.locations ? action.payload.locations : Object.values(action.payload);
      const locationId = Object.values(location)[0].id;
      // if search was done at least once
      if (state.contactInfo && state.contactInfo.locations.all && state.contactInfo.locations.locationsGroup.manual) {
        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            locations: {
              ...state.contactInfo.locations,
              all: {
                ...state.contactInfo.locations.all,
                [locationId]: {
                  ...state.contactInfo.locations.all[locationId],
                  ...location[locationId],
                },
              },
              locationsGroup: {
                ...state.contactInfo.locations.locationsGroup,
                all: {
                  ...state.contactInfo.locations.locationsGroup.all,
                  [locationId]: {
                    ...state.contactInfo.locations.locationsGroup.all[locationId],
                    ...location[locationId],
                  },
                },
                manual: {
                  ...state.contactInfo.locations.locationsGroup.manual,
                  [locationId]: {
                    ...state.contactInfo.locations.locationsGroup.manual[locationId],
                    ...location[locationId],
                  },
                },
              },
            },
          },
        };
        // if search is done for the first time
      } else if (state.contactInfo && state.contactInfo.locations && state.contactInfo.locations.locationsGroup) {
        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            locations: {
              ...state.contactInfo.locations,
              all: {
                ...state.contactInfo.locations.all,
                [locationId]: {
                  ...location[locationId],
                },
              },
              locationsGroup: {
                ...state.contactInfo.locations.locationsGroup,
                all: {
                  ...state.contactInfo.locations.locationsGroup.all,
                  [locationId]: {
                    ...location[locationId],
                  },
                },
                manual: {
                  ...state.contactInfo.locations.locationsGroup.manual,
                  [locationId]: {
                    ...location[locationId],
                  },
                },
              },
            },
          },
        };
      }

      return state;
    }
    case UPDATE_JOB_PERCENTAGE_PROGRESS: {
      if (
        state.contactInfo &&
        state.contactInfo.locations &&
        state.contactInfo.locations.all &&
        state.contactInfo.locations.all[action.payload.locationId] &&
        state.contactInfo.locations.locationsGroup &&
        state.contactInfo.locations.locationsGroup.manual[action.payload.locationId]
      ) {
        return {
          ...state,
          contactInfo: {
            ...state.contactInfo,
            locations: {
              ...state.contactInfo.locations,
              all: {
                ...state.contactInfo.locations.all,
                [action.payload.locationId]: {
                  ...state.contactInfo.locations.all[action.payload.locationId],
                  ...action.payload,
                },
              },
              locationsGroup: {
                ...state.contactInfo.locations.locationsGroup,
                manual: {
                  ...state.contactInfo.locations.locationsGroup.manual,
                  [action.payload.locationId]: {
                    ...state.contactInfo.locations.locationsGroup.manual[action.payload.locationId],
                    ...action.payload,
                  },
                },
              },
            },
          },
        };
      }

      return state;
    }
    case DELETE_CONTACT: {
      const newContacts = state.allContacts.contacts;
      delete newContacts[action.payload.id];
      return {
        ...state,
        allContacts: {
          ...state.allContacts,
          contacts: newContacts,
        },
      };
    }
    case GET_COMMENT:
      return {
        ...state,
        comments: action.payload,
      };
    case CREATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.id]: action.payload,
        },
      };
    case EDIT_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.id]: action.payload,
        },
      };
    case DELETE_COMMENT: {
      const obj = state.comments;
      delete obj[action.payload.id];
      return {
        ...state,
        comments: { ...obj },
      };
    }
    case GET_PLOTS: {
      return {
        ...state,
        plotsLocations: {
          ...state.plotsLocations,
          [action.payload.id]: { ...action.payload.data },
        },
      };
    }
    case ADD_TO_CANCELLED_LIST: {
      return {
        ...state,
        cancelledList: [...(state.cancelledList || []), action.payload],
      };
    }
    case REMOVE_FROM_CANCELLED_LIST: {
      return {
        ...state,
        cancelledList: (state.cancelledList || []).filter((item) => item !== action.payload),
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
