import React from 'react';
import styled from 'styled-components';

// We can't use theme here because map is rendered outside ThemeProvider
const CustomMarker = ({ markerColor }: { markerColor: string }) => {
  return (
    <Circle color={markerColor}>
      <SmallInnerCircle />
    </Circle>
  );
};

const SmallInnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  width: 5px;
  height: 5px;

  background: white;
  border-radius: 50%;
  z-index: 1;
`;

const Circle = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;

  border-radius: 50%;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    background: ${({ color }) => color};
    animation: pulse-ring 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: ${({ color }) => color};
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.3);
    }
    80%,
    100% {
      opacity: 0;
      transform: scale(0.7);
    }
  }
`;

export default CustomMarker;
