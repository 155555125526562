import React, { MouseEvent, FC, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Tooltip } from 'antd';

import { Icon, Text, Flag, Button, PopOver } from 'components';

import {
  DirectionDownIcon,
  DirectionUpIcon,
  LoadingBulkIcon,
  MailIcon,
  MapLocationIcon,
  ProfileIcon,
  StopIcon,
} from 'assets/icons';

import { ILocation } from 'types/storeTypes';
import getCountryName from 'utils/getCountryName';
import ApplyUserTimezone from 'utils/convertToUserTimezone';
import getCountryMcc from 'utils/getCountryMcc';

import { useTheme } from 'theme/useTheme';
import SmsAgent from 'components/location/smsAgent';
import { useAppSelector } from 'hooks/useSelector';
import { useAppDispatch } from 'hooks/useDispatch';
import { cancelJobSearch } from 'actions/jobs/jobs';
import { addToCancelledList } from 'actions/contacts/contact';
import { allAllowedSearches } from 'constants/searchTypes';
// @ts-ignore
import lte from 'assets/images/lte.png';

interface IAccordion {
  id: number | string;
  header: JSX.Element;
  body: JSX.Element;
  withSpace?: boolean;
  terminalColor?: string;
  onExpand?: (status: boolean) => void;
}

interface StyledIconProps {
  spin?: boolean;
}

const Accordion: FC<IAccordion> = ({ id, header, body, withSpace, terminalColor, onExpand }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const _onExpand = () => {
    setIsExpanded(!isExpanded);
    onExpand?.(!isExpanded);
  };

  const getLocation = () => (
    <LocationWrapper key={id} transition={{ duration: 5 }}>
      <TerminalColorIdentifier bg={terminalColor} />
      <LocationHeader onClick={_onExpand}>
        <Icon
          color="textGrayColor"
          transparentFillSvg
          icon={isExpanded ? DirectionUpIcon : DirectionDownIcon}
          onClick={_onExpand}
          hover={{ color: theme.primaryColor }}
        />
        <div style={{ display: 'flex', flexGrow: 1 }}>{header}</div>
      </LocationHeader>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            key={id}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}
          >
            <LocationBody>{body}</LocationBody>
          </motion.div>
        )}
      </AnimatePresence>
    </LocationWrapper>
  );

  if (withSpace)
    return (
      <div>
        {getLocation()}
        <div style={{ height: '10px' }} />
      </div>
    );

  return getLocation();
};

interface ILocationComponent {
  data?: ILocation;
  size?: 'small' | 'default';
  onLocationPin: (location: ILocation) => void;
  terminalColor?: string;
  contactLabelColor?: string;
}

const LocationComponent: FC<ILocationComponent> = ({
  data,
  size = 'default',
  onLocationPin,
  terminalColor,
  contactLabelColor,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const smsService = useAppSelector((store) => store.user.sms_service);
  const cancelledList = useAppSelector((store) => store.contact.cancelledList);
  const contactsList = useAppSelector((store) => store.groups?.singleGroup?.contacts);
  const allAvailableContacts = useAppSelector((store) => store.contact?.allContacts?.contacts);

  const [isSmsPopoverOpen, setIsSmsPopoverOpen] = useState(false);

  const pinAction = (e: MouseEvent<HTMLAnchorElement & HTMLButtonElement>) => {
    e.stopPropagation();
    if (data) onLocationPin(data);
  };

  const pinOnExpand = (status: boolean) => {
    if (data && data.lat && data.lng && status) onLocationPin(data);
  };

  const handleCancelJobSearch = (e: MouseEvent, id: number) => {
    e.stopPropagation();
    dispatch(addToCancelledList(id));
    dispatch(cancelJobSearch(id, 'contact'));
  };

  if (!data) return null;

  const getBody = () => {
    if (data.additional_info?.error)
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('code')}:
                </Text>
                <Text color="redColor" variant="bodyRegularSm">
                  {data.additional_info?.error.code}
                </Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('title')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.error.title}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('detail')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.error.detail}</Text>
              </ColumnItem>
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );

    if (data.resolved === 1 && (data.code || data.detail || data.title))
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('code')}:
                </Text>
                <Text color="redColor" variant="bodyRegularSm">
                  {data.code}
                </Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('title')}:
                </Text>
                <Text variant="bodyRegularSm">{data.title}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('detail')}:
                </Text>
                <Text variant="bodyRegularSm">{data.detail}</Text>
              </ColumnItem>
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );

    if (data.additional_info?.data?.numberOwnership && !data.additional_info?.data?.numberOwnership.errorCode)
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('country')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.numberOwnership.country}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkBrand')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.numberOwnership.networkBrand}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkName')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.numberOwnership.networkName}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkId')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.numberOwnership.networkId}</Text>
              </ColumnItem>
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );

    if (data.additional_info?.data?.numberOwnership && data.additional_info?.data?.numberOwnership.errorCode) {
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('code')}:
                </Text>
                <Text color="redColor" variant="bodyRegularSm">
                  {data.additional_info?.data?.numberOwnership.errorCode}
                </Text>
              </ColumnItem>
              {data?.additional_info?.data?.numberOwnership?.errorDetail ? (
                <ColumnItem>
                  <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                    {t('detail')}:
                  </Text>
                  <Text color="redColor" variant="bodyRegularSm">
                    {data.additional_info?.data?.numberOwnership.errorDetail}
                  </Text>
                </ColumnItem>
              ) : null}
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );
    }

    if (data.additional_info?.data?.subscriptionWith && !data.additional_info?.data?.subscriptionWith.errorCode)
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('country')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.subscriptionWith.country}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkBrand')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.subscriptionWith.networkBrand}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkName')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.subscriptionWith.networkName}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkId')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.subscriptionWith.networkId}</Text>
              </ColumnItem>
              {data.additional_info?.data?.subscriptionWith.plmnId ? (
                <ColumnItem>
                  <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                    PlmnId:
                  </Text>
                  <Text variant="bodyRegularSm">
                    mnc: {data.additional_info?.data?.subscriptionWith.plmnId.mnc}, mcc:{' '}
                    {data.additional_info?.data?.subscriptionWith.plmnId.mcc}
                  </Text>
                </ColumnItem>
              ) : null}
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );

    if (data.additional_info?.data?.subscriptionWith && data.additional_info?.data?.subscriptionWith.errorCode) {
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('code')}:
                </Text>
                <Text color="redColor" variant="bodyRegularSm">
                  {data.additional_info?.data?.subscriptionWith.errorCode}
                </Text>
              </ColumnItem>
              {data?.additional_info?.data?.subscriptionWith?.errorDetail ? (
                <ColumnItem>
                  <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                    {t('detail')}:
                  </Text>
                  <Text color="redColor" variant="bodyRegularSm">
                    {data.additional_info?.data?.subscriptionWith.errorDetail}
                  </Text>
                </ColumnItem>
              ) : null}
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );
    }

    if (data.additional_info?.data?.plmnNetwork && !data.additional_info?.data?.plmnNetwork.errorCode)
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('country')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.plmnNetwork.country}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkBrand')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.plmnNetwork.networkBrand}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkName')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.plmnNetwork.networkName}</Text>
              </ColumnItem>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('networkId')}:
                </Text>
                <Text variant="bodyRegularSm">{data.additional_info?.data?.plmnNetwork.networkId}</Text>
              </ColumnItem>
              {data.additional_info?.data?.plmnNetwork.plmnId ? (
                <ColumnItem>
                  <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                    PlmnId:
                  </Text>
                  <Text variant="bodyRegularSm">
                    mnc: {data.additional_info?.data?.plmnNetwork.plmnId.mnc}, mcc:{' '}
                    {data.additional_info?.data?.plmnNetwork.plmnId.mcc}
                  </Text>
                </ColumnItem>
              ) : null}
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );

    if (data.additional_info?.data?.plmnNetwork && data.additional_info?.data?.plmnNetwork.errorCode) {
      return (
        <BodyWrapper>
          <ColumnsWrapper>
            <Column>
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('code')}:
                </Text>
                <Text color="redColor" variant="bodyRegularSm">
                  {data.additional_info?.data?.plmnNetwork.errorCode}
                </Text>
              </ColumnItem>
              {data?.additional_info?.data?.plmnNetwork?.errorDetail ? (
                <ColumnItem>
                  <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                    {t('detail')}:
                  </Text>
                  <Text color="redColor" variant="bodyRegularSm">
                    {data.additional_info?.data?.plmnNetwork.errorDetail}
                  </Text>
                </ColumnItem>
              ) : null}
            </Column>
          </ColumnsWrapper>
        </BodyWrapper>
      );
    }

    return (
      <BodyWrapper>
        <ColumnsWrapper>
          <Column>
            {data.mcc ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('mcc')}:
                </Text>
                <Text variant="bodyRegularSm">{data.mcc}</Text>
              </ColumnItem>
            ) : null}
            {data.mcc ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('mnc')}:
                </Text>
                <Text variant="bodyRegularSm">{data.mnc}</Text>
              </ColumnItem>
            ) : null}
            {data.lac ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('lac')}/{t('tac')}:
                </Text>
                <Text variant="bodyRegularSm">{data.lac}</Text>
              </ColumnItem>
            ) : null}
            {data.cell_id ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  CELLID:
                </Text>
                <Text variant="bodyRegularSm">{data.cell_id}</Text>
              </ColumnItem>
            ) : null}
            {data.cell_id ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('lat')}/{t('lng')}:
                </Text>
                <Text variant="bodyRegularSm">
                  {data.lat}/{data.lng}
                </Text>
              </ColumnItem>
            ) : null}
            {data.angle && data.width ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('azimuth')}:
                </Text>
                <Text variant="bodyRegularSm">
                  {data.angle !== 360 && (
                    <div>
                      <span>{data.angle}&#176;</span> <span>({data.width}&#176;)</span>
                    </div>
                  )}
                </Text>
              </ColumnItem>
            ) : null}
            {data.range ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('range')}:
                </Text>
                <Text variant="bodyRegularSm">{data.range} (m)</Text>
              </ColumnItem>
            ) : null}
            <ColumnItem>
              <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                {t('age')}:
              </Text>
              <Text variant="bodyRegularSm">{data.age || 0}</Text>
              <Text variant="bodyRegularSm" color="textGrayColor">
                ({t('min')})
              </Text>
              <Text variant="bodyRegularSm">
                {t('at')} <ApplyUserTimezone date={data.updated_at} />
              </Text>
              {(data.age === 0 || data.age === 1) && <BlinkDot />}
            </ColumnItem>
          </Column>
          <Column>
            <ColumnItem>
              <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                IMEI:
              </Text>
              <Text variant="bodyRegularSm">{data.imei || t('noData')}</Text>
            </ColumnItem>
            {data.state ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('state')}:
                </Text>
                <Text variant="bodyRegularSm">{data.state}</Text>
              </ColumnItem>
            ) : null}
            {data.phone ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('phone')}:
                </Text>
                <Text variant="bodyRegularSm">{data.phone}</Text>
              </ColumnItem>
            ) : null}
            {data.imsi ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('imsi')}:
                </Text>
                <Text variant="bodyRegularSm">{data.imsi}</Text>
              </ColumnItem>
            ) : null}
            <ColumnItem>
              <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                {t('startTime')}:
              </Text>
              <Text variant="bodyRegularSm">
                <ApplyUserTimezone date={data.interval?.start} />
              </Text>
            </ColumnItem>
            <ColumnItem>
              <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                {t('endTime')}:
              </Text>
              <Text variant="bodyRegularSm">
                <ApplyUserTimezone date={data.interval?.end} />
              </Text>
            </ColumnItem>
            <ColumnItem>
              <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                {t('locations')}:
              </Text>
              <Text variant="bodyRegularSm">
                {data?.interval?.location_ids ? Object.values(data.interval.location_ids)?.length : t('noLocations')}
              </Text>
            </ColumnItem>
            {data.network ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('network')}:
                </Text>
                <Text variant="bodyRegularSm">{data.network}</Text>
              </ColumnItem>
            ) : null}
            {data.brand_name ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('brandName')}:
                </Text>
                <Text variant="bodyRegularSm">{data.brand_name}</Text>
              </ColumnItem>
            ) : null}
            {data.model_name ? (
              <ColumnItem>
                <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                  {t('modelName')}:
                </Text>
                <Text variant="bodyRegularSm">{data.model_name}</Text>
              </ColumnItem>
            ) : null}
          </Column>
        </ColumnsWrapper>
        {data.name ? (
          <ColumnItem style={{ gap: theme.gapSm }}>
            <Text style={{ whiteSpace: 'nowrap' }} variant="bodyRegularSm" color="textGrayColor">
              {t('locationName')}:
            </Text>
            <Text variant="bodyRegularSm">{data.name}</Text>
          </ColumnItem>
        ) : null}
      </BodyWrapper>
    );
  };

  const getName = () => {
    if (data.additional_info?.error) {
      return (
        <Text
          ellipsis
          style={{
            width: '100%',
            marginBottom: 4,
            whiteSpace: 'break-spaces',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
          }}
          color="redColor"
          variant="bodyBold"
        >
          {t('errorCode')}: {data.additional_info.error.code} - {data.title}
        </Text>
      );
    }

    if (data.resolved === 1 && (data.code || data.detail || data.title)) {
      return (
        <Text
          ellipsis
          style={{
            width: '100%',
            marginBottom: 4,
            whiteSpace: 'break-spaces',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
          }}
          color="redColor"
          variant="bodyBold"
        >
          {t('errorCode')}: {data.code} - {data.title}
        </Text>
      );
    }

    return (
      <Text
        ellipsis
        style={{
          width: '100%',
          marginBottom: 4,
          whiteSpace: 'break-spaces',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
        }}
        variant="bodyBold"
      >
        {data.additional_info?.data?.numberOwnership?.networkName ||
          data.additional_info?.data?.subscriptionWith?.networkName ||
          data.additional_info?.data?.numberOwnership?.networkName ||
          data.additional_info?.data?.plmnNetwork?.networkName ||
          data.name}
      </Text>
    );
  };

  const locationName =
    data.additional_info?.data?.numberOwnership?.networkName ||
    data.additional_info?.data?.subscriptionWith?.networkName ||
    data.additional_info?.data?.numberOwnership?.networkName ||
    data.name;

  // For the moment 05.02.2025 is used for RobotInfo only
  if (size === 'small')
    return (
      <Accordion
        id={data.id}
        onExpand={pinOnExpand}
        header={
          <HeaderWrapper>
            <InfoNameWrapper>
              {data.interval ? (
                <span style={{ position: 'absolute', left: '-10px', top: '6px' }}>
                  <div style={{ width: 5, height: 5, backgroundColor: '#d48f0e', borderRadius: '50%' }} />
                </span>
              ) : null}
              {data.additional_info?.error ? (
                <Text color="redColor">
                  {t('errorCode')}: {data.additional_info.error.code} - {data.title}
                </Text>
              ) : (
                <Tooltip placement="top" title={locationName} mouseEnterDelay={0.7}>
                  <Text ellipsis variant="bodyBold">
                    {locationName}
                  </Text>
                </Tooltip>
              )}
              <InfoLocationAndDate>
                <Tooltip
                  placement="right"
                  title={
                    (allAvailableContacts &&
                      Object.values(allAvailableContacts || {}).find((contact) => contact.id === data?.contact_id)
                        ?.name) ||
                    t('unknown')
                  }
                >
                  <ContactNameSmallBullet $bg={contactLabelColor}>
                    <Icon size={12} color="textWhiteColor" icon={ProfileIcon} />
                  </ContactNameSmallBullet>
                </Tooltip>
                <VerticalLine />
                {data?.country_iso ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: theme.gapSm }}>
                    <Text color="textGrayColor" variant="bodyRegularSm">
                      {getCountryName(data?.country_iso)}
                    </Text>
                    <Flag code={data?.country_iso ? getCountryMcc(data.country_iso) : ''} />
                  </div>
                ) : (
                  <Text color="textGrayColor" variant="bodyRegularSm">
                    {t('noCountry')}
                  </Text>
                )}
                <VerticalLine />
                <Text color="textGrayColor" variant="bodyRegularSm">
                  <ApplyUserTimezone date={data.updated_at} />
                </Text>
              </InfoLocationAndDate>
            </InfoNameWrapper>

            <ActionsWrapper onClick={(e) => e.stopPropagation()}>
              <Button
                transparentFillSvg
                icon={MapLocationIcon}
                shape="circle"
                onClick={pinAction}
                disabled={!data.lat || !data.lng}
              />
              <PopOver
                placement="topLeft"
                title={smsService ? `${t('selectAgent')}` : `${t('smsDisabled')}`}
                trigger="click"
                content={<SmsAgent data={data} closePopover={() => setIsSmsPopoverOpen(false)} />}
                open={isSmsPopoverOpen}
                onOpenChange={(state) => setIsSmsPopoverOpen(state)}
                getPopupContainer={() => {
                  const parent = document.querySelector('#locations-list-layout');
                  return parent instanceof HTMLElement ? parent : document.body;
                }}
              >
                <Button transparentFillSvg icon={MailIcon} shape="circle" disabled={!data.lat || !data.lng} />
              </PopOver>
            </ActionsWrapper>
          </HeaderWrapper>
        }
        body={getBody()}
        terminalColor={terminalColor}
      />
    );

  return (
    <Accordion
      withSpace
      id={data.id}
      onExpand={pinOnExpand}
      header={
        <div style={{ display: 'flex', alignItems: 'center', gap: theme.gapSm, width: '100%' }}>
          <div style={{ width: '100%' }}>
            {data.interval ? (
              <span style={{ position: 'absolute', left: '8px', top: '8px' }}>
                <div style={{ width: 5, height: 5, backgroundColor: '#d48f0e', borderRadius: '50%' }} />
              </span>
            ) : null}
            <HeaderWrapper>{getName()}</HeaderWrapper>
            <HeaderDetailsWrapper>
              <div style={{ display: 'flex', alignItems: 'center', gap: theme.gapSm }}>
                <Text color="textGrayColor" variant="bodyRegularSm">
                  {t(allAllowedSearches?.[data?.api_request_type]) || null}
                </Text>
                {data?.serving_tech === 'EUTRAN' && <StyledLte src={lte} alt="lte" />}
              </div>
              <VerticalLine />
              {data?.country_iso ? (
                <CountryWrapper>
                  <Text color="textGrayColor" variant="bodyRegularSm">
                    {getCountryName(data?.country_iso)}
                  </Text>
                  <Flag code={data?.country_iso ? getCountryMcc(data.country_iso) : ''} />
                </CountryWrapper>
              ) : (
                <Text color="textGrayColor" variant="bodyRegularSm">
                  {t('noCountry')}
                </Text>
              )}
              <VerticalLine />
              <Text color="textGrayColor" variant="bodyRegularSm">
                <ApplyUserTimezone date={data.updated_at} />
              </Text>
            </HeaderDetailsWrapper>
          </div>

          <ActionsWrapper onClick={(e) => e.stopPropagation()}>
            {data.resolved ? (
              <>
                {contactsList ? (
                  <ContactName $bg={contactLabelColor}>
                    {contactsList.find((contact) => contact.id === data?.contact_id)?.name}
                  </ContactName>
                ) : null}
                <Button
                  transparentFillSvg
                  icon={MapLocationIcon}
                  shape="circle"
                  onClick={pinAction}
                  disabled={!data.lat || !data.lng}
                />
                <PopOver
                  placement="topLeft"
                  title={smsService ? `${t('selectAgent')}` : `${t('smsDisabled')}`}
                  trigger="click"
                  content={<SmsAgent data={data} closePopover={() => setIsSmsPopoverOpen(false)} />}
                  open={isSmsPopoverOpen}
                  onOpenChange={(state) => setIsSmsPopoverOpen(state)}
                  getPopupContainer={() => {
                    const parent = document.querySelector('#locations-list-layout');
                    return parent instanceof HTMLElement ? parent : document.body;
                  }}
                >
                  <Button transparentFillSvg icon={MailIcon} shape="circle" disabled={!data.lat || !data.lng} />
                </PopOver>
              </>
            ) : (
              <>
                <StyledIcon spin icon={LoadingBulkIcon} size="middle" style={{ fontSize: 40 }} />
                <Tooltip title={t('cancelSearch')} mouseEnterDelay={1} mouseLeaveDelay={0}>
                  <Button
                    icon={StopIcon}
                    colorOnHover="redColor"
                    shape="circle"
                    onClick={(e) => handleCancelJobSearch(e, data.id)}
                    customIconColor="warningColor"
                    disabled={cancelledList ? cancelledList.includes(data.id) : false}
                  />
                </Tooltip>
              </>
            )}
          </ActionsWrapper>
        </div>
      }
      body={getBody()}
      terminalColor={terminalColor}
    />
  );
};

const LocationWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.backgroundWhiteColor};
  border-radius: ${({ theme }) => theme.borderRadiusBg};
  padding: ${({ theme }) => theme.paddingMd};
  display: flex;
  flex-direction: column;
`;

const TerminalColorIdentifier = styled.div<{ bg?: string }>`
  position: absolute;
  height: 60%;
  width: 2px;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
  background: ${({ bg }) => bg};
`;

const LocationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gapMd};
  cursor: pointer;
`;

const LocationBody = styled.div`
  margin-top: ${({ theme }) => theme.marginBg};

  @media only screen and (max-width: 640px) {
    margin-top: ${({ theme }) => theme.marginSm};
  }
`;

const VerticalLine = styled.div`
  height: 16px;
  width: 1px;
  background: ${({ theme }) => theme.textGrayColor};

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapMd};
  justify-content: flex-end;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${({ theme }) => theme.gapMd};
`;

const HeaderDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.gapSm};

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

const CountryWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapSm};
`;

const InfoNameWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: ${({ theme }) => theme.gapSm};
  grid-template-columns: auto auto;

  @media only screen and (max-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: ${({ theme }) => theme.gapSm};
  }
`;

const InfoLocationAndDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.gapSm};

  @media only screen and (max-width: 1280px) {
    justify-content: flex-start;
  }
`;

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gapMd};

  @media only screen and (max-width: 640px) {
    background: ${({ theme }) => theme.grayShade11Color};
    padding: ${({ theme }) => theme.paddingSm};
    border-radius: ${({ theme }) => theme.borderRadiusSm};
  }
`;

const ColumnsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapMd};

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.gapXs};
`;

const ColumnItem = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapXs};
`;

const BlinkDot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  animation: dotAge 1s linear infinite;

  @keyframes dotAge {
    50% {
      opacity: 0;
    }
  }
`;

const StyledIcon = styled(Icon)<StyledIconProps>`
  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      filter: brightness(130%);
    `}
`;

const ContactName = styled.div<{ $bg?: string }>`
  background: ${({ $bg }) => ($bg ? $bg : 'transparent')};
  color: ${({ theme }) => theme.textWhiteColor};
  font-weight: bold;
  padding-right: ${({ theme }) => theme.paddingSm};
  padding-left: ${({ theme }) => theme.paddingSm};
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ContactNameSmallBullet = styled.div<{ $bg?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: ${({ $bg }) => ($bg ? $bg : 'transparent')};
`;

const StyledLte = styled.img`
  width: 12px;
  height: 12px;
  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      filter: invert(30%);
    `}
`;

export default memo(LocationComponent);
