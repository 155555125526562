import { ComponentType, ForwardRefExoticComponent, SVGProps } from 'react';

import { Dispatch } from 'redux';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import routes from 'constants/routesConfig';

import {
  CellImportIcon,
  ContactsGroupIcon,
  ContactsIcon,
  HomeIcon,
  ListIcon,
  ProfileIcon,
  ReportIcon,
  RobotsIcon,
  SettingsIcon,
  SurveyIcon,
  TurnOffIcon,
  FileSearchSolidIcon,
} from 'assets/icons';

import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_USER, UserRoleType } from 'constants/userRoleType';

export interface IMenuItem {
  id: string;
  path: string[] | string | null;
  icon:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined;
  name: string;
  canView: typeof UserRoleType;
  hoverIconColor?: string;
  onClick?: (dispatch: Dispatch<any>, func: any, func2?: any) => void;
  subPages?: IMenuItem[];
  buttonPermission?: string;
}

export const topMenuItems: IMenuItem[] = [
  {
    id: 'menu-item-dashboard',
    path: routes.dashboard,
    icon: HomeIcon,
    name: 'dashboard',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-contacts',
    path: routes.contacts,
    icon: ContactsIcon,
    name: 'contacts',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-contacts-group',
    path: routes.groups,
    icon: ContactsGroupIcon,
    name: 'contactGroup',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-robots',
    path: routes.robots,
    icon: RobotsIcon,
    name: 'robots',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-audit',
    path: routes.audit,
    icon: FileSearchSolidIcon,
    name: 'audit',
    canView: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-other',
    path: [routes.cellDataImport, routes.report, routes.presence, routes.survey],
    icon: ListIcon,
    name: 'otherPages',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
    subPages: [
      {
        id: 'menu-item-cell-import',
        path: routes.cellDataImport,
        icon: CellImportIcon,
        name: 'cellData',
        canView: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
      },
      {
        id: 'menu-item-report',
        path: routes.report,
        icon: ReportIcon,
        name: 'report',
        canView: [ROLE_USER, ROLE_ADMIN],
        buttonPermission: 'report-view',
      },
      {
        id: 'menu-item-survey',
        path: routes.survey,
        icon: SurveyIcon,
        name: 'survey',
        canView: [ROLE_ADMIN],
        buttonPermission: 'survey-view',
      },
    ],
  },
];

export const bottomMenuItems: IMenuItem[] = [
  {
    id: 'menu-item-profile',
    path: routes.profile,
    icon: ProfileIcon,
    name: 'profile',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-settings',
    path: routes.settings,
    icon: SettingsIcon,
    name: 'settings',
    canView: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  {
    id: 'menu-item-logout',
    path: null,
    icon: TurnOffIcon,
    hoverIconColor: 'redColor',
    name: 'signOut',
    canView: [ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN],
    onClick: (dispatch, func, func2) => {
      if (func2) {
        dispatch(func()).then(() => {
          func2();
        });
      } else {
        dispatch(func());
      }
    },
  },
];
