/* eslint-disable */
import axios from 'axios';
import store from 'index';

import { message } from 'antd';

import { setUserToNotAuthorized } from 'actions/user/auth';
import { FIRE_ALERT } from 'constants/actionTypes';

const sessionStorage = global.window.sessionStorage;

const devKeycloakAuthURL = `${sessionStorage.getItem('url') || ''}/api`;
const devBasicAuthURL = process.env.REACT_APP_API_URL;
const loginMethod = process.env.REACT_APP_LOGIN_METHOD;

export const devURL = loginMethod === 'keycloak' ? devKeycloakAuthURL : devBasicAuthURL;
export const pix = process.env.REACT_APP_PIX;

axios.defaults.baseURL = devURL;

if (sessionStorage.getItem('access_token')) {
  const token = sessionStorage.getItem('access_token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common['X-Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.common.Accept = 'application/json';
} else {
  axios.defaults.headers.common.Authorization = 'Bearer ';
  axios.defaults.headers.common['X-Authorization'] = 'Bearer ';
  axios.defaults.headers.common.Accept = 'application/json';
}

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = (token) => {
  refreshSubscribers.map((cb, index) => {
    cb(token);
    if (index + 1 === refreshSubscribers.length && !isRefreshing) refreshSubscribers = [];
  });
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response: { status } = { status: 0 } } = error;
    const originalRequest = config;

    if (status === 401 && error.response.data.message === 'Unauthenticated.') {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = sessionStorage.getItem('refresh_token');
        axios
          .post('/oauth/token', {
            pix,
            client_id: 2,
            scope: '*',
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
          })
          .then(({ data }) => {
            isRefreshing = false;
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            originalRequest.headers['X-Authorization'] = `Bearer ${data.access_token}`;
            sessionStorage.setItem('access_token', data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            axios.defaults.headers.common['X-Authorization'] = `Bearer ${data.access_token}`;
            onRrefreshed(data.access_token);
          })
          .catch(() => {
            store.dispatch(setUserToNotAuthorized());
            location.reload();
          });
      }

      const retryOrigReq = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${token}`;
          originalRequest.headers['X-Authorization'] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else if (status === 500 || status === 503) {
      if (error.response && error.response.data && typeof error.response.data === 'string')
        message.error('Server Response Error (500)');
    } else if (status === 501) {
      store.dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: 'Server Error 501',
          message: '501',
        },
      });
    } else if (status === 403) {
      setTimeout(() => {
        store.dispatch({
          type: FIRE_ALERT,
          payload: {
            message: 'This action is unauthorized.',
          },
        });
      }, [500]);

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  },
);
