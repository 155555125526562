import React from 'react';
import styled from 'styled-components';

const LinearAnimatedBackground = () => {
  return <AnimatedBg />;
};

const AnimatedBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadiusBg};
  z-index: -1;
  background: ${({ theme }) => theme.animationShade1Color};
  animation: demo-animation 3s ease-in-out infinite;
  -webkit-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  @keyframes demo-animation {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

export default LinearAnimatedBackground;
