import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: localStorage.getItem('lang')?.replaceAll('"', '') || 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          dashboard: 'Dashboard',
          contacts: 'Contacts',
          contactsGroup: 'Contacts Group',
          robots: 'Robots',
          profile: 'Profile',
          settings: 'Settings',
          cellImport: 'Cell Import',
          report: 'Report',
          presence: 'Presence',
          survey: 'Survey',
          audit: 'Audit',
          credits: 'Credits',
          usedCredits: 'Used Credits',
          allocatedCredits: 'Allocated Credits',
          dailyCreditLimit: 'Daily Credit Limit',
          seen: 'seen',
          markAllAsSeen: 'Mark all as seen',
          deleteAll: 'Delete all',
          serviceStatus: 'Service status',
          apiOperational: 'Api Operational',
          personalView: 'Personal view',
          switch: 'Switch',
          noTeamsFound: 'No Teams found',
          locationSearch: 'Location Search',
          imsiLookup: 'IMSI Lookup',
          msisdnLookup: 'MSISDN Lookup',
          forwardingCheck: 'Forwarding Check',
          hold: 'Hold',
          e164Lookup: 'E164 Lookup',
          e212Lookup: 'E212 Lookup',
          roamingSearch: 'Roaming Search',
          e164PhoneNumber: 'E164 Phone number',
          imsi: 'IMSI',
          active: 'Active',
          or: 'or',
          passive: 'Passive',
          search: 'Search',
          sortedBy: 'Sorted by',
          submitTime: 'Submit time',
          unread: 'Unread',
          favorite: 'Favorite',
          clearAll: 'Clear all',
          queryType: 'Query type',
          code: 'Code',
          title: 'Title',
          detail: 'Detail',
          clear: 'Clear',
          areYouSureYouWantToDeleteAllQueries: 'Are you sure you want to delete all queries?',
          activeRobots: 'Active Robots',
          thereIsNoAnyActiveRobot: 'There is no any active Robot!',
          thePhoneMustBeBetween9And17Digits: 'The phone must be between 9 and 17 digits.',
          theImsiMustBeBetween14And15Digits: 'The imsi must be between 14 and 15 digits.',
          mccMustBeBetween3And4Digits: 'MCC must be between 3 and 4 digits',
          mncMustBeBetween1And3Digits: 'MNC must be between 1 and 3 digits',
          createdRobots: 'Created Robots',
          viewAll: 'View All',
          finished: 'Finished',
          noRobotsFound: 'No Robots found',
          createYourFirstRobot: 'Create your first Robot',
          lastSearchedContacts: 'Last Searched Contacts',
          thereIsNoInformationAboutLastSearchedContacts: 'There is no information about last searched contacts',
          areYouSureYouWantToClearAllQueries: 'Are you sure you want to clear all queries?',
          sortBy: 'Sort by',
          createContact: 'Create contact',
          uploadContacts: 'Upload contacts',
          page: 'page',
          contactName: 'Contact name',
          alias: 'Alias',
          createdAt: 'Created at',
          createGroup: 'Create group',
          uploadGroup: 'Upload group',
          groupName: 'Group name',
          name: 'Name',
          phone: 'Phone',
          description: 'Description',
          gender: 'Gender',
          male: 'Male',
          female: 'Female',
          create: 'Create',
          delete: 'Delete',
          required: 'Required',
          clickOrDragFileToThisAreaToUpload: 'Click or drag file (only one) to this area to upload.',
          supportOnlyForCsvFile: 'Support only for CSV file',
          areYouSureYouWantToDeleteThis: 'Are you sure you want to delete this ',
          yes: 'Yes',
          no: 'No',
          createRobot: 'Create Robot',
          robotName: 'Robot name',
          query: 'Query',
          status: 'Status',
          type: 'Type',
          repeat: 'Repeat',
          start: 'Start',
          end: 'End',
          previouslySearchedContacts: 'Previously searched contacts',
          noSearchedContactInfo: 'No searched contact info',
          savedContacts: 'Saved contacts',
          searchSavedContacts: 'Search saved contacts',
          close: 'Close',
          frequentlySearched: 'Frequently searched',
          lastSearched: 'Last searched',
          action: 'Action',
          actions: 'Action',
          searchInSavedContacts: 'Search in saved contacts',
          searchByGroup: 'Search by group',
          unsavedContacts: 'Unsaved contacts',
          searchUnsavedContacts: 'Search unsaved contacts',
          noInformation: 'No information',
          addContact: 'Add contact',
          cancel: 'Cancel',
          createNewContact: 'Create new contact',
          nameIsRequired: 'Name is required',
          wrongCsvStructure: 'Wrong csv structure',
          fileWasUploaded: 'File was uploaded.',
          csvFileDoesNotContainNewContacts: 'CSV file does not contain new contacts.',
          readyForNewUpload: 'Ready for new upload',
          wrongFormat: 'Wrong format',
          areYouSureYouWantToDeleteThisContact: 'Are you sure you want to delete this Contact?',
          uploadContactsGroup: 'Upload Contacts Group',
          uploadContactGroup: 'Upload Contact Group',
          newGroupName: 'New Group Name',
          firstTypeTheNewGroupNameInFieldAbove: 'First, type the new group name in the field above.',
          searchGroup: 'Search group',
          users: 'Users',
          fetchingTable: 'Fetching table',
          trackerRobot: 'Tracker Robot',
          onlineRobot: 'Online Robot',
          countryZoneRobot: 'Country Zone Robot',
          geoPointRobot: 'Geo Point Robot',
          pointToPointRobot: 'Point to Point Robot',
          geoPointsRobot: 'Geo Points Robot',
          offlineRobot: 'Offline Robot',
          tracker: 'Tracker',
          online: 'Online',
          countryZone: 'Country zone',
          geoPoint: 'Geo Point',
          pointToPoint: 'Point to Point',
          geoPoints: 'Geo Points',
          offline: 'Offline',
          source: 'Source',
          sourceMode: 'Source Mode',
          selectSourceMode: 'Select source mode',
          sourceId: 'Source ID',
          selectSourceId: 'Select source ID',
          terminals: 'Terminals',
          selectTerminals: 'Select terminals',
          standardSearch: 'Standard Search',
          scheduledSearch: 'Scheduled Search',
          scheduleTime: 'Schedule Time',
          startEndTime: 'Start & End Time',
          startTime: 'Start Time',
          timeStart: 'Start time',
          endTime: 'End Time',
          repeatTime: 'Repeat time',
          min: 'min',
          repeatTimeMin: 'Repeat time (min)',
          initialSearchTime: 'Initial Search Time',
          nrOfSearches: 'Nr. Of Searches',
          stopOnResult: 'Stop on result',
          country: 'Country',
          countries: 'Countries',
          selectCountry: 'Select country',
          countryCondition: 'Country condition',
          selectCondition: 'Select condition',
          distance: 'Distance',
          lat: 'Lat',
          lng: 'Lng',
          condition: 'Condition',
          exitFrom: 'Exit from',
          enterInto: 'Enter into',
          exit: 'Exit',
          enter: 'Enter',
          savePoint: 'Save point',
          clone: 'Clone',
          areYouSureYouWantToCreateThisRobot: 'Are you sure you want to create this robot?',
          inactive: 'Inactive',
          activeRunning: 'Active running',
          finishedSuccess: 'Finished success',
          finishedTimeEnd: 'Finished time end',
          cannotRun: 'Cannot run',
          scheduleFinished: 'Schedule finished',
          fetchingRobots: 'Fetching robots',
          performedSearches: 'Performed Searches',
          details: 'Details',
          latitudeValueMustBeBetween90And90: 'Latitude value must be between -90 and 90',
          longitudeValueMustBeBetween180And180: 'Longitude value must be between -180 and 180',
          startEndTimeIsRequired: 'Start & End time is required',
          repeatTimeIsRequired: 'Repeat time is required',
          initialSearchTimeIsRequired: 'Initial Search Time is required',
          nrOfSearchesIsRequired: 'Nr. Of Searches is required',
          robotNameIsRequired: 'Robot Name is required',
          sourceIdIsRequired: 'Source ID is required',
          conditionIsRequired: 'Condition is required',
          countryConditionIsRequired: 'Country Condition is required',
          countryIsRequired: 'Country is required',
          distanceIsRequired: 'Distance is required',
          targetIdIsRequired: 'Target ID is required',
          generalExplanation: 'General explanation',
          explanationGp: 'Explanation GP',
          noSearches: 'No searches',
          robotCreation: 'Robot Creation',
          selectType: 'Select type',
          selectStatus: 'Select status',
          youCanDeleteThePointHere: 'You can delete the point here',
          deletePoint: 'Delete Point',
          sourceName: 'Source Name',
          actionCondition: 'Action Condition',
          targetName: 'Target Name',
          information: 'Information',
          areYouSureYouWantToDeleteThisRobot: 'Are you sure you want to delete this robot?',
          confirmDeletion: 'Confirm Deletion',
          loadingAdditionalRobotData: 'Loading additional robot data!',
          resultInformation: 'Result information',
          robotLocationResults: 'Robot Location Results',
          allLocations: 'All locations',
          noLocationResults: 'No location results',
          noCountry: 'No Country',
          changePassword: 'Change Password',
          newPassword: 'New Password',
          repeatString: 'Repeat',
          submit: 'Submit',
          passwordRules:
            'Password must contain at least one upper & lower case character, one number and be at least 6 characters long.',
          changePhoneEmail: 'Change Phone & Email',
          changeNotificationMethod: 'Change Notification Method',
          notificationMethod: 'Notification method',
          off: 'Off',
          userMail: 'User mail',
          userSms: 'User sms',
          userBoth: 'User both',
          teamMail: 'Team mail',
          teamSms: 'Team sms',
          teamBoth: 'Team both',
          changeTimezone: 'Change TimeZone',
          theTimeZoneWasSet: 'The time zone was set',
          browserNotification: 'Browser Notification',
          notificationSound: 'Notification Sound',
          successSound: 'Success Sound',
          errorSound: 'Error Sound',
          selectSuccessSoundForNotification: 'Select success sound for Notification',
          selectErrorSoundForNotification: 'Select error sound for Notification',
          sound: 'Sound',
          navigationBarSticky: 'Navigation bar sticky',
          darkMode: 'Dark mode',
          language: 'Language',
          agentTemplateSettings: 'Agent template settings',
          agents: 'Agents',
          add: 'Add',
          update: 'Update',
          agent: 'Agent',
          passwordMustBeAtLeast6CharactersContainOneUpperLowerCaseAndNumber:
            'Password must be at least 6 characters, contain one upper & lower case and number',
          thePhoneNumberIsRequired: 'The phone number is required',
          emailIsRequired: 'Email is required',
          areYouSureYouWantToDeleteThisAgent: 'Are you sure you want to delete this Agent?',
          somethingWentWrongUpdateFailed: 'Something went wrong. Update failed!',
          theBrowserNotificationWasChangedSuccessfully: 'The browser notification was changed successfully',
          selectAgents: 'Select Agents',
          thisFieldIsRequired: 'This field is required',
          thePasswordMustBeAtLeast6Characters: 'The password must be at least 6 characters',
          passwordMustContainAtLeastOneUpperLowerCaseCharacterAndOneNumber:
            'Password must contain at least one upper & lower case character and one number',
          passwordConfirmationDoesNotMatch: 'Password confirmation does not match',
          yourPasswordHasBeenResetSuccessfully: 'Your password has been reset successfully',
          setNewPassword: 'Set New Password',
          yourContactInfoHasBeenResetSuccessfully: 'Your contact info has been reset successfully',
          email: 'Email',
          theSoundNotificationWasChangedSuccessfully: 'The sound notification was changed successfully',
          license: 'License',
          userList: 'User list',
          inTeams: 'In teams',
          isLeader: 'Is leader',
          lastOnline: 'Last online',
          edit: 'Edit',
          suspend: 'Suspend',
          createTeam: 'Create Team',
          teams: 'Teams',
          info: 'Info',
          updatedAt: 'Updated at',
          usersInTeam: 'Users in team',
          teamsEnabledList: 'Teams Enabled List',
          selectTeam: 'Select Team',
          team: 'Team',
          blockedList: 'Blocked List',
          addPhoneImsiToBlockedList: 'Add phone/IMSI to Blocked list',
          addBlockedListRules: 'First number is Phone and second is IMSI. The terminals are separated by new line.',
          ex: 'Ex',
          ifYouWantToEnterOnlyPhoneOrImsi: 'If you want to enter only phone or Imsi',
          permissionsEdit: 'Permissions edit',
          credentialsCreditsEdit: 'Credentials & Credits Edit',
          toggleAllPermissions: 'Toggle all permissions',
          permission: 'Permission',
          createString: 'Create',
          view: 'View',
          enable: 'Enable',
          robot: 'Robot',
          contact: 'Contact',
          contactGroup: 'Contact Group',
          locate: 'Locate',
          teamContact: 'Team contact',
          teamRobot: 'Team robot',
          teamContactGroup: 'Team contact group',
          cellReport: 'Cell report',
          dailyCreditsRules: 'Daily Credits. (type -1 for unlimited credits)',
          allocatedCreditsRules: 'Allocated Credits. (type -1 to remove allocated credits)',
          leader: 'Leader',
          notLeader: 'Not leader',
          teamManagementDeleteMessage:
            'Delete mode will remove all user data then delete the user himself, transfer mode will transfer all user data to a specific user then remove the user only.',
          transfer: 'Transfer',
          selectUserForTransfer: 'Select user for transfer',
          ok: 'OK',
          editAppSettings: 'Edit App Settings',
          notificationTemplateSettings: 'Notification template settings',
          adminList: 'Admin List',
          createAdmin: 'Create Admin',
          suspendedUsers: 'Suspended Users',
          logs: 'Logs',
          messageId: 'Message id',
          added: 'Added',
          mapConfig: 'Map Config',
          mapApiKey: 'Map API Key',
          mapUrl: 'Map Url',
          tokenExpiration: 'Token expiration',
          accessToken: 'Access Token',
          refreshToken: 'Refresh Token',
          appToken: 'App Token',
          url: 'URL',
          radiusThreshold: 'Radius threshold',
          dailyAppCredits: 'Daily app credits',
          robotRepeatTime: 'Robot repeat time',
          appStatus: 'App Status',
          toggleButtonToOnOff: 'Toggle button to ON/OFF',
          generateCreditRecords: 'Generate Credit Records',
          generate: 'Generate',
          robotDataExpiryDays: 'Robot data expiry days',
          daysOld: 'Days Old',
          setTheMinutesForRecency: 'Set the minutes for recency',
          recency: 'Recency',
          configCellExpiration: 'Config cell expiration (day)',
          cellExpiration: 'Cell expiration',
          dashboardQueriesAge: 'Dashboard queries age',
          computedCellMinThreshold: 'Computed cell min threshold',
          globalMapSettings: 'Global Map Settings',
          public_osm_url: 'Public osm url',
          publicOsmUrlIsARequiredField: 'public osm url is a required field',
          hosted_osm_url: 'Hosted osm url',
          google_map_token: 'Google map token',
          mapbox_style_basic: 'Mapbox style basic',
          mapbox_style_satellite: 'Mapbox style satellite',
          mapbox_token: 'Mapbox token',
          mapbox_account_name: 'Mapbox account name',
          heatmapSettings: 'HeatMap Settings',
          value: 'Value',
          greaterThan: 'Greater than',
          lowerThan: 'Lower than',
          addNewThreshold: 'Add new threshold',
          password: 'Password',
          repeatPassword: 'Repeat Password',
          createUser: 'Create User',
          teamManagement: 'Team Management',
          addUserToTeam: 'Add user to team',
          noUsersIn: 'No users in',
          deleteTeamRules:
            'Delete mode will remove all team data then delete the team itself, transfer mode will transfer all team data to a specific user then remove the team only.',
          save: 'Save',
          teamName: 'Team name',
          userNameSeparate: 'User Name',
          restore: 'Restore',
          dailyCredits: 'Daily Credits',
          pleaseAddSomeValueToOneOrBothInputs: 'Please add some value to one or both inputs',
          imsiMustBe14To15Digits: 'IMSI must be 14 to 15 digits',
          msisdnMustBe9To17Digits: 'MSISDN must be 9 to 17 digits',
          teamNameIsRequired: 'Team Name is required',
          fieldsAreRequired: 'Fields are required',
          pleaseSelectTeam: 'Please, select team',
          pleaseAddSomeValueToOneOfTheInputs: 'Please add some value to one of the inputs',
          imsiMustBe1To15Digits: 'IMSI must be 1 to 15 digits',
          msisdnMustBe1To15Digits: 'MSISDN must be 1 to 15 digits',
          passwordIsRequired: 'Password is required',
          passwordAndRepeatPasswordDoesntMatch: "Password and Repeat Password doesn't match",
          userNameIsRequired: 'User name is required',
          credentialsHasBeenResetSuccessfully: 'Credentials has been reset successfully',
          youMustSelectUserForTransfer: 'You must select user for transfer',
          transferFail: 'Transfer fail!',
          youMustSelectUser: 'You must select user',
          createTeamList: 'Create Team List',
          passwordHasBeenResetSuccessfully: 'Password has been reset successfully',
          changesHasBeenSaved: 'Changes has been saved',
          userDailyCredits: 'User daily credits',
          binary: 'Binary',
          text: 'Text',
          destination: 'Destination',
          robotNotification: 'Robot notification',
          defaultSmsForward: 'Default SMS forward',
          templateSettings: 'Template Settings',
          toggleButtonToLockUnlockTemplate: 'Toggle button to Lock/Unlock Template',
          templateWasUpdated: 'Template was updated',
          appSettings: 'App Settings',
          mailConfig: 'Mail Config',
          mailDriver: 'Mail driver',
          mailHost: 'Mail host',
          mailPort: 'Mail port',
          mailUsername: 'Mail username',
          mailPassword: 'Mail password',
          mailEncryption: 'Mail encryption',
          mailFromAddress: 'Mail from address',
          mailFromName: 'Mail from name',
          sendMailSystemPath: 'Send mail system path',
          notificationMessage: 'Notification message',
          rabitMq: 'Rabit MQ',
          host: 'Host',
          port: 'Port',
          queue: 'Queue',
          smsConfig: 'SMS Config',
          headerName: 'Header name',
          headerValue: 'Header value',
          areYouSure: 'Are you sure?',
          generateReport: 'Generate Report',
          uploadCellData: 'Upload Cell data',
          supportOnlyForZipFiles: 'Support only for ZIP files',
          fileImports: 'File Imports',
          id: 'Id',
          totalRows: 'Total rows',
          new: 'New',
          updated: 'Updated',
          processed: 'Processed',
          current: 'Current',
          progress: 'Progress',
          fileSize: 'File size',
          waitingInQueue: 'Waiting in queue',
          files: 'Files',
          folders: 'Folders',
          noData: 'No Data',
          cell: 'Cell',
          resetFilters: 'Reset filters',
          showManual: 'SHOW MANUAL',
          range: 'Range',
          angle: 'Angle',
          width: 'Width',
          dateOfExpirity: 'Date of Expirity',
          deleteAllCells: 'Delete all Cells',
          cells: 'Cells',
          areYouSureYouWantToDeleteAllCells: 'Are you sure you want to delete all cells?',
          sureToSave: 'Sure to save?',
          sureToDelete: 'Sure to delete?',
          address: 'Address',
          deleteThisFileRecord: 'Delete this file record?',
          deleteFilesInstruction:
            'Deleting the record before the file is processed will leave the physical file orphaned on the disc, please then delete the file manually using the Files section',
          formatError: 'Format Error!',
          supportOnlyZipFiles: 'Support only ZIP files',
          dateOfExpiry: 'Date of Expiry',
          selectors: 'Selectors',
          item: 'item',
          items: 'items',
          availableColumns: 'Available Columns',
          activeColumns: 'Active Columns',
          resultTime: 'Result time',
          msisdn: 'MSISDN',
          mcc: 'MCC',
          mnc: 'MNC',
          lac: 'LAC',
          tac: 'TAC',
          cellid: 'CELLID',
          imei: 'IMEI',
          state: 'State',
          countryIso: 'Country ISO',
          age: 'Age',
          errorCode: 'Error code',
          username: 'Username',
          archive: 'Archive',
          unarchive: 'Unarchive',
          locations: 'Locations',
          archivedLocations: 'Archived Locations',
          downloadCsv: 'Download CSV',
          withArchived: 'With Archived',
          withoutArchived: 'Without Archived',
          noTeam: 'No team',
          searchType: 'Search type',
          youDontHavePermissionsToUseThisFilter: "You don't have permissions to use this filter",
          allQueryTypes: 'All Query types',
          noValue: 'No value',
          noSearchTypes: 'No Search types',
          filterColumns: 'Filter columns',
          sensors: 'Sensors',
          correlation: 'Correlation',
          filters: 'Filters',
          locationTime: 'Location time',
          sensorId: 'Sensor ID',
          sersorTechnology: 'Sersor technology',
          sensorsSerial: 'Sensors serial',
          rsrp: 'rsrp',
          noSensor: 'No sensor',
          filter: 'Filter',
          createCorrelation: 'Create Correlation',
          correlationName: 'Correlation name',
          addSensor: 'Add Sensor',
          correlatedImsiResults: 'Correlated IMSI Results',
          foundAtSensors: 'Found at sensors',
          match: 'Match',
          sensorTechnology: 'Sensor technology',
          thisFieldCanMatchPartialStrings: 'this field can match partial strings',
          sensorTime: 'Sensor time',
          deleteThisSensorGroup: 'Delete this Sensor Group?',
          deleteThisSensor: 'Delete this Sensor?',
          selectSensor: 'Select sensor',
          fileName: 'File Name',
          noFilesAvailable: 'No files available',
          noTechnology: 'No technology',
          addFilter: 'Add filter',
          sensorSerial: 'Sensor Serial',
          refresh: 'Refresh',
          somethingWentWrongPleaseTryAnotherName: 'Something went wrong. Please, try another name',
          pleaseInsertSomeName: 'Please, insert some name',
          somethingWentWrongPleaseTryAgain: 'Something went wrong. Please, try again',
          pleaseFillAllInputs: 'Please, fill all inputs',
          clickToUpload: 'Click to Upload',
          originalName: 'Original Name',
          path: 'Path',
          size: 'Size',
          processedRows: 'Processed Rows',
          areYouSureYouWantToDeleteThisSurvey: 'Are you sure you want to delete this survey?',
          uploadSurvey: 'Upload Survey',
          noAlias: 'No alias',
          noImsi: 'No IMSI',
          editContact: 'Edit contact',
          addTerminal: 'Add terminal',
          isPrimary: 'Is Primary',
          readMore: 'Read more',
          areYouSureYouWantToArchiveUnarchive: 'Are you sure you want to archive/unarchive?',
          removePrimary: 'Remove primary',
          setAsPrimary: 'Set as Primary',
          editTerminal: 'Edit terminal',
          archiveTerminal: 'Archive terminal',
          terminal: 'Terminal',
          deleteTerminal: 'Delete terminal',
          contactComments: 'Contact comments',
          writeComment: 'Write comment',
          show: 'Show',
          all: 'All',
          robotDeleted: 'Robot-deleted',
          manual: 'Manual',
          primary: 'Primary',
          noSearchType: 'No search type',
          noLocation: 'No location',
          youDontHaveAnyTemplate: "You don't have any template. Please",
          one: 'one',
          smsDisabled: 'SMS Disabled',
          azimuth: 'Azimuth',
          timeEnd: 'End time',
          updateRobot: 'Update Robot',
          targetId: 'Target ID',
          selectTargetId: 'Select target ID',
          areYouSureYouWantToCloneThisRobot: 'Are you sure you want to clone this robot?',
          leaveSomeCommentForThatUser: 'Leave some comment for that user',
          thereAreNoComments: 'There are no comments',
          contactInfo: 'Contact Info',
          comments: 'Comments',
          currentLocation: 'Current location',
          toggleContactBlock: 'Toggle contact block',
          areYouSureYouWantToDeleteThisTerminal: 'Are you sure you want to delete this Terminal',
          noArchivedTerminals: 'No archived terminals',
          noCreatedTerminals: 'No created terminals',
          viewPatternOfLife: 'View Pattern of Life',
          enablePatternOfLife: 'Enable Pattern of Life',
          enterPhoneNumber: 'Enter phone number',
          enterImsiNumber: 'Enter imsi number',
          availableTerminals: 'Available Terminals',
          createTerminal: 'Create Terminal',
          archivedTerminals: 'Archived Terminals',
          alert: 'Alert',
          azimuthAt: 'Azimuth at',
          at: 'at',
          cloneRobot: 'Clone Robot',
          creditsGenerationFiled: 'Credits generation filed',
          deleteFail: 'Delete fail',
          editRobot: 'Edit Robot',
          error: 'Error!',
          explanation: 'Explanation',
          filterLocations: 'Filter locations',
          groupSameLocations: 'Group same locations',
          here: 'here',
          incorrectPassword: 'Incorrect password',
          informationWasSent: 'Information was sent',
          locationName: 'Location name',
          locationsClearForbidden: 'Locations clear forbidden',
          network: 'Network',
          noDate: 'No date',
          otherQueries: 'Other Queries',
          others: 'Others',
          plotRoute: 'Plot route',
          recordsWereGenerated: 'Records were generated',
          reset: 'Reset',
          send: 'Send',
          somethingWentWrong: 'Something went wrong',
          success: 'Success!',
          successfullyUploaded: 'Successfully Uploaded',
          theNotificationMethodWasChangedSuccessfully: 'The notification method was changed successfully',
          thePasswordWasChangedSuccessfully: 'The password was changed successfully',
          thePasswordsDontMatch: "The passwords don't match",
          thePasswordFieldsCantBeEmpty: "The password fields can't be empty",
          thePhoneEmailWasChangedSuccessfully: 'The phone & email was changed successfully',
          setTimeZone: 'Set time zone',
          toggleHeatmapView: 'Toggle heatmap view',
          togglePlotsView: 'Toggle plots view',
          viewAllLocations: 'View all locations',
          youCanCheckYourExistingTemplate: 'You can check your existing template',
          youMustSelectDeleteOrTransferOption: 'You must select DELETE or Transfer option',
          selectContact: 'Select a contact',
          saveToContact: 'Save to Contact',
          submitted: 'Submitted',
          completed: 'Completed',
          closeCreation: 'Close Creation',
          checkingForExistingTemplates: 'Checking for existing Templates',
          smsServiceNotConfigured: 'SMS service not configured',
          noCoordinates: 'No coordinates',
          found: 'Found',
          networkName: 'Network Name',
          networkBrand: 'Network Brand',
          networkId: 'Network Id',
          plmnId: 'PlmnId',
          null: 'NULL',
          true: 'True',
          false: 'False',
          dataIsMissing: 'Data is missing',
          subscriberType: 'Subscriber Type',
          reachable: 'Reachable',
          forwarded: 'Forwarded',
          dosState: 'Dos State',
          numberOwnership: 'Number Ownership',
          subscriptionWith: 'Subscription With',
          resultFailed: 'Result failed',
          plmnNetwork: 'Plmn Network',
          loginPage: 'Login Page',
          loginFail: 'Login Fail',
          login: 'Login',
          usernameIsRequired: 'Username is required',
          somethingWentWrongPleaseAskAdmin: 'Something went wrong. Please ask Admin!',
          userNameFieldCantBeBlank: "User name field can't be blank",
          passwordFieldCantBeBlank: "Password field can't be blank",
          created: 'Created',
          edited: 'Edited',
          deleted: 'Deleted',
          suspended: 'Suspended',
          updatedPassword: 'Updated password',
          assignedCredits: 'Assigned credits',
          selectAction: 'Select action',
          superadmin: 'Superadmin',
          admin: 'Admin',
          user: 'User',
          roles: 'Roles',
          selectRole: 'Select role',
          startDate: 'Start date',
          dateStart: 'Start date',
          endDate: 'End date',
          dateEnd: 'End date',
          noInfo: 'No info',
          logType: 'Log type',
          timestamp: 'Timestamp',
          loggedOut: 'Logged out',
          loggedIn: 'Logged in',
          restored: 'Restored',
          allocated: 'Allocated',
          unlimited: 'Unlimited',
          totalCreditsToUser: 'Total credits to user',
          dailyCreditsToUser: 'Daily credits to user',
          createdTeam: 'Created team',
          updatedTeam: 'Updated team',
          deletedTeam: 'Deleted team',
          assignedUser: 'Assigned user',
          toTeam: 'To team',
          detachedUser: 'Detached user',
          fromTeam: 'From team ',
          createdRobot: 'Created robot',
          deletedRobot: 'Deleted robot',
          editedRobot: 'Edited robot',
          wasFinished: 'Was finished',
          author: 'Author',
          createdContact: 'Created contact',
          editedContact: 'Edited contact',
          deletedContact: 'Deleted contact',
          changedPasswordForUser: 'Changed password for user',
          deletedUser: 'Deleted user',
          restoredUser: 'Restored user',
          suspendedUser: 'Suspended user',
          createdUser: 'Created user',
          toUser: 'To user',
          creditsToUser: 'Credits to user',
          logout: 'Logout',
          userCreated: 'User created',
          userSuspended: 'User suspended',
          userRestored: 'User restored',
          userDeleted: 'User deleted',
          userChangedPassword: 'User changed password',
          adminAllocatedCreditToUser: 'Admin allocated credit to user',
          superadminAllocatedCreditToAdmin: 'Superadmin allocated credit to admin',
          leaderAllocatedCreditToUser: 'Leader allocated credit to user',
          teamCreated: 'Team created',
          teamUpdated: 'Team updated',
          teamDeleted: 'Team deleted',
          teamAssigned: 'Team assigned',
          teamDetached: 'Team detached',
          robotCreated: 'Robot created',
          robotEdited: 'Robot edited',
          robotFinished: 'Robot finished',
          contactCreated: 'Contact created',
          contactEdited: 'Contact edited',
          contactDeleted: 'Contact deleted',
          welcomeBack: 'Welcome back',
          otherPages: 'Other Pages',
          signOut: 'Sign out',
          notifications: 'Notifications',
          markAllAsRead: 'Mark all as read',
          pleaseSwitchTo: 'Please switch to',
          toSeeTheResult: 'to see the result',
          loading: 'Loading',
          result: 'Result',
          forwardSearchFailed: 'Forward search failed',
          numberCopiedToClipboard: 'Number copied to clipboard',
          undoDeleting: 'Undo deleting',
          copy: 'Copy',
          quickSearch: 'Quick Search',
          unfavorite: 'Unfavorite',
          unknown: 'Unknown',
          forward: 'Forward',
          relatedContacts: 'Related Contacts',
          noContacts: 'No Contacts',
          createContactWithResultsBellow: 'Create contact with results bellow',
          selectAgent: 'Select Agent(s)',
          noQueriesAvailable: 'No queries available',
          selectLanguage: 'Select Language',
          english: 'English',
          spanish: 'Spanish',
          ruler: 'Ruler',
          minimiseMap: 'Minimise map',
          viewFullScreen: 'View Full Screen',
          heatMap: 'Heat Map',
          plot: 'Plot',
          noHeatMapPoints: 'No heat map points',
          plotFailed: 'Plot failed',
          noSingleView: 'No single view',
          option: 'Option',
          theLabelMustBeAtLeast2Characters: 'The label must be at least 2 characters',
          noLabel: 'No label',
          frequency: 'Frequency',
          frequencyShorten: 'Frq',
          date: 'Date',
          time: 'Time',
          day: 'Day',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          selectImsiPhone: 'Select IMSI/Phone',
          weekday: 'Weekday',
          weekdays: 'Weekday(s)',
          disable: 'Disable',
          export: 'Export',
          patternOfLife: 'Pattern of life ',
          searches: 'Searches',
          location: 'Location',
          toggleLocationSearchResults: 'Toggle location search results',
          other: 'Other',
          noLocationsFoundWithThisContact: 'No locations found with this contact',
          package: 'Package',
          repository: 'Repository',
          version: 'Version',
          sorryThePageYouVisitedDoesNotExist: 'Sorry, the page you visited does not exist.',
          goToDashboard: 'Go to Dashboard',
          stop: 'Stop',
          playRoutePath: 'Play Route Path',
          markerInfo: 'Marker Info',
          targetHeatPoints: 'Target heat points',
          pointSizeM: 'Point Size (m)',
          pointOpacity: 'Point Opacity',
          borderOpacity: 'Border Opacity',
          setSettings: 'Set Settings',
          tryToRefreshThePageOrContactAdministrator: 'Try to refresh the page or contact administrator',
          youDontHavePermissionsToUseThisAction: "You don't have permissions to use this action",
          selectDate: 'Select date',
          notice: 'Notice',
          noLocations: 'No locations',
          editColor: 'Edit Color',
          selectTimezone: 'Select timezone',
          clickToSortAscending: 'Click to sort ascending',
          clickToSortDescending: 'Click to sort descending',
          clickToCancelSorting: 'Click to cancel sorting',
          mon: 'Mon',
          tue: 'Tue',
          wed: 'Wed',
          thu: 'Thu',
          fri: 'Fri',
          sat: 'Sat',
          sun: 'Sun',
          viewTeam: 'View team',
          changesHaveBeenSaved: 'Changes have been saved',
          leaderStatusWarning: 'Leaders can view other users within their teams and assign credits to users',
          sorryYouAreNotAuthorizedToAccessThisTeam: 'Sorry, you are not authorized to access this team.',
          sorryThisTeamWasNotFound: 'Sorry, this team was not found.',
          appSettingsUpdated: 'App Settings updated',
          notificationMessageUpdated: 'Notification message updated',
          creditsGenerationFailed: 'Credits generation failed',
          cellExportFile: 'Cell export file',
          readyToDownload: 'ready to download',
          preparingCellsFilePleaseCheckTheNotificationsToDownloadIt:
            'Preparing cells file. Please, check the notifications to download it.',
          requestAccessFromAdministrator: 'Request access from Administrator',
          serviceUnavailable: 'Service Unavailable',
          empty: 'Empty',
          askForPermissionToCreateATeam: 'Ask for permission to create a Team',
          selectAllData: 'Select all data',
          invertCurrentPage: 'Invert current page',
          createContactsGroup: 'Create Contacts Group',
          createContactGroup: 'Create Contact Group',
          deleteThisGroup: 'Delete this group',
          allLocationsHistory: 'All Locations History',
          noRobots: 'No robots',
          showAllLocations: 'Show All Locations',
          viewContact: 'View Contact',
          primaryTerminal: 'Primary Terminal',
          lastLocation: 'Last Location',
          noLastLocation: 'No last location',
          pleaseSelectContact: 'Please, select contact',
          contactsGroupNameIsRequired: 'Contacts Group Name is required',
          contactGroupNameIsRequired: 'Contact Group Name is required',
          runLocationSearchIsRequired: 'Run Location Search is required',
          immediately: 'Immediately',
          fiveMinutesAfter: '5 Minutes After',
          tenMinutesAfter: '10 Minutes After',
          twentyMinutesAfter: '20 Minutes After',
          thirtyMinutesAfter: '30 Minutes After',
          sixtyMinutesAfter: '60 Minutes After',
          runLocationSearch: 'Run Location Search',
          startTimeIsRequired: 'Start time is required',
          liveSearchIsRequired: 'Live Search is required',
          on: 'On',
          liveSearch: 'Live Search',
          select: 'Select',
          registeredElsewhere: 'Registered elsewhere',
          initiatingRoamingRequest: 'Initiating roaming request',
          alertDetails: 'Alert details',
          incomingSms: 'Incoming SMS',
          noMessages: 'No messages',
          roamed: 'Roamed',
          registerCanceled: 'Register canceled',
          smsIntercept: 'SMS Intercept',
          waiting: 'Waiting',
          cancelSmsRegister: 'Cancel SMS Register',
          areYouSureYouWantToCancelSmsRegister: 'Are you sure you want to cancel SMS register',
          smsLocate: 'SMS Locate',
          smsLocateSettings: 'SMS Locate Settings',
          numbersHandler: 'Numbers handler',
          managePhoneNumbers: 'Manage phone numbers',
          imsiIsRequired: 'IMSI is required',
          itIsNotAllowedToAddMoreThen5Contacts: 'It is not allowed to add more then 5 contacts',
          exportCellData: 'Export cell data',
          cellData: 'Cell data',
          noActiveRobots: 'No active robots',
          ssoLogin: 'SSO Login',
          cantBeAuthorized: "Can't be authorized",
          invalid: 'Invalid',
          existing: 'Existing',
          seeDetails: 'See details',
          theExactRowNumbersWith: 'The exact row numbers with',
          row: 'Row',
          newData: 'New data',
          existingData: 'Existing data',
          invalidData: 'Invalid data',
          yourTemplateIsLockedByTheSuperAdmin: 'Your template is locked by the Super Admin',
          yourTemplateIsEmptyAndItIsProvidedByTheSuperAdmin:
            'Your template is empty and it is provided by the Super Admin',
          hoverToSeeTheDefaultTemplate: 'Hover to see the default template',
          orCreateYourOwn: 'or create your own',
          yourTemplateIsLockedByTheSuperAdminButTheDefaultOneIsEmpty:
            'Your template is locked by the Super Admin, but the default one is empty',
          cancelSearch: 'Cancel search',
          setTime: 'Set time',
          sureToRegenerateAndResendPassword: 'Sure to regenerate and resend password?',
          regeneratePassword: 'Regenerate Password',
          requestPassword: 'Request Password',
          sureToRequestPassword: 'Sure to request password?',
          requestHasBeenSent: 'Request has been sent',
          timeHasBeenReset: 'Time has been reset',
          passwordHasBeenRegenerated: 'Password has been regenerated',
          manageTimeAndPassword: 'Manage time and password',
          highPrecision: 'High precision',
          uuid: 'UUID',
          smsLocatePhoneList: 'SMS Locate phone list',
          smsLocateNumber: 'SMS Locate number',
          nothingAvailable: 'Nothing available',
          isRegistered: 'Is registered',
          notRegistered: 'Not registered',
          notPossibleToUseThisNumber: 'Not possible to use this number.',
          pleaseContactAdministrator: 'Please, contact administrator.',
          attach: 'Attach',
          youAreNotAllowedToAddMoreThen5Contacts: 'You are not allowed to add more then 5 contacts',
          hide: 'Hide',
          unhide: 'Unhide',
          hiddenList: 'Hidden list',
          enabledList: 'Enabled list',
          disableSmsLocateInfo:
            'Are you sure you want to disable SMS Locate? In case you accept all numbers for this admin and his users are gonna be deregistered.',
          changedPhone: 'Changed phone',
          actionRequired: 'Action required',
          noFiltersApplied: 'No filters applied',
          generatingYourPdf: 'Generating your PDF',
          pleaseDontRefreshOrNavigateAway: 'Please, don’t refresh or navigate away',
          isHiddenList: 'Is hidden list',
          highPrecisionDetails: 'High precision details',
          apiKey: 'API key',
          refine: 'Refine',
          refinePercentileConfig: 'Refine percentile config',
          refineConfig: 'Refine config',
          brandName: 'Brand name',
          modelName: 'Model name',
          refineIsNotCurrentlyAvailableAtThisLocation: 'Refine is not currently available at this location.',
          locationHasNotAllNecessaryParametersForGettingPolygon:
            'Location has not all necessary parameters for getting polygon.',
          noResult: 'No result',
          resetDates: 'Reset Dates',
          resetTags: 'Reset Tags',
          showCellRange: 'Show Cell Range',
          hideCellRange: 'Hide Cell Range',
          sorryYouAreNotAuthorizedToAccessThisRobot: 'Sorry, you are not authorized to access this robot.',
          sorryTheRequestedRobotIsNotFound: 'Sorry, the requested robot is not found.',
          sorryTheRequestedContactIsNotFound: 'Sorry, the requested contact is not found.',
          sorryTheRequestedContactGroupIsNotFound: 'Sorry, the requested contact group is not found.',
          loadMore: 'Load more',
          of: 'Of',
          deletedAt: 'Deleted at',
          selectByDateOrShowAll: 'Select by date or show all',
          applyFilters: 'Apply filters',
          clearFilters: 'Clear filters',
          showAll: 'Show all',
          refinePercentileIsRequired: 'Refine Percentile is required',
          locationHasNotAllNecessaryParametersForGettingHeatMap:
            'Location has not all necessary parameters for getting Heat Map.',
        },
      },
      es: {
        translation: {
          dashboard: 'Tablero',
          contacts: 'Contactos',
          contactsGroup: 'Grupo de contactos',
          robots: 'Robots',
          profile: 'Perfil',
          settings: 'Ajustes',
          cellImport: 'Importación de celdas',
          report: 'Informe',
          presence: 'Presencia',
          survey: 'Encuesta',
          audit: 'Auditoría',
          credits: 'Créditos',
          usedCredits: 'Créditos usados',
          allocatedCredits: 'Créditos asignados',
          dailyCreditLimit: 'Límite de crédito diario',
          seen: 'visto',
          markAllAsSeen: 'Marcar todo como visto',
          deleteAll: 'Eliminar todos',
          serviceStatus: 'Estado del servicio',
          apiOperational: 'API operativa',
          personalView: 'Punto de vista personal',
          switch: 'Cambiar',
          noTeamsFound: 'No se encontraron equipos',
          locationSearch: 'Búsqueda de ubicación',
          imsiLookup: 'Búsqueda IMSI',
          msisdnLookup: 'Búsqueda de MSISDN',
          forwardingCheck: 'Comprobación de reenvío',
          hold: 'Sostener',
          e164Lookup: 'Búsqueda E164',
          e212Lookup: 'Búsqueda E212',
          roamingSearch: 'Búsqueda itinerante',
          e164PhoneNumber: 'E164 Número de teléfono',
          imsi: 'IMSI',
          active: 'Activo',
          or: 'o',
          passive: 'Pasivo',
          search: 'Buscar',
          sortedBy: 'Ordenado por',
          submitTime: 'Hora de envío',
          unread: 'No leído',
          favorite: 'Favorito',
          clearAll: 'Limpiar todo',
          queryType: 'Tipo de consulta',
          code: 'Código',
          title: 'Título',
          detail: 'Detalle',
          clear: 'Claro',
          areYouSureYouWantToDeleteAllQueries: '¿Está seguro de que desea eliminar todas las consultas?',
          activeRobots: 'Robots Activos',
          thereIsNoAnyActiveRobot: '¡No hay ningún Robot activo!',
          thePhoneMustBeBetween9And17Digits: 'El teléfono debe tener entre 9 y 17 dígitos.',
          theImsiMustBeBetween14And15Digits: 'El imsi debe tener entre 14 y 15 dígitos.',
          mccMustBeBetween3And4Digits: 'MCC debe tener entre 3 y 4 dígitos',
          mncMustBeBetween1And3Digits: 'MNC must be between 1 and 3 digits',
          createdRobots: 'Robots creados',
          viewAll: 'Ver todo',
          finished: 'Finalizado',
          noRobotsFound: 'No se encontraron robots',
          createYourFirstRobot: 'Crea tu primer Robot',
          lastSearchedContacts: 'Últimos contactos buscados',
          thereIsNoInformationAboutLastSearchedContacts: 'No hay información sobre los últimos contactos buscados',
          areYouSureYouWantToClearAllQueries: '¿Estás seguro de que quieres borrar todas las consultas?',
          sortBy: 'Ordenar por',
          createContact: 'Crear contacto',
          uploadContacts: 'Subir contactos',
          page: 'Página',
          contactName: 'Nombre de contacto',
          alias: 'Alias',
          createdAt: 'Creado en',
          createGroup: 'Crea un grupo',
          uploadGroup: 'Subir grupo',
          groupName: 'Nombre del grupo',
          name: 'Nombre',
          phone: 'Teléfono',
          description: 'Descripción',
          gender: 'Género',
          male: 'Masculino',
          female: 'Femenino',
          create: 'Crear',
          delete: 'Borrar',
          required: 'Obligatorio',
          clickOrDragFileToThisAreaToUpload: 'Haga clic o arrastre el archivo (solo uno) a esta área para cargarlo.',
          supportOnlyForCsvFile: 'Soporte solo para archivo CSV',
          areYouSureYouWantToDeleteThis: 'Estás seguro que quieres eliminar esto',
          yes: 'Sí',
          no: 'No',
          createRobot: 'Crear Robot',
          robotName: 'Nombre del robot',
          query: 'Consulta',
          status: 'Estado',
          type: 'Tipo',
          repeat: 'Repetir',
          start: 'Inicio',
          end: 'Fin',
          previouslySearchedContacts: 'Contactos buscados previamente',
          noSearchedContactInfo: 'No se ha buscado información de contacto',
          savedContacts: 'Contactos guardados',
          searchSavedContacts: 'Buscar contactos guardados',
          close: 'Cerca',
          frequentlySearched: 'Buscado con frecuencia',
          lastSearched: 'Última búsqueda',
          action: 'Acción',
          actions: 'Acciones',
          searchInSavedContacts: 'Buscar en contactos guardados',
          searchByGroup: 'Buscar por grupo',
          unsavedContacts: 'Contactos no guardados',
          searchUnsavedContacts: 'Buscar contactos no guardados',
          noInformation: 'Sin información',
          addContact: 'Agregar contacto',
          cancel: 'Cancelar',
          createNewContact: 'Crear nuevo contacto',
          nameIsRequired: 'Se requiere el nombre',
          wrongCsvStructure: 'Estructura csv incorrecta',
          fileWasUploaded: 'El archivo fue subido.',
          csvFileDoesNotContainNewContacts: 'El archivo CSV no contiene nuevos contactos.',
          readyForNewUpload: 'Listo para nueva carga',
          wrongFormat: 'Formato erróneo',
          areYouSureYouWantToDeleteThisContact: '¿Está seguro de que desea eliminar este contacto?',
          uploadContactsGroup: 'Subir grupo de contactos',
          uploadContactGroup: 'Subir grupo de contacto',
          newGroupName: 'Nuevo nombre de grupo',
          firstTypeTheNewGroupNameInFieldAbove: 'Primero, escriba el nuevo nombre del grupo en el campo de arriba.',
          searchGroup: 'Grupo de búsqueda',
          users: 'Usuarios',
          fetchingTable: 'Tabla de búsqueda',
          trackerRobot: 'Robot rastreador',
          onlineRobot: 'Robot en línea',
          countryZoneRobot: 'Robot Zona País',
          geoPointRobot: 'Robot de punto geográfico',
          pointToPointRobot: 'Robot punto a punto',
          geoPointsRobot: 'Robot de puntos geográficos',
          offlineRobot: 'Robot fuera de línea',
          tracker: 'Rastreador',
          online: 'En línea',
          countryZone: 'Zona del País',
          geoPoint: 'Punto geográfico',
          pointToPoint: 'Punto a punto',
          geoPoints: 'Puntos geográficos',
          offline: 'Desconectado',
          source: 'Fuente',
          sourceMode: 'Modo fuente',
          selectSourceMode: 'Seleccione el modo de fuente',
          sourceId: 'ID de la fuente',
          selectSourceId: 'Seleccione la ID de la fuente',
          terminals: 'Terminales',
          selectTerminals: 'Seleccionar terminales',
          standardSearch: 'Busqueda estandar',
          scheduledSearch: 'Búsqueda programada',
          scheduleTime: 'Tiempo programado',
          startEndTime: 'Hora de inicio y finalización',
          startTime: 'Hora de inicio',
          timeStart: 'Hora de inicio',
          endTime: 'Hora de finalización',
          repeatTime: 'Tiempo de repetición',
          min: 'min',
          repeatTimeMin: 'Tiempo de repetición (min)',
          initialSearchTime: 'Tiempo de búsqueda inicial',
          nrOfSearches: 'No. de búsquedas',
          stopOnResult: 'Detener en el resultado',
          country: 'País',
          countries: 'Países',
          selectCountry: 'Seleccionar país',
          countryCondition: 'Condición del país',
          selectCondition: 'Seleccionar condición',
          distance: 'Distancia',
          lat: 'Lat',
          lng: 'Lon',
          condition: 'Condición',
          exitFrom: 'Salir de',
          enterInto: 'Contraer',
          exit: 'Salida',
          enter: 'Ingresar',
          savePoint: 'Punto de guardado',
          clone: 'Clon',
          areYouSureYouWantToCreateThisRobot: '¿Estás segura de que quieres crear este robot?',
          inactive: 'Inactivo',
          activeRunning: 'Funcionamiento activo',
          finishedSuccess: 'Exito terminado',
          finishedTimeEnd: 'Fin del tiempo terminado',
          cannotRun: 'No puede correr',
          scheduleFinished: 'Horario terminado',
          fetchingRobots: 'Buscando robots',
          performedSearches: 'Búsquedas Realizadas',
          details: 'Detalles',
          latitudeValueMustBeBetween90And90: 'El valor de latitud debe estar entre -90 y 90',
          longitudeValueMustBeBetween180And180: 'El valor de longitud debe estar entre -180 y 180',
          startEndTimeIsRequired: 'Se requiere hora de inicio y finalización',
          repeatTimeIsRequired: 'Se requiere tiempo de repetición',
          initialSearchTimeIsRequired: 'Se requiere tiempo de búsqueda inicial',
          nrOfSearchesIsRequired: 'Nro. De búsquedas es obligatorio',
          robotNameIsRequired: 'El nombre del robot es obligatorio',
          sourceIdIsRequired: 'Se requiere ID de fuente',
          conditionIsRequired: 'Se requiere condición',
          countryConditionIsRequired: 'Se requiere la condición del país',
          countryIsRequired: 'Se requiere país',
          distanceIsRequired: 'Se requiere distancia',
          targetIdIsRequired: 'Se requiere ID de objetivo',
          generalExplanation: 'Explicación general',
          explanationGp: 'Explicación PG',
          noSearches: 'Sin búsquedas',
          robotCreation: 'Creación de Robots',
          selectType: 'Seleccione tipo',
          selectStatus: 'Seleccionar estado',
          youCanDeleteThePointHere: 'Puede eliminar el punto aquí',
          deletePoint: 'Eliminar punto',
          sourceName: 'Nombre de la fuente',
          actionCondition: 'Condición de acción',
          targetName: 'Nombre de destino',
          information: 'Información',
          areYouSureYouWantToDeleteThisRobot: '¿Está seguro de que desea eliminar este robot?',
          confirmDeletion: 'Confirmar la eliminación',
          loadingAdditionalRobotData: '¡Cargando datos de robot adicionales!',
          resultInformation: 'Información de resultados',
          robotLocationResults: 'Resultados de la ubicación del robot',
          allLocations: 'Todas las localizaciones',
          noLocationResults: 'No hay resultados de ubicación',
          noCountry: 'Ningún país',
          changePassword: 'Cambiar la contraseña',
          newPassword: 'Nueva contraseña',
          repeatString: 'Repetir',
          submit: 'Entregar',
          passwordRules:
            'La contraseña debe contener al menos un carácter en mayúsculas y minúsculas, un número y tener al menos 6 caracteres.',
          changePhoneEmail: 'Cambiar teléfono y correo electrónico',
          changeNotificationMethod: 'Cambiar método de notificación',
          notificationMethod: 'Método de notificación',
          off: 'Apagado',
          userMail: 'Correo de usuario',
          userSms: 'SMS de usuario',
          userBoth: 'Usuario ambos',
          teamMail: 'Correo del equipo',
          teamSms: 'SMS de equipo',
          teamBoth: 'Equipo ambos',
          changeTimezone: 'Cambiar zona horaria',
          theTimeZoneWasSet: 'Se configuró la zona horaria',
          browserNotification: 'Notificación del navegador',
          notificationSound: 'Sonido de notificación',
          successSound: 'Sonido de éxito',
          errorSound: 'Sonido de error',
          selectSuccessSoundForNotification: 'Seleccione el sonido de éxito para la notificación',
          selectErrorSoundForNotification: 'Seleccione el sonido de error para la notificación',
          sound: 'Sonido',
          navigationBarSticky: 'Barra de navegación pegajosa',
          darkMode: 'Modo oscuro',
          language: 'Idioma',
          agentTemplateSettings: 'Configuración de la plantilla de agente',
          agents: 'Agentes',
          add: 'Agregar',
          update: 'Actualizar',
          agent: 'Agente',
          passwordMustBeAtLeast6CharactersContainOneUpperLowerCaseAndNumber:
            'La contraseña debe tener al menos 6 caracteres, contener una mayúscula, una minúscula y un número.',
          thePhoneNumberIsRequired: 'El número de teléfono es requerido',
          emailIsRequired: 'Correo electronico es requerido',
          areYouSureYouWantToDeleteThisAgent: '¿Está seguro de que desea eliminar este agente?',
          somethingWentWrongUpdateFailed: 'Something went wrong. Update failed!',
          theBrowserNotificationWasChangedSuccessfully: 'La notificación del navegador se cambió con éxito',
          thisFieldIsRequired: 'Este campo es obligatorio',
          thePasswordMustBeAtLeast6Characters: 'La contraseña debe tener al menos 6 caracteres.',
          passwordMustContainAtLeastOneUpperLowerCaseCharacterAndOneNumber:
            'La contraseña debe contener al menos un carácter en mayúsculas y minúsculas y un número',
          passwordConfirmationDoesNotMatch: 'La confirmación de la contraseña no coincide',
          yourPasswordHasBeenResetSuccessfully: 'Su contraseña ha sido restablecida con éxito',
          setNewPassword: 'Establecer nueva contraseña',
          yourContactInfoHasBeenResetSuccessfully: 'Su información de contacto se ha restablecido con éxito',
          email: 'Correo electrónico',
          theSoundNotificationWasChangedSuccessfully: 'La notificación de sonido se cambió con éxito',
          license: 'Licencia',
          userList: 'Lista de usuarios',
          inTeams: 'En equipos',
          isLeader: 'Es lider',
          lastOnline: 'Última conexión',
          edit: 'Editar',
          suspend: 'Suspender',
          createTeam: 'Crear equipo',
          teams: 'Equipos',
          info: 'Información',
          updatedAt: 'Actualizado en',
          usersInTeam: 'Usuarios en equipo',
          teamsEnabledList: 'Lista de equipos habilitados',
          selectTeam: 'Selecciona un equipo',
          team: 'Equipo',
          blockedList: 'Lista bloqueada',
          addPhoneImsiToBlockedList: 'Agregar teléfono/IMSI a la lista de bloqueados',
          addBlockedListRules:
            'El primer número es Teléfono y el segundo es IMSI. Los terminales están separados por nueva línea.',
          ex: 'Ex',
          ifYouWantToEnterOnlyPhoneOrImsi: 'Si quieres entrar solo por teléfono o Imsi',
          permissionsEdit: 'Edición de permisos',
          credentialsCreditsEdit: 'Credenciales y créditos Editar',
          toggleAllPermissions: 'Alternar todos los permisos',
          permission: 'Permiso',
          createString: 'Crear',
          view: 'Vista',
          enable: 'Permitir',
          robot: 'Robot',
          contact: 'Contacto',
          contactGroup: 'Grupo de contacto',
          locate: 'Localizar',
          teamContact: 'Contacto del equipo',
          teamRobot: 'Robot de equipo',
          teamContactGroup: 'Grupo de contacto del equipo',
          cellReport: 'Informe de celda',
          dailyCreditsRules: 'Créditos diarios. (escriba -1 para créditos ilimitados)',
          allocatedCreditsRules: 'Créditos asignados. (escriba -1 para eliminar los créditos asignados)',
          leader: 'Líder',
          notLeader: 'No líder',
          teamManagementDeleteMessage:
            'El modo de eliminación eliminará todos los datos del usuario y luego eliminará al usuario mismo, el modo de transferencia transferirá todos los datos del usuario a un usuario específico y luego eliminará solo al usuario.',
          transfer: 'Transferir',
          selectUserForTransfer: 'Seleccionar usuario para transferencia',
          ok: 'OK',
          editAppSettings: 'Editar la configuración de la aplicación',
          notificationTemplateSettings: 'Configuración de la plantilla de notificación',
          adminList: 'Lista de administradores',
          createAdmin: 'Crear administrador',
          suspendedUsers: 'Usuarios suspendidos',
          logs: 'Registros',
          messageId: 'Identificación del mensaje',
          added: 'Agregado',
          mapConfig: 'Configuración del mapa',
          mapApiKey: 'Clave de la API del mapa',
          mapUrl: 'URL del mapa',
          tokenExpiration: 'Caducidad del token',
          accessToken: 'Ficha de acceso',
          refreshToken: 'Ficha de actualización',
          appToken: 'Ficha de aplicación',
          url: 'URL',
          radiusThreshold: 'Umbral de radio',
          dailyAppCredits: 'Créditos diarios de la aplicación',
          robotRepeatTime: 'Tiempo de repetición del robot',
          appStatus: 'Estado de la aplicación',
          toggleButtonToOnOff: 'Botón de alternancia para ENCENDIDO/APAGADO',
          generateCreditRecords: 'Generar Registros de Crédito',
          generate: 'Generar',
          robotDataExpiryDays: 'Días de caducidad de los datos del robot',
          daysOld: 'Viejos días',
          setTheMinutesForRecency: 'Establecer los minutos de actualidad',
          recency: 'Frescura',
          configCellExpiration: 'Caducidad de la celda de configuración (día)',
          cellExpiration: 'Caducidad celular',
          dashboardQueriesAge: 'Edad de las consultas del panel',
          computedCellMinThreshold: 'Umbral mínimo de celda calculada',
          globalMapSettings: 'Configuración del mapa global',
          public_osm_url: 'URL pública de osm',
          publicOsmUrlIsARequiredField: 'la url pública de osm es un campo obligatorio',
          hosted_osm_url: 'URL osm alojada',
          google_map_token: 'Token de mapa de Google',
          mapbox_style_basic: 'Estilo Mapbox básico',
          mapbox_style_satellite: 'Satélite estilo Mapbox',
          mapbox_token: 'Ficha de cuadro de mapa',
          mapbox_account_name: 'Nombre de cuenta de Mapbox',
          heatmapSettings: 'Configuración del mapa de calor',
          value: 'Valor',
          greaterThan: 'Mas grande que',
          lowerThan: 'Más bajo que',
          addNewThreshold: 'Agregar nuevo umbral',
          password: 'Contraseña',
          repeatPassword: 'Repita la contraseña',
          createUser: 'Crear usuario',
          teamManagement: 'Gestión de equipos',
          addUserToTeam: 'Agregar usuario al equipo',
          noUsersIn: 'No hay usuarios en',
          deleteTeamRules:
            'El modo de eliminación eliminará todos los datos del equipo y luego eliminará el equipo en sí, el modo de transferencia transferirá todos los datos del equipo a un usuario específico y luego eliminará solo el equipo.',
          save: 'Ahorrar',
          teamName: 'Nombre del equipo',
          userNameSeparate: 'Nombre de usuario',
          restore: 'Restaurar',
          dailyCredits: 'Créditos diarios',
          pleaseAddSomeValueToOneOrBothInputs: 'Agregue algún valor a una o ambas entradas',
          imsiMustBe14To15Digits: 'IMSI debe tener entre 14 y 15 dígitos',
          msisdnMustBe9To17Digits: 'MSISDN debe tener entre 9 y 17 dígitos',
          teamNameIsRequired: 'El nombre del equipo es obligatorio',
          fieldsAreRequired: 'Los campos son obligatorios',
          pleaseSelectTeam: 'Por favor seleccione equipo',
          pleaseAddSomeValueToOneOfTheInputs: 'Por favor agregue algún valor a una de las entradas.',
          imsiMustBe1To15Digits: 'IMSI debe tener de 1 a 15 dígitos',
          msisdnMustBe1To15Digits: 'MSISDN debe tener entre 1 y 15 dígitos',
          passwordIsRequired: 'Se requiere contraseña',
          passwordAndRepeatPasswordDoesntMatch: 'La contraseña y la repetición de contraseña no coinciden',
          userNameIsRequired: 'Se requiere nombre de usuario',
          credentialsHasBeenResetSuccessfully: 'Las credenciales se han restablecido correctamente',
          youMustSelectUserForTransfer: 'Debe seleccionar el usuario para la transferencia',
          transferFail: 'Transferencia fallida!',
          youMustSelectUser: 'Debes seleccionar usuario',
          createTeamList: 'Crear lista de equipos',
          passwordHasBeenResetSuccessfully: 'La contraseña se ha restablecido con éxito',
          changesHasBeenSaved: 'Los cambios se han guardado',
          userDailyCredits: 'Créditos diarios de usuario',
          binary: 'Binario',
          text: 'Texto',
          destination: 'Destino',
          robotNotification: 'Notificación de robots',
          defaultSmsForward: 'Reenvío de SMS predeterminado',
          templateSettings: 'Configuración de la plantilla',
          toggleButtonToLockUnlockTemplate: 'Botón de alternar para bloquear/desbloquear plantilla',
          templateWasUpdated: 'Se actualizó la plantilla',
          appSettings: 'Ajustes de Aplicacion',
          mailConfig: 'Configuración de correo',
          mailDriver: 'Controlador de correo',
          mailHost: 'Servidor de correo',
          mailPort: 'Puerto de correo',
          mailUsername: 'Nombre de usuario de correo',
          mailPassword: 'Contraseña de correo',
          mailEncryption: 'Cifrado de correo',
          mailFromAddress: 'Correo de la dirección',
          mailFromName: 'Correo desde Nombre',
          sendMailSystemPath: 'Ruta del sistema de envío de correo',
          notificationMessage: 'Mensaje de notificación',
          rabitMq: 'Conejo MQ',
          host: 'Anfitrión',
          port: 'Puerto',
          queue: 'Cola',
          smsConfig: 'Configuración SMS',
          headerName: 'Nombre del encabezado',
          headerValue: 'Valor de encabezado',
          areYouSure: 'Estas seguro?',
          generateReport: 'Generar informe',
          uploadCellData: 'Subir datos de celda',
          supportOnlyForZipFiles: 'Soporte solo para archivos ZIP',
          fileImports: 'Importaciones de archivos',
          id: 'Id',
          totalRows: 'Filas totales',
          new: 'Nuevo',
          updated: 'Actualizado',
          processed: 'Procesada',
          current: 'Actual',
          progress: 'Progreso',
          fileSize: 'Tamaño del archivo',
          waitingInQueue: 'Esperando en cola',
          files: 'Archivos',
          folders: 'Carpetas',
          noData: 'Sin datos',
          cell: 'Celúla',
          resetFilters: 'Restablecer filtros',
          showManual: 'MOSTRAR MANUAL',
          range: 'Rango',
          angle: 'Ángulo',
          width: 'Ancho',
          dateOfExpirity: 'Fecha de vencimiento',
          deleteAllCells: 'Eliminar todas las celdas',
          cells: 'Células',
          areYouSureYouWantToDeleteAllCells: '¿Estás seguro de que quieres eliminar todas las celdas?',
          sureToSave: '¿Seguro de guardar?',
          sureToDelete: '¿Estás seguro de eliminar?',
          address: 'Dirección',
          deleteThisFileRecord: '¿Eliminar este registro de archivo?',
          deleteFilesInstruction:
            'Eliminar el registro antes de que se procese el archivo dejará el archivo físico huérfano en el disco, luego elimine el archivo manualmente usando la sección Archivos',
          formatError: '¡Error de formato!',
          supportOnlyZipFiles: 'Admite solo archivos ZIP',
          dateOfExpiry: 'Fecha de expiración',
          selectors: 'Selectores',
          item: 'Artículo',
          items: 'Elementos',
          availableColumns: 'Columnas disponibles',
          activeColumns: 'Columnas activas',
          resultTime: 'Tiempo de resultado',
          msisdn: 'MSISDN',
          mcc: 'MCC',
          mnc: 'MNC',
          lac: 'LAC',
          tac: 'TAC',
          cellid: 'CELLID',
          imei: 'IMEI',
          state: 'Estado',
          countryIso: 'País ISO',
          age: 'Edad',
          errorCode: 'Código de error',
          username: 'Nombre de usuario',
          archive: 'Archivo',
          unarchive: 'Desarchivar',
          locations: 'Ubicaciones',
          archivedLocations: 'Ubicaciones archivadas',
          downloadCsv: 'Descargar CSV',
          withArchived: 'Con archivado',
          withoutArchived: 'Sin Archivado',
          noTeam: 'Sin equipo',
          searchType: 'Tipo de búsqueda',
          youDontHavePermissionsToUseThisFilter: 'No tienes permisos para usar este filtro',
          allQueryTypes: 'Todos los tipos de consulta',
          noValue: 'Sin valor',
          noSearchTypes: 'Sin tipos de búsqueda',
          filterColumns: 'Filtrar columnas',
          sensors: 'Sensores',
          correlation: 'Correlación',
          filters: 'Filtros',
          locationTime: 'Hora de la ubicación',
          sensorId: 'Identificación del sensor',
          sersorTechnology: 'Tecnología de sensores',
          sensorsSerial: 'Serie de sensores',
          rsrp: 'rsrp',
          noSensor: 'Sin sensor',
          filter: 'Filtrar',
          createCorrelation: 'Crear correlación',
          correlationName: 'Nombre de correlación',
          addSensor: 'Añadir sensor',
          correlatedImsiResults: 'Resultados IMSI correlacionados',
          foundAtSensors: 'Encontrado en los sensores',
          match: 'Fósforo',
          sensorTechnology: 'Tecnología de sensores',
          thisFieldCanMatchPartialStrings: 'este campo puede coincidir con cadenas parciales',
          sensorTime: 'Tiempo del sensor',
          deleteThisSensorGroup: '¿Eliminar este grupo de sensores?',
          deleteThisSensor: '¿Eliminar este sensor?',
          selectSensor: 'Seleccionar sensor',
          fileName: 'Nombre del archivo',
          noFilesAvailable: 'No hay archivos disponibles',
          noTechnology: 'Sin tecnología',
          addFilter: 'Añadir filtro',
          sensorSerial: 'Serie del sensor',
          refresh: 'Actualizar',
          somethingWentWrongPleaseTryAnotherName: 'Algo salió mal. Por favor, prueba con otro nombre.',
          pleaseInsertSomeName: 'Por favor, inserte algún nombre',
          somethingWentWrongPleaseTryAgain: 'Algo salió mal. Inténtalo de nuevo',
          pleaseFillAllInputs: 'Por favor, complete todas las entradas',
          clickToUpload: 'Haga clic para cargar',
          originalName: 'Nombre original',
          path: 'Camino',
          size: 'Tamaño',
          processedRows: 'Filas procesadas',
          areYouSureYouWantToDeleteThisSurvey: '¿Estás seguro de que quieres eliminar esta encuesta?',
          uploadSurvey: 'Subir encuesta',
          noAlias: 'Sin alias',
          noImsi: 'Sin IMSI',
          editContact: 'Editar contacto',
          addTerminal: 'Añadir terminal',
          isPrimary: 'Es primaria',
          readMore: 'Leer más',
          areYouSureYouWantToArchiveUnarchive: '¿Está seguro de que desea archivar/desarchivar?',
          removePrimary: 'Eliminar principal',
          setAsPrimary: 'Establecer como primario',
          editTerminal: 'Editar terminal',
          archiveTerminal: 'terminal de archivo',
          terminal: 'Terminal',
          deleteTerminal: 'Eliminar terminal',
          contactComments: 'Comentarios de contacto',
          writeComment: 'Escribir comentario',
          show: 'Mostrar',
          all: 'Todo',
          robotDeleted: 'Eliminado por robot',
          manual: 'Manual',
          primary: 'Primario',
          noSearchType: 'Sin tipo de búsqueda',
          noLocation: 'Sin ubicación',
          youDontHaveAnyTemplate: 'No tienes ninguna plantilla. Por favor',
          one: 'Uno',
          smsDisabled: 'SMS deshabilitado',
          azimuth: 'Azimuth',
          timeEnd: 'Fin del tiempo',
          updateRobot: 'Actualizar robot',
          targetId: 'ID de destino',
          selectTargetId: 'Seleccione la ID del objetivo',
          areYouSureYouWantToCloneThisRobot: '¿Estás seguro de que quieres clonar este robot?',
          leaveSomeCommentForThatUser: 'Deja algún comentario para ese usuario',
          thereAreNoComments: 'No hay comentarios',
          contactInfo: 'Datos de contacto',
          comments: 'Comentarios',
          currentLocation: 'Ubicación actual',
          toggleContactBlock: 'Alternar bloque de contactos',
          areYouSureYouWantToDeleteThisTerminal: 'Estás seguro de que deseas eliminar esta Terminal',
          noArchivedTerminals: 'Sin terminales archivados',
          noCreatedTerminals: 'No hay terminales creados',
          viewPatternOfLife: 'Ver patrón de vida',
          enablePatternOfLife: 'Habilitar patrón de vida',
          enterPhoneNumber: 'Ingresa número telefónico',
          enterImsiNumber: 'Ingrese el número imsi',
          availableTerminals: 'Terminales disponibles',
          createTerminal: 'Crear terminal',
          archivedTerminals: 'Terminales archivadas',
          alert: 'Alerta',
          azimuthAt: 'Acimut en',
          at: 'en',
          cloneRobot: 'Robot clon',
          creditsGenerationFiled: 'Generación de créditos presentada',
          deleteFail: 'Error al eliminar',
          editRobot: 'Editar robot',
          error: '¡Error!',
          explanation: 'Explicación',
          filterLocations: 'Filtrar ubicaciones',
          groupSameLocations: 'Agrupar las mismas ubicaciones',
          here: 'aquí',
          incorrectPassword: 'Contraseña incorrecta',
          informationWasSent: 'Se envió información',
          locationName: 'Nombre del lugar',
          locationsClearForbidden: 'Ubicaciones claras prohibidas',
          network: 'Red',
          noDate: 'Sin cita',
          otherQueries: 'Otras Consultas',
          others: 'Otros',
          plotRoute: 'Trazar ruta',
          recordsWereGenerated: 'Se generaron registros',
          reset: 'Reiniciar',
          selectAgents: 'Seleccionar agentes',
          send: 'Enviar',
          somethingWentWrong: 'Algo salió mal',
          success: '¡Éxito!',
          successfullyUploaded: 'Cargado con éxito',
          theNotificationMethodWasChangedSuccessfully: 'El método de notificación se cambió con éxito',
          thePasswordWasChangedSuccessfully: 'La contraseña fue cambiada exitosamente',
          thePasswordsDontMatch: 'Las contraseñas no coinciden',
          thePasswordFieldsCantBeEmpty: 'Los campos de contraseña no pueden estar vacíos',
          thePhoneEmailWasChangedSuccessfully: 'El teléfono y el correo electrónico se cambiaron con éxito',
          setTimeZone: 'Se configuró la zona horaria',
          toggleHeatmapView: 'Alternar vista de mapa de calor',
          togglePlotsView: 'Alternar vista de gráficos',
          viewAllLocations: 'Ver todas las ubicaciones',
          youCanCheckYourExistingTemplate: 'Puede comprobar su plantilla existente',
          youMustSelectDeleteOrTransferOption: 'Debe seleccionar la opción ELIMINAR o Transferir',
          selectContact: 'Seleccione un contacto',
          saveToContact: 'Guardar en contacto',
          submitted: 'Enviado',
          completed: 'Terminado',
          closeCreation: 'Cerrar creación',
          checkingForExistingTemplates: 'Comprobación de plantillas existentes',
          smsServiceNotConfigured: 'Servicio SMS no configurado',
          noCoordinates: 'Sin coordenadas',
          found: 'Encontró',
          networkName: 'Nombre de red',
          networkBrand: 'Marca de red',
          networkId: 'Id de red',
          plmnId: 'PlmnId',
          null: 'NULO',
          true: 'Verdadero',
          false: 'Falso',
          dataIsMissing: 'Faltan datos',
          subscriberType: 'Tipo de suscriptor',
          reachable: 'Accesible',
          forwarded: 'Reenviado',
          dosState: 'Dos Estado',
          numberOwnership: 'Número de propiedad',
          subscriptionWith: 'Suscripción con',
          resultFailed: 'Resultado fallido',
          plmnNetwork: 'Red Plmn',
          loginPage: 'Página de inicio de sesión',
          loginFail: 'Carga fallida',
          login: 'Acceso',
          usernameIsRequired: 'Se requiere nombre de usuario',
          somethingWentWrongPleaseAskAdmin: 'Algo salió mal. ¡Pregúntele al administrador!',
          userNameFieldCantBeBlank: 'El campo de nombre de usuario no puede estar en blanco',
          passwordFieldCantBeBlank: 'El campo de contraseña no puede estar en blanco',
          created: 'Creado',
          edited: 'Editado',
          deleted: 'Eliminado',
          suspended: 'Suspendido',
          updatedPassword: 'Contraseña actualizada',
          assignedCredits: 'Créditos asignados',
          selectAction: 'Seleccione la acción',
          superadmin: 'superadministrador',
          admin: 'Administración',
          user: 'Usuario',
          roles: 'Roles',
          selectRole: 'Seleccionar rol',
          startDate: 'Fecha de inicio',
          dateStart: 'Fecha de inicio',
          endDate: 'Fecha final',
          dateEnd: 'Fecha final',
          noInfo: 'Sin información',
          logType: 'Tipo de registro',
          timestamp: 'Marca de tiempo',
          loggedOut: 'Desconectado',
          loggedIn: 'Conectado',
          restored: 'Restaurado',
          allocated: 'Asignado',
          unlimited: 'Ilimitado',
          totalCreditsToUser: 'Créditos totales al usuario',
          dailyCreditsToUser: 'Créditos diarios al usuario',
          createdTeam: 'Equipo creado',
          updatedTeam: 'Equipo actualizado',
          deletedTeam: 'equipo eliminado',
          assignedUser: 'Usuario asignado',
          toTeam: 'Al equipo',
          detachedUser: 'Usuario desconectado',
          fromTeam: 'Del equipo',
          createdRobot: 'Robot creado',
          deletedRobot: 'Robot eliminado',
          editedRobot: 'Robot editado',
          wasFinished: 'Estuvo terminado',
          author: 'Autor',
          createdContact: 'Contacto creado',
          editedContact: 'Contacto editado',
          deletedContact: 'Contacto eliminado',
          changedPasswordForUser: 'Contraseña cambiada para el usuario.',
          deletedUser: 'Usuario eliminado',
          restoredUser: 'Usuario restaurado',
          suspendedUser: 'Usuario suspendido',
          createdUser: 'Usuario creado',
          toUser: 'Al usuario',
          creditsToUser: 'Créditos al usuario',
          logout: 'Cerrar sesión',
          userCreated: 'Usuario creado',
          userSuspended: 'Usuario suspendido',
          userRestored: 'Usuario restaurado',
          userDeleted: 'Usuario eliminado',
          userChangedPassword: 'Usuario cambió contraseña',
          adminAllocatedCreditToUser: 'El administrador asignó crédito al usuario',
          superadminAllocatedCreditToAdmin: 'El superadministrador asignó crédito al administrador',
          leaderAllocatedCreditToUser: 'Líder asignado crédito al usuario',
          teamCreated: 'Equipo creado',
          teamUpdated: 'Equipo actualizado',
          teamDeleted: 'Equipo eliminado',
          teamAssigned: 'Equipo asignado',
          teamDetached: 'Equipo separado',
          robotCreated: 'Robot creado',
          robotEdited: 'Robot editado',
          robotFinished: 'Robot terminado',
          contactCreated: 'Contacto creado',
          contactEdited: 'Contacto editado',
          contactDeleted: 'Contacto eliminado',
          welcomeBack: 'Bienvenido de nuevo',
          otherPages: 'Otras páginas',
          signOut: 'Desconectar',
          notifications: 'Notificaciones',
          markAllAsRead: 'Marcar todo como leido',
          pleaseSwitchTo: 'Cafmbia a',
          toSeeTheResult: 'para ver el resultado',
          loading: 'Cargando',
          result: 'Resultado',
          forwardSearchFailed: 'Error en la búsqueda directa',
          numberCopiedToClipboard: 'Número copiado al portapapeles',
          undoDeleting: 'Deshacer eliminación',
          copy: 'Copiar',
          quickSearch: 'Búsqueda rápida',
          unfavorite: 'No favorito',
          unknown: 'Desconocido',
          forward: 'Adelante',
          relatedContacts: 'Contactos Relacionados',
          noContacts: 'Sin contactos',
          createContactWithResultsBellow: 'Crear contacto con los resultados a continuación',
          selectAgent: 'Seleccionar agente(s)',
          noQueriesAvailable: 'No hay consultas disponibles',
          selectLanguage: 'Seleccione el idioma',
          english: 'Inglés',
          spanish: 'Español',
          ruler: 'Gobernante',
          minimiseMap: 'Minimizar mapa',
          viewFullScreen: 'Ver en pantalla completa',
          heatMap: 'Mapa de calor',
          plot: 'Trama',
          noHeatMapPoints: 'Sin puntos del mapa de calor',
          plotFailed: 'Trama fallida',
          noSingleView: 'Ninguna vista única',
          option: 'Opción',
          theLabelMustBeAtLeast2Characters: 'La etiqueta debe tener al menos 2 caracteres.',
          noLabel: 'Sin etiqueta',
          frequency: 'Frecuencia',
          frequencyShorten: 'Frc',
          date: 'Fecha',
          time: 'Tiempo',
          day: 'Día',
          monday: 'Lunes',
          tuesday: 'Martes',
          wednesday: 'Miércoles',
          thursday: 'Jueves',
          friday: 'Viernes',
          saturday: 'Sábado',
          sunday: 'Domingo',
          selectImsiPhone: 'Seleccione IMSI/Teléfono',
          weekday: 'Día laborable',
          weekdays: 'Día(s) laborable(s)',
          disable: 'Desactivar',
          export: 'Exportar',
          patternOfLife: 'Patrón de vida',
          searches: 'Búsquedas',
          location: 'Ubicación',
          toggleLocationSearchResults: 'Alternar resultados de búsqueda de ubicación',
          other: 'Otro',
          noLocationsFoundWithThisContact: 'No se encontraron ubicaciones con este contacto',
          package: 'Paquete',
          repository: 'Repositorio',
          version: 'Versión',
          sorryThePageYouVisitedDoesNotExist: 'Lo sentimos, la página que visitaste no existe.',
          goToDashboard: 'Ir al panel de control',
          stop: 'Detener',
          playRoutePath: 'Jugar Ruta Ruta',
          markerInfo: 'Información del marcador',
          targetHeatPoints: 'Puntos de calor objetivo',
          pointSizeM: 'Tamaño del punto (m)',
          pointOpacity: 'Opacidad puntual',
          borderOpacity: 'Opacidad del borde',
          setSettings: 'Establecer configuración',
          tryToRefreshThePageOrContactAdministrator: 'Intente actualizar la página o comuníquese con el administrador',
          youDontHavePermissionsToUseThisAction: 'No tienes permisos para usar esta acción',
          selectDate: 'Seleccione fecha',
          notice: 'Aviso',
          noLocations: 'Sin ubicaciones',
          editColor: 'Editar color',
          selectTimezone: 'Selecciona la zona horaria',
          clickToSortAscending: 'Haga clic para ordenar de forma ascendente',
          clickToSortDescending: 'Haga clic para ordenar de forma descendente',
          clickToCancelSorting: 'Haga clic para cancelar la clasificación',
          mon: 'Lun',
          tue: 'Mar',
          wed: 'Mié',
          thu: 'Jue',
          fri: 'Vie',
          sat: 'Sáb',
          sun: 'Dom',
          viewTeam: 'Ver equipo',
          changesHaveBeenSaved: 'Los cambios han sido guardados',
          leaderStatusWarning:
            'Los líderes pueden ver a otros usuarios dentro de sus equipos y asignar créditos a los usuarios',
          sorryYouAreNotAuthorizedToAccessThisTeam: 'Lo sentimos, no estás autorizado a acceder a este equipo.',
          sorryThisTeamWasNotFound: 'Lo sentimos, este equipo no fue encontrado.',
          appSettingsUpdated: 'Configuración de la aplicación actualizada',
          notificationMessageUpdated: 'Mensaje de notificación actualizado',
          creditsGenerationFailed: 'Error en la generación de créditos',
          cellExportFile: 'Archivo de exportación celular ',
          readyToDownload: 'listo para descargar',
          preparingCellsFilePleaseCheckTheNotificationsToDownloadIt:
            'Preparando archivo de celdas. Por favor, revisa las notificaciones para descargarlo.',
          requestAccessFromAdministrator: 'Solicitar acceso al administrador',
          serviceUnavailable: 'Servicio No Disponible',
          empty: 'Vacío',
          askForPermissionToCreateATeam: 'Solicitar permiso para crear un equipo',
          selectAllData: 'Seleccionar todos los datos',
          invertCurrentPage: 'Invertir la página actual',
          createContactsGroup: 'Crear grupo de contactos',
          createContactGroup: 'Crear grupo de contact',
          deleteThisGroup: 'Eliminar este grupo',
          allLocationsHistory: 'Historial de todas las ubicaciones',
          noRobots: 'No robots',
          showAllLocations: 'Mostrar todas las ubicaciones',
          viewContact: 'Ver contacto',
          primaryTerminal: 'Terminal principal',
          lastLocation: 'Última ubicación',
          noLastLocation: 'Sin última ubicación',
          pleaseSelectContact: 'Por favor, seleccione contacto',
          contactsGroupNameIsRequired: 'El nombre del grupo de contactos es obligatorio',
          contactGroupNameIsRequired: 'El nombre del grupo de contacto es obligatorio',
          runLocationSearchIsRequired: 'Se requiere ejecutar búsqueda de ubicación',
          immediately: 'Inmediatamente',
          fiveMinutesAfter: '5 minutos después',
          tenMinutesAfter: '10 minutos después',
          twentyMinutesAfter: '20 minutos después',
          thirtyMinutesAfter: '30 minutos después',
          sixtyMinutesAfter: '60 minutos después',
          runLocationSearch: 'Ejecutar búsqueda de ubicación',
          startTimeIsRequired: 'Se requiere hora de inicio',
          liveSearchIsRequired: 'Se requiere búsqueda en vivo',
          on: 'Encendido',
          liveSearch: 'Búsqueda en vivo',
          select: 'Seleccionar',
          registeredElsewhere: 'Registrado en otro lugar',
          initiatingRoamingRequest: 'Iniciando solicitud de roaming',
          alertDetails: 'Detalles de alerta',
          incomingSms: 'SMS entrantes',
          noMessages: 'Ningún mensaje',
          roamed: 'Vagaba',
          registerCanceled: 'Registro cancelado',
          smsIntercept: 'Intercepción de SMS',
          waiting: 'Espera',
          cancelSmsRegister: 'Cancelar SMS Registrarse',
          areYouSureYouWantToCancelSmsRegister: 'Estás seguro de que deseas cancelar el registro de SMS',
          smsLocate: 'Localizar SMS',
          smsLocateSettings: 'Configuración de localización de SMS',
          numbersHandler: 'Manejador de números',
          managePhoneNumbers: 'Administrar números de teléfono',
          imsiIsRequired: 'Se requiere IMSI',
          itIsNotAllowedToAddMoreThen5Contacts: 'No está permitido agregar más de 5 contactos.',
          exportCellData: 'Exportar datos celulares',
          cellData: 'Datos celulares',
          noActiveRobots: 'Sin robots activos',
          ssoLogin: 'Iniciar sesión SSO',
          cantBeAuthorized: 'No se puede autorizar',
          invalid: 'Inválido',
          existing: 'Existente',
          seeDetails: 'Ver detalles',
          theExactRowNumbersWith: 'Los números de fila exactos con',
          row: 'Fila',
          newData: 'Nuevos datos',
          existingData: 'Los datos existentes',
          invalidData: 'Datos inválidos',
          yourTemplateIsLockedByTheSuperAdmin: 'Su plantilla está bloqueada por el superadministrador',
          yourTemplateIsEmptyAndItIsProvidedByTheSuperAdmin:
            'Su plantilla está vacía y la proporciona el superadministrador',
          hoverToSeeTheDefaultTemplate: 'Coloca el cursor para ver la plantilla predeterminada',
          orCreateYourOwn: 'o crea el tuyo propio',
          yourTemplateIsLockedByTheSuperAdminButTheDefaultOneIsEmpty:
            'El superadministrador bloquea su plantilla, pero la predeterminada está vacía',
          cancelSearch: 'Cancelar búsqueda',
          setTime: 'Fijar tiempo',
          sureToRegenerateAndResendPassword: 'Seguro que deseas regenerar y reenviar la contraseña?',
          regeneratePassword: 'Regenerar contraseña',
          requestPassword: 'Solicitud de contraseña',
          sureToRequestPassword: 'Seguro que solicitarás la contraseña?',
          requestHasBeenSent: 'La solicitud ha sido enviada',
          timeHasBeenReset: 'El tiempo se ha reiniciado',
          passwordHasBeenRegenerated: 'La contraseña ha sido regenerada',
          manageTimeAndPassword: 'Administrar tiempo y contraseña',
          highPrecision: 'Alta precisión',
          uuid: 'UUID',
          smsLocatePhoneList: 'SMS Localizar lista de teléfonos',
          smsLocateNumber: 'SMS Localizar número',
          nothingAvailable: 'Nada disponible',
          isRegistered: 'Esta registrado',
          notRegistered: 'No registrado',
          notPossibleToUseThisNumber: 'No es posible utilizar este número.',
          pleaseContactAdministrator: 'Por favor contacte al administrador',
          attach: 'Adjuntar',
          youAreNotAllowedToAddMoreThen5Contacts: 'No puedes agregar más de 5 contactos',
          hide: 'Esconder',
          unhide: 'Mostrar',
          hiddenList: 'Lista oculta',
          enabledList: 'Lista habilitada',
          disableSmsLocateInfo:
            'Está seguro de que desea desactivar la localización por SMS? En caso de que acepte todos los números de este administrador y sus usuarios, se cancelará el registro.',
          changedPhone: 'Cambió de teléfono',
          actionRequired: 'Acción requerida',
          noFiltersApplied: 'No se aplicaron filtros',
          generatingYourPdf: 'Generando tu PDF',
          pleaseDontRefreshOrNavigateAway: 'Por favor, no actualices ni navegues',
          isHiddenList: 'Es una lista oculta',
          highPrecisionDetails: 'Detalles de alta precisión',
          apiKey: 'Clave API',
          refine: 'Refinar',
          refinePercentileConfig: 'Refinar la configuración del percentil',
          refineConfig: 'Refinar configuración',
          brandName: 'Nombre de marca',
          modelName: 'Nombre del modelo',
          refineIsNotCurrentlyAvailableAtThisLocation: 'Refinar no está disponible actualmente en esta ubicación.',
          locationHasNotAllNecessaryParametersForGettingPolygon:
            'La ubicación no tiene todos los parámetros necesarios para obtener el polígono.',
          noResult: 'Sin resultado',
          resetDates: 'Restablecer Fechas',
          resetTags: 'Borrar Etiquetas',
          showCellRange: 'Mostrar rango de celdas',
          hideCellRange: 'Ocultar rango de celdas',
          sorryYouAreNotAuthorizedToAccessThisRobot: 'Lo sentimos, no estás autorizado para acceder a este robot.',
          sorryTheRequestedRobotIsNotFound: 'Lo sentimos, el robot solicitado no se encuentra.',
          sorryTheRequestedContactIsNotFound: 'Lo sentimos, el contacto solicitado no se encuentra.',
          sorryTheRequestedContactGroupIsNotFound: 'Lo sentimos, el grupo de contacto solicitado no se encuentra.',
          loadMore: 'Cargar más',
          of: 'De',
          deletedAt: 'Eliminado en',
          selectByDateOrShowAll: 'Seleccionar por fecha o mostrar todo',
          applyFilters: 'Aplicar filtros',
          clearFilters: 'Limpiar filtros',
          showAll: 'Mostrar todo',
          refinePercentileIsRequired: 'Refinar la configuración del percentil',
          locationHasNotAllNecessaryParametersForGettingHeatMap:
            'La ubicación no tiene todos los parámetros necesarios para obtener el mapa de calor.',
        },
      },
    },
  });

// setTranslations(localStorage.getItem('lang')?.replaceAll('"', '') || 'en');

// function setTranslations(lang) {
//   switch (lang) {
//     case 'en': {
//       setLocale(yupEn);
//       break;
//     }
//     case 'es': {
//       setLocale(yupEs);
//       break;
//     }
//   }
// }

export default i18n;
