import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import L, { PointExpression } from 'leaflet';
import { LayerGroup, Rectangle } from 'react-leaflet';

import { Form, Tooltip, Dropdown } from 'antd';

import { Button, InputNumber } from 'components';
import { TargetIcon, SettingsIcon } from 'assets/icons';

import { HeatMapResponse, ICustomLeafletMap, OFFSET_PADDING } from 'components/map/map';
import { useAppSelector } from 'hooks/useSelector';
import parseHeatMapSettings from 'utils/parseHeatMapSettings';
import { notificationAlert } from 'utils/notificationAlert';

const HeatPointsComponent = memo(
  ({
    heatMapPoints,
    heatMapSettings,
    parsedSignals,
  }: {
    heatMapPoints: { lat: string; lng: string; signal: string }[];
    heatMapSettings: { pointSize: number; opacity: number; borderOpacity: number };
    parsedSignals: ParsedSignalType[];
  }) => {
    const { t } = useTranslation();

    const getColor = (signal: number) => {
      const global = {
        opacity: heatMapSettings.borderOpacity,
        fillOpacity: heatMapSettings.opacity,
      };

      if (parsedSignals && parsedSignals.length) {
        if (signal >= parseInt(parsedSignals[1].value)) return { ...global, color: parsedSignals[0].color };
        else if (signal < parseInt(parsedSignals[parsedSignals.length - 2].value) - 10)
          return { ...global, color: parsedSignals[parsedSignals.length - 1].color };

        let resultToReturn = undefined;

        parsedSignals.forEach((item, index) => {
          if (index !== 0 && index !== parsedSignals.length - 1) {
            const intValue = parseInt(item.value);
            const nextIntValue =
              parsedSignals[index + 1].value === 'lowerThen'
                ? Number(item.value) - 10
                : parseInt(parsedSignals[index + 1].value);
            if (signal <= intValue && signal >= nextIntValue) resultToReturn = { ...global, color: item.color };
          }
        });

        return resultToReturn;
      }
    };

    const heatPoints: React.ReactNode[] = [];

    heatMapPoints?.length &&
      heatMapPoints.constructor !== Object &&
      heatMapPoints.forEach(
        ({ lat, lng, signal }, index) =>
          signal &&
          heatPoints.push(
            <Rectangle
              key={`${lat}-${lng}-${index}`}
              bounds={L.latLng([Number(lat), Number(lng)]).toBounds(heatMapSettings.pointSize)}
              pathOptions={getColor(parseFloat(signal))}
            />,
          ),
      );

    if (heatPoints.length === 0) {
      notificationAlert('warning', `${t('noResult')}`, `${t('noHeatMapPoints')}.`);
    }

    return <LayerGroup>{heatPoints}</LayerGroup>;
  },
);

type ParsedSignalType = {
  color: string;
  created_at: string;
  id: number;
  updated_at: string;
  user_id: number;
  value: string;
};

const HeatMap = ({
  heatMapPoints,
  map,
  customWithOffset,
  withOffset,
  isContactPage,
}: {
  heatMapPoints?: HeatMapResponse;
  map: ICustomLeafletMap;
  customWithOffset?: { paddingTopLeft: PointExpression; paddingBottomRight: PointExpression };
  withOffset?: boolean;
  isContactPage?: boolean;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null); // Create a ref for the container element

  const signalSettings = useAppSelector((store) => store.user?.map?.settings);

  const [heatMapSettings, setHeatMapSettings] = useState({ pointSize: 3, opacity: 0.5, borderOpacity: 0.5 });

  const settingsOnSubmit = useCallback(
    (values: { pointSize: number; opacity: number; borderOpacity: number }) => setHeatMapSettings({ ...values }),
    [],
  );

  const parsedSignals: ParsedSignalType[] = useMemo(() => parseHeatMapSettings(signalSettings), [signalSettings]);

  const targetHeatPointsBounds = useCallback(() => {
    if (heatMapPoints?.Data && Object.keys(heatMapPoints.Data).length > 0) {
      const bounds = L.latLngBounds(
        heatMapPoints?.Data?.map((point) => ({ lat: Number(point.lat), lng: Number(point.lng) })),
      );
      map.fitBounds(bounds, customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, heatMapPoints?.Data]);

  useEffect(() => {
    targetHeatPointsBounds();
  }, [targetHeatPointsBounds]);

  function on() {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) map.tap.enable();
  }

  function off() {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }
  if (!heatMapPoints?.Data) return null;

  return (
    <div ref={ref}>
      {heatMapPoints.Data.length ? (
        <HeatMapInfoAndControlsWrapper $withSpace={isContactPage}>
          <div style={{ display: 'flex' }}>
            {parsedSignals?.map((signal, index) => {
              if (index === 0)
                return <ColorBox key={signal.id} color={signal.color}>{`> ${parsedSignals[1].value} dBm`}</ColorBox>;
              else if (index === parsedSignals.length - 1)
                return (
                  <ColorBox key={signal.id} color={signal.color}>{`< ${
                    Number(parsedSignals[parsedSignals.length - 2].value) - 10
                  } dBm`}</ColorBox>
                );

              return <ColorBox key={signal.id} color={signal.color}>{`< ${signal.value} dBm`}</ColorBox>;
            })}
          </div>

          <Tooltip
            placement="bottom"
            title={t('targetHeatPoints')}
            getPopupContainer={() => ref.current?.parentElement || document.body}
          >
            <Button
              shape="circle"
              icon={TargetIcon}
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
              }}
              onClick={targetHeatPointsBounds}
            />
          </Tooltip>

          <Dropdown
            placement="bottomCenter"
            getPopupContainer={() => map.getContainer()}
            dropdownRender={() => (
              <SettingsWrapper onMouseOver={off} onMouseLeave={on}>
                <Form
                  initialValues={{ ...heatMapSettings }}
                  onFinish={settingsOnSubmit}
                  style={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center' }}
                >
                  <Form.Item name="pointSize" style={{ margin: 0, width: 'fit-content' }}>
                    <InputNumber
                      label={t('pointSizeM')}
                      min={1}
                      max={100}
                      wrapperStyle={{ width: '100%' }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item name="opacity" style={{ margin: 0, width: 'fit-content' }}>
                    <InputNumber
                      name="opacity"
                      label={t('pointOpacity')}
                      min={0}
                      max={1}
                      step={0.1}
                      wrapperStyle={{ width: '100%' }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item name="borderOpacity" style={{ margin: 0, width: 'fit-content' }}>
                    <InputNumber
                      label={t('borderOpacity')}
                      min={0}
                      max={1}
                      step={0.1}
                      wrapperStyle={{ width: '100%' }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Button type="primary" htmlType="submit">
                      {t('setSettings')}
                    </Button>
                  </Form.Item>
                </Form>
              </SettingsWrapper>
            )}
          >
            <Button
              shape="circle"
              icon={SettingsIcon}
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
              }}
            />
          </Dropdown>
        </HeatMapInfoAndControlsWrapper>
      ) : null}

      <HeatPointsComponent
        heatMapPoints={heatMapPoints.Data}
        heatMapSettings={heatMapSettings}
        parsedSignals={parsedSignals}
      />
    </div>
  );
};

const SettingsWrapper = styled.div`
  min-width: 150px;

  padding: ${({ theme }) => theme.paddingMd};
  border-radius: ${({ theme }) => theme.borderRadiusBg};

  background: ${({ theme }) => theme.backgroundWhiteColor};
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

  z-index: 1000;
`;

const HeatMapInfoAndControlsWrapper = styled.div<{ $withSpace?: boolean }>`
  position: absolute;

  top: ${({ $withSpace }) => ($withSpace ? '100px' : '10px')};
  right: 50%;
  transform: translateX(50%);

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gapMd};

  z-index: 1000;
`;

const ColorBox = styled.div`
  width: 80px;
  height: 25px;

  background: ${({ color }) => color};

  color: white;
  ${({ color }) =>
    color === 'yellow' &&
    css`
      color: black;
    `}

  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  &:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

export default HeatMap;
