import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'components';
import styled from 'styled-components';

interface LanguageSwitcher {
  value: string;
  onChange: (value: string) => void;
}

const LanguageSwitcher = (props: LanguageSwitcher) => {
  const { t } = useTranslation();
  const url = window.location.href;
  const page = url.split('/')[3];

  const languageOptions = [
    {
      label: `${t('english')}`,
      value: 'en',
    },
    {
      label: `${t('spanish')}`,
      value: 'es',
    },
  ];

  return (
    <StyledSelect
      placeholder={t('selectLanguage')}
      onChange={(e: any) => props.onChange(e)}
      value={props.value}
      options={languageOptions}
      wrapperStyle={{ width: page === 'profile' ? '100%' : undefined }}
      $isProfile={page === 'profile'}
    />
  );
};

const StyledSelect = styled(Select)<{ $isProfile?: boolean }>`
  min-width: ${({ $isProfile }) => ($isProfile ? '250px' : '100px')};
  @media only screen and (max-width: 640px) {
    min-width: 100%;
  }
  .custom-select-input-wrapper {
    width: 100% !important;
  }
`;

export default LanguageSwitcher;
