import React from 'react';

import styled from 'styled-components';

import LiveClock from 'react-live-clock';

import { useAppSelector } from 'hooks/useSelector';

const Clock = () => {
  const timezone = useAppSelector((store) => store.user.timezone);
  return (
    <Wrapper>
      <StyledClock format={'HH:mm:ss'} ticking={true} timezone={timezone} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 48px;
  height: 15px;

  display: flex;
  justify-content: start;
`;

const StyledClock = styled(LiveClock)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.darkGrayColor};
  font-size: 12px;
`;

export default Clock;
