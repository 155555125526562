import React, { FC, useEffect, useRef } from 'react';

import ReactDOMServer from 'react-dom/server';

import L, { Circle as CircleType } from 'leaflet';
import { Marker as MarkerLeaflet, MarkerProps, Circle } from 'react-leaflet';

import CustomMarker from 'components/map/customMarker';
import { useTheme } from 'theme/useTheme';
import { ICustomLeafletMap } from 'components/map/map';
// @ts-ignore
import LocationPin from 'assets/icons/locationPin.png';
import './controls/sector';

export const icon = (markerColor: string) =>
  L.divIcon({
    className: 'custom-marker-icon',
    iconSize: [20, 20],
    html: ReactDOMServer.renderToString(<CustomMarker markerColor={markerColor} />),
  });
export const iconPNG = L.icon({
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  iconUrl: LocationPin,
});

type MarkerType = MarkerProps & {
  map?: ICustomLeafletMap;
  single?: boolean;
  range?: number | null;
  angleStart?: number | null;
  angleEnd?: number | null;
  isPatternOfLifeTabActive?: boolean;
  markerColor?: string;
};

const Marker: FC<MarkerType> = ({
  children,
  range,
  position,
  single,
  map,
  angleStart,
  angleEnd,
  isPatternOfLifeTabActive,
  markerColor,
  ...rest
}) => {
  const theme = useTheme();
  const azimuthRef = useRef<CircleType | null>(null);

  if (
    map &&
    position &&
    single &&
    (angleStart !== 0 || angleEnd !== 360) &&
    !isNaN(angleStart as number) &&
    !isNaN(angleEnd as number)
  ) {
    azimuthRef.current?.removeFrom(map);
    azimuthRef.current = null;
    // @ts-ignore
    azimuthRef.current = L.circle(position, {
      radius: range as number,
      startAngle: angleStart,
      endAngle: angleEnd,
      weight: 1,
      color: markerColor || theme.primaryColor,
    }).addTo(map);
  }

  useEffect(() => {
    return () => {
      if (azimuthRef.current && map) {
        azimuthRef.current?.removeFrom(map);
        azimuthRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MarkerLeaflet
        icon={isPatternOfLifeTabActive ? iconPNG : icon(markerColor || theme.primaryColor)}
        position={position}
        {...rest}
      >
        {children}
        {range &&
        ((angleStart === 0 && angleEnd === 360) || (isNaN(angleStart as number) && isNaN(angleEnd as number))) ? (
          <Circle
            center={position}
            pathOptions={{ color: markerColor || theme.primaryColor, weight: 1, fillOpacity: 0.1, opacity: 1 }}
            radius={range}
          />
        ) : null}
      </MarkerLeaflet>
    </>
  );
};

export default Marker;
