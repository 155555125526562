import {
  FIRE_ALERT,
  SET_TOKEN_TIME,
  GET_APP_DATA,
  GET_NOTIFICATIONS_TEMPLATE_VARS,
  TOGGLE_APP_STATUS,
  NOTICE_MESSAGE,
  SEARCH_ON_GOING,
  TOGGLE_DARK_MODE,
  TOGGLE_MENU,
  SET_IS_MENU_EXPANDED,
  TOGGLE_RESULTS_MENU,
  SET_IS_RESULTS_MENU_EXPANDED,
  TOGGLE_CONTACT_INFO,
  SET_IS_CONTACT_INFO_EXPANDED,
  SET_DARK_MODE,
  ADD_HEATMAP_SETTING,
  EDIT_HEATMAP_SETTING,
  DELETE_HEATMAP_SETTING,
  GET_ALL_EXISTING_PERMISSIONS_LIST,
  GET_SMS_GEO_LOCATION_NUMBERS,
  ADD_SMS_GEO_LOCATION_NUMBERS,
  DELETE_SMS_GEO_LOCATION_NUMBERS,
  GET_SMS_GEO_LOCATION_PASSWORD_TIME,
  SET_SMS_GEO_LOCATION_PASSWORD_TIME,
  SET_DOWNLOAD_PDF_PATTERN_OF_LIFE_PROGRESS,
} from 'constants/actionTypes';
import api from 'api/appApi';

import { notificationAlert } from 'utils/notificationAlert';

export const updateAppData = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .updateAppData(data)
      .then((res) => {
        dispatch({
          type: SET_TOKEN_TIME,
          payload: res.data.data,
        });
        notificationAlert('success', t('success'), t('appSettingsUpdated'));
        return resolve(res.data.data);
      })
      .catch((err) => {
        const error = !err.response.data.errors.access_token_lifetime
          ? ''
          : err.response.data.errors.access_token_lifetime[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${error}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const sendNoticeMessage = (notice, t) => (dispatch) =>
  api.app.sendNoticeMessage(notice).then(() => {
    notificationAlert('success', t('success'), t('notificationMessageUpdated'));
    dispatch({
      type: NOTICE_MESSAGE,
      payload: '',
    });
  });

export const getAppData = () => (dispatch) =>
  api.app.getAppData().then((res) =>
    dispatch({
      type: GET_APP_DATA,
      payload: res.data.data,
    }),
  );

export const getAllExistingPermissionsList = () => (dispatch) =>
  api.app.getAllExistingPermissionsList().then((res) =>
    dispatch({
      type: GET_ALL_EXISTING_PERMISSIONS_LIST,
      payload: res.data.data,
    }),
  );

export const getNotificationsTemplateVars = () => (dispatch) =>
  api.app.getNotificationsTemplateVars().then((res) =>
    dispatch({
      type: GET_NOTIFICATIONS_TEMPLATE_VARS,
      payload: res.data.data,
    }),
  );

export const toggleAppStatus = () => (dispatch) =>
  api.app.toggleAppStatus().then((res) =>
    dispatch({
      type: TOGGLE_APP_STATUS,
      payload: res.data.data,
    }),
  );

export const generateCredits = (t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .generateCredits()
      .then((res) => {
        notificationAlert('success', t('success'), t('recordsWereGenerated'));
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('error'),
            message: t('creditsGenerationFailed'),
          },
        });
        return reject(err);
      }),
  );

export const getLogs = () => () => api.app.getLogs().then((res) => res.data.data);

export const setSearchOnGoing = (state) => (dispatch) => dispatch({ type: SEARCH_ON_GOING, payload: state });

const setClassToHtml = (mode) => {
  const element = document.getElementsByTagName('html');

  if (mode) element[0].classList.add('app--dark-mode');
  else element[0].classList.remove('app--dark-mode');
};

export const setDarkMode = () => (dispatch) => {
  const darkMode = localStorage.getItem('darkMode');

  if (darkMode && darkMode === 'true') {
    dispatch({ type: SET_DARK_MODE, payload: true });
  } else if (darkMode && darkMode === 'false') {
    dispatch({ type: SET_DARK_MODE, payload: false });
  } else {
    dispatch({ type: SET_DARK_MODE, payload: false });
  }
};

export const toggleDarkMode = () => (dispatch) => {
  const darkMode = localStorage.getItem('darkMode');

  if (darkMode && darkMode === 'true') {
    localStorage.setItem('darkMode', 'false');
  } else if (darkMode && darkMode === 'false') {
    localStorage.setItem('darkMode', 'true');
  } else {
    localStorage.setItem('darkMode', 'true');
  }

  dispatch({ type: TOGGLE_DARK_MODE });
};

export const setIsMenuExpanded = () => (dispatch) => {
  const isMenuExpanded = sessionStorage.getItem('isMenuExpanded');

  if (isMenuExpanded && isMenuExpanded === 'true') {
    sessionStorage.setItem('isMenuExpanded', 'true');
    dispatch({ type: SET_IS_MENU_EXPANDED, payload: true });
  } else if (isMenuExpanded && isMenuExpanded === 'false') {
    sessionStorage.setItem('isMenuExpanded', 'false');
    dispatch({ type: SET_IS_MENU_EXPANDED, payload: false });
  } else {
    sessionStorage.setItem('isMenuExpanded', 'false');
    dispatch({ type: SET_IS_MENU_EXPANDED, payload: false });
  }
};

export const toggleMenu = () => (dispatch) => {
  const isMenuExpanded = sessionStorage.getItem('isMenuExpanded');

  if (isMenuExpanded && isMenuExpanded === 'true') {
    sessionStorage.setItem('isMenuExpanded', 'false');
  } else {
    sessionStorage.setItem('isMenuExpanded', 'true');
  }

  dispatch({ type: TOGGLE_MENU });
};

export const setIsResultsMenuExpanded = () => (dispatch) => {
  const isResultsMenuExpanded = sessionStorage.getItem('isResultsMenuExpanded');

  if (isResultsMenuExpanded && isResultsMenuExpanded === 'true') {
    sessionStorage.setItem('isResultsMenuExpanded', 'true');
    dispatch({ type: SET_IS_RESULTS_MENU_EXPANDED, payload: true });
  } else if (isResultsMenuExpanded && isResultsMenuExpanded === 'false') {
    sessionStorage.setItem('isResultsMenuExpanded', 'false');
    dispatch({ type: SET_IS_RESULTS_MENU_EXPANDED, payload: false });
  } else {
    sessionStorage.setItem('isResultsMenuExpanded', 'true');
    dispatch({ type: SET_IS_RESULTS_MENU_EXPANDED, payload: true });
  }
};

export const toggleResultsMenu = () => (dispatch) => {
  const isResultsMenuExpanded = sessionStorage.getItem('isResultsMenuExpanded');

  if (isResultsMenuExpanded && isResultsMenuExpanded === 'true') {
    sessionStorage.setItem('isResultsMenuExpanded', 'false');
  } else {
    sessionStorage.setItem('isResultsMenuExpanded', 'true');
  }

  dispatch({ type: TOGGLE_RESULTS_MENU });
};

export const setIsContactInfoExpanded = () => (dispatch) => {
  const isContactInfoExpanded = sessionStorage.getItem('isContactInfoExpanded');

  if (isContactInfoExpanded && isContactInfoExpanded === 'true') {
    sessionStorage.setItem('isContactInfoExpanded', 'true');
    dispatch({ type: SET_IS_CONTACT_INFO_EXPANDED, payload: true });
  } else if (isContactInfoExpanded && isContactInfoExpanded === 'false') {
    sessionStorage.setItem('isContactInfoExpanded', 'false');
    dispatch({ type: SET_IS_CONTACT_INFO_EXPANDED, payload: false });
  } else {
    sessionStorage.setItem('isContactInfoExpanded', 'true');
    dispatch({ type: SET_IS_CONTACT_INFO_EXPANDED, payload: true });
  }
};

export const toggleContactInfo = () => (dispatch) => {
  const isContactInfoExpanded = sessionStorage.getItem('isContactInfoExpanded');

  if (isContactInfoExpanded && isContactInfoExpanded === 'true') {
    sessionStorage.setItem('isContactInfoExpanded', 'false');
  } else {
    sessionStorage.setItem('isContactInfoExpanded', 'true');
  }

  dispatch({ type: TOGGLE_CONTACT_INFO });
};

export const getHeatMapSettings = () => () =>
  new Promise((resolve, reject) =>
    api.app
      .getHeatMapSettings()
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const addHeatMapSetting = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .addHeatMapSetting(data)
      .then((res) => {
        dispatch({ type: ADD_HEATMAP_SETTING, payload: res.data.data });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const editHeatMapSetting = (id, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .editHeatMapSetting(id, data)
      .then((res) => {
        dispatch({ type: EDIT_HEATMAP_SETTING, payload: { id, data } });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteHeatMapSetting = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .deleteHeatMapSetting(id)
      .then((res) => {
        dispatch({ type: DELETE_HEATMAP_SETTING, payload: id });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const getApplicationVersion = () => api.app.appVersion();

export const getSmsLocateNumbers = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .getSmsLocateNumbers()
      .then((res) => {
        dispatch({
          type: GET_SMS_GEO_LOCATION_NUMBERS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })

      .catch((err) => reject(err)),
  );

export const addSmsLocateNumber = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .addSmsLocateNumber(data)
      .then((res) => {
        dispatch({
          type: ADD_SMS_GEO_LOCATION_NUMBERS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err.response.data.message,
            message: t('error'),
          },
        });
        return reject(err);
      }),
  );

export const deleteSmsLocateNumber = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .deleteSmsLocateNumber(id)
      .then(() => {
        dispatch({
          type: DELETE_SMS_GEO_LOCATION_NUMBERS,
          payload: id,
        });
      })
      .catch((err) => reject(err)),
  );

export const getSmsLocatePasswordTime = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .getSmsLocatePasswordTime()
      .then((res) => {
        dispatch({
          type: GET_SMS_GEO_LOCATION_PASSWORD_TIME,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })

      .catch((err) => {
        reject(err);
      }),
  );

export const setSmsLocatePasswordTime = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .setSmsLocatePasswordTime(data)
      .then((res) => {
        dispatch({
          type: SET_SMS_GEO_LOCATION_PASSWORD_TIME,
          payload: res.data.data,
        });
        notificationAlert('success', t('success'), t('timeHasBeenReset'));

        return resolve(res.data.data);
      })

      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err.response.data.message,
            message: t('error'),
          },
        });
        reject(err);
      }),
  );

export const regenerateSmsLocatePassword = (t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .regenerateSmsLocatePassword()
      .then((res) => {
        notificationAlert('success', t('success'), t('passwordHasBeenRegenerated'));

        return resolve(res.data.data);
      })

      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err.response.data.message,
            message: t('error'),
          },
        });
        reject(err);
      }),
  );

export const updateDownloadPdfPatternOfLifeProgress = (percentage) => (dispatch) =>
  dispatch({
    type: SET_DOWNLOAD_PDF_PATTERN_OF_LIFE_PROGRESS,
    payload: percentage,
  });
