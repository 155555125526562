import {
  GET_GROUPS,
  GET_SINGLE_GROUP,
  CREATE_GROUP,
  FIRE_ALERT,
  ADD_CONTACT_TO_GROUP,
  LOCATE_CONTACT_GROUP,
  DELETE_GROUP,
  REMOVE_CONTACT_FROM_GROUP,
  SEARCH_CONTACT_GROUP_RESULT,
  EDIT_CONTACT_GROUP,
} from 'constants/actionTypes';
import api from 'api/appApi';
import store from '../../index';

export const getGroups = () => (dispatch) =>
  api.groups.getGroups().then((res) => {
    dispatch({
      type: GET_GROUPS,
      payload: res.data.data,
    });
  });

export const getSingleGroup = (groupId, data, signal) => (dispatch) =>
  api.groups.getSingleGroup(groupId, data, signal).then((res) =>
    dispatch({
      type: GET_SINGLE_GROUP,
      payload: res.data.data,
    }),
  );

export const createGroup = (name) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.groups
      .createGroup(name)
      .then((res) => {
        dispatch({
          type: CREATE_GROUP,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const error = !err.response.data.errors.name ? '' : err.response.data.errors.name[0];
        const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${error} ${limit}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const addContactToGroup = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.groups
      .addContactToGroup(data)
      .then((res) => {
        dispatch({
          type: ADD_CONTACT_TO_GROUP,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: limit !== '' ? limit : t('requestAccessFromAdministrator'),
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const locateContactGroup = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .locateContact(data)
      .then((res) => {
        // dispatch({
        //   type: LOCATE_CONTACT_GROUP,
        //   payload: res,
        // });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            message: t('error'),
            specificMessage: err.response.data.message || err.message,
          },
        });
        return reject(err);
      }),
  );

export const locateContactGroupAction = (data) => (dispatch) =>
  dispatch({
    type: LOCATE_CONTACT_GROUP,
    payload: data,
  });

export const deleteGroup = (groupId, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.groups
      .deleteGroup(groupId)
      .then((res) => {
        dispatch({
          type: DELETE_GROUP,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('requestAccessFromAdministrator'),
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const removeContactFromGroup = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.groups
      .removeContactFromGroup(data)
      .then((res) => {
        dispatch({
          type: REMOVE_CONTACT_FROM_GROUP,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('requestAccessFromAdministrator'),
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const uploadContactsGroup = (file, name, config) => (dispatch) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_name', name);
    api.groups
      .uploadContactsGroup(formData, config)
      .then((res) => {
        store.dispatch(getGroups());
        return resolve(res.data);
      })
      .catch((err) => {
        const nameError = !err.response.data.errors.group_name ? '' : err.response.data.errors.group_name[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${nameError}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      });
  });

export const searchContactGroup = (text) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.groups
      .searchContactGroup(text)
      .then((res) => {
        dispatch({
          type: SEARCH_CONTACT_GROUP_RESULT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const editGroup = (groupId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.groups
      .editGroup(groupId, data)
      .then((res) => {
        dispatch({
          type: EDIT_CONTACT_GROUP,
          payload: res.data.data,
        });
        resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );
