import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { motion } from 'framer-motion';

import { Icon } from 'components';

const { Text } = Typography;

const TopMenuContent = styled.div``;

const BottomMenuContent = styled.div``;

const LogoWrapper = styled.div`
  position: relative;
  height: 20px;
`;

const LineBreak = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.lightGrayColor};
  margin: ${({ theme }) => `${theme.marginBg} 0`};
`;

const MiniLineBreak = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.lightGrayColor};
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

const MenuItemWrapper = styled.div<{
  disableSelection?: boolean;
  hoverIconColor?: string;
  isSelected?: boolean;
  marginBottom?: string;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 8px;
  cursor: ${({ disableSelection }) => (!disableSelection ? 'pointer' : 'initial')};

  &:hover {
    background: ${({ theme, isSelected, disableSelection }) =>
      !disableSelection && !isSelected && theme.mainShade7Color};

    ${({ hoverIconColor, theme }) =>
      hoverIconColor &&
      css`
        svg {
          path {
            fill: ${theme[hoverIconColor]};
          }
        }
      `}
  }

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}

  ${({ disableSelection }) =>
    !disableSelection &&
    css`
      span {
        line-height: 24px;
      }
    `}

  ${({ isSelected, disableSelection, theme }) =>
    isSelected &&
    !disableSelection &&
    css`
      background: ${theme.primaryColor};
      span {
        color: ${theme.textWhiteColor};
      }
      svg {
        path {
          fill: ${theme.backgroundWhiteColor};
        }
      }
    `}
`;

const MinimizedContent = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.borderRadiusBg};
`;

const MaximizedContent = styled.div`
  min-width: 214px;
  padding: ${({ theme }) => `0 ${theme.paddingMd}`};
  flex: 1;
`;

const SubMinimizedContent = styled(MinimizedContent)``;

const SubMaximizedContent = styled(MaximizedContent)<{ isExpanded: boolean }>`
  width: fit-content;
  min-width: auto;
`;

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  background: ${({ theme }) => theme.mainShade5Color};
  border-radius: ${({ theme }) => theme.borderRadiusRound};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const AvatarText = styled(Text)`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizeLg};
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
`;

const ArrowIconStyled = styled(Icon)<{ $isExpanded?: boolean; onClick?: () => void }>`
  position: absolute;
  right: ${({ $isExpanded }) => ($isExpanded ? '-12px' : '-24px')};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const NameText = styled(Text)`
  color: ${({ theme }) => theme.darkGrayColor};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizeMd};
  line-height: ${({ theme }) => theme.lineSizeLg};
  word-break: break-all;
`;

const EmailText = styled(Text)`
  width: 100%;
  color: ${({ theme }) => theme.textGrayColor};
  word-break: keep-all;
  line-height: ${({ theme }) => theme.lineSizeLg};
`;

const CounterBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: ${({ theme }) => theme.borderRadiusMd};
  background: ${({ theme }) => theme.mainShade5Color};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreditWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: ${({ theme }) => theme.paddingSm};
  border: ${({ theme }) => `1px solid ${theme.darkGrayColor}`};
  border-radius: ${({ theme }) => theme.borderRadiusMd};

  span {
    color: ${({ theme }) => theme.darkGrayColor};
    line-height: ${({ theme }) => theme.lineSizeBtn};
  }
`;

const StyledMotionDiv = styled(motion.div)`
  background: ${({ theme }) => theme.backgroundWhiteColor};
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.paddingBg};
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    background: ${({ theme }) => theme.mainShade6Color};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.mainShade5Color};
  }
`;

const MenuStyles = {
  LogoWrapper,
  TopMenuContent,
  BottomMenuContent,
  MiniLineBreak,
  LineBreak,
  MenuItemWrapper,
  MinimizedContent,
  MaximizedContent,
  SubMinimizedContent,
  SubMaximizedContent,
  Avatar,
  AvatarText,
  NameWrapper,
  ArrowIconStyled,
  NameText,
  EmailText,
  CounterBox,
  CreditWrapper,
  StyledMotionDiv,
};

export default MenuStyles;
