import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import L, { PointExpression } from 'leaflet';
import { Circle, Popup } from 'react-leaflet';
import styled from 'styled-components';

import Marker from 'components/map/marker';

// @ts-ignore
import markerGreenTarget from 'assets/markers/marker-green-target.png';

import { Text } from 'components';
import { ICustomLeafletMap, OFFSET_PADDING } from 'components/map/map';

const myIcon = L.icon({
  iconUrl: markerGreenTarget,
  iconSize: [60, 60],
  iconAnchor: [30, 60],
  popupAnchor: [0, -60],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
});

const HighPrecisionMarker: FC<{
  map: ICustomLeafletMap;
  highPrecisionMarker?: { lat: string; long: string; range: number | null }[];
  customWithOffset?: { paddingTopLeft: PointExpression; paddingBottomRight: PointExpression };
  withOffset?: boolean;
}> = ({ map, highPrecisionMarker, customWithOffset, withOffset }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      const locationsGroup: L.LatLngBounds[] = [];

      highPrecisionMarker?.forEach(({ lat, long, range }) => {
        const parsedRange = range ?? 0;

        if (lat && long) {
          const circle = L.circle([Number(lat), Number(long)], Number(parsedRange));
          // Adding then removing the circle from the map due to some bug
          circle.addTo(map);
          locationsGroup.push(circle.getBounds());
          circle.removeFrom(map);
        }
      });

      if (locationsGroup.length)
        map.fitBounds(
          locationsGroup as any,
          customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined,
        );
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highPrecisionMarker]);

  return highPrecisionMarker ? (
    <div>
      {highPrecisionMarker[0] ? (
        <>
          <Marker position={[Number(highPrecisionMarker[0].lat), Number(highPrecisionMarker[0].long)]}>
            <StyledPopup>
              <Row>
                <Text variant="bodyRegularSm" color="textGrayColor">
                  {t('lat')}:
                </Text>
                <Text variant="bodyRegularSm">{highPrecisionMarker[0].lat}</Text>
              </Row>
              <Row>
                <Text variant="bodyRegularSm" color="textGrayColor">
                  {t('lng')}:
                </Text>
                <Text variant="bodyRegularSm">{highPrecisionMarker[0].long}</Text>
              </Row>
              {highPrecisionMarker[0].range ? (
                <Row>
                  <Text variant="bodyRegularSm" color="textGrayColor">
                    {t('range')}:
                  </Text>
                  <Text variant="bodyRegularSm">
                    {highPrecisionMarker[0].range} <span style={{ opacity: 0.5 }}>(m)</span>
                  </Text>
                </Row>
              ) : null}
            </StyledPopup>
          </Marker>
          {highPrecisionMarker[0].range ? (
            <Circle
              center={[Number(highPrecisionMarker[0].lat), Number(highPrecisionMarker[0].long)]}
              radius={highPrecisionMarker[0].range}
            />
          ) : null}
        </>
      ) : null}

      {highPrecisionMarker[1] ? (
        <>
          <Marker position={[Number(highPrecisionMarker[1].lat), Number(highPrecisionMarker[1].long)]} icon={myIcon}>
            <StyledPopup>
              <Row>
                <Text variant="bodyRegularSm" color="textGrayColor">
                  {t('lat')}:
                </Text>
                <Text variant="bodyRegularSm">{highPrecisionMarker[1].lat}</Text>
              </Row>
              <Row>
                <Text variant="bodyRegularSm" color="textGrayColor">
                  {t('lng')}:
                </Text>
                <Text variant="bodyRegularSm">{highPrecisionMarker[1].long}</Text>
              </Row>
              <Row>
                <Text variant="bodyRegularSm" color="textGrayColor">
                  {t('range')}:
                </Text>
                {highPrecisionMarker[1].range ? (
                  <Text variant="bodyRegularSm">
                    {highPrecisionMarker[1].range} <span style={{ opacity: 0.5 }}>(m)</span>
                  </Text>
                ) : (
                  '-'
                )}
              </Row>
            </StyledPopup>
          </Marker>
          {highPrecisionMarker[1].range ? (
            <Circle
              center={[Number(highPrecisionMarker[1].lat), Number(highPrecisionMarker[1].long)]}
              pathOptions={{ color: '#00A86D', weight: 1 }}
              radius={highPrecisionMarker[1].range}
            />
          ) : null}
        </>
      ) : null}
    </div>
  ) : null;
};

const StyledPopup = styled(Popup)``;

const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapSm};
`;

export default HighPrecisionMarker;
