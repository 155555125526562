import axios from 'axios';
import history from '../../utils/history';
import logoutSession from '../../utils/logoutSesion';

import {
  LOGIN,
  LOGOUT,
  CHANGE_TIMEZONE,
  CHANGE_USER,
  FIRE_ALERT,
  STICKY_HEADER,
  SOUND_NOTIFICATION,
  BROWSER_NOTIFICATION,
  SWITCH_USER_LANGUAGE,
} from 'constants/actionTypes';
import api from 'api/appApi';
import { echo } from 'api/socketConfig';
import { getServiceStatus } from '../serviceStatus/serviceStatus';
import { notificationAlert } from 'utils/notificationAlert';

export const login = (loginData) => (dispatch) =>
  api.user.login(loginData).then((res) => {
    sessionStorage.setItem('access_token', res.data?.access_token);
    sessionStorage.setItem('refresh_token', res.data?.refresh_token);
    sessionStorage.setItem('isAuthorized', 'true');
    axios.defaults.headers.common.Authorization = `Bearer ${res.data?.access_token}`;
    axios.defaults.headers.common['X-Authorization'] = `Bearer ${res.data?.access_token}`;
    return api.user.getUser().then((res) => {
      sessionStorage.setItem('hash', res.data.data?.hash);
      return dispatch({
        type: LOGIN,
        payload: res.data.data,
      });
    });
  });

export const keycloakLogin = (token, keycloak, t) => (dispatch) =>
  api.app
    .getTokenExchange(token)
    .then((res) => {
      const token = res.data.token.accessToken;
      sessionStorage.setItem('access_token', token);
      sessionStorage.setItem('refresh_token', token);
      sessionStorage.setItem('isAuthorized', 'true');
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      return api.user.getUser().then((res) => {
        sessionStorage.setItem('hash', res.data.data?.hash);
        return dispatch({
          type: LOGIN,
          payload: res.data.data,
        });
      });
    })
    .catch(() => {
      setTimeout(() => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('somethingWentWrong'),
            message: t('cantBeAuthorized'),
          },
        });
      }, 500);

      sessionStorage.setItem('logout-was-done', 'true');
      keycloak.logout();
    });

export const getUser = () => (dispatch) =>
  new Promise((resolve) =>
    api.user
      .getUser()
      .then((res) => {
        sessionStorage.setItem('hash', res.data.data?.hash);
        dispatch(getServiceStatus());
        dispatch({
          type: LOGIN,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => Promise.reject(err)),
  );

export const editUser = (userId, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.user
      .editUser(userId, data)
      .then((res) => {
        dispatch({
          type: CHANGE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });

export const setUserToAuthorized = () => (dispatch) =>
  dispatch({
    type: LOGIN,
    payload: {},
  });

export const changeTimeZone = (id, timezone) => (dispatch) =>
  api.user.changeTimeZone(id, timezone).then((res) =>
    dispatch({
      type: CHANGE_TIMEZONE,
      payload: res.data.data,
    }),
  );

export const setUserToNotAuthorized = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: {},
  });
  logoutSession();
  history.push('/login');
};

export const logout = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.user
      .logOut()
      .then((res) => {
        echo.disconnect();
        logoutSession();
        localStorage.setItem('darkMode', 'false');
        sessionStorage.setItem('isMenuExpanded', 'false');
        sessionStorage.setItem('isResultsMenuExpanded', 'true');
        sessionStorage.setItem('isContactInfoExpanded', 'true');
        dispatch({
          type: LOGOUT,
          payload: null,
        });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const switchHeader = (id, checkedValue) => (dispatch) =>
  api.user.switchHeader(id, checkedValue).then((res) => {
    return dispatch({
      type: STICKY_HEADER,
      payload: res.data.data?.sticky_header,
    });
  });

export const switchSoundNotification = (id, checkedValue) => (dispatch) =>
  api.user.switchSoundNotification(id, checkedValue).then((res) =>
    dispatch({
      type: SOUND_NOTIFICATION,
      payload: res.data.data?.sound,
    }),
  );

export const switchBrowserNotification = (id, checkedValue) => (dispatch) =>
  api.user.switchBrowserNotification(id, checkedValue).then((res) =>
    dispatch({
      type: BROWSER_NOTIFICATION,
      payload: res.data.data?.browser_notification,
    }),
  );

export const switchUserLanguage = (userId, language, t) => (dispatch) =>
  api.user
    .switchUserLanguage(userId, language)
    .then((res) => {
      dispatch({
        type: SWITCH_USER_LANGUAGE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: t('error'),
          message: err.response.data.message,
        },
      });
      return reject(err);
    });

export const requestSmsLocatePassword = (t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.user
      .requestSmsLocatePassword()
      .then((res) => {
        notificationAlert('success', t('success'), t('requestHasBeenSent'));

        return resolve(res.data);
      })

      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err.response.data.message,
            message: t('error'),
          },
        });
        reject(err);
      }),
  );
