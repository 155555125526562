import React, { CSSProperties, ForwardedRef, forwardRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import {
  Input as InputAntd,
  InputProps,
  Tooltip,
  Typography,
  InputNumber as InputNumberAntd,
  InputNumberProps,
} from 'antd';
import { TextAreaProps } from 'antd/es/input';

import { Icon } from 'components';

import { QuestionMarkIcon } from 'assets/icons';
import { textStyles } from 'components/text/text';

const { Text } = Typography;
const { TextArea: TextAreaAntd } = InputAntd;

interface IInput extends InputProps {
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  asPassword?: boolean;
  wrapperStyle?: CSSProperties;
  isSmall?: boolean;
  isFormSubmitted?: boolean;
}

interface ITextArea extends TextAreaProps {
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  wrapperStyle?: CSSProperties;
  isSmall?: boolean;
}

interface IInputNumber extends InputNumberProps {
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  wrapperStyle?: CSSProperties;
  isSmall?: boolean;
}

export const Input = forwardRef(
  (
    { label, tooltipText, isRequired, asPassword, wrapperStyle, disabled, isSmall, isFormSubmitted, ...rest }: IInput,
    ref: ForwardedRef<any>,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handlePasswordVisible = (isVisible: boolean) => {
      setPasswordVisible(isVisible);
    };

    useEffect(() => {
      if (isFormSubmitted === true) {
        setPasswordVisible(false);
      }
    }, [isFormSubmitted]);

    return (
      <InputWrapper className="custom-input-wrapper" style={wrapperStyle} isDisabled={disabled} isSmall={isSmall}>
        {label || tooltipText ? (
          <LabelWrapper>
            {label && <InputLabel $isRequired={isRequired}>{label}</InputLabel>}
            {tooltipText && (
              <Tooltip placement="right" title={tooltipText}>
                <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
              </Tooltip>
            )}
          </LabelWrapper>
        ) : null}
        {asPassword ? (
          <StyledInput.Password
            ref={ref}
            disabled={disabled}
            visibilityToggle={{ visible: passwordVisible, onVisibleChange: handlePasswordVisible }}
            {...rest}
          />
        ) : (
          <StyledInput ref={ref} disabled={disabled} {...rest} />
        )}
      </InputWrapper>
    );
  },
);

export const TextArea = forwardRef(
  ({ label, tooltipText, isRequired, wrapperStyle, disabled, ...rest }: ITextArea, ref: ForwardedRef<any>) => (
    <InputWrapper style={wrapperStyle} isDisabled={disabled}>
      {label || tooltipText ? (
        <LabelWrapper>
          {label && <InputLabel $isRequired={isRequired}>{label}</InputLabel>}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
            </Tooltip>
          )}
        </LabelWrapper>
      ) : null}
      <StyledTextArea ref={ref} disabled={disabled} {...rest} />
    </InputWrapper>
  ),
);

export const InputNumber = forwardRef(
  ({ label, tooltipText, isRequired, wrapperStyle, disabled, ...rest }: IInputNumber, ref: ForwardedRef<any>) => (
    <InputWrapper className="custom-input-wrapper" style={wrapperStyle} isDisabled={disabled}>
      {label || tooltipText ? (
        <LabelWrapper>
          {label && <InputLabel $isRequired={isRequired}>{label}</InputLabel>}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
            </Tooltip>
          )}
        </LabelWrapper>
      ) : null}
      <StyledInputNumber ref={ref} disabled={disabled} {...rest} />
    </InputWrapper>
  ),
);

const InputWrapper = styled.div<{ isDisabled?: boolean; isSmall?: boolean }>`
  width: fit-content;

  .ant-input {
    border-color: ${({ theme }) => theme.lightGrayColor};
    color: ${({ theme }) => theme.textBlackColor};
    padding: ${({ theme, isSmall }) => isSmall && `0 ${theme.paddingSm}`} !important;

    &:hover {
      border-color: ${({ theme }) => theme.primaryColor};
    }
  }

  .ant-input-number {
    overflow: hidden;
  }

  &:hover {
    .ant-typography {
      color: ${({ theme, isDisabled }) => !isDisabled && theme.textBlackColor};
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
    background: ${({ theme }) => theme.backgroundWhiteColor};
    border-color: ${({ theme }) => theme.lightGrayColor};

    input {
      background: ${({ theme }) => theme.backgroundWhiteColor};
      color: ${({ theme }) => theme.textBlackColor};

      &::placeholder {
        color: ${({ theme }) => theme.grayShade7Color};
      }
    }

    .anticon {
      svg {
        path {
          fill: ${({ theme }) => theme.textBlackColor};
        }
      }
    }
  }

  .ant-input-number-handler-wrap {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        background: ${theme.mainShade4Color};
        span {
          border-color: ${theme.mainShade2Color};
          color: ${theme.textBlackColor};
        }
      `}
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
    `}
`;

const InputLabel = styled(Text)<{ $isRequired?: boolean }>`
  display: block;
  ${textStyles.bodyRegularSm}
  color: ${({ theme }) => theme.textGrayColor};

  ${({ $isRequired }) =>
    $isRequired &&
    css`
      &:before {
        display: inline-block;
        margin-right: ${({ theme }) => theme.marginSm};
        color: ${({ theme }) => theme.orangeColor};
        font-size: ${({ theme }) => theme.fontSizeBtn};
        line-height: 1;
        content: '*';
      }
    `}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gapSm};
  margin-bottom: ${({ theme }) => theme.gapXs};
`;

const StyledInput = styled(InputAntd)`
  border-radius: ${({ theme }) => theme.borderRadiusMd};
  background: ${({ theme }) => theme.backgroundWhiteColor};
  color: ${({ theme }) => theme.textBlackColor};

  &::placeholder {
    color: ${({ theme }) => theme.grayShade7Color};
  }
`;

const StyledTextArea = styled(TextAreaAntd)`
  border-radius: ${({ theme }) => theme.borderRadiusMd};
  background: ${({ theme }) => theme.backgroundWhiteColor};
  color: ${({ theme }) => theme.textBlackColor};

  &::placeholder {
    color: ${({ theme }) => theme.grayShade7Color};
  }
`;

const StyledInputNumber = styled(InputNumberAntd)`
  border-radius: ${({ theme }) => theme.borderRadiusMd};
  background: ${({ theme }) => theme.backgroundWhiteColor};
  color: ${({ theme }) => theme.textBlackColor};
  border-color: ${({ theme }) => theme.lightGrayColor};

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor};
  }
  .ant-input-number-input {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        color: ${theme.textBlackColor};
      `}
  }

  .ant-input-number-group-addon {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        color: ${theme.textBlackColor};
      `}
  }

  &::placeholder {
    color: ${({ theme }) => theme.grayShade7Color};
  }
`;
