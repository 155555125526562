import React, { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { Icon } from 'components';
import MenuStyles from 'components/menu/menuStyles';
import ExpandedContent from 'components/menu/expandedContent';
import { IMenuItem } from 'components/menu/itemsList';
import MenuPopover from './menuPopover';

import { useTheme } from 'theme/useTheme';
import useCanView from 'utils/canUserView';
import PermissionHandler from 'utils/permissionHandler';

import { logout } from 'actions/user/auth';

import { useLogoutApp } from 'hooks/useLogout';

const { Text } = Typography;

const { MenuItemWrapper, MinimizedContent, SubMinimizedContent, SubMaximizedContent, NameText, CounterBox } =
  MenuStyles;

interface IProps extends IMenuItem {
  urlPath: string;
  history: any;
  isExpanded: boolean;
  counter?: number;
  subItem?: boolean;
  dispatch: Dispatch<any>;
  buttonPermission?: string | undefined;
  closePopover?: any;
}

const Item: FC<IProps> = ({
  path,
  urlPath,
  history,
  icon,
  hoverIconColor,
  name,
  isExpanded,
  counter,
  subItem,
  dispatch,
  canView,
  onClick,
  closePopover,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const canViewCheck = useCanView();

  const onClickMenuItem = () => {
    history.push(path);

    if (subItem) {
      closePopover();
    }
  };

  return (
    <MenuItemWrapper
      hoverIconColor={hoverIconColor}
      marginBottom={subItem ? theme.marginXs : theme.marginMd}
      isSelected={path ? (typeof path === 'object' ? path.includes(urlPath) : urlPath === path) : false}
      onClick={() =>
        onClick ? onClick(dispatch, logout, useLogoutApp) : typeof path === 'string' ? onClickMenuItem() : null
      }
    >
      {subItem && canViewCheck(canView) ? (
        <>
          <SubMinimizedContent>
            <Icon icon={icon} color="darkGrayColor" />
          </SubMinimizedContent>
          <SubMaximizedContent isExpanded>
            <NameText>{t(name)}</NameText>
          </SubMaximizedContent>
        </>
      ) : canViewCheck(canView) ? (
        <>
          {isExpanded ? (
            <MinimizedContent>
              <Icon icon={icon} color="darkGrayColor" />
            </MinimizedContent>
          ) : (
            <Tooltip placement="right" title={t(name)} mouseEnterDelay={0.5}>
              <MinimizedContent>
                <Icon icon={icon} color="darkGrayColor" />
              </MinimizedContent>
            </Tooltip>
          )}

          <ExpandedContent isExpanded={isExpanded}>
            <NameText>{t(name)}</NameText>
            {counter ? (
              <CounterBox>
                <Text style={{ color: theme.primaryColor }}>{counter}</Text>
              </CounterBox>
            ) : null}
          </ExpandedContent>
        </>
      ) : null}
    </MenuItemWrapper>
  );
};

const Wrapper: FC<PropsWithChildren<IProps>> = (props): any => {
  const theme = useTheme();
  const { children, subPages } = props;
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibility = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const closePopover = () => {
    setPopoverVisible(false);
  };

  return subPages ? (
    <MenuPopover
      content={
        <div style={{ padding: theme.paddingMd }}>
          {subPages.map((subProps) =>
            subProps.buttonPermission ? (
              <PermissionHandler key={`sub-item-${subProps.id}`} button={subProps.buttonPermission}>
                <Item {...props} {...subProps} subItem closePopover={closePopover} />
              </PermissionHandler>
            ) : (
              <Item key={`sub-item-${subProps.id}`} {...props} {...subProps} subItem closePopover={closePopover} />
            ),
          )}
        </div>
      }
      open={popoverVisible}
      onVisibleChange={handlePopoverVisibility}
    >
      {children}
    </MenuPopover>
  ) : (
    children
  );
};

const MenuItem: FC<Omit<IProps, 'dispatch'>> = (props) => {
  const dispatch = useDispatch();
  const canView = useCanView();

  if (!canView(props.canView)) return null;

  return (
    <Wrapper {...props} dispatch={dispatch}>
      <div>
        <Item {...props} dispatch={dispatch} />
      </div>
    </Wrapper>
  );
};

export default MenuItem;
