import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LoadingComponent from 'utils/suspenseLoading';

const SurveyRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user.isAuthorized && user.permissions.find((p) => p.name === 'survey-view') !== undefined ? (
        <Suspense fallback={<LoadingComponent />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

SurveyRoute.propTypes = {
  component: PropTypes.shape({}),
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(SurveyRoute);
