import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';
import { Tooltip } from 'antd';

import { Button } from 'components/index';
import { TargetWithArrowIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';
import styled, { css } from 'styled-components';

interface IHighPrecisionButton {
  toggle?: () => void;
  isActive: boolean;
  highPrecisionUsedAt: string | null;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const HighPrecisionButton: FC<IHighPrecisionButton> = ({ toggle, isActive, highPrecisionUsedAt, tooltipPosition }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  const highPrecisionRead = !!highPrecisionUsedAt;

  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <Tooltip
      title={t('highPrecision')}
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
    >
      <StyledButton
        ref={ref}
        shape="circle"
        icon={TargetWithArrowIcon}
        onClick={toggle}
        transparentFillSvg
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
          background: isActive ? theme.primaryColor : theme.backgroundWhiteColor,
        }}
        customIconColor={isActive ? 'backgroundWhiteColor' : undefined}
        highPrecisionRead={highPrecisionRead}
        isActive={isActive}
      />
    </Tooltip>
  );
};

const StyledButton = styled(Button)<{ highPrecisionRead: boolean; isActive: boolean }>`
  position: relative;
  background: ${({ theme, highPrecisionRead, isActive }) =>
    highPrecisionRead
      ? isActive
        ? theme.primaryColor
        : theme.backgroundWhiteColor
      : `${theme.mainShade4Color} !important`};

  @keyframes pulse-ring {
    0% {
      transform: scale(0.3);
    }
    80%,
    100% {
      opacity: 0;
      transform: scale(0.7);
    }
  }

  ${({ highPrecisionRead }) =>
    highPrecisionRead === false &&
    css`
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        border-radius: 50%;
        background: ${({ theme }) => theme.primaryColor};
        animation: pulse-ring 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        transition: background 0.3s ease-in-out;
        z-index: -1;
      }
    `}
`;

export default HighPrecisionButton;
