import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';
import { Tooltip } from 'antd';

import { Button } from 'components/index';
import { DestinationIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

interface IPlotRouteButton {
  toggle: () => void;
  isActive: boolean;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const PlotRouteButton: FC<IPlotRouteButton> = ({ toggle, isActive, tooltipPosition }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <Tooltip
      title={t('plotRoute')}
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
    >
      <Button
        ref={ref}
        shape="circle"
        icon={DestinationIcon}
        onClick={toggle}
        transparentFillSvg
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
          background: isActive ? theme.primaryColor : theme.backgroundWhiteColor,
        }}
        customIconColor={isActive ? 'backgroundWhiteColor' : undefined}
      />
    </Tooltip>
  );
};

export default PlotRouteButton;
