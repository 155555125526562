import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'antd';
import { BlockProps, EllipsisConfig } from 'antd/lib/typography/Base';

import { KeysOfColorPalette } from 'theme/types';

// @ts-ignore
interface TextProps extends BlockProps {
  ellipsis?: boolean | Omit<EllipsisConfig, 'expandable' | 'rows' | 'onExpand'>;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

export const textStyles = {
  bodyLightSx: {
    'font-weight': '300',
    'font-size': '10px',
    'line-height': '14px',
  },
  bodyRegularSx: {
    'font-weight': '400',
    'font-size': '10px',
    'line-height': '14px',
  },
  bodyMediumSx: {
    'font-weight': '500',
    'font-size': '10px',
    'line-height': '14px',
  },
  bodyBoldSx: {
    'font-weight': '700',
    'font-size': '10px',
    'line-height': '14px',
  },
  bodyLightSm: {
    'font-weight': '300',
    'font-size': '12px',
    'line-height': '16px',
  },
  bodyRegularSm: {
    'font-weight': '400',
    'font-size': '12px',
    'line-height': '16px',
  },
  bodyMediumSm: {
    'font-weight': '500',
    'font-size': '12px',
    'line-height': '16px',
  },
  bodyBoldSm: {
    'font-weight': '700',
    'font-size': '12px',
    'line-height': '14px',
  },
  bodyLight: {
    'font-weight': '300',
    'font-size': '14px',
    'line-height': '18px',
  },
  bodyRegular: {
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '18px',
  },
  bodyMedium: {
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '18px',
  },
  bodyBold: {
    'font-weight': '700',
    'font-size': '14px',
    'line-height': '18px',
  },
  button: {
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '16px',
  },
  cationU: {
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '16px',
    'text-decoration': 'underline',
  },
  bodyLightBg: {
    'font-weight': '300',
    'font-size': '16px',
    'line-height': '20px',
  },
  bodyRegularBg: {
    'font-weight': '400',
    'font-size': '16px',
    'line-height': '20px',
  },
  bodyMediumBg: {
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '20px',
  },
  bodyBoldBg: {
    'font-weight': '700',
    'font-size': '16px',
    'line-height': '20px',
  },
  subtitleLight: {
    'font-weight': '300',
    'font-size': '24px',
    'line-height': '32px',
  },
  subtitleRegular: {
    'font-weight': '400',
    'font-size': '24px',
    'line-height': '32px',
  },
  subtitleMedium: {
    'font-weight': '500',
    'font-size': '24px',
    'line-height': '32px',
  },
  subtitleBold: {
    'font-weight': '700',
    'font-size': '24px',
    'line-height': '32px',
  },
  headingLight: {
    'font-weight': '300',
    'font-size': '32px',
    'line-height': '150%',
  },
  headingRegular: {
    'font-weight': '400',
    'font-size': '32px',
    'line-height': '150%',
  },
  headingMedium: {
    'font-weight': '500',
    'font-size': '32px',
    'line-height': '150%',
  },
  headingBold: {
    'font-weight': '700',
    'font-size': '32px',
    'line-height': '150%',
  },
};

type KeysOfTextStyles = keyof typeof textStyles;

interface IText extends TextProps {
  variant?: KeysOfTextStyles;
  color?: KeysOfColorPalette;
  hover?: any;
}

const { Text: TextAntd } = Typography;

const Text: FC<IText> = ({ children, variant = 'bodyRegular', color = 'textBlackColor', hover, ...rest }) => {
  return (
    // @ts-ignore
    <StyledText variant={variant} color={color} $customHover={hover} {...rest}>
      {children}
    </StyledText>
  );
};

const StyledText = styled(TextAntd)<{
  variant: KeysOfTextStyles;
  color: KeysOfColorPalette;
  $customHover?: any;
  disabled?: boolean;
}>`
  ${({ variant }) => (variant ? textStyles[variant] : undefined)};
  color: ${({ theme, color }) => (color ? theme[color] : undefined)};

  ${({ $customHover, disabled }) =>
    $customHover &&
    !disabled &&
    css`
      &:hover {
        ${$customHover}
      }
    `}
`;

export default Text;
