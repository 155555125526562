import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { getUser } from 'actions/user/auth';
import { useAppSelector } from 'hooks/useSelector';

import ServiceStatus from 'components/menu/serviceStatus';
import MenuItem from 'components/menu/menuItem';
import CreditsItem from 'components/menu/creditsItem/creditsItem';
import UserInfoItem from 'components/menu/userInfoItem';
import MenuStyles from 'components/menu/menuStyles';

import Team from 'components/menu/teamItem/teamItem';
import Notification from 'components/menu/notificationItem/notificationItem';

import { topMenuItems, bottomMenuItems } from 'components/menu/itemsList';
import { Clock } from 'components';

import { toggleMenu } from 'actions/app/application';
import { useAppDispatch } from 'hooks/useDispatch';

const { TopMenuContent, BottomMenuContent, LineBreak, StyledMotionDiv } = MenuStyles;

const MIN_WIDTH_SIZE = 90;
const MAX_WIDTH_SIZE = 310;

const Menu = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const user = useAppSelector((store) => store.user);
  const isMenuExpanded = useAppSelector((store) => store.app.isMenuExpanded);

  const toggle = () => {
    dispatch(toggleMenu());
  };

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line
  }, []);

  if (!user || !user.isAuthorized) return null;

  return (
    <StyledMotionDiv
      id="menu-layout"
      initial={false}
      animate={{ width: isMenuExpanded ? MAX_WIDTH_SIZE : MIN_WIDTH_SIZE }}
    >
      <TopMenuContent>
        {/*<LogoWrapper>*/}
        {/*  <Logo size={12} style={{ position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)' }} />*/}
        {/*</LogoWrapper>*/}

        <UserInfoItem toggle={toggle} isExpanded={isMenuExpanded} user={user} />

        {topMenuItems.map((props) => (
          <MenuItem
            key={`top-menu-item-${props.id}`}
            history={history}
            urlPath={pathname}
            isExpanded={isMenuExpanded}
            {...props}
          />
        ))}
      </TopMenuContent>

      <BottomMenuContent>
        <LineBreak />

        <ServiceStatus isExpanded={isMenuExpanded} />

        <Notification isExpanded={isMenuExpanded} />

        <Team isExpanded={isMenuExpanded} />

        <CreditsItem isExpanded={isMenuExpanded} />

        <LineBreak />

        {bottomMenuItems.map((props) => (
          <MenuItem
            key={`bottom-menu-item-${props.id}`}
            history={history}
            urlPath={pathname}
            isExpanded={isMenuExpanded}
            {...props}
          />
        ))}

        <LineBreak />

        <Clock />
      </BottomMenuContent>
    </StyledMotionDiv>
  );
};

export default Menu;
