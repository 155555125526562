import React from 'react';

import BasicAuthApp from './basicAuthApp';
import KeycloakAuthApp from './keycloakAuthApp';
import useSetupLanguage from 'hooks/useSetupLanguage';

const App = () => {
  const loginMethod = process.env.REACT_APP_LOGIN_METHOD;
  useSetupLanguage();

  if (loginMethod === 'keycloak') {
    return <KeycloakAuthApp />;
  }

  return <BasicAuthApp />;
};

export default App;
