import { notification } from 'antd';
import { GET_AGENTS, STORE_AGENT, DELETE_AGENT, FIRE_ALERT } from 'constants/actionTypes';
import api from 'api/appApi';

export const getAgents = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.agents
      .getAgents()
      .then((res) => {
        dispatch({
          type: GET_AGENTS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const storeAgent = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.agents
      .storeAgent(data)
      .then((res) => {
        dispatch({
          type: STORE_AGENT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: err.response.data.errors.phone[0],
            message: t('error'),
          },
        });
        return reject(err);
      }),
  );

export const deleteAgent = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.agents
      .deleteAgent(id)
      .then((res) => {
        dispatch({
          type: DELETE_AGENT,
          payload: id,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const sendMessage = (data, t) => () =>
  new Promise((resolve, reject) =>
    api.agents
      .sendMessage(data)
      .then(() => {
        notification.success({
          message: t('success'),
          description: t('informationWasSent'),
        });
        return resolve();
      })
      .catch((err) => {
        notification.error({
          message: t('error'),
          description: `${t('somethingWentWrong')}...`,
        });
        return reject(err);
      }),
  );
