import React, { FC, forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { Button as ButtonAntd, ButtonProps } from 'antd';

import { Icon } from 'components';
import { KeysOfColorPalette } from 'theme/types';

interface IButton extends ButtonProps {
  ref?: any;
  icon?: any;
  transparentFillSvg?: boolean;
  customIconColor?: KeysOfColorPalette;
  colorOnHover?: KeysOfColorPalette;
}

const getSvgColor = (type: ButtonProps['type'], customIconColor?: KeysOfColorPalette) => {
  switch (type) {
    case 'primary':
    case 'link':
      return 'textWhiteColor';
    case 'default':
    case 'dashed':
    case 'text':
    default:
      return customIconColor ?? 'grayColor';
  }
};

const Button: FC<IButton> = forwardRef(
  (
    {
      children,
      size = 'middle',
      type = 'default',
      icon,
      shape = 'default',
      transparentFillSvg = false,
      disabled,
      customIconColor,
      colorOnHover,
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledButton
        ref={ref}
        size={size}
        icon={icon ? <Icon icon={icon} size={size} color={getSvgColor(type, customIconColor)} /> : undefined}
        type={type}
        shape={shape}
        disabled={disabled}
        $transparentFillSvg={transparentFillSvg}
        $colorOnHover={colorOnHover}
        {...rest}
      >
        {children}
      </StyledButton>
    );
  },
);

const StyledButton = styled(ButtonAntd)<{
  $transparentFillSvg?: boolean;
  $colorOnHover?: KeysOfColorPalette;
  type?: ButtonProps['type'];
}>`
  border-radius: ${({ theme, shape }) => (shape === 'default' ? theme.borderRadiusMd : undefined)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ shape }) =>
    shape === 'circle' &&
    css`
      justify-content: center;
    `}

  ${({ theme, type }) =>
    theme.isDarkMode &&
    type === 'default' &&
    css`
      background: ${theme.mainShade8Color};
      border-color: ${theme.mainShade5Color};
      color: ${theme.mainShade3Color};
    `}
  
  &:disabled {
    color: ${({ theme }) => theme.textBlackColor};

    ${({ theme, type }) =>
      theme.isDarkMode &&
      type === 'primary' &&
      css`
        background: ${theme.mainShade7Color};
        border-color: ${theme.mainShade5Color};
        color: ${theme.mainShade3Color};
      `}

    ${({ theme, type }) =>
      !theme.isDarkMode &&
      type === 'primary' &&
      css`
        background: ${theme.mainShade4Color};
        border-color: ${theme.mainShade4Color};
        color: ${theme.grayShade10Color};
      `}

    ${({ theme, type }) =>
      theme.isDarkMode &&
      type === 'default' &&
      css`
        background: ${theme.grayShade9Color};
        border-color: ${theme.grayShade4Color};
        color: ${theme.textBlackColor};
        opacity: 0.5;

        svg {
          color: ${theme.textBlackColor};
          filter: invert(0%);
        }

        &:hover {
          svg {
            color: ${theme.textBlackColor};
            filter: invert(0%);
          }
        }
      `}

    ${({ theme, type }) =>
      !theme.isDarkMode &&
      type === 'default' &&
      css`
        background: ${theme.grayShade11Color};
        color: ${theme.grayShade7Color};
        border-color: ${theme.grayShade8Color};
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        color: initial;
        opacity: 0.3;
      }
    `}

  svg {
    ${({ $transparentFillSvg }) =>
      $transparentFillSvg &&
      css`
        fill: transparent !important;
      `}

    transition: 0.2s;

    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        filter: invert(70%);
      `};

    ${({ theme, type }) =>
      theme.isDarkMode &&
      type === 'primary' &&
      css`
        filter: invert(100%);

        &:hover {
          filter: invert(100%);
        }
      `}
  }

  .anticon {
    line-height: 0;
  }

  .ant-btn-loading-icon {
    .anticon {
      svg {
        path {
          fill: currentColor;
        }
      }
    }
  }

  &:hover {
    svg {
      color: ${({ theme, type }) => (type !== 'primary' ? theme.mainShade3Color : undefined)};
    }
  }

  ${({ theme, type }) =>
    type === 'default' &&
    !theme.isDarkMode &&
    css`
      background: ${theme.grayShade11Color};
      color: ${theme.grayShade1Color};
      border-color: ${theme.grayShade5Color};
    `};

  ${({ theme, type }) =>
    theme.isDarkMode &&
    type === 'default' &&
    css`
      background: ${theme.mainShade7Color};
      border-color: ${theme.textLightGrayColor};
      color: ${theme.textLightGrayColor};
    `}

  ${({ theme, $colorOnHover }) =>
    theme.isDarkMode &&
    $colorOnHover &&
    css`
      svg {
        filter: invert(0%);
      }
      &:hover {
        svg {
          color: ${({ theme }) => theme[$colorOnHover]};
          filter: invert(0%);
        }
      }
    `}


  ${({ $colorOnHover }) =>
    $colorOnHover &&
    css`
      &:hover {
        svg {
          color: ${({ theme }) => theme[$colorOnHover]};
        }
        color: ${({ theme }) => theme[$colorOnHover]};
        border-color: ${({ theme }) => theme[$colorOnHover]};
      }
    `}
`;

export default Button;
