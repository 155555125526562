import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Result, Spin, Tooltip } from 'antd';

import { getLocalNotifications } from 'actions/smsNotifications/smsNotifications';
import { sendMessage } from 'actions/agents/smsNotifications';
import { getAgents } from 'actions/agents/smsNotifications';

import { useAppSelector } from 'hooks/useSelector';
import { useAppDispatch } from 'hooks/useDispatch';

import { Button, Select, Text, Icon } from 'components';
import { ILocation } from 'types/storeTypes';
import { useTheme } from 'theme/useTheme';
import { QuestionMarkIcon } from 'assets/icons';

const SmsAgent = ({ data, closePopover }: { data: ILocation; closePopover: () => void }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const roleId = useAppSelector((store) => store.user.role_id);
  const smsService = useAppSelector((store) => store.user.sms_service);
  const agents = useAppSelector((store) => store.agents);
  const isSaAgentSmsLocked = useAppSelector((store) => store.user.is_sa_agent_sms_locked);
  const saDefaultSmsTemplate = useAppSelector((store) => store.user.sa_notification_templates);

  const [selectedAgents, setSelectedAgents] = useState([]);
  const [templates, setTemplates] = useState<{ loading: boolean; data: any }>({ loading: false, data: [] });
  const [sendLoading, setSendLoading] = useState(false);

  const sendMessageAction = () => {
    if (selectedAgents.length > 0) {
      setSendLoading(true);
      dispatch(
        sendMessage(
          {
            agent_ids: selectedAgents,
            location: data,
          },
          t,
        ),
      )
        .then(() => {
          closePopover();
        })
        .finally(() => {
          setSendLoading(false);
        });
    }
  };

  const isSaSmsTemplateNotEmpty = saDefaultSmsTemplate ? saDefaultSmsTemplate?.length > 0 : false;
  const isAdminSmsTemplateNotEmpty = templates?.data?.length > 0 && templates.data[0].content;

  const getTemplateMessage = () => {
    const formatTooltipContent = (content: string | null): JSX.Element[] => {
      if (!content) return [];

      const lines: string[] = content.split('\n');
      const formattedContent: JSX.Element[] = lines.map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
      return formattedContent;
    };

    if (roleId === 1) {
      if (isSaSmsTemplateNotEmpty) {
        return (
          <Text variant="bodyRegular">
            {t('youCanCheckYourExistingTemplate')} {<Link to="/settings">{t('here').toLowerCase()}</Link>}
          </Text>
        );
      } else {
        return (
          <Text variant="bodyRegular">
            {t('youDontHaveAnyTemplate')} <Link to="/settings">{t('create').toLowerCase()}</Link>{' '}
            {t('one').toLowerCase()}
          </Text>
        );
      }
    } else {
      if (isSaAgentSmsLocked === 0 && isAdminSmsTemplateNotEmpty) {
        return (
          <Text variant="bodyRegular">
            {t('youCanCheckYourExistingTemplate')} {<Link to="/profile">{t('here').toLowerCase()}</Link>}
          </Text>
        );
      }

      if (isSaAgentSmsLocked === 0 && isSaSmsTemplateNotEmpty && !isAdminSmsTemplateNotEmpty) {
        return (
          <Text variant="bodyRegular">
            {t('yourTemplateIsEmptyAndItIsProvidedByTheSuperAdmin')}. {t('hoverToSeeTheDefaultTemplate')}{' '}
            <Tooltip
              title={
                isSaSmsTemplateNotEmpty ? (
                  <Text variant="bodyRegularSx" color="textWhiteColor">
                    {formatTooltipContent(saDefaultSmsTemplate)}
                  </Text>
                ) : null
              }
            >
              <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
            </Tooltip>{' '}
            {t('orCreateYourOwn').toLowerCase()} {<Link to="/profile">{t('here').toLowerCase()}</Link>}
          </Text>
        );
      }

      if (isSaAgentSmsLocked === 0 && !isSaSmsTemplateNotEmpty && !isAdminSmsTemplateNotEmpty) {
        return (
          <Text variant="bodyRegular">
            {t('youDontHaveAnyTemplate')} <Link to="/profile">{t('create').toLowerCase()}</Link>{' '}
            {t('one').toLowerCase()}
          </Text>
        );
      }

      if (isSaAgentSmsLocked === 1 && isSaSmsTemplateNotEmpty) {
        return (
          <Text variant="bodyRegular">
            {t('yourTemplateIsLockedByTheSuperAdmin')}. {t('hoverToSeeTheDefaultTemplate')}{' '}
            <Tooltip
              title={
                isSaSmsTemplateNotEmpty ? (
                  <Text variant="bodyRegularSx" color="textWhiteColor">
                    {formatTooltipContent(saDefaultSmsTemplate)}
                  </Text>
                ) : null
              }
            >
              <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
            </Tooltip>
          </Text>
        );
      }

      if (isSaAgentSmsLocked === 1 && !isSaSmsTemplateNotEmpty) {
        return <Text variant="bodyRegular">{t('yourTemplateIsLockedByTheSuperAdminButTheDefaultOneIsEmpty')}.</Text>;
      }
    }
  };

  const isDisabledSendSmsSA = roleId === 1 && !isSaSmsTemplateNotEmpty;

  const isDisabledSendSmsAdmin =
    roleId !== 1 &&
    ((isSaAgentSmsLocked === 1 && !isSaSmsTemplateNotEmpty) ||
      (isSaAgentSmsLocked === 0 && !isSaSmsTemplateNotEmpty && !isAdminSmsTemplateNotEmpty));

  const isDisabledSendSms = isDisabledSendSmsSA || isDisabledSendSmsAdmin;

  useEffect(() => {
    setTemplates((prevState) => ({ ...prevState, loading: true }));

    dispatch(getAgents());

    dispatch(getLocalNotifications()).then(
      (
        res: Record<
          number,
          {
            content: string;
            created_at: string;
            id: number;
            name: string;
            status: number;
            type: number;
            updated_at: string;
            user_id: number;
          }
        >,
      ) => {
        const data = Object.values(res);
        if (res && data.length > 0) {
          setTemplates((prevState) => ({ ...prevState, data: data, loading: false }));
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!smsService) return <Result status="warning" title={t('smsServiceNotConfigured')} />;

  return (
    <SMSAgentWrapper>
      <div style={{ minHeight: 30 }}>
        {templates?.loading ? (
          <span>
            <Spin size="small" />
            <span style={{ marginLeft: 10 }}>{t('checkingForExistingTemplates')}</span>
          </span>
        ) : (
          getTemplateMessage()
        )}
      </div>
      <Select
        optionFilterProp="label"
        mode="multiple"
        style={{ width: 400 }}
        placeholder={t('selectAgent')}
        onChange={(e) => setSelectedAgents(e)}
        value={selectedAgents}
        options={
          Object.values(agents).length
            ? Object.values(agents)
                .reverse()
                .map((agent) => ({ label: agent.name, value: agent.id }))
            : []
        }
      />

      <div style={{ display: 'flex', marginTop: theme.marginBg, gap: theme.gapSm }}>
        <Button onClick={closePopover}>{t('cancel')}</Button>
        <Button loading={sendLoading} onClick={sendMessageAction} type="primary" disabled={isDisabledSendSms}>
          {t('send')}
        </Button>
      </div>
    </SMSAgentWrapper>
  );
};

const SMSAgentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gapMd};
`;

export default SmsAgent;
