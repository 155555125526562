import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Text } from 'components/index';
import { useTheme } from 'theme/useTheme';
import { useAppSelector } from 'hooks/useSelector';

import { ROLE_USER } from 'constants/userRoleType';

const PopUpCreditInfo = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const user = useAppSelector((store) => store.user);

  const { day_credit_row, allocated_credits, daily_credits } = user;

  return (
    <CreditInfoWrapper>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: theme.marginSm }}>
        <Text variant="bodyBold">{t('credits')}</Text>
      </div>
      <div style={{ display: 'grid', gap: theme.gapXs }}>
        <div style={{ display: 'flex', gap: theme.gapSm }}>
          <Text>{t('usedCredits')}:</Text>
          <Text variant="bodyBold">{day_credit_row ? day_credit_row.used_credits ?? '∞' : `${t('noData')}`}</Text>
        </div>
        <div style={{ display: 'flex', gap: theme.gapSm }}>
          <Text>{t('allocatedCredits')}:</Text>
          <Text variant="bodyBold">{allocated_credits ?? '∞'}</Text>
        </div>
        {[ROLE_USER].includes(user.role.name) && (
          <div style={{ display: 'flex', gap: theme.gapSm }}>
            <Text>{t('dailyCreditLimit')}:</Text>
            <Text variant="bodyBold">{daily_credits ?? '∞'}</Text>
          </div>
        )}
      </div>
    </CreditInfoWrapper>
  );
};

const CreditInfoWrapper = styled.div`
  width: 100%;
  min-width: 175px;
  padding: ${({ theme }) => theme.paddingBg};
  border-radius: 5px;
`;

export default PopUpCreditInfo;
