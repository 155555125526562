import React from 'react';
import { Spin } from 'antd';

import { useTheme } from 'theme/useTheme';
const LoadingComponent = () => {
  const theme = useTheme();

  const defaultStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100000000,
  };

  return (
    <div style={theme.isDarkMode ? { ...defaultStyles, background: theme.backgroundWhiteColor } : defaultStyles}>
      <Spin size="large" />
    </div>
  );
};

export default LoadingComponent;
