import React from 'react';

import styled from 'styled-components';

import { Popover, PopoverProps } from 'antd';

import './popOver.sass';

interface CustomPopOverProps extends PopoverProps {
  popupContainerSelector?: string;
}

const PopOver = ({ popupContainerSelector = '#main-layout', ...props }: CustomPopOverProps) => (
  <StyledPopOver
    overlayClassName="custom-popover"
    getPopupContainer={() => {
      const parent = document.querySelector(popupContainerSelector);
      return parent instanceof HTMLElement ? parent : document.body;
    }}
    {...props}
  />
);

const StyledPopOver = styled(Popover)``;

export default PopOver;
