import React, { FC, HTMLAttributes } from 'react';
import { Icon, Text } from 'components/index';
import { MarkerIcon } from 'assets/icons';
import styled from 'styled-components';

interface ILogo extends HTMLAttributes<any> {
  size?: number;
}

const Logo: FC<ILogo> = ({ size = 30, ...rest }: { size?: number }) => {
  return (
    <LogoWrapper {...rest}>
      <Icon size={size + 3} color="primaryColor" icon={MarkerIcon} />
      <LogoTextWrapper>
        <Text color="primaryColor" variant="subtitleBold" style={{ fontSize: size }}>
          M2
        </Text>
        <Text variant="subtitleLight" style={{ fontSize: size }}>
          MAP
        </Text>
      </LogoTextWrapper>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoTextWrapper = styled.div`
  display: flex;
`;

export default Logo;
