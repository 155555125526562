import React from 'react';

import styled from 'styled-components';

import { Upload, UploadProps } from 'antd';

const UploadComponent = (props: UploadProps) => <StyledUpload {...props} />;

const StyledUpload = styled(Upload)``;

export default UploadComponent;
