import {
  GET_FILES,
  ADD_FILE,
  UPDATE_FILE,
  GET_CELLS,
  UPDATE_CELL,
  DELETE_CELL,
  DELETE_FOLDER,
  DELETE_FILE,
  DELETE_CELLS,
  DELETE_FILE_ROW,
  LOGOUT,
} from 'constants/actionTypes';

export default function files(state = {}, action = {}) {
  switch (action.type) {
    case GET_FILES: {
      const folders = action.payload.files_meta?.folders;
      const files = action.payload.files_meta?.files;

      return {
        ...state,
        files: { files, folders, data: Object.values(action.payload.data).reverse() },
      };
    }
    case ADD_FILE:
      return {
        ...state,
        files: { ...state.files, data: [action.payload, ...state.files.data] },
      };
    case UPDATE_FILE: {
      const newFiles = [...state.files.data];
      const index = newFiles.findIndex((item) => item.id === action.payload.id);

      if (index > -1) newFiles[index] = action.payload;
      return {
        ...state,
        files: {
          ...state.files,
          data: newFiles,
        },
      };
    }

    case GET_CELLS:
      return {
        ...state,
        cells: { ...action.payload },
      };
    case UPDATE_CELL: {
      const newCells = [...state.cells.data];
      const index = newCells.findIndex((item) => item.id === action.payload.id);

      if (index > -1) newCells[index] = action.payload;
      return {
        ...state,
        cells: {
          ...state.cells,
          data: newCells,
        },
      };
    }
    case DELETE_CELL: {
      const newCells = [...state.cells.data];
      const index = newCells.findIndex((item) => item.id === action.payload.id);

      if (index > -1) newCells.splice(index, 1);
      return {
        ...state,
        cells: {
          ...state.cells,
          data: newCells,
        },
      };
    }
    case DELETE_FOLDER: {
      const foldersArray = state.files.folders;
      const index = foldersArray.indexOf(action.payload);
      foldersArray.splice(index, 1);
      return {
        ...state,
        files: {
          ...state.files,
          folders: foldersArray,
        },
      };
    }
    case DELETE_FILE: {
      const filesArray = state.files.files;
      const index = filesArray.indexOf(action.payload);
      filesArray.splice(index, 1);
      return {
        ...state,
        files: {
          ...state.files,
          files: filesArray,
        },
      };
    }
    case DELETE_CELLS:
      return {
        ...state,
        cells: {
          current_page: 1,
          data: {},
          last_page: 0,
          total: 0,
          per_page: 10,
        },
      };
    case DELETE_FILE_ROW: {
      const newData = [...state.files.data];
      newData.splice(
        newData.findIndex((item) => item.id === action.payload),
        1,
      );
      return {
        ...state,
        files: { ...state.files, data: newData },
      };
    }
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
