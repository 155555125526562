import axios from 'axios';

export default () => {
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('isAuthorized');
  sessionStorage.removeItem('hash');
  sessionStorage.removeItem('url');
  axios.defaults.headers.common.Authorization = 'Bearer ';
  axios.defaults.headers.common['X-Authorization'] = 'Bearer ';
};
