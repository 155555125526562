import React from 'react';

import styled from 'styled-components';

import { Card, CardProps } from 'antd';

const CardComponent = (props: CardProps) => <StyledCard {...props} />;

const StyledCard = styled(Card)`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.grayShade9Color}`};
  border-radius: ${({ theme }) => theme.borderRadiusBg};
  overflow: hidden;
  background: ${({ theme }) => theme.backgroundWhiteColor};

  .ant-card-head {
    background: ${({ theme }) => theme.mainShade7Color};
    border-bottom: 0;

    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding-top: ${({ theme }) => theme.paddingMd};
        padding-bottom: ${({ theme }) => theme.paddingMd};
      }

      .ant-card-extra {
        padding-top: ${({ theme }) => theme.paddingMd};
        padding-bottom: ${({ theme }) => theme.paddingMd};
      }
    }
  }

  .ant-card-body {
    max-height: 428px;
    overflow-y: auto;
  }
`;

export default CardComponent;
