import React, { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';

import { Tooltip } from 'antd';

import { Button } from 'components';
import { WirelessCharging } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

interface ICellRange {
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  onToggleCellRange?: (state: boolean) => void;
}

const CellRangeButton: FC<ICellRange> = ({ tooltipPosition, onToggleCellRange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  const [isActive, setIsActive] = useState(false);

  const toggleCellRangeStatus = () => {
    setIsActive((prevState) => {
      const newState = !prevState;
      onToggleCellRange && onToggleCellRange(newState);
      return newState;
    });
  };

  // Stop propagation
  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <Tooltip
      title={isActive ? t('hideCellRange') : t('showCellRange')}
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
    >
      <Button
        ref={ref}
        shape="circle"
        icon={WirelessCharging}
        onClick={toggleCellRangeStatus}
        transparentFillSvg
        // @ts-ignore
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
          background: isActive ? theme.primaryColor : theme.backgroundWhiteColor,
        }}
        customIconColor={isActive ? 'backgroundWhiteColor' : undefined}
      />
    </Tooltip>
  );
};

export default CellRangeButton;
