import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';
import { Tooltip } from 'antd';

import { Button } from 'components/index';
import { SensorIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

import { PlotResponse } from 'components/map/map';

interface IPlotRouteButton {
  getPlotMarkers?: () => Promise<PlotResponse>;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const PlotButton: FC<IPlotRouteButton> = ({ getPlotMarkers, tooltipPosition }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const _getPlots = () => {
    if (getPlotMarkers && !isActive) {
      setLoading(true);
      getPlotMarkers?.()
        .then((res) => {
          if (res.response?.data) setIsActive(true);
        })
        .finally(() => setLoading(false));
    } else {
      getPlotMarkers?.();
      setIsActive(false);
    }
  };

  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <Tooltip
      title={t('plot')}
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
    >
      <Button
        ref={ref}
        shape="circle"
        icon={SensorIcon}
        onClick={_getPlots}
        transparentFillSvg
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
          background: isActive ? theme.primaryColor : theme.backgroundWhiteColor,
        }}
        loading={loading}
        customIconColor={isActive ? 'backgroundWhiteColor' : undefined}
      />
    </Tooltip>
  );
};

export default PlotButton;
