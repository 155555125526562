import React, { FC } from 'react';
import styled from 'styled-components';
import { IRobotMessage } from 'types/storeTypes';
import { Text } from '../text';
import ApplyUserTimezone from 'utils/convertToUserTimezone';

interface IMessage {
  data?: IRobotMessage;
}

const Message: FC<IMessage> = (data) => {
  const formattedDate = data?.data?.received.replace(' ', '').replace('+0000', '.000000Z');

  return (
    <MainWrapper>
      <MessageContent>
        <Text variant="bodyRegular">{data?.data?.message}</Text>
      </MessageContent>
      <MessageInfo>
        <Text color="textGrayColor" variant="bodyRegularSm">
          {data?.data?.from}
        </Text>
        <VerticalLine />
        <Text color="textGrayColor" variant="bodyRegularSm">
          <ApplyUserTimezone date={formattedDate} />
        </Text>
      </MessageInfo>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.backgroundWhiteColor};
  border-radius: ${({ theme }) => theme.borderRadiusBg};
  padding: ${({ theme }) => theme.paddingMd};
  gap: ${({ theme }) => theme.gapSm};
  display: flex;
  flex-direction: column;
`;

const VerticalLine = styled.div`
  height: 12px;
  width: 1px;
  background: ${({ theme }) => theme.textGrayColor};
`;

const MessageInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gapSm};
`;

const MessageContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gapSm};
`;

export default Message;
