import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'antd';
import { Switch } from 'components';

import { allowedContactSearches } from 'constants/searchTypes';
import styled from 'styled-components';
import { SEARCH_LOCATION, SEARCH_E164_LOOKUP, SEARCH_ROAMING, SEARCH_LOCATION_SMS } from 'constants/searchTypes';

interface ILocationSearchButton {
  onClick: (searchId: number, queryType: 'active' | 'passive') => void;
  onSearchTypeChange?: (searchId: number) => void;
  loading?: boolean;
}

const LocationSearchButton: FC<ILocationSearchButton> = ({ onClick, onSearchTypeChange, loading }) => {
  const { t } = useTranslation();

  const [requestType, setRequestType] = useState(1);
  const [currentQueryType, setCurrentQueryType] = useState<'passive' | 'active'>('passive');

  const _onSearchTypeChange = ({ key }: { key: string }) => {
    const value = Number(key);
    setRequestType(value);
    onSearchTypeChange?.(value);
  };

  const _onClick = () => onClick(requestType, currentQueryType);

  const validRequestTypes = [SEARCH_LOCATION, SEARCH_E164_LOOKUP, SEARCH_ROAMING, SEARCH_LOCATION_SMS];

  const isButtonVisible = validRequestTypes.includes(requestType);

  return (
    <StyledDropdown>
      <Dropdown.Button
        menu={{
          items: Object.keys(allowedContactSearches).map((searchId) => ({
            key: searchId,
            label: t(allowedContactSearches[Number(searchId)]) as string,
          })),
          onClick: _onSearchTypeChange,
        }}
        onClick={_onClick}
        loading={loading}
        disabled={loading}
      >
        {t(allowedContactSearches[requestType])}
      </Dropdown.Button>
      {isButtonVisible && (
        <Switch
          checkedChildren={t('active')}
          unCheckedChildren={t('passive')}
          checked={currentQueryType === 'active'}
          onChange={(checked) => setCurrentQueryType(checked ? 'active' : 'passive')}
          disabled={loading}
        />
      )}
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.gapSm};
  flex-wrap: wrap;

  max-width: 275px;

  .ant-space-compact {
    width: fit-content;
  }

  .ant-btn {
    border-radius: ${({ theme }) => theme.borderRadiusX1};
    background: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.textWhiteColor};
    outline: none;
    border: none;

    width: fit-content;
    font-size: 12px;

    &:last-child {
      min-width: 25px;
      border-left: ${({ theme }) => `1px solid ${theme.textWhiteColor}`};
    }
    &:hover,
    &:active,
    &:focus {
      z-index: inherit;
      background: ${({ theme }) => theme.mainShade2Color};
    }
  }

  .ant-switch {
    min-width: 75px;
  }
`;

export default LocationSearchButton;
