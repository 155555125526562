import React, { memo } from 'react';

import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { TileLayer } from 'react-leaflet';

import { useAppSelector } from 'hooks/useSelector';

const TileLayerComponent = memo(() => {
  const mapCredentials = useAppSelector((store) => store.user?.map?.credentials);

  if (mapCredentials.google_map_token)
    return <ReactLeafletGoogleLayer apiKey={mapCredentials.google_map_token} type={'roadmap'} />;
  else if (mapCredentials.hosted_osm_url) return <TileLayer url={mapCredentials.hosted_osm_url} />;
  else if (mapCredentials.mapbox_token && mapCredentials.mapbox_style_basic && mapCredentials.mapbox_account_name) {
    const { mapbox_style_basic, mapbox_token, mapbox_account_name } = mapCredentials;
    const style = mapbox_style_basic.split(/[/ ]+/).pop();
    const url = `https://api.mapbox.com/styles/v1/${mapbox_account_name}/${style}/tiles/256/{z}/{x}/{y}@2x?access_token=${mapbox_token}`;
    return <TileLayer url={url} />;
  } else if (mapCredentials.public_osm_url) return <TileLayer url={mapCredentials.public_osm_url} />;

  return null;
});

export default TileLayerComponent;
