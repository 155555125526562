import {
  GET_ROBOTS,
  GET_SINGLE_ROBOT,
  GET_CONTACT_TERMINALS,
  CREATE_ROBOT,
  DELETE_ROBOT,
  REMOVE_SINGLE_ROBOT,
  EDIT_ROBOT,
  CANCEL_SMS_REGISTER,
  UPDATE_ROBOT_DATA,
  UPDATE_ROBOT_LOCATION_DATA,
  UPDATE_ROBOT_ROAMING_DATA,
  LOGOUT,
  CLONE_ROBOT,
  ADD_MESSAGE_TO_ROBOT,
} from 'constants/actionTypes';
import initialState from './initState';

export default function robots(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ROBOTS: {
      const robots = {};
      Object.values(action.payload).forEach((robot) => (robots[robot.id] = robot));
      return {
        ...state,
        allRobots: robots,
      };
    }
    case GET_SINGLE_ROBOT:
      const newAllRobots = { ...state.allRobots };
      newAllRobots[action.payload.id] = { ...newAllRobots[action.payload.id], ...action.payload };

      return {
        ...state,
        singleRobot: {
          ...action.payload,
        },
        allRobots: newAllRobots,
      };
    case EDIT_ROBOT: {
      return {
        ...state,
        singleRobot: action.payload,
        allRobots: {
          ...state.allRobots,
          [action.payload.id]: action.payload,
        },
      };
    }
    case CANCEL_SMS_REGISTER: {
      return {
        ...state,
        singleRobot: action.payload,
        allRobots: {
          ...state.allRobots,
          [action.payload.id]: action.payload,
        },
      };
    }
    case GET_CONTACT_TERMINALS:
      return {
        ...state,
        contactTerminals: action.payload,
      };
    case CREATE_ROBOT:
      return {
        ...state,
        allRobots: {
          ...state.allRobots,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    case REMOVE_SINGLE_ROBOT:
      return {
        ...state,
        singleRobot: undefined,
      };
    case DELETE_ROBOT: {
      const robotsObj = state.allRobots;
      delete robotsObj[action.payload.id];
      return {
        ...state,
        allRobots: {
          ...robotsObj,
        },
      };
    }
    case CLONE_ROBOT: {
      const robotsObj = { ...state.allRobots, [action.payload.id]: action.payload };
      return {
        ...state,
        allRobots: robotsObj,
      };
    }
    case UPDATE_ROBOT_DATA: {
      return {
        ...state,
        singleRobot: {
          ...state.singleRobot,
          is_roaming: action.payload.is_roaming,
          ...action.payload,
        },
      };
    }
    case UPDATE_ROBOT_LOCATION_DATA: {
      return {
        ...state,
        singleRobot: {
          ...state.singleRobot,
          locations: {
            ...state.singleRobot.locations,
            [action.payload.id]: action.payload,
          },
        },
      };
    }
    case UPDATE_ROBOT_ROAMING_DATA: {
      const newAllRobots = { ...state.allRobots };
      newAllRobots[action.payload.id] = { ...newAllRobots[action.payload.id], ...action.payload };
      return {
        ...state,
        singleRobot: {
          ...state.singleRobot,
          ...action.payload,
        },
        allRobots: newAllRobots,
      };
    }
    case ADD_MESSAGE_TO_ROBOT: {
      const updatedMessages = { ...state.singleRobot.messages };

      Object.keys(action.payload).forEach((key) => {
        if (!updatedMessages[key]) {
          updatedMessages[key] = action.payload[key];
        }
      });

      return {
        ...state,
        singleRobot: {
          ...state.singleRobot,
          messages: updatedMessages,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
