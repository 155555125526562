export default class SessionUnresolvedLocationsHelper {
  constructor() {
    this.state = JSON.parse(sessionStorage.getItem('sessionUnresolvedLocations')) || {};
  }

  getData() {
    return this.state;
  }

  setSession(data) {
    this.state = data;
    sessionStorage.setItem('sessionUnresolvedLocations', JSON.stringify(data));
  }

  setLocationToSession(data) {
    // Ensure data and location exist
    if (!data || !data.location) return;

    const { contact_id, id } = data.location;

    // If contact_id does not exist in state, initialize it as an empty object
    if (!this.state[contact_id]) {
      this.state[contact_id] = {};
    }

    // Add or update location under specified contact_id
    this.state[contact_id][id] = data.location;

    // Update sessionStorage
    this.setSession(this.state);
  }

  updateLocationToSession(data) {
    // Ensure data and location exist
    if (!data || !data.location) return;

    const { contact_id, id } = data.location;

    // If contact_id is missing in state and specific location does not exists - exit
    if (!this.state[contact_id] || !this.state[contact_id][id]) return;

    // Update existing location
    this.state[contact_id][id] = {
      ...this.state[contact_id][id],
      ...data.location,
    };

    // Update sessionStorage
    this.setSession(this.state);
  }

  removeLocationFromSession(data) {
    // Ensure data and location exist
    if (!data || !data.location) return;

    const { contact_id, id } = data.location;

    // Ensure safe access before deleting properties
    if (!this.state[contact_id] || !this.state[contact_id][id]) return;

    delete this.state[contact_id][id];

    // If contact_id has no more locations, remove contact_id key as well
    if (Object.keys(this.state[contact_id]).length === 0) {
      delete this.state[contact_id];
    }

    this.setSession(this.state);
  }

  destroySession() {
    this.state = {};
    sessionStorage.removeItem('sessionUnresolvedLocations');
  }
}
