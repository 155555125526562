import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import * as L from 'leaflet';
import { Circle, LayerGroup, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Circle as LeafletCircle } from 'leaflet';

import { Text } from 'components';
import TimeStamp from 'utils/timeStamp';
import Marker from './marker';
import PlotsView from 'components/map/plotsView';
import HeatMap from 'components/map/heatMap';

import { HeatMapResponse, ICustomLeafletMap, PlotResponse, SingleViewConfigType } from 'components/map/map';
import IStore, { ICoverageData, ILocation } from 'types/storeTypes';
import { PointExpression } from 'leaflet';
import HighPrecisionView from './controls/highPrecision/highPrecisionView';
import CoveragePolygonView from './controls/coveragePolygon/coveragePolygonView';
import './controls/sector';

interface ILocationsView {
  map: ICustomLeafletMap;
  locations?: Record<number, ILocation> | IStore['patternOfLife']['viewList'] | null;
  singleViewConfig?: SingleViewConfigType;
  plotMarkers?: PlotResponse;
  highPrecisionMarker?: { lat: string; long: string; range: number | null }[];
  coverageData?: ICoverageData;
  heatMapPoints?: HeatMapResponse;
  customWithOffset?: {
    paddingTopLeft: PointExpression;
    paddingBottomRight: PointExpression;
  };
  withOffset: boolean;
  isContactPage?: boolean;
  isPatternOfLifeTabActive?: boolean;
  contactLabelColorMapping?: Record<string, string>;
  hasMultipleColoredClusterIcon?: boolean;
  isCellRangeVisible?: boolean;
}

const LocationsView: FC<ILocationsView> = ({
  locations,
  singleViewConfig,
  map,
  plotMarkers,
  highPrecisionMarker,
  coverageData,
  heatMapPoints,
  customWithOffset,
  withOffset,
  isContactPage,
  isPatternOfLifeTabActive,
  contactLabelColorMapping,
  hasMultipleColoredClusterIcon,
  isCellRangeVisible,
}) => {
  const { t } = useTranslation();

  const azimuthRefs = useRef<LeafletCircle[]>([]);

  useEffect(() => {
    if (!isCellRangeVisible) {
      // Remove all azimuth sectors from the map
      azimuthRefs.current.forEach((azimuth) => azimuth?.removeFrom(map));
      azimuthRefs.current = []; // Clear the array after removing
    }
  }, [isCellRangeVisible, singleViewConfig]);

  if (!locations && !singleViewConfig?.location) return null;

  const locationsArray = Object.values(locations || {});

  if (singleViewConfig?.location || locationsArray.length === 1) {
    const {
      id,
      lat,
      long,
      title,
      name,
      updated_at,
      range,
      angle_start,
      angle_end,
      pattern_of_life_setting_id,
      label,
      contact_id,
    } = singleViewConfig?.location || locationsArray[0];

    if (lat && long)
      return (
        <>
          {plotMarkers ? (
            <PlotsView
              plotMarkers={plotMarkers}
              map={map}
              customWithOffset={customWithOffset}
              withOffset={withOffset}
            />
          ) : null}
          {highPrecisionMarker ? (
            <HighPrecisionView
              highPrecisionMarker={highPrecisionMarker}
              map={map}
              customWithOffset={customWithOffset}
              withOffset={withOffset}
            />
          ) : null}
          {heatMapPoints ? (
            <HeatMap
              heatMapPoints={heatMapPoints}
              map={map}
              customWithOffset={customWithOffset}
              withOffset={withOffset}
              isContactPage={isContactPage}
            />
          ) : null}
          {coverageData ? (
            <CoveragePolygonView
              coverageData={coverageData}
              map={map}
              customWithOffset={customWithOffset}
              withOffset={withOffset}
              singleViewConfig={singleViewConfig}
            />
          ) : null}

          <Marker
            key={id}
            position={[lat, long]}
            range={range}
            single
            map={map}
            angleStart={angle_start}
            angleEnd={angle_end}
            isPatternOfLifeTabActive={isPatternOfLifeTabActive}
            markerColor={contactLabelColorMapping?.[contact_id]}
          >
            <Popup>
              {pattern_of_life_setting_id ? (
                <>
                  <Row>
                    <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                      {t('location')}:{' '}
                    </Text>
                    <Text variant="bodyBoldSm">{label?.label}</Text>
                  </Row>
                  <Row>
                    <span>
                      <Text variant="bodyRegularSm" color="textGrayColor">
                        {t('lat')}:{' '}
                      </Text>
                      <Text variant="bodyBoldSm">{lat}</Text>
                    </span>
                    <span>
                      <Text variant="bodyRegularSm" color="textGrayColor">
                        {t('lng')}:{' '}
                      </Text>
                      <Text variant="bodyBoldSm">{long}</Text>
                    </span>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Text variant="bodyRegularSm">{name}</Text>
                  </Row>
                  <Row>
                    <Text variant="bodyBoldSm">{title}</Text>
                  </Row>
                  <Row>
                    <Text variant="bodyRegularSm">{lat}</Text> <Text variant="bodyRegularSm">/</Text>{' '}
                    <Text variant="bodyRegularSm">{long}</Text>
                  </Row>
                  <Row>
                    <Text variant="bodyBoldSm">
                      <TimeStamp date={updated_at} />
                    </Text>
                  </Row>
                </>
              )}
            </Popup>
          </Marker>
        </>
      );
  }

  // @ts-ignore
  const customClusterIcon = (cluster) => {
    const count = cluster.getChildCount();
    const markers = cluster.getAllChildMarkers();

    const colors: string[] = Array.from(
      new Set(
        markers
          .map((marker: any) => {
            const html = marker?.options?.icon?.options?.html; // Get the HTML string
            if (typeof html === 'string') {
              // Extract the color using a regex
              const match = html.match(/color=["']([^"']+)["']/);
              return match ? match[1] : null; // Return the color value or null if not found
            }
            return null;
          })
          .filter((color: string | undefined) => typeof color === 'string'), // Filter only valid string colors
      ),
    );

    //  Convert colors to RGBA
    const convertToRgba = (hexColor: string | undefined = '#3569F5', opacity = 1) => {
      if (hexColor.startsWith('#')) {
        // Remove the '#' if present
        const hex = hexColor.replace('#', '');

        // Convert hex to RGB
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }

      // Default to a fallback color if not a valid hex
      return `rgba(53, 105, 245, 0.6)`;
    };

    // Use the first color or a default value
    const parentBackground = colors.length > 0 ? convertToRgba(colors[0], 0.6) : 'rgba(53, 105, 245, 0.6)';

    const background =
      colors.length > 1
        ? colors
            .map((color, index) => {
              const stripeWidth = 100 / colors.length;
              return `${color} ${index * stripeWidth}%, ${color} ${(index + 1) * stripeWidth}%`;
            })
            .join(', ')
        : colors[0];

    return L.divIcon({
      html: `
      <div style="
        background: ${parentBackground};
        width: 40px; 
        height: 40px;
        display: flex; 
        align-items: center; 
        justify-content: center;
        border-radius: 50%; 
        position: relative;
      ">
        <div style="
          background: ${
            colors.length > 0
              ? colors.length > 1
                ? `linear-gradient(to right, ${background})`
                : colors[0]
              : 'rgba(53, 105, 245, 0.6)' // Default background with opacity
          };
          width: 30px; 
          height: 30px;
          border-radius: 50%; 
          position: absolute;
          top: 5px;
          left: 5px;
        "></div>
        <span style="
          position: relative;
          color: white;
          font-size: 12px;
          font-weight: bold;
        ">
          ${count}
        </span>
      </div>
    `,
      className: 'custom-cluster-icon',
      iconSize: [40, 40],
    });
  };

  if (locationsArray.length > 1 && !isPatternOfLifeTabActive) {
    return (
      <>
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={hasMultipleColoredClusterIcon ? customClusterIcon : undefined}
        >
          {locationsArray
            .filter((location) => location !== null && location.lat && location.long)
            .map(({ id, lat, long, name, title, location_time, phone, imsi, contact_id }) => (
              <Marker
                key={id}
                position={[lat as number, long as number]}
                isPatternOfLifeTabActive={isPatternOfLifeTabActive}
                markerColor={contactLabelColorMapping?.[contact_id]}
              >
                <StyledPopup>
                  <Row>
                    <Text variant="bodyRegularSm">{name}</Text>
                  </Row>
                  <Row>
                    <Text variant="bodyRegularSm">{title}</Text>
                  </Row>
                  <Row>
                    <Text variant="bodyRegularSm">{phone ?? imsi}</Text>
                  </Row>
                  <Row>
                    <Text variant="bodyBoldSm">
                      <TimeStamp date={location_time} />
                    </Text>
                  </Row>
                </StyledPopup>
              </Marker>
            ))}
        </MarkerClusterGroup>
        {isCellRangeVisible &&
          locationsArray
            .filter((location) => location !== null && location.lat && location.long)
            .map(({ id, lat, long, contact_id, range, angle_start, angle_end }) => {
              if (
                map &&
                (angle_start !== 0 || angle_end !== 360) &&
                !isNaN(angle_start as number) &&
                !isNaN(angle_end as number)
              ) {
                // Create a new azimuth sector and add it to the map
                // @ts-ignore
                const azimuth = L.circle([lat, long], {
                  radius: range as number,
                  startAngle: angle_start,
                  endAngle: angle_end,
                  fillOpacity: 0.1,
                  weight: 1,
                  color: contactLabelColorMapping?.[contact_id] || 'red',
                }).addTo(map);

                // Store reference for later removal
                azimuthRefs.current.push(azimuth);
              } else {
                return (
                  <Circle
                    key={id}
                    center={[lat, long]}
                    pathOptions={{
                      color: contactLabelColorMapping?.[contact_id],
                      weight: 1,
                      fillOpacity: 0.1,
                      opacity: 1,
                    }}
                    radius={range}
                  />
                );
              }
            })}
      </>
    );
  }

  if (locationsArray.length > 1 && isPatternOfLifeTabActive) {
    return (
      <LayerGroup>
        {locationsArray
          .filter((location) => location !== null && location.lat && location.long)
          .map(({ id, lat, long, label, range, contact_id }) => (
            <Marker
              key={id}
              position={[lat as number, long as number]}
              isPatternOfLifeTabActive={isPatternOfLifeTabActive}
              range={range}
              markerColor={contactLabelColorMapping?.[contact_id]}
            >
              <StyledPopup>
                <Row>
                  <Text variant="bodyRegularSm" color="textGrayColor" style={{ whiteSpace: 'nowrap' }}>
                    {t('location')}:{' '}
                  </Text>
                  <Text variant="bodyBoldSm">{label?.label}</Text>
                </Row>
                <Row>
                  <span>
                    <Text variant="bodyRegularSm" color="textGrayColor">
                      {t('lat')}:{' '}
                    </Text>
                    <Text variant="bodyBoldSm">{lat}</Text>
                  </span>
                  <span>
                    <Text variant="bodyRegularSm" color="textGrayColor">
                      {t('lng')}:{' '}
                    </Text>
                    <Text variant="bodyBoldSm">{long}</Text>
                  </span>
                </Row>
              </StyledPopup>
            </Marker>
          ))}
      </LayerGroup>
    );
  }

  return null;
};

const StyledPopup = styled(Popup)``;

const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapSm};
`;

export default LocationsView;
