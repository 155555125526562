import React, { ComponentType, SVGProps, ForwardRefExoticComponent, FC, MouseEventHandler } from 'react';

import styled, { css } from 'styled-components';

import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { useTheme } from 'theme/useTheme';

import { KeysOfColorPalette } from 'theme/types';

type IconSizeType = 'small' | 'middle' | 'large' | number;

interface IIcon extends Partial<CustomIconComponentProps> {
  icon:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined;
  color?: KeysOfColorPalette;
  size?: IconSizeType;
  hover?: any;
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  transparentFillSvg?: boolean;
  rotate?: number;
  bulkColor?: KeysOfColorPalette;
}

const IconComponent: FC<IIcon> = ({
  icon,
  color = 'grayColor',
  size = 24,
  hover,
  transparentFillSvg,
  rotate,
  bulkColor,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <IconStyled
      component={icon}
      color={theme[color]}
      $customSize={size}
      hover={hover}
      $transparentFillSvg={transparentFillSvg}
      rotate={rotate}
      $bulkColor={bulkColor}
      {...rest}
    />
  );
};

const getIconSize = (size: IconSizeType) => {
  switch (size) {
    case 'small':
      return 12;
    case 'middle':
      return 16;
    case 'large':
      return 20;
    default:
      return size;
  }
};

const IconStyled = styled(Icon)<{
  $customSize: IconSizeType;
  hover?: any;
  $transparentFillSvg?: boolean;
  $bulkColor?: KeysOfColorPalette;
}>`
  color: ${({ color, theme }) => color || theme.textBlackColor};
  font-size: ${({ $customSize }) => `${getIconSize($customSize)}px`};
  line-height: 0 !important;

  svg {
    ${({ $transparentFillSvg }) =>
      $transparentFillSvg &&
      css`
        fill: transparent !important;
      `}
  }

  ${({ theme, $bulkColor }) =>
    $bulkColor &&
    css`
      svg {
        path {
          fill: ${theme[$bulkColor]};
        }
      }
    `};

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        ${hover}
      }
    `};
`;

export default IconComponent;
