import api from 'api/appApi';
import { GET_SURVEYS, UPLOAD_SURVEY, UPDATE_SURVEY, DELETE_SURVEY, FIRE_ALERT } from 'constants/actionTypes';

export const getSurveys = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .getSurveys(data)
      .then((res) => {
        dispatch({
          type: GET_SURVEYS,
          payload: res.data?.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const uploadSurvey = (file, t) => (dispatch) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    return api.survey
      .upload(formData)
      .then((res) => {
        dispatch({ type: UPLOAD_SURVEY, payload: res.data?.data });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('error'),
            message: err.response.data.message,
          },
        });
        return reject(err.response.data);
      });
  });

export const updateSurvey = (data) => (dispatch) => dispatch({ type: UPDATE_SURVEY, payload: data });

export const deleteSurvey = (id, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .delete(id)
      .then((res) => {
        dispatch({ type: DELETE_SURVEY, payload: id });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );
