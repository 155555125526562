import {
  CREATE_OR_UPDATE_PATTERN_OF_LIFE,
  RETRIEVE_PATTERN_OF_LIFE,
  RETRIEVE_NEXT_DATA_PATTERN_OF_LIFE,
  CREATE_LABEL_PATTERN_OF_LIFE,
  UPDATE_LABEL_PATTERN_OF_LIFE,
  DELETE_LABEL_PATTERN_OF_LIFE,
  GET_PATTERN_OF_LIFE_ENABLED_LIST,
  SET_ACTIVE_ITEM_PATTERN_OF_LIFE,
  RESET_PATTERN_OF_LIFE_ENABLED_LIST,
  RESET_PATTERN_OF_LIFE_VIEW_LIST,
  HIDE_PATTERN_OF_LIFE_ITEM_FROM_LIST,
  GET_REPORT_PATTERN_OF_LIFE,
} from 'constants/actionTypes';

export default function patternOfLife(state = {}, action = {}) {
  switch (action.type) {
    case CREATE_OR_UPDATE_PATTERN_OF_LIFE:
      const targetId = action.payload.data.terminal_id;
      const index = state.enabledList.findIndex((item) => item.terminal_id === targetId);

      // No update needed if not found
      if (index === -1) return state;

      const updatedEnabledList = [...state.enabledList];
      updatedEnabledList[index] = action.payload.data;

      return {
        ...state,
        enabledList: updatedEnabledList,
      };
    case RETRIEVE_PATTERN_OF_LIFE:
      return {
        ...state,
        viewList: action.payload.data,
        viewListMeta: action.payload.meta,
      };
    case RETRIEVE_NEXT_DATA_PATTERN_OF_LIFE:
      const newData = [...state.viewList, ...action.payload.data];
      return {
        ...state,
        viewList: newData,
        viewListMeta: action.payload.meta,
      };
    case CREATE_LABEL_PATTERN_OF_LIFE: {
      const { baseLocationId, data } = action.payload;
      const index = state.viewList.findIndex((item) => item.base_location_id === baseLocationId);

      // No update needed if not found
      if (index === -1) return state;

      const updatedViewList = [...state.viewList];
      updatedViewList[index] = {
        ...updatedViewList[index],
        label: data,
      };

      return {
        ...state,
        viewList: updatedViewList,
      };
    }
    case UPDATE_LABEL_PATTERN_OF_LIFE: {
      const { baseLocationId, data } = action.payload;
      const index = state.viewList.findIndex((item) => item.base_location_id === baseLocationId);

      // No update needed if not found
      if (index === -1) return state;

      const updatedViewList = [...state.viewList];
      updatedViewList[index] = {
        ...updatedViewList[index],
        label: {
          ...updatedViewList[index].label,
          label: data.label,
        },
      };

      return {
        ...state,
        viewList: updatedViewList,
      };
    }
    case DELETE_LABEL_PATTERN_OF_LIFE: {
      const baseLocationId = action.payload;
      const index = state.viewList.findIndex((item) => item.base_location_id === baseLocationId);

      // No update needed if not found
      if (index === -1) return state;

      const updatedViewList = [...state.viewList];
      updatedViewList[index] = {
        ...updatedViewList[index],
        label: null,
      };

      return {
        ...state,
        viewList: updatedViewList,
      };
    }
    case GET_PATTERN_OF_LIFE_ENABLED_LIST:
      return {
        ...state,
        enabledList: action.payload.data,
      };
    case SET_ACTIVE_ITEM_PATTERN_OF_LIFE:
      return {
        ...state,
        activeItem: action.payload,
      };
    case RESET_PATTERN_OF_LIFE_ENABLED_LIST:
      const { enabledList, ...stateWithoutEnabledList } = state;
      return {
        ...stateWithoutEnabledList,
      };
    case RESET_PATTERN_OF_LIFE_VIEW_LIST:
      const { viewListMeta, viewList, ...stateWithoutViewList } = state;
      return {
        ...stateWithoutViewList,
      };
    case HIDE_PATTERN_OF_LIFE_ITEM_FROM_LIST:
      return {
        ...state,
        viewList: state.viewList.filter((item) => item.base_location_id !== action.payload),
        viewListMeta: {
          ...state.viewListMeta,
          to: state.viewListMeta.to - 1,
          total: state.viewListMeta.total - 1,
        },
      };
    case GET_REPORT_PATTERN_OF_LIFE:
      return {
        ...state,
        reportList: action.payload.data,
      };
    default:
      return state;
  }
}
