import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';
import { Tooltip } from 'antd';

import { Button } from 'components/index';
import { GraphicEqIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';

import { HeatMapResponse } from 'components/map/map';

interface IHeatPointsButton {
  getHeatMap?: () => Promise<HeatMapResponse>;
  isHeatMapActive: boolean;
  setIsHeatMapActive: (isHeatMapActive: boolean) => void;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const HeatPointsButton: FC<IHeatPointsButton> = ({
  getHeatMap,
  isHeatMapActive,
  setIsHeatMapActive,
  tooltipPosition,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const ref = useRef<HTMLElement>();

  const [loading, setLoading] = useState(false);

  const _getHeatMap = () => {
    if (getHeatMap && !isHeatMapActive) {
      setLoading(true);
      getHeatMap?.()
        .then((res) => {
          if (res.Data?.length) setIsHeatMapActive(true);
        })
        .finally(() => setLoading(false));
    } else {
      getHeatMap?.();
      setIsHeatMapActive(false);
    }
  };

  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip
        title={t('heatMap')}
        placement={tooltipPosition}
        mouseEnterDelay={1}
        getPopupContainer={() => ref.current?.parentElement || document.body}
      >
        <Button
          ref={ref}
          shape="circle"
          icon={GraphicEqIcon}
          onClick={_getHeatMap}
          transparentFillSvg
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
            background: isHeatMapActive ? theme.primaryColor : theme.backgroundWhiteColor,
          }}
          loading={loading}
        />
      </Tooltip>
    </div>
  );
};

export default HeatPointsButton;
