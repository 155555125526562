import store, { notificationPermissionResult } from '../index';
import icon from '../assets/images/mapLogo.png';
import { successAudioNotification, errorAudioNotification } from './notificationSound';

const INTERVAL_TIME = 5000;
const options = {
  icon,
};

export const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
    return true;
  }
  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
    return true;
  }
  return false;
};

export const playSound = (state) => {
  successAudioNotification?.pause();
  errorAudioNotification?.pause();
  if (successAudioNotification) successAudioNotification.currentTime = 0;
  if (errorAudioNotification) errorAudioNotification.currentTime = 0;
  if (state === 'success') successAudioNotification?.play();
  else errorAudioNotification?.play();
};

function triggerNotificationAndSound({ notificationMessage, url, state = 'success' }, sound = true) {
  const trigger = () => {
    const notification = new Notification(notificationMessage, {
      body: url ? 'Press to visit Page' : 'Switch to another team or Personal view',
      ...options,
    });
    setTimeout(() => notification.close(), INTERVAL_TIME);
    notification.onclick = () => {
      if (url !== window.location.href && url !== null) window.open(url);
      else window.focus();
    };
  };

  if (!isIOS()) {
    if (notificationPermissionResult === 'granted') {
      trigger();
      if (sound) playSound(state);
    } else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          trigger();
          if (sound) playSound(state);
        }
      });
    }
  }
}

let queueInterval = false;
const queueActions = ({ sound, browser_notification: browserNotification }, queueArray) => {
  if (!queueInterval) {
    queueInterval = setInterval(() => {
      if (queueArray.length !== 0) {
        if (sound === 1 && browserNotification === 1) {
          triggerNotificationAndSound(queueArray.shift());
        } else if (browserNotification === 1 && sound === 0) {
          triggerNotificationAndSound(queueArray.shift(), false);
        } else if (sound === 1 && browserNotification === 0) {
          playSound(queueArray.shift().state);
        }
      } else {
        clearInterval(queueInterval);
        queueInterval = false;
      }
    }, INTERVAL_TIME);
  }
};

export function dispatchNotificationOrSound(
  notificationMessage,
  url,
  state = 'success',
  playInstant = false,
  userStore = null,
) {
  let user = null;
  if (!userStore) user = store.getState().user;
  else user = { ...userStore };

  const queueArray = [{ notificationMessage, url, state }];

  if ((user.sound === 1 || user.browser_notification === 1) && !playInstant) {
    queueActions(user, queueArray);
  } else if ((user.sound === 1 || user.browser_notification === 1) && playInstant) {
    if (user.sound === 1 && user.browser_notification === 1) {
      triggerNotificationAndSound({ notificationMessage, url, state });
    } else if (user.browser_notification === 1 && user.sound === 0) {
      triggerNotificationAndSound({ notificationMessage, url, state }, false);
    } else if (user.sound === 1 && user.browser_notification === 0) {
      playSound(state);
    }
  }
}
