import React, { FC, ReactNode, useMemo } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Menu } from 'components';

const LoginLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <LoginLayoutStyle id="main-layout">{children}</LoginLayoutStyle>
);
const DefaultLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <DefaultLayoutStyle id="main-layout">{children}</DefaultLayoutStyle>
);
const ContactLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <ContactLayoutStyle id="main-layout">{children}</ContactLayoutStyle>
);
const RobotsLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <RobotsLayoutStyle id="main-layout">{children}</RobotsLayoutStyle>
);

const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();

  const Layout = useMemo(() => {
    if (pathname === '/') return DefaultLayout;
    else if (pathname.includes('/login')) return LoginLayout;
    else if (pathname.includes('/contact')) return ContactLayout;
    else if (pathname.includes('/robots')) return RobotsLayout;

    return DefaultLayout;
  }, [pathname]);

  return (
    <MainContainer>
      {pathname !== '/login' ? <Menu /> : null}
      <Layout>{children}</Layout>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.backgroundLightGrayColor};

  overflow: auto;
`;

const ContactLayoutStyle = styled.div`
  display: grid;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const RobotsLayoutStyle = styled.div`
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const LoginLayoutStyle = styled.div`
  display: grid;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const DefaultLayoutStyle = styled.div`
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  position: relative;

  @media only screen and (max-width: 640px) {
    padding: ${({ theme }) => theme.paddingMd};
    min-width: 250px;
  }
`;

export default MainLayout;
