import {
  GET_TEAMS,
  ADD_USER_TO_TEAM,
  MAKE_LEADER,
  REMOVE_USER_FROM_TEAM,
  GET_SINGLE_TEAM,
  FIRE_ALERT,
  CREATE_TEAM,
  DELETE_TEAM,
  EDIT_TEAM_NAME,
  EDIT_USER_TEAM_CREDITS,
} from 'constants/actionTypes';
import api from 'api/appApi';
import history from '../../utils/history';

export const getTeams = () => (dispatch) =>
  new Promise((resolve, reject) => {
    api.teams
      .getTeams()
      .then((res) => {
        dispatch({
          type: GET_TEAMS,
          payload: Object.values(res.data.data),
        });
        return resolve(Object.values(res.data.data));
      })
      .catch((err) => reject(err));
  });

export const addUserToTeam = (data) => (dispatch) => {
  return new Promise((resolve, reject) =>
    api.teams
      .addUserToTeam(data)
      .then((res) => {
        dispatch({
          type: ADD_USER_TO_TEAM,
          payload: res.data.data,
        });
        return resolve(data);
      })
      .catch((err) => {
        const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${limit}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );
};

export const removeUserFromTeam = (userId, teamId) => (dispatch) =>
  api.teams.removeUserFromTeam(userId, teamId).then((res) =>
    dispatch({
      type: REMOVE_USER_FROM_TEAM,
      payload: res.data.data,
    }),
  );

export const makeUserTeamLeader = (userId) => (dispatch) =>
  api.teams.makeUserTeamLeader(userId).then((res) =>
    dispatch({
      type: MAKE_LEADER,
      payload: res.data,
    }),
  );

export const getSingleTeam = (teamId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.teams
      .getSingleTeam(teamId)
      .then((res) => {
        dispatch({
          type: GET_SINGLE_TEAM,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        return reject(err);
      }),
  );

export const createTeam = (teamName, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.teams
      .createTeam(teamName)
      .then((res) => {
        dispatch({
          type: CREATE_TEAM,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: t('askForPermissionToCreateATeam'),
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const deleteTeam = (teamId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.teams
      .deleteTeam(teamId, data)
      .then((res) => {
        dispatch({
          type: DELETE_TEAM,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const modeError = !err.response.data.errors.mode ? '' : err.response.data.errors.mode[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${modeError}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const switchTeam = (teamId) => () =>
  api.teams.switchTeam(teamId).then(() => {
    history.push('/');
    document.location.reload();
  });

export const editTeam = (teamId, name) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.teams
      .editTeam(teamId, name)
      .then((res) => {
        dispatch({
          type: EDIT_TEAM_NAME,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const nameError = !err.response.data.errors.name ? '' : err.response.data.errors.name[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${nameError}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      });
  });

export const editUserTeamCredits = (userId, credits) => (dispatch) =>
  api.users
    .editUserCredits(userId, credits)
    .then((res) => {
      dispatch({
        type: EDIT_USER_TEAM_CREDITS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data.data);
    })
    .catch((err) => {
      const allocatedCredits = !err.response.data.errors.allocated_credits
        ? ''
        : err.response.data.errors.allocated_credits[0];
      const dailyCredits = !err.response.data.errors.daily_credits ? '' : err.response.data.errors.daily_credits[0];
      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: `${allocatedCredits} ${dailyCredits}`,
          message: err.response.data.message,
        },
      });
      return Promise.reject(err);
    });
