import React, { FC, PropsWithChildren } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import MenuStyles from 'components/menu/menuStyles';

const { MaximizedContent } = MenuStyles;

interface Props extends PropsWithChildren {
  isExpanded: boolean;
  id?: number;
}

const ExpandedContent: FC<Props> = ({ children, isExpanded }) => (
  <AnimatePresence>
    {isExpanded && (
      <MaximizedContent>
        <motion.div
          key="menu-item"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{ display: 'flex', justifyContent: isExpanded ? 'space-between' : 'left', alignItems: 'center' }}
        >
          {children}
        </motion.div>
      </MaximizedContent>
    )}
  </AnimatePresence>
);

export default ExpandedContent;
