import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PopOver, Text } from 'components';
import { useAppSelector } from 'hooks/useSelector';
import { useAppDispatch } from 'hooks/useDispatch';
import { getAllExistingPermissionsList } from 'actions/app/application';
import { ROLE_SUPER_ADMIN } from 'constants/userRoleType';

interface IPermissions {
  children: any;
  button: string | string[];
  dependencyStrategy?: 'any' | 'all';
}

const PermissionHandler: FC<IPermissions> = ({ children, button, dependencyStrategy = 'any', ...rest }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const permissions = useAppSelector((store) => store.user.permissions);
  const userRoleName = useAppSelector((store) => store.user.role.name);
  const allExistingPermissionsList = useAppSelector((store) => store.app.allExistingPermissionsList);

  useEffect(() => {
    if (!allExistingPermissionsList) dispatch(getAllExistingPermissionsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // PART 1: If button permission is true for user's permissions -> make button enable
  // Convert button to an array if it's a string
  const buttonsArray = Array.isArray(button) ? button : [button];

  let havePermissions = true;
  if (dependencyStrategy === 'all') {
    // Check if the user has permissions for all buttons
    havePermissions = buttonsArray.every((btn) => permissions.some((permission) => permission.name === btn));
  } else {
    // Check if the user has permissions for any of the buttons
    havePermissions = buttonsArray.some((btn) => permissions.some((permission) => permission.name === btn));
  }

  // PART 2: If button permission is not used anymore in project -> ignore and make button enable
  let ignorePermissions = true;
  // Check if allExistingPermissionsList is defined and if any button in the array exists in it
  const isAnyButtonInPermissions =
    allExistingPermissionsList &&
    buttonsArray.some((btn) => allExistingPermissionsList.some((permission) => permission.name === btn));

  // If at least one button is found and allExistingPermissionsList is defined, set ignorePermissions to false
  if (isAnyButtonInPermissions) {
    ignorePermissions = false;
  }

  if (ignorePermissions || userRoleName === ROLE_SUPER_ADMIN) {
    return (
      <div className="button-handler-component" style={{ display: 'flex', alignItems: 'center' }} {...rest}>
        {children}
      </div>
    );
  }

  return (
    <div className="button-handler-component" style={{ display: 'flex', alignItems: 'center' }} {...rest}>
      {havePermissions ? (
        children
      ) : (
        <div
          className="button-handler-wrapper"
          onClick={(e) => e.stopPropagation()}
          style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
        >
          <PopOver
            style={{ zIndex: 1000 }}
            trigger="hover"
            content={<Text>{t('youDontHavePermissionsToUseThisAction')}</Text>}
          >
            <div style={{ position: 'absolute', width: '100%', height: '100%' }} />
          </PopOver>
          <div
            className="handler-children-component"
            style={{ display: 'flex', alignItems: 'center', pointerEvents: 'none', opacity: 0.4 }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default PermissionHandler;
