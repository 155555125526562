// Search types
export const SEARCH_LOCATION = 1;
export const SEARCH_PUT = 2; // NEVER USED
export const SEARCH_CELL_TOWER = 3; // NEVER USED
export const SEARCH_IMSI_LOOKUP = 4;
export const SEARCH_MSISDN_LOOKUP = 5;
export const SEARCH_FORWARDING_CHECK = 6;
export const SEARCH_HOLD = 7;
export const SEARCH_E164_LOOKUP = 8;
export const SEARCH_GET_PLOTTED_CELLS = 9; // NEVER USED
export const SEARCH_E212_LOOKUP = 10;
export const SEARCH_SERVICE_STATUS = 11; // NEVER USED
export const SEARCH_ROAMING = 12;
export const SEARCH_LOCATION_SMS = 17;

export enum EnumSearchTypes {
  'locationSearch' = SEARCH_LOCATION,
  'imsiLookup' = SEARCH_IMSI_LOOKUP,
  'msisdnLookup' = SEARCH_MSISDN_LOOKUP,
  'forwardingCheck' = SEARCH_FORWARDING_CHECK,
  'hold' = SEARCH_HOLD,
  'e164Lookup' = SEARCH_E164_LOOKUP,
  'e212Lookup' = SEARCH_E212_LOOKUP,
  'roamingSearch' = SEARCH_ROAMING,
  'smsLocate' = SEARCH_LOCATION_SMS,
}

export type SearchTypes = keyof typeof EnumSearchTypes;

export const allAllowedSearches: Record<number, SearchTypes> = {
  [SEARCH_LOCATION]: 'locationSearch',
  [SEARCH_IMSI_LOOKUP]: 'imsiLookup',
  [SEARCH_MSISDN_LOOKUP]: 'msisdnLookup',
  [SEARCH_FORWARDING_CHECK]: 'forwardingCheck',
  // [SEARCH_HOLD]: 'hold',
  [SEARCH_E164_LOOKUP]: 'e164Lookup',
  [SEARCH_E212_LOOKUP]: 'e212Lookup',
  [SEARCH_ROAMING]: 'roamingSearch', // No Roaming Search into Contacts page
  [SEARCH_LOCATION_SMS]: 'smsLocate', // Only in Dashboard results
};

export const allowedDashboardSearches: Record<number, SearchTypes> = {
  [SEARCH_LOCATION]: 'locationSearch',
  [SEARCH_IMSI_LOOKUP]: 'imsiLookup',
  [SEARCH_MSISDN_LOOKUP]: 'msisdnLookup',
  [SEARCH_FORWARDING_CHECK]: 'forwardingCheck',
  // [SEARCH_HOLD]: 'hold',
  [SEARCH_E164_LOOKUP]: 'e164Lookup',
  [SEARCH_E212_LOOKUP]: 'e212Lookup',
  [SEARCH_ROAMING]: 'roamingSearch', // No Roaming Search into Contacts page
  // [SEARCH_LOCATION_SMS]: 'smsLocate', // Only in Dashboard results
};

export const allowedContactSearches: Record<number, SearchTypes> = {
  [SEARCH_LOCATION]: 'locationSearch',
  [SEARCH_IMSI_LOOKUP]: 'imsiLookup',
  [SEARCH_MSISDN_LOOKUP]: 'msisdnLookup',
  [SEARCH_FORWARDING_CHECK]: 'forwardingCheck',
  // [SEARCH_HOLD]: 'hold',
  [SEARCH_E164_LOOKUP]: 'e164Lookup',
  [SEARCH_E212_LOOKUP]: 'e212Lookup',
  // [SEARCH_ROAMING]: 'roamingSearch', // No Roaming Search into Contacts page
  // [SEARCH_LOCATION_SMS]: 'smsLocate', // Only in Dashboard results
};
