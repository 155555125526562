import React, { FC, PropsWithChildren } from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { themeLight, themeDark } from './themeConstatns';

import GlobalStyle from 'theme/globalStyle';

import 'theme/normalize.css';
import 'theme/antdCustom.sass';

const ThemeProviderComponent: FC<PropsWithChildren> = ({ children }) => {
  const isDarkMode = useSelector((state: any) => state.app.darkMode);
  const theme = isDarkMode ? themeDark : themeLight;

  ConfigProvider.config({
    // @ts-ignore
    theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider>
        <GlobalStyle theme={theme} />
        {children}
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default ThemeProviderComponent;
