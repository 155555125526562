import React, { FC } from 'react';

import styled from 'styled-components';
import { Pagination, PaginationProps } from 'antd';

const PaginationComponent: FC<PaginationProps> = (props) => {
  return <StyledPagination {...props} />;
};

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.marginLg};
  flex-wrap: wrap;
`;

export default PaginationComponent;
