import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';

import L from 'leaflet';
import { Tooltip } from 'antd';

import { Button, InputNumber } from 'components/index';
import { useForm, Controller } from 'react-hook-form';

import { PlygonIcon } from 'assets/icons';
import { useTheme } from 'theme/useTheme';
import { notificationAlert } from 'utils/notificationAlert';
import { ICoverageData } from 'types/storeTypes';

interface ICoveragePolygonButton {
  toggle: any;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const CoveragePolygonButton: FC<ICoveragePolygonButton> = ({ toggle, tooltipPosition }) => {
  const { t } = useTranslation();
  const map = useMap();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const ref = useRef<HTMLElement>();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const _getCoveragePolygon = (data: any) => {
    if (isActive) {
      toggle(null);
      setIsActive(false);
    } else {
      setLoading(true);
      toggle(data.percentile)
        .then((res: ICoverageData) => {
          if (res && res?.polygon && res?.polygon.coordinates.length === 0) {
            notificationAlert('error', `${t('noResult')}`, `${t('refineIsNotCurrentlyAvailableAtThisLocation')}`);
          } else if (res && res?.errors) {
            notificationAlert(
              'error',
              `${t('noResult')}`,
              `${t('locationHasNotAllNecessaryParametersForGettingPolygon')}`,
            );
          } else {
            setIsActive(true);
          }
        })
        .finally(() => {
          setLoading(false);
          setTooltipVisible(false);
        });
    }
  };

  function on() {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) map.tap.enable();
  }
  function off() {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }

  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <Tooltip
      trigger="click"
      color="white"
      title={
        <Form onSubmit={handleSubmit(_getCoveragePolygon)} onMouseOver={off} onMouseLeave={on}>
          <Controller
            name="percentile"
            control={control}
            rules={{
              required: { value: true, message: `${t('required')}` },
              min: { value: 0, message: '0 - 100' },
              max: { value: 100, message: '0 - 100' },
            }}
            defaultValue={85}
            render={({ field }) => (
              <InputNumber
                wrapperStyle={{ display: 'flex', flexDirection: 'column' }}
                label={
                  errors?.percentile?.message && typeof errors.percentile.message === 'string'
                    ? errors.percentile.message
                    : t('refine')
                }
                style={{ width: 60 }}
                status={errors.percentile ? 'error' : undefined}
                size="small"
                onFocus={() => setTooltipVisible(true)}
                formatter={(val) => (val ? String(val).replace(/[^0-9]/g, '') : '')}
                parser={(val) => (val ? val.replace(/[^0-9]/g, '') : '')}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) && // Allow numbers
                    e.key !== 'Backspace' &&
                    e.key !== 'Delete' &&
                    e.key !== 'ArrowLeft' &&
                    e.key !== 'ArrowRight' &&
                    e.key !== 'Tab' &&
                    e.key !== 'Enter'
                  ) {
                    e.preventDefault();
                  }
                }}
                onStep={(value) => {
                  if (value >= 100) field.onChange(100); // Prevent going above 100
                  if (value <= 0) field.onChange(0); // Prevent going below 0
                }}
                {...field}
              />
            )}
          />
          <Button htmlType="submit" type="primary" disabled={loading} size="small">
            {t('ok')}
          </Button>
        </Form>
      }
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
      open={!isActive && tooltipVisible}
      onOpenChange={(visible) => !isActive && setTooltipVisible(visible)}
      style={{ zIndex: 2 }}
    >
      <Tooltip
        title={t('refine')}
        placement={tooltipPosition}
        mouseEnterDelay={1}
        getPopupContainer={() => ref.current?.parentElement || document.body}
        style={{ zIndex: 1 }}
      >
        <StyledButton
          ref={ref}
          shape="circle"
          icon={PlygonIcon}
          transparentFillSvg
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
            background: isActive ? theme.primaryColor : theme.backgroundWhiteColor,
          }}
          customIconColor={isActive ? 'backgroundWhiteColor' : undefined}
          $isActive={isActive}
          loading={loading}
          onClick={() => {
            if (isActive) {
              _getCoveragePolygon(null);
            } else {
              setTooltipVisible(true);
            }
          }}
        />
      </Tooltip>
    </Tooltip>
  );
};

const StyledButton = styled(Button)<{ $isActive: boolean }>`
  position: relative;
  background: ${({ theme, $isActive }) => ($isActive ? theme.primaryColor : theme.backgroundWhiteColor)};
`;

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.gapSm};
  width: 100%;
`;

export default CoveragePolygonButton;
