import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';
import styled, { css } from 'styled-components';
import { Spin } from 'antd';

import { getUser } from 'actions/user/auth';
import { getAgents } from 'actions/agents/smsNotifications';
import {
  setDarkMode,
  setIsMenuExpanded,
  setIsResultsMenuExpanded,
  setIsContactInfoExpanded,
} from 'actions/app/application';

import Routes from 'routes';
import NotificationAlert from 'utils/notification';
import { configSounds } from 'utils/notificationSound';

import { initSocket } from 'api/socketConfig';

import { useAppDispatch } from 'hooks/useDispatch';

const BasicAuthApp = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem('access_token')) {
      dispatch(getUser())
        .then((user) => {
          configSounds(user.sound_success, user.sound_failure);
          initSocket(user.id, null, t);
          dispatch(getAgents());
          setLoading(false);
          moment.tz.setDefault(user.timezone);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    dispatch(setDarkMode());
    dispatch(setIsMenuExpanded());
    dispatch(setIsResultsMenuExpanded());
    dispatch(setIsContactInfoExpanded());

    if (location.pathname === '/login') setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <NotificationAlert />
      {loading && (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      )}
      {!loading && <Routes location={location} />}
    </Wrapper>
  );
};

const SpinWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      background-color: rgba(0, 0, 0, 0.85) !important;
    `}
`;

const Wrapper = styled.div``;

export default BasicAuthApp;
