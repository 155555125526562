import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Text } from 'components';
import { textStyles } from '../text/text';

interface IPhoneInput {
  label?: string;
  inputStyle?: object;
  country: string;
  phoneValue: string;
  onChange: (value: string) => void;
  hasError?: boolean;
  isRequired?: boolean;
}

const PhoneInputComponent = forwardRef(
  ({ label, inputStyle, country, phoneValue, onChange, hasError, isRequired }: IPhoneInput) => {
    return (
      <InputWrapper>
        <LabelWrapper>{label && <InputLabel $isRequired={isRequired}>{label}</InputLabel>}</LabelWrapper>
        <StyledPhoneInput
          hasError={hasError}
          inputStyle={inputStyle}
          country={country}
          value={phoneValue}
          onChange={onChange}
        />
      </InputWrapper>
    );
  },
);
const InputWrapper = styled.div``;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gapSm};
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledPhoneInput = styled(PhoneInput)<{ hasError?: boolean }>`
  .form-control {
    color: ${({ theme }) => theme.textBlackColor} !important;
    border-color: ${({ theme, hasError }) => (hasError ? theme.errorColor : theme.lightGrayColor)} !important;
    height: 32px;
  }

  .flag-dropdown {
    background: ${({ theme }) =>
      theme.isDarkMode ? theme.backgroundLightGrayColor : theme.backgroundWhiteColor} !important;
    border-color: ${({ theme, hasError }) => (hasError ? theme.errorColor : theme.lightGrayColor)} !important;
  }

  .open,
  .selected-flag,
  &:hover {
    background: ${({ theme }) => (theme.isDarkMode ? theme.grayShade4Color : theme.mainShade7Color)} !important;
  }

  .country-list {
    background: ${({ theme }) =>
      theme.isDarkMode ? theme.backgroundLightGrayColor : theme.backgroundWhiteColor} !important;

    li {
      color: ${({ theme }) => theme.textBlackColor} !important;
    }

    .highlight {
      background-color: ${({ theme }) => (theme.isDarkMode ? theme.mainShade7Color : theme.mainShade7Color)} !important;
    }
  }
`;

const InputLabel = styled(Text)<{ $isRequired?: boolean }>`
  display: block;
  ${textStyles.bodyRegularSm}
  color: ${({ theme }) => theme.textGrayColor};

  ${({ $isRequired }) =>
    $isRequired &&
    css`
      &:before {
        display: inline-block;
        margin-right: ${({ theme }) => theme.marginSm};
        color: ${({ theme }) => theme.orangeColor};
        font-size: ${({ theme }) => theme.fontSizeBtn};
        line-height: 1;
        content: '*';
      }
    `}
`;

export default PhoneInputComponent;
