import Keycloak from 'keycloak-js';

// keycloak prod config
const keycloak = new Keycloak({
  url: 'https://keycloak.greyhawk.io:8443',
  realm: 'enzo',
  clientId: 'enzo-locate',
});

export default keycloak;
