import { playSound } from '../../utils/notificationAndSound';
import { FIRE_ALERT, LOGOUT } from 'constants/actionTypes';
import initialState from './initState';
import store from '../../index';

export default function alert(state = initialState, action = {}) {
  switch (action.type) {
    case FIRE_ALERT: {
      const user = store.getState().user;

      if (user.sound === 1) {
        playSound('error');
      }

      return {
        ...action.payload,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
