import React, { FC, useEffect, useState } from 'react';

import L from 'leaflet';
import styled, { css } from 'styled-components';

import ZoomControl from 'components/map/controls/zoom';
import FullScreen from 'components/map/controls/fullScreen';
import RulerComponent from 'components/map/controls/ruler/rulerComponent';
import PlotRouteButton from 'components/map/controls/plotRoute/plotRouteButton';
import PlotButton from 'components/map/controls/plots';
import HeatPointsButton from 'components/map/controls/heatPoints';
import HighPrecisionButton from 'components/map/controls/highPrecision/highPrecisionButton';
import CoveragePolygonButton from 'components/map/controls/coveragePolygon/coveragePolygonButton';
import CellRangeButton from 'components/map/controls/cellRangeButton';

import {
  ControlsConfigType,
  HeatMapResponse,
  ICustomLeafletMap,
  PlotResponse,
  SingleViewConfigType,
} from 'components/map/map';
import { ILocation } from 'types/storeTypes';
import { useAppSelector } from 'hooks/useSelector';

interface IMapControls {
  map: ICustomLeafletMap;
  locations?: ILocation[] | null;
  singleViewConfig?: SingleViewConfigType;
  togglePlotRootView?: () => void;
  toggleHighPrecisionButtonView?: () => void;
  toggleCoveragePolygonView?: (percentile: number) => void;
  getPlotMarkers?: () => Promise<PlotResponse>;
  getHeatMap?: () => Promise<HeatMapResponse>;
  isPlotRouteActive?: boolean;
  isHighPrecisionButtonActive?: boolean;
  isSingleView?: boolean;
  controlsConfig?: ControlsConfigType;
  hasPlotButton?: boolean;
  hasHeatMapButton?: boolean;
  hasHighPrecisionButton?: boolean;
  hasCoveragePolygonButton?: boolean;
  hasCellRangeButton?: boolean;
  isPatternOfLifeTabActive?: boolean;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  onToggleCellRange?: (state: boolean) => void;
}

const MapControls: FC<IMapControls> = ({
  map,
  togglePlotRootView,
  toggleHighPrecisionButtonView,
  toggleCoveragePolygonView,
  getPlotMarkers,
  getHeatMap,
  isPlotRouteActive,
  isHighPrecisionButtonActive,
  isSingleView,
  locations,
  singleViewConfig,
  controlsConfig,
  hasPlotButton,
  hasHeatMapButton,
  hasHighPrecisionButton,
  hasCoveragePolygonButton,
  hasCellRangeButton,
  isPatternOfLifeTabActive,
  tooltipPosition,
  onToggleCellRange,
}) => {
  const isMenuExpanded = useAppSelector((store) => store.app.isMenuExpanded);
  const isResultsMenuExpanded = useAppSelector((store) => store.app.isResultsMenuExpanded);

  const [isHeatMapActive, setIsHeatMapActive] = useState(false);

  // Add scale item
  useEffect(() => {
    L.control.scale({ metric: true, imperial: false }).setPosition('bottomright').addTo(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ControlsWrapper
      $placement={controlsConfig?.placement || 'left'}
      $isResultsMenuExpanded={isResultsMenuExpanded}
      $isMenuExpanded={isMenuExpanded}
      $isHeatMapActive={isHeatMapActive}
    >
      <ZoomControl map={map} isResultsMenuExpanded={isResultsMenuExpanded} isMenuExpanded={isMenuExpanded} />
      <FullScreen map={map} tooltipPosition={tooltipPosition} />
      <RulerComponent map={map} tooltipPosition={tooltipPosition} />
      {locations && togglePlotRootView && !isSingleView && locations?.length > 1 ? (
        <PlotRouteButton
          toggle={togglePlotRootView}
          isActive={isPlotRouteActive || false}
          tooltipPosition={tooltipPosition}
        />
      ) : null}
      {hasPlotButton && ((!isPatternOfLifeTabActive && isSingleView) || locations?.length === 1) ? (
        <PlotButton getPlotMarkers={getPlotMarkers} tooltipPosition={tooltipPosition} />
      ) : null}
      {hasHeatMapButton && ((!isPatternOfLifeTabActive && isSingleView) || locations?.length === 1) ? (
        <HeatPointsButton
          getHeatMap={getHeatMap}
          setIsHeatMapActive={setIsHeatMapActive}
          isHeatMapActive={isHeatMapActive}
          tooltipPosition={tooltipPosition}
        />
      ) : null}
      {hasHighPrecisionButton && ((!isPatternOfLifeTabActive && isSingleView) || locations?.length === 1) ? (
        <HighPrecisionButton
          toggle={toggleHighPrecisionButtonView}
          isActive={isHighPrecisionButtonActive || false}
          tooltipPosition={tooltipPosition}
          highPrecisionUsedAt={singleViewConfig?.location.hp_used_at}
        />
      ) : null}
      {hasCoveragePolygonButton && ((!isPatternOfLifeTabActive && isSingleView) || locations?.length === 1) ? (
        <CoveragePolygonButton toggle={toggleCoveragePolygonView} tooltipPosition={tooltipPosition} />
      ) : null}
      {hasCellRangeButton && !isSingleView && locations && locations?.length > 1 && (
        <CellRangeButton onToggleCellRange={onToggleCellRange} tooltipPosition={tooltipPosition} />
      )}
    </ControlsWrapper>
  );
};

const ControlsWrapper = styled.div<{
  $placement: 'left' | 'right';
  $isResultsMenuExpanded: boolean;
  $isMenuExpanded: boolean;
  $isHeatMapActive: boolean;
}>`
  position: absolute;

  height: 100%;
  z-index: 1001;

  ${({ $placement }) =>
    $placement === 'left'
      ? css`
          left: ${({ theme }) => theme.paddingBg};
        `
      : css`
          right: ${({ theme }) => theme.paddingBg};
        `}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.gapSm};

  @media only screen and (max-width: 1800px) {
    ${({ $isResultsMenuExpanded, $isMenuExpanded }) =>
      $isResultsMenuExpanded &&
      $isMenuExpanded &&
      css`
        top: 28px;
        height: 32px;
        flex-direction: row;
        width: 100%;
        transition: top 0.3s ease-in-out;
      `}

    ${({ $isHeatMapActive }) =>
      $isHeatMapActive &&
      css`
        top: 50px;
        transition: top 0.3s ease-in-out;
      `}
  }

  @media only screen and (max-width: 1600px) {
    ${({ $isResultsMenuExpanded }) =>
      $isResultsMenuExpanded &&
      css`
        top: 28px;
        height: 32px;
        flex-direction: row;
        width: 100%;
        transition: top 0.3s ease-in-out;
      `}

    ${({ $isHeatMapActive }) =>
      $isHeatMapActive &&
      css`
        top: 50px;
        transition: top 0.3s ease-in-out;
      `}
  }

  @media only screen and (max-width: 1350px) {
    ${({ $isMenuExpanded }) =>
      $isMenuExpanded &&
      css`
        top: 28px;
        height: 32px;
        flex-direction: row;
        width: 100%;
        transition: top 0.3s ease-in-out;
      `}

    ${({ $isHeatMapActive }) =>
      $isHeatMapActive &&
      css`
        top: 50px;
        transition: top 0.3s ease-in-out;
      `}
  }

  @media only screen and (max-width: 1180px) {
    top: 28px;
    height: 32px;
    flex-direction: row;
    width: 100%;
    transition: top 0.3s ease-in-out;

    ${({ $isHeatMapActive }) =>
      $isHeatMapActive &&
      css`
        top: 50px;
        transition: top 0.3s ease-in-out;
      `}
  }
`;

export default MapControls;
