import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import L, { PointExpression } from 'leaflet';
import { Popup, Polygon } from 'react-leaflet';

import { ICustomLeafletMap, OFFSET_PADDING } from 'components/map/map';
import { Text } from 'components';
import Marker from 'components/map/marker';

// @ts-ignore
import markerOrangePolygon from 'assets/markers/marker-orange-polygon.png';
import { ICoverageData, ILocation } from 'types/storeTypes';

const myIcon = L.icon({
  iconUrl: markerOrangePolygon,
  iconSize: [60, 60],
  iconAnchor: [30, 60],
  popupAnchor: [0, -60],
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
});

const CoveragePolygonMarker: FC<{
  coverageData?: ICoverageData;
  map: ICustomLeafletMap;
  customWithOffset?: { paddingTopLeft: PointExpression; paddingBottomRight: PointExpression };
  withOffset?: boolean;
  singleViewConfig?: { location: ILocation };
}> = ({ coverageData, map, customWithOffset, withOffset, singleViewConfig }) => {
  const { t } = useTranslation();

  const [polygonPosition, setPolygonPosition] = useState<L.LatLngExpression[]>();

  useEffect(() => {
    setTimeout(() => {
      const previousLat = singleViewConfig?.location.lat;
      const previousLng = singleViewConfig?.location.lng;

      if (
        coverageData &&
        coverageData?.polygon &&
        coverageData?.polygon.coordinates.length &&
        previousLat &&
        previousLng
      ) {
        const reversedArray = coverageData.polygon.coordinates.map((el) => [...el].reverse());
        const polygon = L.polygon(reversedArray as L.LatLngExpression[]);

        setPolygonPosition(reversedArray as L.LatLngExpression[]);
        // Get the bounds of the polygon
        const polygonBounds = polygon.getBounds();

        // Define the previous location
        const previousLocation = L.latLng([previousLat, previousLng]);

        // Create bounds that include both the polygon and the previous location
        const combinedBounds = polygonBounds.extend(previousLocation);

        // Fit the map to the combined bounds
        map.fitBounds(combinedBounds, customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined);
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageData]);

  return coverageData && coverageData?.point && coverageData?.point.coordinates.length ? (
    <>
      <Marker position={[coverageData.point.coordinates[1], coverageData.point.coordinates[0]]} icon={myIcon}>
        <StyledPopup>
          <Row>
            <Text variant="bodyRegularSm" color="textGrayColor">
              {t('name')}:
            </Text>
            <Text variant="bodyRegularSm">
              {coverageData.point.properties ? coverageData.point.properties.name : '-'}
            </Text>
          </Row>
          <Row>
            <Text variant="bodyRegularSm" color="textGrayColor">
              {t('lat')}:
            </Text>
            <Text variant="bodyRegularSm">{coverageData.point.coordinates[1]}</Text>
          </Row>
          <Row>
            <Text variant="bodyRegularSm" color="textGrayColor">
              {t('lng')}:
            </Text>
            <Text variant="bodyRegularSm">{coverageData.point.coordinates[0]}</Text>
          </Row>
        </StyledPopup>
      </Marker>

      {polygonPosition && <Polygon positions={polygonPosition} color={'#ff6d1f'} weight={1} />}
    </>
  ) : null;
};

const StyledPopup = styled(Popup)``;

const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gapSm};
`;

export default CoveragePolygonMarker;
