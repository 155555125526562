import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import Routes from 'constants/routesConfig';

import {
  adminRoute as AdminRoute,
  leaderTeamMembersRoute as LeaderTeamMembersRoute,
  loginRoute as LoginRoute,
  surveyRoute as SurveyRoute,
  reportRoute as ReportRoute,
  userRoute as UserRoute,
} from 'components';

import MainLayout from 'layout/mainLayout';

const LazyLoginPage = React.lazy(() => import('./pages/LoginPage/loginPage'));
const LazyDashboardPage = React.lazy(() => import('./pages/DashboardPage/dashboardPage'));
const LazyContactsPage = React.lazy(() => import('./pages/ContactsPage/contactsPage'));
const LazyContactsGroupsPage = React.lazy(() => import('./pages/ContactsGroupsPage/contactsGroupsPage'));
const LazyContactsGroupPage = React.lazy(() => import('./pages/ContactsGroupPage/contactsGroupPage'));
const LazyProfilePage = React.lazy(() => import('./pages/ProfilePage/profilePage'));
const LazyCellDataPage = React.lazy(() => import('./pages/CellDataPage'));
const LazyUserSettingsPage = React.lazy(() => import('./pages/UserSettingsPage/userSettingsPage'));
const LazyContactPage = React.lazy(() => import('./pages/ContactPage/contactPage'));
const LazyRobotsPage = React.lazy(() => import('./pages/RobotsPage/robotsPage'));
const LazyLicensePage = React.lazy(() => import('./pages/LicensePage'));
const LazyReportPage = React.lazy(() => import('./pages/ReportPage/reportPage'));
const LazySurveyPage = React.lazy(() => import('./pages/SurveyPage/surveyPage'));
const LazyAuditPage = React.lazy(() => import('./pages/AuditPage/AuditPage'));
const LazyLeaderTeamMembersPage = React.lazy(() => import('./pages/LeaderTeamMembersPage/LeaderTeamMembersPage'));
const LazyNotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

const Router = ({ location }) => (
  <MainLayout>
    <Switch>
      <LoginRoute location={location} exact path={Routes.login} component={LazyLoginPage} />
      <UserRoute location={location} exact path={Routes.dashboard} component={LazyDashboardPage} />
      <UserRoute location={location} exact path={Routes.contacts} component={LazyContactsPage} />
      <UserRoute location={location} exact path={Routes.groups} component={LazyContactsGroupsPage} />
      <UserRoute location={location} exact path={Routes.groupView} component={LazyContactsGroupPage} />
      <UserRoute location={location} exact path={Routes.profile} component={LazyProfilePage} />
      <AdminRoute location={location} exact path={Routes.cellDataImport} component={LazyCellDataPage} />
      <AdminRoute location={location} exact path={Routes.settings} component={LazyUserSettingsPage} />
      <UserRoute location={location} exact path={Routes.contactView} component={LazyContactPage} />
      <UserRoute location={location} exact path={[Routes.robots, Routes.robotsSelected]} component={LazyRobotsPage} />
      <UserRoute location={location} exact path={Routes.license} component={LazyLicensePage} />
      <AdminRoute location={location} exact path={Routes.audit} component={LazyAuditPage} />
      <LeaderTeamMembersRoute location={location} exact path={Routes.team} component={LazyLeaderTeamMembersPage} />
      <ReportRoute location={location} exact path={Routes.report} component={LazyReportPage} />
      <SurveyRoute location={location} exact path={Routes.survey} component={LazySurveyPage} />
      <UserRoute location={location} exact component={LazyNotFoundPage} />
    </Switch>
  </MainLayout>
);

Router.propTypes = {
  location: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default Router;
