import React, { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import L from 'leaflet';

import { Tooltip } from 'antd';

import { Button } from 'components';
import { ICustomLeafletMap } from 'components/map/map';
import { ZoomInIcon, ZoomOutIcon } from 'assets/icons';

interface IFullScreen {
  map: ICustomLeafletMap;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const FullScreen: FC<IFullScreen> = ({ map, tooltipPosition }) => {
  const { t } = useTranslation();

  const [fullScreenState, setFullScreenState] = useState(map?.isFullscreen ? map?.isFullscreen() : false);

  const ref = useRef<HTMLElement>();
  const toggleFullScreen = () => {
    map?.toggleFullscreen && map?.toggleFullscreen();
    setFullScreenState((prevState) => !prevState);
  };

  useEffect(() => {
    map?.on('fullscreenchange', function () {
      if (map?.isFullscreen && map?.isFullscreen()) {
        return;
      } else {
        setFullScreenState(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Stop propagation
  useEffect(() => {
    L.DomEvent.disableClickPropagation(ref.current as HTMLElement);
  }, []);

  return (
    <Tooltip
      title={fullScreenState ? t('minimiseMap') : t('viewFullScreen')}
      placement={tooltipPosition}
      mouseEnterDelay={1}
      getPopupContainer={() => ref.current?.parentElement || document.body}
    >
      <Button
        ref={ref}
        shape="circle"
        icon={fullScreenState ? ZoomOutIcon : ZoomInIcon}
        onClick={toggleFullScreen}
        transparentFillSvg
        // @ts-ignore
        style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px' }}
      />
    </Tooltip>
  );
};

export default FullScreen;
