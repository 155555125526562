import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { notification, Spin } from 'antd';

import L, { Map as ILeafletMap, PointExpression } from 'leaflet';
import { useMap, MapContainer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// Addons
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

// Components
import TileLayerComponent from 'components/map/tileLayerComponent';
import MapControls from 'components/map/controls/mapControls';
import LocationsView from 'components/map/locationsView';

import IStore, { ICoverageData, ILocation } from 'types/storeTypes';
import PlotRoute from 'components/map/controls/plotRoute/plotRoute';
import { useAppDispatch } from 'hooks/useDispatch';
import { useAppSelector } from 'hooks/useSelector';
import { getHeatPoints, getPlots } from 'actions/contacts/contact';
import { getCoveragePolygon, setLocationHighPrecisionUsed } from 'actions/jobs/jobs';
import { notificationAlert } from 'utils/notificationAlert';

export interface ICustomLeafletMap extends ILeafletMap {
  toggleFullscreen?: () => void;
  isFullscreen?: () => boolean;
}

export type SingleViewConfigType = {
  location: ILocation | IStore['patternOfLife']['viewList'] | any;
  zoom?: number;
};

export type ControlsConfigType = {
  placement: 'left' | 'right';
};

export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

interface IMap {
  singleViewConfig?: SingleViewConfigType;
  locations?: ILocation[] | null;
  loading?: boolean;
  zoom?: number;
  withOffset?: boolean;
  controlsConfig?: ControlsConfigType;
  customWithOffset?: {
    paddingTopLeft: PointExpression;
    paddingBottomRight: PointExpression;
  };
  isContactPage?: boolean;
  centerAndZoomLocations?: boolean;
  hasPlotButton?: boolean;
  hasHeatMapButton?: boolean;
  hasHighPrecisionButton?: boolean;
  hasCoveragePolygonButton?: boolean;
  hasCellRangeButton?: boolean;
  isPatternOfLifeTabActive?: boolean;
  printImageComponent?: any;
  tooltipPosition?: TooltipPosition;
  zoomAnimation?: boolean;
  contactLabelColorMapping?: Record<string, string>;
  hasMultipleColoredClusterIcon?: boolean;
}

interface IMapComponent extends IMap {}

export type PlotResponse = {
  error: string | null;
  response?: {
    data: {
      found: boolean;
      id: string;
      object: {
        accuracy: number;
        lacf: boolean;
        location: ILocation;
      }[];
    };
    meta: null;
  };
};

export type HeatMapResponse = {
  Data: {
    lat: string;
    lng: string;
    signal: string;
  }[];
  Message: string;
};

export const OFFSET_PADDING: { paddingTopLeft: PointExpression; paddingBottomRight: PointExpression } = {
  paddingTopLeft: [0, 200],
  paddingBottomRight: [0, 300],
};

// fit location based on marker or marker and circle
export const fitLocation = (
  position: [number, number],
  range: number | null | undefined,
  map: ICustomLeafletMap,
  withOffset: boolean,
  customWithOffset: { paddingTopLeft: PointExpression; paddingBottomRight: PointExpression } | undefined,
) => {
  const locationsGroup: [number, number][] = [];

  if (range) {
    const circle = L.circle([position[0], position[1]], range);

    // Adding then removing the circle from the map due to some bug
    circle.addTo(map);
    map
      .panTo(new L.LatLng(position[0], position[1]))
      .fitBounds(circle.getBounds(), customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined);
    circle.removeFrom(map);
  } else {
    locationsGroup.push([position[0], position[1]]);
    map.fitBounds(locationsGroup, customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined);
  }
};

const MapComponent: FC<IMapComponent> = ({
  locations,
  loading = false,
  singleViewConfig,
  withOffset = false,
  customWithOffset,
  controlsConfig = { placement: 'left' },
  isContactPage = false,
  centerAndZoomLocations,
  hasPlotButton,
  hasHeatMapButton,
  hasHighPrecisionButton,
  hasCoveragePolygonButton,
  hasCellRangeButton,
  isPatternOfLifeTabActive,
  tooltipPosition = 'right',
  contactLabelColorMapping,
  hasMultipleColoredClusterIcon,
}) => {
  const { t } = useTranslation();

  const isMenuExpanded = useAppSelector((store) => store.app.isMenuExpanded);
  const isResultsMenuExpanded = useAppSelector((store) => store.app.isResultsMenuExpanded);

  const map: ICustomLeafletMap = useMap();
  const dispatch = useAppDispatch();

  const [isPlotRouteActive, setIsPlotRouteActive] = useState(false);
  const [isHighPrecisionButtonActive, setIsHighPrecisionButtonActive] = useState(false);

  const [plotMarkers, setPlotMarkers] = useState<PlotResponse>();
  const [coveragePolygonMarkers, setCoveragePolygonMarkers] = useState<ICoverageData>();
  const [highPrecisionMarker, setHighPrecisionMarker] =
    useState<{ lat: string; long: string; range: number | null }[]>();

  const [heatMapPoints, setHeatMapPoints] = useState<HeatMapResponse>();
  const [isCellRangeVisible, setIsCellRangeVisible] = useState(false);

  const togglePlotRootView = () => setIsPlotRouteActive((prevState) => !prevState);

  useEffect(() => {
    // This function will work for DASHBOARD PAGE ONLY
    const url = window.location.href;
    const page = url.split('/')[3];
    const isDashboardPage = page.length === 0;

    if (!isDashboardPage) return;

    const mapContainer = map.getContainer();

    // Disable scroll zoom immediately when the dropdown opens
    map.scrollWheelZoom.disable();

    // DESKTOP: Enable zoom on mouse press + scroll
    const enableScrollZoom = () => map.scrollWheelZoom.enable();
    const disableScrollZoom = () => map.scrollWheelZoom.disable();

    mapContainer.addEventListener('mousedown', enableScrollZoom);
    mapContainer.addEventListener('mouseup', disableScrollZoom);
    mapContainer.addEventListener('mouseleave', disableScrollZoom);

    // MOBILE: Prevent One-Finger Dragging, Allow Two-Finger Gestures
    const handleTouchStart = (event: TouchEvent) => {
      if (event.touches.length === 1) {
        map.dragging.disable(); // Disable drag when using one finger
      } else if (event.touches.length === 2) {
        map.dragging.enable(); // Enable drag when using two fingers
      }
    };

    const handleTouchEnd = () => map.dragging.disable();

    mapContainer.addEventListener('touchstart', handleTouchStart);
    mapContainer.addEventListener('touchend', handleTouchEnd);
    mapContainer.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      mapContainer.removeEventListener('mousedown', enableScrollZoom);
      mapContainer.removeEventListener('mouseup', disableScrollZoom);
      mapContainer.removeEventListener('mouseleave', disableScrollZoom);
      mapContainer.removeEventListener('touchstart', handleTouchStart);
      mapContainer.removeEventListener('touchend', handleTouchEnd);
      mapContainer.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize(true);
    }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuExpanded, isResultsMenuExpanded]);

  const toggleHighPrecisionButtonView = () => {
    setIsHighPrecisionButtonActive((prevState) => !prevState);
    if (!singleViewConfig?.location.hp_used_at) dispatch(setLocationHighPrecisionUsed(singleViewConfig?.location.id));

    if (isHighPrecisionButtonActive) {
      setHighPrecisionMarker(undefined);
    } else {
      setHighPrecisionMarker([
        {
          lat: singleViewConfig?.location.lat,
          long: singleViewConfig?.location.long,
          range: singleViewConfig?.location.range,
        },
        {
          lat: singleViewConfig?.location.hp_lat,
          long: singleViewConfig?.location.hp_long,
          range: singleViewConfig?.location.hp_range,
        },
      ]);
    }
  };

  const toggleCoveragePolygonView = (percentileValue: number) => {
    const percentile = `?percentile=${percentileValue}`;
    if ((singleViewConfig || locations?.length === 1) && !coveragePolygonMarkers?.polygon.coordinates.length) {
      return dispatch(getCoveragePolygon(singleViewConfig?.location.id, percentile, t)).then((res: ICoverageData) => {
        if (res?.polygon?.coordinates) {
          setCoveragePolygonMarkers(res);
        } else {
          setCoveragePolygonMarkers(undefined);
        }
        return res;
      });
    }

    setCoveragePolygonMarkers(undefined);
  };

  const getPlotMarkers = (): Promise<PlotResponse> => {
    if ((singleViewConfig || locations?.length === 1) && !plotMarkers) {
      const location = singleViewConfig?.location || (locations as ILocation[])[0];
      const { mcc, mnc, lac, id } = location;
      return new Promise<PlotResponse>((resolve, reject) =>
        dispatch(getPlots(mcc, mnc, lac, id))
          .then((res: PlotResponse) => {
            if (res.response?.data) setPlotMarkers(res);
            if (res.error) {
              notification.error({
                message: `${t('plotFailed')}!`,
                description: res.error,
              });
            }
            return resolve(res);
          })
          .catch((err: Error) => reject(err)),
      );
    }

    setPlotMarkers(undefined);
    return Promise.reject();
  };

  const getHeatMap = () => {
    if (
      (singleViewConfig || locations?.length === 1) &&
      (!heatMapPoints || (heatMapPoints && Object.keys(heatMapPoints.Data).length === 0))
    ) {
      const location = singleViewConfig?.location || (locations as ILocation[])[0];
      const { cell_id, mcc, mnc, lac } = location;
      if (cell_id && mcc && mnc && lac) {
        return new Promise<HeatMapResponse>((resolve, reject) =>
          dispatch(getHeatPoints(`${cell_id}/${mcc}/${mnc}/${lac}`, t))
            .then((res: HeatMapResponse) => {
              setHeatMapPoints(res);
              return resolve(res);
            })
            .catch((err: Error) => {
              return reject(err);
            }),
        );
      } else {
        notificationAlert('error', `${t('noResult')}`, `${t('locationHasNotAllNecessaryParametersForGettingHeatMap')}`);
      }
    } else if (singleViewConfig && singleViewConfig.location.lat && singleViewConfig.location.long) {
      const { lat, long, range } = singleViewConfig.location;
      fitLocation([lat, long], range, map, withOffset, customWithOffset);
    }

    setHeatMapPoints(undefined);
    return Promise.reject();
  };

  const onToggleCellRange = (status: boolean) => {
    setIsCellRangeVisible(status);
  };

  // logic for showing the locations with right zoom (fit bounds)
  useEffect(() => {
    // checking if its position that is coming from pin action
    if (singleViewConfig && singleViewConfig.location.lat && singleViewConfig.location.long) {
      const { lat, long, range } = singleViewConfig.location;
      fitLocation([lat, long], range, map, withOffset, customWithOffset);
    } else if (locations) {
      const arrayLocations = Object.values(locations);
      // if there is only on location from location cluster we trigger its behavior as single location
      if (arrayLocations.length === 1) {
        const { lat, long, range } = arrayLocations[0];
        // again checking if its position that is coming from pin action
        if (singleViewConfig && singleViewConfig.location.lat && singleViewConfig.location.long) {
          if (lat && long) fitLocation([lat, long], range, map, withOffset, customWithOffset);
        } else {
          arrayLocations.forEach(({ lat, long }) => {
            if (lat && long) {
              fitLocation([lat, long], range, map, withOffset, customWithOffset);
            }
          });
        }
      } else {
        const locationsGroup: [number, number, number?][] = [];

        arrayLocations.forEach(({ lat, long, range }) => {
          if (lat && long) {
            locationsGroup.push(range !== null ? [lat, long, range] : [lat, long]);
          }
        });

        if (locationsGroup.length) {
          const [firstLat, firstLong] = locationsGroup[0];
          let combinedBounds = L.latLngBounds([L.latLng(firstLat, firstLong)]);

          locationsGroup.forEach(([lat, long, range]) => {
            const latLng = L.latLng(lat, long);
            const extendedBounds = range ? latLng.toBounds(range * 2) : latLng.toBounds(0);

            combinedBounds.extend(extendedBounds);
          });

          const offset = customWithOffset ? customWithOffset : withOffset ? OFFSET_PADDING : undefined;

          // Calculate bounds with `fitBounds` but respect maxZoom and minZoom constraints
          map.fitBounds(combinedBounds, {
            maxZoom: map.getMaxZoom(),
            ...offset,
          });

          // Check if the zoom level is too low and adjust it
          const currentZoom = map.getZoom();
          if (currentZoom < map.getMinZoom()) {
            map.setZoom(map.getMinZoom());
          }
        } else {
          // Fallback for when no locations exist
          map.setZoom(3);
        }
      }
    }

    // Set plotView to false if it is active and single view kicks in
    if (singleViewConfig && isPlotRouteActive) setIsPlotRouteActive(false);

    // Set isCellRangeVisible to false if it is active and single view kicks in
    if (singleViewConfig && isCellRangeVisible) setIsCellRangeVisible(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locations,
    singleViewConfig,
    isPlotRouteActive,
    centerAndZoomLocations,
    isHighPrecisionButtonActive,
    coveragePolygonMarkers,
  ]);

  return (
    <>
      {loading && (
        <BlurLayer>
          <Spin />
        </BlurLayer>
      )}

      <TileLayerComponent />

      <MapControls
        map={map}
        locations={locations}
        togglePlotRootView={togglePlotRootView}
        toggleHighPrecisionButtonView={toggleHighPrecisionButtonView}
        toggleCoveragePolygonView={toggleCoveragePolygonView}
        isPlotRouteActive={isPlotRouteActive}
        isHighPrecisionButtonActive={isHighPrecisionButtonActive}
        isSingleView={!!singleViewConfig}
        getPlotMarkers={getPlotMarkers}
        getHeatMap={getHeatMap}
        controlsConfig={controlsConfig}
        hasPlotButton={hasPlotButton}
        hasHeatMapButton={hasHeatMapButton}
        hasHighPrecisionButton={hasHighPrecisionButton}
        hasCoveragePolygonButton={hasCoveragePolygonButton}
        hasCellRangeButton={hasCellRangeButton}
        isPatternOfLifeTabActive={isPatternOfLifeTabActive}
        tooltipPosition={tooltipPosition}
        onToggleCellRange={onToggleCellRange}
        singleViewConfig={singleViewConfig}
      />

      {!isPlotRouteActive && (
        <LocationsView
          map={map}
          locations={locations}
          singleViewConfig={singleViewConfig}
          plotMarkers={plotMarkers}
          highPrecisionMarker={highPrecisionMarker}
          coverageData={coveragePolygonMarkers}
          heatMapPoints={heatMapPoints}
          customWithOffset={customWithOffset}
          withOffset={withOffset}
          isContactPage={isContactPage}
          isPatternOfLifeTabActive={isPatternOfLifeTabActive}
          contactLabelColorMapping={contactLabelColorMapping}
          hasMultipleColoredClusterIcon={hasMultipleColoredClusterIcon}
          isCellRangeVisible={isCellRangeVisible}
        />
      )}

      {isPlotRouteActive ? <PlotRoute locations={locations} map={map} /> : null}
    </>
  );
};

const Map: FC<IMap> = (props) => {
  const southWest = L.latLng(-89.98155760646617, -180);
  const northEast = L.latLng(89.99346179538875, 180);
  const mapBounds = L.latLngBounds(southWest, northEast);

  return (
    <StyledMapContainer
      center={[51.505, -0.09]}
      zoom={props.zoom ?? 3}
      minZoom={3}
      scrollWheelZoom={true}
      fullscreenControl={false}
      zoomControl={false}
      tap={false}
      attributionControl={false}
      maxBoundsViscosity={1}
      maxBounds={mapBounds}
      zoomAnimation={props.zoomAnimation}
    >
      <MapComponent {...props} />
      {props.printImageComponent && props.printImageComponent}
    </StyledMapContainer>
  );
};

const StyledMapContainer = styled(MapContainer)<{ fullscreenControl: boolean }>`
  height: 100%;
  width: 100%;

  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      .leaflet-layer {
        filter: invert(95%) hue-rotate(390deg) brightness(100%) contrast(90%);
      }
    `}
`;

const BlurLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  backdrop-filter: blur(10px);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default memo(Map);
