import React from 'react';

import styled from 'styled-components';

import { Collapse, CollapsePanelProps } from 'antd';

const { Panel } = Collapse;

const PanelComponent = (props: CollapsePanelProps) => <StyledPanel {...props} />;

const StyledPanel = styled(Panel)``;

export default PanelComponent;
