import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useKeycloak } from '@react-keycloak/web';

import axios from 'axios';

import moment from 'moment-timezone';
import styled, { css } from 'styled-components';
import { Spin } from 'antd';

import { getUser, keycloakLogin } from 'actions/user/auth';
import { getAgents } from 'actions/agents/smsNotifications';
import {
  setDarkMode,
  setIsMenuExpanded,
  setIsResultsMenuExpanded,
  setIsContactInfoExpanded,
} from 'actions/app/application';

import Routes from 'routes';
import NotificationAlert from 'utils/notification';
import { configSounds } from 'utils/notificationSound';

import { initSocket } from 'api/socketConfig';

import { useAppDispatch } from 'hooks/useDispatch';

const setAxiosUrl = (url) => (axios.defaults.baseURL = `${url}/api`);

const KeycloakAuthApp = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  const token = keycloak.token;

  useEffect(() => {
    if (sessionStorage.getItem('access_token')) {
      const url = sessionStorage.getItem('url');

      if (!url) {
        if (initialized && isLoggedIn && token) {
          setLoading(true);
          const url = JSON.parse(atob(token.split('.')[1]))?.be_url || '';
          sessionStorage.setItem('url', url);
          setAxiosUrl(url);
          axios.defaults.baseURL = `${url}/api`;

          dispatch(keycloakLogin(token, keycloak, t))
            .then((res) => {
              dispatch(getAgents());
              initSocket(res.payload.id, url, t);
              setLoading(false);
              history.push('/');
              moment.tz.setDefault(res.payload.timezone);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        axios.defaults.baseURL = `${url}/api`;
        dispatch(getUser())
          .then((user) => {
            configSounds(user.sound_success, user.sound_failure);
            initSocket(user.id, url, t);
            dispatch(getAgents());
            moment.tz.setDefault(user.timezone);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }

    dispatch(setDarkMode());
    dispatch(setIsMenuExpanded());
    dispatch(setIsResultsMenuExpanded());
    dispatch(setIsContactInfoExpanded());

    if (location.pathname === '/login') setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, isLoggedIn, token]);

  return (
    <Wrapper>
      <NotificationAlert />
      {loading && (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      )}
      {!loading && <Routes location={location} />}
    </Wrapper>
  );
};

const SpinWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      background-color: rgba(0, 0, 0, 0.85) !important;
    `}
`;

const Wrapper = styled.div``;

export default KeycloakAuthApp;
