import React, { CSSProperties, ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { Select as SelectAntd, Typography, SelectProps, Tooltip, Spin } from 'antd';

import { QuestionMarkIcon, DirectionDownIcon } from 'assets/icons';

import { Icon } from 'components';

import { textStyles } from 'components/text/text';

const { Text } = Typography;

interface ISelect extends SelectProps {
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  wrapperStyle?: CSSProperties;
  loading?: boolean;
}

const Select = forwardRef(
  ({ label, tooltipText, isRequired, wrapperStyle, disabled, loading, ...res }: ISelect, ref: ForwardedRef<any>) => {
    const { t } = useTranslation();

    return (
      <SelectWrapper className="custom-select-input-wrapper" style={wrapperStyle} isDisabled={disabled}>
        {label || tooltipText ? (
          <LabelWrapper>
            {label && <SelectLabel $isRequired={isRequired}>{label}</SelectLabel>}
            {tooltipText && (
              <Tooltip placement="right" title={tooltipText}>
                <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
              </Tooltip>
            )}
          </LabelWrapper>
        ) : null}
        {/* @ts-ignore */}
        <StyledSelect
          suffixIcon={loading ? <Spin size="small" /> : <DirectionDownIcon />}
          loading
          ref={ref}
          disabled={disabled}
          getPopupContainer={(trigger) => trigger.parentNode}
          {...res}
          notFoundContent={t('noData')}
        />
      </SelectWrapper>
    );
  },
);

const SelectWrapper = styled.div<{ isDisabled?: boolean }>`
  width: fit-content;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
    `}

  &:hover {
    .ant-typography {
      color: ${({ theme, isDisabled }) => !isDisabled && theme.textBlackColor};
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const SelectLabel = styled(Text)<{ $isRequired?: boolean }>`
  display: block;
  ${textStyles.bodyRegularSm}
  color: ${({ theme }) => theme.textGrayColor};

  ${({ $isRequired }) =>
    $isRequired &&
    css`
      &:before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    `}
`;

const StyledSelect = styled(SelectAntd)`
  .ant-select-selector {
    border-radius: 5px !important;
    background: ${({ theme }) => theme.backgroundWhiteColor} !important;
    border-color: ${({ theme }) => theme.lightGrayColor} !important;
  }

  &.ant-select-status-error {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.orangeColor} !important;
    }
  }

  .ant-select-selection-search-input {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        color: ${theme.grayShade1Color} !important;
      `}
  }

  &:hover {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }

    &.ant-select-status-error {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.orangeColor} !important;
      }
    }
  }

  .ant-select-selection-placeholder {
    ${({ theme }) =>
      theme.isDarkMode &&
      css`
        color: ${theme.grayShade7Color};
      `}
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.textBlackColor};
  }

  .ant-select-selection-item {
    color: ${({ theme }) => theme.textBlackColor} !important;
  }
`;

export default Select;
