import {
  GET_GLOBAL_NOTIFICATIONS,
  GET_LOCAL_NOTIFICATIONS,
  UPDATE_GLOBAL_NOTIFICATIONS,
  UPDATE_LOCAL_NOTIFICATIONS,
} from 'constants/actionTypes';
import api from 'api/appApi';

export const getGlobalNotifications = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.smsNotifications
      .getGlobalNotifications()
      .then((res) => {
        dispatch({
          type: GET_GLOBAL_NOTIFICATIONS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const getLocalNotifications = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.smsNotifications
      .getLocalNotifications()
      .then((res) => {
        dispatch({
          type: GET_LOCAL_NOTIFICATIONS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const updateGlobalNotifications = (templateId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.smsNotifications
      .updateGlobalNotifications(templateId, data)
      .then((res) => {
        dispatch({
          type: UPDATE_GLOBAL_NOTIFICATIONS,
          payload: res.data,
        });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const updateLocalNotifications = (templateId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.smsNotifications
      .updateLocalNotifications(templateId, data)
      .then((res) => {
        dispatch({
          type: UPDATE_LOCAL_NOTIFICATIONS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );
