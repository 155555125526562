import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LoadingComponent from 'utils/suspenseLoading';

const UserRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user.isAuthorized ? (
        <Suspense fallback={<LoadingComponent />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

UserRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape({}).isRequired, PropTypes.func]),
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(UserRoute);
