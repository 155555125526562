import React from 'react';
import styled, { css } from 'styled-components';

import { Switch as SwitchAntd, SwitchProps } from 'antd';

interface ISwitch extends SwitchProps {
  disabled?: boolean;
  checked: boolean;
}

const SwitchComponent = ({ disabled, checked, ...rest }: ISwitch) => (
  <StyledSwitch disabled={disabled} checked={checked} {...rest} />
);

const StyledSwitch = styled(SwitchAntd)`
  height: 25px;
  border: ${({ theme }) => `1px solid ${theme.textLightGrayColor}`};
  background: ${({ theme }) => theme.backgroundWhiteColor};
  ${css`
    .ant-switch-handle {
      &:before {
        border: ${({ theme }) => `1px solid ${theme.grayShade8Color}`};
        background: ${({ theme }) => theme.grayShade9Color};
        box-shadow: none;
      }
    }
    .ant-switch-inner {
      .ant-switch-inner-unchecked {
        color: ${({ theme }) => theme.grayShade3Color};
      }
      .ant-switch-inner-checked {
        color: ${({ theme }) => theme.textWhiteColor};
      }
    }
    &:not(.ant-switch-checked):hover {
      border: ${({ theme }) => `1px solid ${theme.primaryColor}`};

      ${({ theme }) =>
        !theme.isDarkMode &&
        css`
          background: ${theme.grayShade10Color} !important;
        `}
    }

    &.ant-switch-checked {
      background: ${({ theme }) => theme.primaryColor};
      .ant-switch-handle {
        &:before {
          background: ${({ theme }) => theme.backgroundWhiteColor};
        }
      }
    }

    &.ant-switch-disabled {
      background: ${({ theme }) => theme.grayShade8Color};
      border: ${({ theme }) => `2px solid ${theme.textLightGrayColor}`};
      .ant-switch-handle {
        &:before {
          background: ${({ theme }) => theme.grayShade9Color};
        }
      }
    }
  `}
`;

export default SwitchComponent;
