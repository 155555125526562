// APPLICATION
export const SET_TOKEN_TIME = 'SET_TOKEN_TIME';
export const GET_APP_DATA = 'GET_APP_DATA';
export const GET_ALL_EXISTING_PERMISSIONS_LIST = 'GET_ALL_EXISTING_PERMISSIONS_LIST';
export const GET_NOTIFICATIONS_TEMPLATE_VARS = 'GET_NOTIFICATIONS_TEMPLATE_VARS';
export const TOGGLE_APP_STATUS = 'TOGGLE_APP_STATUS';
export const NOTICE_MESSAGE = 'NOTICE_MESSAGE';
export const SEARCH_ON_GOING = 'SEARCH_ON_GOING';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_IS_MENU_EXPANDED = 'SET_IS_MENU_EXPANDED';
export const TOGGLE_RESULTS_MENU = 'TOGGLE_RESULTS_MENU';
export const SET_IS_RESULTS_MENU_EXPANDED = 'SET_IS_RESULTS_MENU_EXPANDED';
export const TOGGLE_CONTACT_INFO = 'TOGGLE_CONTACT_INFO';
export const SET_IS_CONTACT_INFO_EXPANDED = 'SET_IS_CONTACT_INFO_EXPANDED';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const ADD_HEATMAP_SETTING = 'ADD_HEATMAP_SETTING';
export const EDIT_HEATMAP_SETTING = 'EDIT_HEATMAP_SETTING';
export const DELETE_HEATMAP_SETTING = 'DELETE_HEATMAP_SETTING';
export const GET_SMS_GEO_LOCATION_NUMBERS = 'GET_SMS_GEO_LOCATION_NUMBERS';
export const ADD_SMS_GEO_LOCATION_NUMBERS = 'ADD_SMS_GEO_LOCATION_NUMBERS';
export const DELETE_SMS_GEO_LOCATION_NUMBERS = 'DELETE_SMS_GEO_LOCATION_NUMBERS';
export const GET_SMS_GEO_LOCATION_PASSWORD_TIME = 'GET_SMS_GEO_LOCATION_PASSWORD_TIME';
export const SET_SMS_GEO_LOCATION_PASSWORD_TIME = 'SET_SMS_GEO_LOCATION_PASSWORD_TIME';
export const SET_DOWNLOAD_PDF_PATTERN_OF_LIFE_PROGRESS = 'SET_DOWNLOAD_PDF_PATTERN_OF_LIFE_PROGRESS';

// SERVICE STATUS
export const GET_SERVICE_STATUS = 'GET_SERVICE_STATUS';

// AUTH VARIABLES
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE';
export const CHANGE_USER = 'CHANGE_USER';

// CONTACTS VARIABLES
export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS';
export const GET_CONTACT_INFO = 'GET_CONTACT_INFO';
export const ERASE_CONTACT_INFO = 'ERASE_CONTACT_INFO';
export const SET_PRIMARY = 'SET_PRIMARY';
export const SAVE_TERMINAL = 'SAVE_TERMINAL';
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const ADD_TERMINAL = 'ADD_TERMINAL';
export const ARCHIVE_TERMINAL = 'ARCHIVE_TERMINAL';
export const DELETE_TERMINALS = 'DELETE_TERMINALS';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const LOCATE_CONTACT = 'LOCATE_CONTACT';
export const DASHBOARD_INFO = 'DASHBOARD_INFO';
export const SINGLE_SEARCH = 'SINGLE_SEARCH';
export const GET_IMSI_BY_PHONE = 'GET_IMSI_BY_PHONE';
export const GET_PHONE_BY_IMSI = 'GET_PHONE_BY_IMSI';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const GET_COMMENT = 'GET_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const GET_PLOTS = 'GET_PLOTS';
export const SEARCH_CONTACT_RESULT = 'SEARCH_CONTACT_RESULT';
export const SEARCH_UNSAVED_CONTACT_RESULT = 'SEARCH_UNSAVED_CONTACT_RESULT';
export const ADD_TO_CANCELLED_LIST = 'ADD_TO_CANCELLED_LIST';
export const REMOVE_FROM_CANCELLED_LIST = 'REMOVE_FROM_CANCELLED_LIST';

// USERS
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_PERMISSIONS = 'EDIT_PERMISSIONS';
export const SUSPEND_USER = 'SUSPEND_USER';
export const RESTORED_USER = 'RESTORED_USER';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_ADMIN_CREDITS = 'EDIT_ADMIN_CREDITS';
export const EDIT_USER_CREDITS = 'EDIT_USER_CREDITS';
export const DELETE_USER = 'DELETE_USER';
export const STICKY_HEADER = 'STICKY_HEADER';
export const SOUND_NOTIFICATION = 'SOUND_NOTIFICATION';
export const BROWSER_NOTIFICATION = 'BROWSER_NOTIFICATION';
export const SWITCH_USER_LANGUAGE = 'SWITCH_USER_LANGUAGE';

// TEAMS
export const GET_TEAMS = 'GET_TEAMS';
export const ADD_USER_TO_TEAM = 'ADD_USER_TO_TEAM';
export const REMOVE_USER_FROM_TEAM = 'REMOVE_USER_FROM_TEAM';
export const MAKE_LEADER = 'MAKE_LEADER';
export const GET_SINGLE_TEAM = 'GET_SINGLE_TEAM';
export const CREATE_TEAM = 'CREATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const EDIT_TEAM_NAME = 'EDIT_TEAM_NAME';
export const EDIT_USER_TEAM_CREDITS = 'EDIT_USER_TEAM_CREDITS';

// CONTACT GROUPS
export const GET_GROUPS = 'GET_GROUPS';
export const GET_SINGLE_GROUP = 'GET_SINGLE_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const ADD_CONTACT_TO_GROUP = 'ADD_CONTACT_TO_GROUP';
export const LOCATE_CONTACT_GROUP = 'LOCATE_CONTACT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const REMOVE_CONTACT_FROM_GROUP = 'REMOVE_CONTACT_FROM_GROUP';
export const SEARCH_CONTACT_GROUP_RESULT = 'SEARCH_CONTACT_GROUP_RESULT';
export const EDIT_CONTACT_GROUP = 'EDIT_CONTACT_GROUP';

// ALERT
export const FIRE_ALERT = 'FIRE_ALERT';

// ROBOTS
export const GET_ROBOTS = 'GET_ROBOTS';
export const GET_SINGLE_ROBOT = 'GET_SINGLE_ROBOT';
export const GET_CONTACT_TERMINALS = 'GET_CONTACT_TERMINALS';
export const CREATE_ROBOT = 'CREATE_ROBOT';
export const DELETE_ROBOT = 'DELETE_ROBOT';
export const REMOVE_SINGLE_ROBOT = 'REMOVE_SINGLE_ROBOT';
export const CLONE_ROBOT = 'CLONE_ROBOT';
export const DELETE_ROBOT_GROUP = 'DELETE_ROBOT_GROUP';
export const EDIT_ROBOT = 'EDIT_ROBOT';
export const CANCEL_SMS_REGISTER = 'CANCEL_SMS_REGISTER';

export const UPDATE_ROBOT_DATA = 'UPDATE_ROBOT_DATA';
export const UPDATE_ROBOT_LOCATION_DATA = 'UPDATE_ROBOT_LOCATION_DATA';
export const UPDATE_ROBOT_ROAMING_DATA = 'UPDATE_ROBOT_ROAMING_DATA';
export const ADD_MESSAGE_TO_ROBOT = 'ADD_MESSAGE_TO_ROBOT';

// NOTIFICATIONS
export const MAR_AS_SEEN = 'MAR_AS_SEEN';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

// WHITE LIST
export const GET_WHITE_LIST = 'GET_WHITE_LIST';
export const ADD_TO_WHITE_LIST = 'ADD_TO_WHITE_LIST';
export const DELETE_FROM_WHITE_LIST = 'DELETE_FROM_WHITE_LIST';
export const CREATE_TEAM_WHITELIST = 'CREATE_TEAM_WHITELIST';
export const GET_TEAM_WHITE_LIST = 'GET_TEAM_WHITE_LIST';
export const DELETE_TEAM_NUMBER_FROM_WHITE_LIST = 'DELETE_TEAM_NUMBER_FROM_WHITE_LIST';

// FILE
export const ADD_FILE = 'ADD_FILE';
export const UPDATE_FILE = 'UPDATE_FILE';
export const GET_FILES = 'GET_FILES';
export const GET_CELLS = 'GET_CELLS';
export const UPDATE_CELL = 'UPDATE_CELL';
export const DELETE_CELL = 'DELETE_CELL';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_CELLS = 'DELETE_CELLS';
export const DELETE_FILE_ROW = 'DELETE_FILE_ROW';

// REPORT
export const GET_REPORT = 'GET_REPORT';
export const GET_ARCHIVED_REPORT = 'GET_ARCHIVED_REPORT';
export const ARCHIVE_REPORT_CELL = 'ARCHIVE_REPORT_CELL';
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';

// SURVEY
export const GET_SURVEYS = 'GET_SURVEYS';
export const UPLOAD_SURVEY = 'UPLOAD_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const DELETE_SURVEY = 'DELETE_SURVEY';

// CELL REPORT
export const GET_CELL_REPORT = 'GET_CELL_REPORT';
export const GET_SENSORS = 'GET_SENSORS';

// NOTIFICATIONS
export const GET_GLOBAL_NOTIFICATIONS = 'GET_GLOBAL_NOTIFICATIONS';
export const GET_LOCAL_NOTIFICATIONS = 'GET_LOCAL_NOTIFICATIONS';
export const UPDATE_GLOBAL_NOTIFICATIONS = 'UPDATE_GLOBAL_NOTIFICATIONS';
export const UPDATE_LOCAL_NOTIFICATIONS = 'UPDATE_LOCAL_NOTIFICATIONS';

// AGENTS
export const GET_AGENTS = 'GET_AGENTS';
export const STORE_AGENT = 'STORE_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';

// SENSOR GROUPS
export const GET_SENSOR_GROUPS = 'GET_SENSOR_GROUPS';
export const CRATE_SENSOR_GROUP = 'CRATE_SENSOR_GROUP';
export const SHOW_SENSORS_GROUP = 'SHOW_SENSORS_GROUP';
export const ADD_SENSOR_TOGROUP = 'ADD_SENSOR_TOGROUP';
export const DELETE_SENSOR_GROUP = 'DELETE_SENSOR_GROUP';
export const REMOVE_SENSOR_FROM_GROUP = 'REMOVE_SENSOR_FROM_GROUP';
export const GET_ALL_SENSORS = 'GET_ALL_SENSORS';

// JOBS
export const GET_JOBS = 'GET_JOBS';
export const ADD_JOB_TO_LIST = 'ADD_JOB_TO_LIST';
export const JOB_SEARCH_LOADING = 'JOB_SEARCH_LOADING';
export const SEARCH_DATA_ON_CHANGE = 'SEARCH_DATA_ON_CHANGE';
export const UPDATE_JOB_VISIBILITY = 'UPDATE_JOB_VISIBILITY';
export const SORT_BY = 'SORT_BY';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const UPDATE_JOB_PERCENTAGE_PROGRESS = 'UPDATE_JOB_PERCENTAGE_PROGRESS';
export const SET_LAST_JOB_RESULT_ID = 'SET_LAST_JOB_RESULT_ID';
export const UPDATE_JOB_COVERAGE_POLYGON_DATA = 'UPDATE_JOB_COVERAGE_POLYGON_DATA';
export const UPDATE_JOB_HIGH_PRECISION_USED_AT_DATA = 'UPDATE_JOB_HIGH_PRECISION_USED_AT_DATA';

// PATTERN OF LIFE
export const CREATE_OR_UPDATE_PATTERN_OF_LIFE = 'CREATE_OR_UPDATE_PATTERN_OF_LIFE';
export const RETRIEVE_PATTERN_OF_LIFE = 'RETRIEVE_PATTERN_OF_LIFE';
export const RETRIEVE_NEXT_DATA_PATTERN_OF_LIFE = 'RETRIEVE_NEXT_DATA_PATTERN_OF_LIFE';
export const CREATE_LABEL_PATTERN_OF_LIFE = 'CREATE_LABEL_PATTERN_OF_LIFE';
export const UPDATE_LABEL_PATTERN_OF_LIFE = 'UPDATE_LABEL_PATTERN_OF_LIFE';
export const DELETE_LABEL_PATTERN_OF_LIFE = 'DELETE_LABEL_PATTERN_OF_LIFE';
export const GET_PATTERN_OF_LIFE_ENABLED_LIST = 'GET_PATTERN_OF_LIFE_ENABLED_LIST';
export const SET_ACTIVE_ITEM_PATTERN_OF_LIFE = 'SET_ACTIVE_ITEM_PATTERN_OF_LIFE';
export const RESET_PATTERN_OF_LIFE_ENABLED_LIST = 'RESET_PATTERN_OF_LIFE_ENABLED_LIST';
export const RESET_PATTERN_OF_LIFE_VIEW_LIST = 'RESET_PATTERN_OF_LIFE_VIEW_LIST';
export const HIDE_PATTERN_OF_LIFE_ITEM_FROM_LIST = 'HIDE_PATTERN_OF_LIFE_ITEM_FROM_LIST';
export const GET_REPORT_PATTERN_OF_LIFE = 'GET_REPORT_PATTERN_OF_LIFE';

// AUDIT
export const SET_FILTER_AUDIT = 'SET_FILTER_AUDIT';
export const UPDATE_AUDIT_LOGS_REPORT = 'UPDATE_AUDIT_LOGS_REPORT';
