const routes: Record<string, any> = {
  dashboard: '/',
  login: '/login',
  audit: '/audit',
  contacts: '/contacts',
  groups: '/groups',
  groupView: '/groups/:groupId',
  profile: '/profile',
  settings: '/settings',
  contactView: '/contact/:contactId',
  robots: '/robots',
  robotsSelected: '/robots/:id?',
  cellDataImport: '/cell-data-import',
  report: '/report',
  presence: '/presence',
  survey: '/survey',
  license: '/license',
  team: '/team/:teamId',
};

routes.unauthorized = {
  login: routes.login,
};

routes.onlyUnauthorized = {
  login: routes.login,
};

export default routes;
