const sizeConstants = {
  // radius size
  borderRadiusXs: '2px',
  borderRadiusSm: '3px',
  borderRadiusMd: '5px',
  borderRadiusBg: '10px',
  borderRadiusX1: '20px',
  borderRadiusRound: '50%',

  //gap size
  gapXs: '2px',
  gapSm: '5px',
  gapMd: '10px',
  gapLg: '15px',
  gapBg: '20px',
  gapX1: '30px',
  gapX2: '40px',
  gapX3: '50px',
  gapX4: '60px',

  // margins size
  marginXs: '2px',
  marginSm: '5px',
  marginMd: '10px',
  marginLg: '15px',
  marginBg: '20px',
  marginX1: '30px',
  marginX2: '40px',
  marginX3: '50px',
  marginX4: '60px',

  // padding size
  paddingXs: '3px',
  paddingSm: '5px',
  paddingMd: '10px',
  paddingLg: '15px',
  paddingBg: '20px',
  paddingX1: '30px',
  paddingX2: '40px',
  paddingX3: '50px',
  paddingX4: '60px',

  // font size
  fontSizeXs: '10px',
  fontSizeSm: '12px',
  fontSizeBtn: '14px',
  fontSizeMd: '16px',
  fontSizeLg: '18px',
  fontSizeBg: '24px',
  fontSizeX1: '32px',
  fontSizeX2: '36px',
  fontSizeX3: '42px',

  // line height
  lineSizeXs: '10px',
  lineSizeSm: '12px',
  lineSizeBtn: '14px',
  lineSizeMd: '16px',
  lineSizeLg: '20px',
  lineSizeBg: '24px',
};

export const lightColorPalette = {
  primaryColor: '#3569F5',
  secondaryColor: '#328683',
  grayColor: '#464555',
  darkGrayColor: '#464555',
  lightGrayColor: '#AAA9BC',
  backgroundLightGrayColor: '#F7F7F7',
  backgroundWhiteColor: '#FFFFFF',
  greenColor: '#00A86D',
  greenLight: '#00b800',
  redColor: '#F2003D',
  orangeColor: '#ff6d1f',
  yellowColor: '#FFC556',
  textBlackColor: '#191919',
  textGrayColor: '#8F8F8F',
  textWhiteColor: '#FFFFFF',
  textLightGrayColor: '#DCDCDC',
  mainShade2Color: '#5F82FF',
  mainShade3Color: '#829DFF',
  mainShade4Color: '#A2B8FF',
  mainShade5Color: '#C3D4FF',
  mainShade6Color: '#E2EAFE',
  mainShade7Color: '#F3F7FF',
  mainShade8Color: '#f8f9ff',
  mainShade9Color: '#fbfcff',
  grayShade1Color: '#595866',
  grayShade2Color: '#6B6A77',
  grayShade3Color: '#7E7D88',
  grayShade4Color: '#908F99',
  grayShade5Color: '#A3A2AA',
  grayShade6Color: '#B5B5BB',
  grayShade7Color: '#C8C7CC',
  grayShade8Color: '#DADADD',
  grayShade9Color: '#EDECEE',
  grayShade10Color: '#F5F5F5',
  grayShade11Color: '#FBFBFB',
  animationShade1Color: '#5F82FF',

  // Antd color setup
  linkColor: '#328683',
  successColor: '#00A86D',
  warningColor: '#FF5545',
  errorColor: '#F2003D',
  headingColor: '#f10a0a',
  textColor: '#f10f0f',
  textColorSecondary: '#8F8F8F',
  disabledColor: '#DCDCDC',
  borderColorBase: '#d9d9d9',
  boxShadowBase:
    '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
};

export const themeLight = {
  ...lightColorPalette,
  ...sizeConstants,
  isDarkMode: false,
};

export const darkColorPalette = {
  primaryColor: '#3569F5',
  secondaryColor: '#020202',
  grayColor: '#939393',
  darkGrayColor: '#D5DBDB',
  lightGrayColor: '#AAA9BC',
  backgroundLightGrayColor: '#181818',
  backgroundWhiteColor: '#1E1E1E',
  greenColor: '#00A86D',
  greenLight: '#00b800',
  redColor: '#F2003D',
  orangeColor: '#FF5545',
  yellowColor: '#FFC556',
  textBlackColor: '#FFFFFF',
  textGrayColor: '#9F9F9F',
  textWhiteColor: '#232323',
  textLightGrayColor: '#DCDCDC',
  mainShade2Color: '#D5DBDB',
  mainShade3Color: '#4d4d4d',
  mainShade4Color: '#444444',
  mainShade5Color: '#3b3b3b',
  mainShade6Color: '#313131',
  mainShade7Color: '#282828',
  mainShade8Color: '#070600',
  mainShade9Color: '#040300',
  grayShade1Color: '#868686',
  grayShade2Color: '#7c7c7c',
  grayShade3Color: '#757575',
  grayShade4Color: '#6c6c6c',
  grayShade5Color: '#606060',
  grayShade6Color: '#575757',
  grayShade7Color: '#505050',
  grayShade8Color: '#464646',
  grayShade9Color: '#3a3a3a',
  grayShade10Color: '#2c2c2c',
  grayShade11Color: '#232323',
  animationShade1Color: '#7a8094',

  // Antd color setup
  linkColor: '#328683',
  successColor: '#00A86D',
  warningColor: '#FF5545',
  errorColor: '#F2003D',
  headingColor: '#f10a0a',
  textColor: '#f10f0f',
  textColorSecondary: '#8F8F8F',
  disabledColor: '#DCDCDC',
  borderColorBase: '#d9d9d9',
  colorBgContainer: 'red',
  'select-dropdown-bg': 'red',
  colorBgBase: 'red',
  'component-background': 'red',
  colorBgElevated: 'red',
  componentBackground: 'red',
  boxShadowBase:
    '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
};

export const themeDark = {
  ...darkColorPalette,
  ...sizeConstants,
  isDarkMode: true,
};
