import { MAR_AS_SEEN, DELETE_NOTIFICATION } from 'constants/actionTypes';
import api from 'api/appApi';
import { getUser } from '../user/auth';
import { AppDispatch } from 'hooks/useDispatch';

export const markAsSeenNotification = (notificationId: string) => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .markSeen(notificationId)
      .then((res) => {
        dispatch({
          type: MAR_AS_SEEN,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteNotification = (notificationId: string) => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .deleteNotification(notificationId)
      .then((res) => {
        dispatch({
          type: DELETE_NOTIFICATION,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const markAllNotificationAsSeen = () => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .markAllSeen()
      .then((res) => {
        dispatch(getUser());
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
export const deleteAllNotifications = () => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .deleteAll()
      .then((res) => {
        dispatch(getUser());
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
