import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error' | undefined;
const defaultMessages = {
  success: {
    message: 'Success!',
    description: 'Congrats!',
  },
  info: {
    message: 'Info!',
    description: '',
  },
  warning: {
    message: 'Warning!',
    description: '',
  },
  error: {
    message: 'Error!',
    description: '',
  },
};

export const notificationAlert = (
  type: NotificationType = 'warning',
  messageProp: string | undefined,
  descriptionProp: string | undefined | any,
  rest?: any,
) => {
  const message = messageProp || defaultMessages[type].message;
  const description = descriptionProp || defaultMessages[type].description;

  notification[type]({
    message,
    description,
    ...rest,
  });
};
