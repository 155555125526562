import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

import L from 'leaflet';
import { Marker, MarkerProps, Tooltip } from 'react-leaflet';
import ConvertToUserTimezone from 'utils/convertToUserTimezone';

const StyledPoint = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3569f5;
  color: #fff;
  border-radius: 50%;
`;

type MarkerType = MarkerProps & {
  index: number;
  position: [number, number];
  locationTime: string | null;
};

const PlotPoint: FC<MarkerType> = ({ index, position, locationTime, ...rest }) => {
  const { t } = useTranslation();

  const displayedIndex = index + 1;

  const icon = L.divIcon({
    className: `custom-plot-point-icon-${index}`,
    iconSize: [20, 20],
    html: ReactDOMServer.renderToString(<StyledPoint>{displayedIndex}</StyledPoint>),
  });

  return (
    <div>
      <Marker zIndexOffset={displayedIndex} icon={icon} position={position} {...rest}>
        <Tooltip direction="top" offset={[0, -15]}>
          <div>
            <div>
              <span>
                {t('lat').toUpperCase()}/{t('lng').toUpperCase()}:{' '}
              </span>
              <span>
                {position[0]} / {position[1]}
              </span>
            </div>
            <div>
              <span>{t('locationTime')}: </span>
              <ConvertToUserTimezone date={locationTime} />
            </div>
          </div>
        </Tooltip>
      </Marker>
    </div>
  );
};

export default PlotPoint;
