import {
  GET_ALL_USERS,
  EDIT_USER,
  EDIT_PERMISSIONS,
  SUSPEND_USER,
  RESTORED_USER,
  ADD_USER_TO_TEAM,
  MAKE_LEADER,
  REMOVE_USER_FROM_TEAM,
  CREATE_USER,
  EDIT_ADMIN_CREDITS,
  EDIT_USER_CREDITS,
  DELETE_USER,
  LOGOUT,
} from 'constants/actionTypes';
import initialState from './initState';

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case EDIT_USER: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = { ...newUsers[index], ...action.payload };

      return {
        ...state,
        allUsers: newUsers,
      };
    }

    case EDIT_ADMIN_CREDITS: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = { ...newUsers[index], ...action.payload };

      return {
        ...state,
        allUsers: newUsers,
      };
    }

    case EDIT_USER_CREDITS: {
      if (state?.allUsers) {
        const index = state?.allUsers?.findIndex((i) => i.id === action.payload.id);
        const newUsers = [...state.allUsers];
        newUsers[index] = { ...newUsers[index], ...action.payload };

        return {
          ...state,
          allUsers: newUsers,
        };
      }
      return {
        ...state,
      };
    }

    case EDIT_PERMISSIONS: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = { ...newUsers[index], ...action.payload };

      return {
        ...state,
        allUsers: newUsers,
      };
    }

    case SUSPEND_USER:
    case RESTORED_USER: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = action.payload;

      if (index >= 0) {
        return {
          ...state,
          allUsers: newUsers,
        };
      }
      return state;
    }

    case REMOVE_USER_FROM_TEAM: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = { ...action.payload, team: null };

      if (index >= 0) {
        return {
          ...state,
          allUsers: newUsers,
        };
      }
      return state;
    }

    case ADD_USER_TO_TEAM: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = action.payload;

      if (index >= 0) {
        return {
          ...state,
          allUsers: newUsers,
        };
      }
      return state;
    }
    case MAKE_LEADER: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];
      newUsers[index] = { ...newUsers[index], ...action.payload };

      return {
        ...state,
        allUsers: newUsers,
      };
    }

    case CREATE_USER: {
      return {
        ...state,
        allUsers: [...state.allUsers, action.payload],
      };
    }
    case DELETE_USER: {
      const index = state?.allUsers.findIndex((i) => i.id === action.payload.id);
      const newUsers = [...state.allUsers];

      newUsers.splice(index, 1);
      return {
        ...state,
        allUsers: newUsers,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
