import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notification } from 'antd';

class NotificationAlert extends Component {
  state = {};
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.alert && (nextProps.alert.message || nextProps.alert.specificMessage)) {
      notification.config({
        duration: 8,
      });
      notification.error({
        message: nextProps.alert.message,
        description: nextProps.alert.specificMessage,
      });
    }
  }
  render() {
    return null;
  }
}

const mapStateToProps = (store) => ({
  alert: store.alert,
});

NotificationAlert.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string,
    specificMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

export default connect(mapStateToProps)(NotificationAlert);
