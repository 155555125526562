import {
  GET_TEAM_WHITE_LIST,
  CREATE_TEAM_WHITELIST,
  DELETE_TEAM_NUMBER_FROM_WHITE_LIST,
  LOGOUT,
} from 'constants/actionTypes';

export default function teamWhiteList(state = {}, action = {}) {
  switch (action.type) {
    case GET_TEAM_WHITE_LIST:
      return [...Object.values(action.payload)];
    case CREATE_TEAM_WHITELIST:
      return [...Object.values(action.payload)];
    case DELETE_TEAM_NUMBER_FROM_WHITE_LIST:
      return [...Object.values(action.payload)];
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
