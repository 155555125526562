import React, { CSSProperties, FC, forwardRef } from 'react';

import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import { DatePicker as DatePickerAntd, Tooltip } from 'antd';
import { DatePickerProps } from 'antd/es';
import { RangePickerProps } from 'antd/es/date-picker';

import { QuestionMarkIcon } from 'assets/icons';
import { textStyles } from 'components/text/text';
import { Icon, Text } from 'components';
import { useAppSelector } from 'hooks/useSelector';

import esLocale from 'antd/lib/date-picker/locale/es_ES';
import enLocale from 'antd/lib/date-picker/locale/en_US';

interface IDatePickerProps {
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  wrapperStyle?: CSSProperties;
}

export const DatePicker: FC<IDatePickerProps & DatePickerProps> = forwardRef(
  // @ts-ignore
  ({ label, tooltipText, isRequired, wrapperStyle, disabled, ...res }, ref) => {
    const language = useAppSelector((store) => store.user.language);
    const locale = language === 'es' ? esLocale : enLocale;

    const timeZone = useAppSelector((store) => store.user.timezone);
    const initialTime = moment().tz(timeZone);

    return (
      <Wrapper style={wrapperStyle} isDisabled={disabled}>
        <LabelWrapper>
          {label && <Label $isRequired={isRequired}>{label}</Label>}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
            </Tooltip>
          )}
        </LabelWrapper>
        {/*@ts-ignore*/}
        <DatePickerAntd
          ref={ref}
          disabled={disabled}
          getPopupContainer={(trigger) => trigger.parentNode}
          {...res}
          defaultPickerValue={initialTime}
          locale={locale}
        />
      </Wrapper>
    );
  },
);

interface IRangePicker {
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  wrapperStyle?: CSSProperties;
  format?: any;
}

const { RangePicker: RangePickerAntd } = DatePickerAntd;

export const RangePicker: FC<IRangePicker & RangePickerProps> = forwardRef(
  // @ts-ignore
  ({ label, tooltipText, isRequired, wrapperStyle, disabled, ...res }, ref) => {
    const language = useAppSelector((store) => store.user.language);
    const locale = language === 'es' ? esLocale : enLocale;

    const timeZone = useAppSelector((store) => store.user.timezone);
    const initialTime = moment().tz(timeZone);

    return (
      <Wrapper style={wrapperStyle} isDisabled={disabled}>
        <LabelWrapper>
          {label && <Label $isRequired={isRequired}>{label}</Label>}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Icon size={16} color="lightGrayColor" icon={QuestionMarkIcon} style={{ cursor: 'help' }} />
            </Tooltip>
          )}
        </LabelWrapper>
        {/* @ts-ignore */}
        <RangePickerAntd
          ref={ref}
          disabled={disabled}
          getPopupContainer={(trigger) => trigger.parentNode}
          {...res}
          defaultPickerValue={initialTime}
          locale={locale}
        />
      </Wrapper>
    );
  },
);

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const Label = styled(Text)<{ $isRequired?: boolean }>`
  display: block;
  ${textStyles.bodyRegularSm}
  color: ${({ theme }) => theme.textGrayColor};

  ${({ $isRequired }) =>
    $isRequired &&
    css`
      &:before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    `}
`;

const Wrapper = styled.div<{ isDisabled?: boolean | [boolean, boolean] }>`
  &:hover {
    .ant-typography {
      color: ${({ theme, isDisabled }) => !isDisabled && theme.textBlackColor};
    }
  }

  .ant-picker {
    border-radius: 5px;
    background: ${({ theme }) => theme.backgroundWhiteColor};
    border-color: ${({ theme }) => theme.lightGrayColor};

    &:hover {
      border-color: ${({ theme }) => theme.primaryColor};
    }
  }

  .ant-picker {
    .ant-picker-active-bar {
      display: none;
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
    `}
`;
