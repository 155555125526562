import React, { FC, HTMLAttributes, MouseEvent, PropsWithChildren } from 'react';

import styled, { css } from 'styled-components';

import { Text, Icon } from 'components';
import { RemoveIcon } from 'assets/icons';

type TagType = 'default' | 'primary';

interface ITag extends HTMLAttributes<HTMLDivElement> {
  count?: number | string;
  type?: TagType;
  disabled?: boolean;
  closable?: boolean;
  closeAction?: () => void;
}

const Tag: FC<PropsWithChildren<ITag>> = ({
  children,
  type = 'default',
  count,
  disabled = false,
  onClick,
  closable,
  closeAction,
  ...rest
}) => {
  const _onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };
  return (
    <TagStyled type={type} disabled={disabled} onClick={_onClick} {...rest}>
      <Text variant="bodyRegularSm">{children}</Text>
      {count !== undefined && count >= 0 && (
        <Count type={type}>
          <Text variant="bodyRegularSm">{count}</Text>
        </Count>
      )}
      {closable && <Icon onClick={closeAction} icon={RemoveIcon} />}
    </TagStyled>
  );
};

const TagStyled = styled.div<{ type: TagType; disabled: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.gapMd};
  align-items: center;

  padding: ${({ theme }) => `${theme.paddingXs} ${theme.paddingSm}`};
  background: ${({ theme, type }) => (type === 'default' ? theme.grayShade9Color : theme.mainShade6Color)};
  border-radius: ${({ theme }) => theme.borderRadiusMd};

  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  border: 1px solid transparent;

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.mainShade5Color}`};
  }

  .anticon {
    color: ${({ theme, type }) => (type === 'default' ? theme.grayShade3Color : theme.mainShade2Color)};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  ${({ theme, type }) =>
    theme.isDarkMode &&
    css`
      background: ${({ theme }) => (type === 'default' ? theme.grayShade9Color : theme.grayShade4Color)};

      &:hover {
        background: ${theme.grayShade6Color};
      }
    `}
`;

export const Count = styled.div<{ type: TagType }>`
  display: flex;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.paddingXs} ${theme.paddingXs}`};
  background: ${({ theme, type }) => (type === 'default' ? theme.grayShade8Color : theme.mainShade5Color)};
  border-radius: ${({ theme }) => theme.borderRadiusMd};
`;

export default Tag;
