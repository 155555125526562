import { FIRE_ALERT, SET_FILTER_AUDIT, UPDATE_AUDIT_LOGS_REPORT } from 'constants/actionTypes';
import api from 'api/appApi';
import moment from 'moment-timezone';

export const setFilterAudit = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.audit
      .filterAudit(data)
      .then((res) => {
        dispatch({
          type: SET_FILTER_AUDIT,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message ? err.response.data.message : err.message;
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: errorMessage,
            message: t('error'),
          },
        });

        dispatch({
          type: SET_FILTER_AUDIT,
          payload: [],
        });
        return reject(err);
      }),
  );

export const updateAuditLogsReport = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_AUDIT_LOGS_REPORT,
    payload: data,
  });
};

export const exportAuditReport = (data, t) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.audit
      .exportAuditReport(data)
      .then((res) => {
        const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const blob = new Blob([res.data], { type: 'text/csv' });
        const a = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Audit-Report-${date}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        return resolve(res);
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message ? err.response.data.message : err.message;
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: errorMessage,
            message: t('error'),
          },
        });

        return reject(err);
      }),
  );
