import Echo from 'laravel-echo';
// eslint-disable-next-line no-unused-vars
import pusher from 'pusher-js';

import store from 'index';
import { dispatchNotificationOrSound } from 'utils/notificationAndSound';
import { getUser } from 'actions/user/auth';
import {
  getRobots,
  getSingleRobot,
  updateRobotData,
  updateRobotLocationData,
  updateRobotMessages,
  updateRobotRoamingData,
} from 'actions/robots/robots';
import { locateContactResult, removeFromCancelledList } from 'actions/contacts/contact';
import { setJobSearchLoading, addJobToList, updateJobPercentageProgress, scrollToTop } from 'actions/jobs/jobs';
import querySourceTypes from 'constants/querySourceTypes';
import { getSingleGroup, locateContactGroupAction } from 'actions/groups/groups';
import { allAllowedSearches } from 'constants/searchTypes';
import SessionUnresolvedLocationsHelper from 'utils/SessionUnresolvedLocationsHelper';
import { updateSurvey } from 'actions/survey/survey';
import { updateFile } from 'actions/files/files';
import { notificationAlert } from '../utils/notificationAlert';
import { updateAuditLogsReport } from 'actions/audit/audit';

export let echo = null;

export const initSocket = (userId, url, t) => {
  const loginMethod = process.env.REACT_APP_LOGIN_METHOD;

  echo = new Echo({
    key: 'f92fd08e5587042b4496',
    broadcaster: 'pusher',
    wsHost: loginMethod === 'keycloak' ? url.replace(/(^\w+:|^)\/\//, '') : process.env.REACT_APP_SOCKET_WSHOST,
    encrypted: true,
    authEndpoint: loginMethod === 'keycloak' ? `${url}/broadcasting/auth` : process.env.REACT_APP_SOCKET_AUTH_ENDPOINT,
    disableStats: true,
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ` + sessionStorage.getItem('access_token'),
        ['X-Authorization']: `Bearer ` + sessionStorage.getItem('access_token'),
      },
    },
  });

  echo.connector.pusher.connection.bind('disconnected', () => {
    echo.connector.pusher.connect();
  });

  // --------ROBOT LOCATION SOCKET--------
  echo.channel(`dashboard_${userId}`).listen('DashboardQueryRowReservedEvent', (data) => {
    store.dispatch(addJobToList(data.location));
    store.dispatch(setJobSearchLoading(false));
  });

  echo.channel(`dashboard_${userId}`).listen('DashboardLocationResolvedEvent', (data) => {
    const url = window.location.href;
    const page = url.split('/')[3];
    const isDashboardPage = page.length === 0;

    const storeData = store.getState();
    const userStore = storeData.user;
    const searchType = data.location.api_request_type;
    const notificationMessage =
      searchType === 1 || searchType === 12
        ? `${allAllowedSearches[data.location.api_request_type]} finished`
        : `${allAllowedSearches[data.location.api_request_type]} search finished`;

    store.dispatch(addJobToList(data.location));
    dispatchNotificationOrSound(
      notificationMessage,
      `${window.location.protocol}//${window.location.host}/`,
      data.location.code !== null ? 'error' : 'success',
      userStore,
    );

    if (isDashboardPage) {
      const payload = {
        code: data.location.code,
        detail: data.location.detail,
        id: data.location.id,
        resolved: data.location.resolved,
        title: data.location.title,
        type: data.location.api_request_type,
      };

      // Set last job ID
      store.dispatch(scrollToTop(payload));
      // And remove it after it is not needed any more
      setTimeout(() => {
        store.dispatch(scrollToTop(null));
      }, [500]);
    }
  });

  // --------SEARCH TIME SOCKET--------
  echo.channel(`search-time_${userId}`).listen('AlertSearchTimeEvent', () => {});

  // --------CREDITS SOCKET--------
  echo.channel(`credits_${userId}`).listen('CreditChangeEvent', (data) => {
    store.dispatch(getUser());
  });

  // --------PROGRESS UPDATE SOCKET--------
  echo.channel(`progress_update_${userId}`).listen('AlertProgressUpdateEvent', (data) => {
    const locationPath = window.location.pathname;
    const regex = /^\/groups\/(\d+)$/;
    const match = regex.exec(locationPath);

    if (match) {
      const groupId = match[1];
      store.dispatch(getSingleGroup(groupId));
    }

    store.dispatch(updateJobPercentageProgress(data));
    const sessionData = new SessionUnresolvedLocationsHelper();
    sessionData.updateLocationToSession(data);

    let parsedProgress = null;

    // Implementing progress info into SessionStorage for spread the information into JobList Array
    const progressString = sessionStorage.getItem('jobsProgress');
    if (progressString) {
      parsedProgress = JSON.parse(sessionStorage.getItem('jobsProgress'));
      parsedProgress = { ...parsedProgress, [data.apiId]: data };

      if (data.majorStatusUpdate === 100 && data.minorStatusUpdate === 100) {
        delete parsedProgress[data.apiId];
      }
    }

    sessionStorage.setItem('jobsProgress', JSON.stringify(parsedProgress));
  });

  // --------CONTACT LOCATION SOCKET--------
  echo.channel(`contact_${userId}`).listen('ContactLocationRowReservedEvent', (data) => {
    const url = window.location.href;
    const id = parseInt(/[^/]*$/.exec(url)[0], 10);
    const page = url.split('/')[3];
    const storeData = store.getState();

    if (data.location.query_source === querySourceTypes.SOURCE_CONTACT) {
      const locationTime = new Date().toISOString();

      if (id && page) {
        if (data.location.contact_id === id && page === 'contact') {
          store.dispatch(
            locateContactResult({
              locations: { [data.location.id]: { ...data.location, location_time: locationTime } },
            }),
          );
        }
      }
    } else if (data.location.query_source === querySourceTypes.SOURCE_CONTACT_GROUP) {
      const groupStore = storeData.groups;
      if (id && page) {
        if (
          data.location.contact_id &&
          groupStore.singleGroup.contacts[data.location.contact_id] &&
          page === 'groups'
        ) {
          store.dispatch(locateContactGroupAction(data.location));
        }
      }
    }

    const sessionData = new SessionUnresolvedLocationsHelper();
    sessionData.setLocationToSession(data);
  });

  echo.channel(`contact_${userId}`).listen('ContactLocationResolvedEvent', (data) => {
    const url = window.location.href;
    const id = parseInt(/[^/]*$/.exec(url)[0], 10);
    const page = url.split('/')[3];
    const storeData = store.getState();

    store.dispatch(removeFromCancelledList(data.location.id));

    if (data.location.query_source === querySourceTypes.SOURCE_CONTACT) {
      // Store Data
      const userStore = storeData.user;
      const contacts = storeData.contact && storeData.contact.allContacts;
      if (id && page) {
        if (data.location.contact_id === id && page === 'contact') {
          store.dispatch(locateContactResult({ locations: { [data.location.id]: data.location } }));
        }
      }

      // Checking if User is in team and not. This is done for avoiding unavailable contacts
      if (
        (userStore.team_id && userStore.team_id === data.location.team_id) ||
        (userStore.team_id === null && data.location.team_id === null)
      ) {
        dispatchNotificationOrSound(
          `Contact: ${storeData.contact.contactInfo && storeData.contact.contactInfo.name} was updated. `,
          `${window.location.protocol}//${window.location.host}/contact/${
            data && data.location && data.location.contact_id && data.location.contact_id
          }`,
          data.location.code !== null ? 'error' : 'success',
          userStore,
        );
      } else if (data?.location?.contact_id && contacts.length > 0) {
        dispatchNotificationOrSound(
          `Contact: ${storeData.contact.contactInfo && storeData.contact.contactInfo.name} was updated. `,
          null,
          data.location.code !== null ? 'error' : 'success',
          userStore,
        );
      }
    } else if (data.location.query_source === querySourceTypes.SOURCE_CONTACT_GROUP) {
      const groupStore = storeData.groups;
      if (id && page) {
        if (
          data.location.contact_id &&
          groupStore.singleGroup.contacts[data.location.contact_id] &&
          page === 'groups'
        ) {
          store.dispatch(locateContactGroupAction(data.location));
        }
      }
    }

    const sessionData = new SessionUnresolvedLocationsHelper();
    sessionData.removeLocationFromSession(data);
  });

  const getUrlRobotId = () => {
    const url = window.location.href;
    return parseInt(/[^/]*$/.exec(url)[0], 10);
  };

  // --------ROBOT LOCATION SOCKET--------
  echo.channel(`robot_${userId}`).listen('RobotLocationRowReservedEvent', (data) => {
    const id = getUrlRobotId();

    if (data.location && id === data.location.robot_id) {
      const locationTime = new Date().toISOString();
      store.dispatch(updateRobotLocationData({ ...data.location, location_time: locationTime }));
    }
  });

  echo.channel(`robot_${userId}`).listen('RobotLocationResolvedEvent', (data) => {
    const id = getUrlRobotId();

    if (data.location && id === data.location.robot_id) {
      const locationTime = new Date().toISOString();
      store.dispatch(updateRobotLocationData({ ...data.location, location_time: locationTime }));

      setTimeout(() => {
        store.dispatch(getSingleRobot(data.location.robot_id));
      }, 3000);
    }
  });

  echo.channel(`robot_${userId}`).listen('RobotUpdatedEvent', (data) => {
    const id = getUrlRobotId();

    if (data.location && id === data.location.robot_id) {
      // This will run for all robots except SMS Intercept Robot Type
      setTimeout(() => {
        store.dispatch(getSingleRobot(data.location.robot_id));
      }, 3000);
    } else if (
      data.robot.status === 2 ||
      data.robot.status === 4 ||
      data.robot.status === 6 ||
      data.robot.status === 7 ||
      data.robot.status === 8
    ) {
      // This will run mostly for SMS Intercept Robot Type
      if (id === data.robot.id) {
        setTimeout(() => {
          store.dispatch(updateRobotRoamingData(data.robot));
        }, 500);
      }
    }
  });

  echo.channel(`robot_${userId}`).listen('RobotResultNotificationEvent', (data) => {
    const id = getUrlRobotId();
    const userStore = store.getState().user;

    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    const pageName = segments[1];

    if (id === data.robot.id) {
      setTimeout(() => {
        store.dispatch(getSingleRobot(data.robot.id));
      }, 3000);
    } else if (pageName === 'groups') {
      store.dispatch(getSingleGroup(id));
    } else store.dispatch(getRobots());

    store.dispatch(getUser());

    dispatchNotificationOrSound(
      `Robot '${data.robot.name}' finished. `,
      `${window.location.protocol}//${window.location.host}/robots/${data.robot.id}`,
      data.robot.id ? 'success' : 'error',
      userStore,
    );
  });

  // --------SURVEY SOCKET--------
  echo.channel(`survey_${userId}`).listen('FolderBeginImportEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });

  echo.channel(`survey_${userId}`).listen('FolderImportProgressEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });

  echo.channel(`survey_${userId}`).listen('FolderImportedEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });

  echo.channel(`survey_${userId}`).listen('FolderDeletedEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });

  // --------CELL IMPORT--------
  echo.channel(`cell_data_import_${userId}`).listen('CellDataBeginImportEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });

  echo.channel(`cell_data_import_${userId}`).listen('CellDataImportProgressEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });

  echo.channel(`cell_data_import_${userId}`).listen('CellDataImportedEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });

  echo.channel(`cell_data_import_error_${userId}`).listen('CellDataImportErrorEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });

  // --------CELL EXPORT--------
  echo.channel(`cell_data_download_ready_${userId}`).listen('CellDownloadFileReadyEvent', (data) => {
    store.dispatch(getUser());
    notificationAlert(
      'success',
      t('success'),
      `${t('cellExportFile')} "${data.data.fileName}" ${t('readyToDownload')}`,
    );
  });

  // --------AUDIT LOG--------
  echo.channel(`activity_log_${userId}`).listen('ActivityLogCreatedEvent', (data) => {
    store.dispatch(updateAuditLogsReport(data['activity']));
  });

  // --------SMS INTERCEPT--------
  echo.channel(`robot_${userId}`).listen('ReceivedMessageEvent', (data) => {
    const id = getUrlRobotId();

    if (data.robot && id === data.robot.id) {
      store.dispatch(updateRobotMessages(data.messages));
      store.dispatch(updateRobotData(data.robot));
    }
  });

  // --------USER CHANGED PHONE--------
  echo.channel(`user_changed_phone_${userId}`).listen('UserChangedPhoneEvent', (data) => {
    store.dispatch(getUser());
    notificationAlert('info', t('information'), `${data.data.username} ${t('changedPhone').toLowerCase()}`);
  });
};
