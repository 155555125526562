import {
  ADD_SENSOR_TOGROUP,
  CRATE_SENSOR_GROUP,
  GET_SENSOR_GROUPS,
  SHOW_SENSORS_GROUP,
  DELETE_SENSOR_GROUP,
  REMOVE_SENSOR_FROM_GROUP,
  LOGOUT,
} from 'constants/actionTypes';
import initialState from './initState';

export default function sensorGroups(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SENSOR_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case CRATE_SENSOR_GROUP:
      return {
        ...state,
        groups: [...state.groups, { ...action.payload }],
      };
    case SHOW_SENSORS_GROUP:
      return {
        ...state,
        groupsSensors: {
          ...state.groupsSensors,
          [action.payload.groupId]: { ...action.payload.data },
        },
      };
    case ADD_SENSOR_TOGROUP: {
      const groupsArray = [...state.groups];
      const index = groupsArray.findIndex((group) => group.id === action.payload.id);
      groupsArray[index].sensor_count += 1;
      return {
        ...state,
        groups: groupsArray,
        groupsSensors: {
          ...state.groupsSensors,
          [action.payload.id]: {
            ...state.groupsSensors[action.payload.id],
            sensors: action.payload.data,
          },
        },
      };
    }
    case DELETE_SENSOR_GROUP: {
      const groupArray = [...state.groups];
      const index = groupArray.findIndex((group) => group.id === action.payload.id);

      if (index > -1) {
        groupArray.splice(index, 1);
        return {
          ...state,
          groups: groupArray,
        };
      }
    }
    case REMOVE_SENSOR_FROM_GROUP:
      return {
        ...state,
        groupsSensors: {
          ...state.groupsSensors,
          [action.payload.id]: {
            ...state.groupsSensors[action.payload.id],
            sensors: action.payload.data,
          },
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
