import {
  SET_TOKEN_TIME,
  GET_APP_DATA,
  GET_ALL_EXISTING_PERMISSIONS_LIST,
  GET_NOTIFICATIONS_TEMPLATE_VARS,
  TOGGLE_APP_STATUS,
  SEARCH_ON_GOING,
  TOGGLE_DARK_MODE,
  TOGGLE_MENU,
  SET_IS_MENU_EXPANDED,
  TOGGLE_RESULTS_MENU,
  SET_IS_RESULTS_MENU_EXPANDED,
  TOGGLE_CONTACT_INFO,
  SET_IS_CONTACT_INFO_EXPANDED,
  SET_DARK_MODE,
  GET_SMS_GEO_LOCATION_NUMBERS,
  GET_SMS_GEO_LOCATION_PASSWORD_TIME,
  SET_SMS_GEO_LOCATION_PASSWORD_TIME,
  SET_DOWNLOAD_PDF_PATTERN_OF_LIFE_PROGRESS,
  LOGOUT,
} from 'constants/actionTypes';

export default function app(
  state = {
    searchOnGoing: false,
    darkMode: false,
    isResultsMenuExpanded: true,
    isMenuExpanded: false,
    isContactInfoExpanded: true,
    downloadPdfPatternOfLifeProgress: 0,
  },
  action = {},
) {
  switch (action.type) {
    case SET_TOKEN_TIME:
      return {
        ...state,
        ...action.payload,
      };
    case GET_APP_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ALL_EXISTING_PERMISSIONS_LIST:
      return {
        ...state,
        allExistingPermissionsList: action.payload,
      };
    case GET_NOTIFICATIONS_TEMPLATE_VARS:
      return {
        ...state,
        notificationsTemplateVars: action.payload,
      };
    case TOGGLE_APP_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case SEARCH_ON_GOING:
      return {
        ...state,
        searchOnGoing: action.payload,
      };
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuExpanded: !state.isMenuExpanded,
      };
    case SET_IS_MENU_EXPANDED:
      return {
        ...state,
        isMenuExpanded: action.payload,
      };
    case TOGGLE_RESULTS_MENU:
      return {
        ...state,
        isResultsMenuExpanded: !state.isResultsMenuExpanded,
      };
    case SET_IS_RESULTS_MENU_EXPANDED:
      return {
        ...state,
        isResultsMenuExpanded: action.payload,
      };
    case TOGGLE_CONTACT_INFO:
      return {
        ...state,
        isContactInfoExpanded: !state.isContactInfoExpanded,
      };
    case SET_IS_CONTACT_INFO_EXPANDED:
      return {
        ...state,
        isContactInfoExpanded: action.payload,
      };
    case GET_SMS_GEO_LOCATION_NUMBERS:
      return {
        ...state,
        smsLocateNumbers: action.payload,
      };
    case GET_SMS_GEO_LOCATION_PASSWORD_TIME:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SMS_GEO_LOCATION_PASSWORD_TIME:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DOWNLOAD_PDF_PATTERN_OF_LIFE_PROGRESS:
      return {
        ...state,
        downloadPdfPatternOfLifeProgress: action.payload,
      };

    case LOGOUT:
      return {
        searchOnGoing: false,
        darkMode: false,
        isMenuExpanded: false,
        isResultsMenuExpanded: true,
        isContactInfoExpanded: true,
      };
    default:
      return state;
  }
}
