import React, { FC, forwardRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Empty } from 'antd';

import {
  markAsSeenNotification,
  deleteNotification,
  deleteAllNotifications,
  markAllNotificationAsSeen,
} from 'actions/notification/notification';

import { Icon, Text } from 'components/index';
import { ArchiveDownloadSolidIcon, MobileBulkIcon, RobotsBulkIcon, TrashIcon } from 'assets/icons';
import { useAppSelector } from 'hooks/useSelector';
import { useAppDispatch } from 'hooks/useDispatch';
import { useTheme } from 'theme/useTheme';
import { INotification } from 'types/storeTypes';
import { useVirtualizer } from '@tanstack/react-virtual';
import ApplyUserTimezone from 'utils/convertToUserTimezone';
import PermissionHandler from 'utils/permissionHandler';
import toCamelCase from 'utils/toCamelCase';
import { downloadCellsReport } from 'actions/files/files';

const NotificationItem: FC<any> = forwardRef(
  (
    {
      notification,
      onClickRobotNotification,
      onClickCellReportNotification,
      onClickPhoneChangedNotification,
      onDelete,
      theme,
      user,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const notificationResult = notification?.data?.result_data || notification?.data;

    const typeParts = notification?.type?.split('\\');
    const notificationType = typeParts ? typeParts[typeParts.length - 1] : null;

    const notificationTime = notification?.created_at;

    if (!notificationResult) return null;

    if (notificationType === 'UserChangedPhone') {
      return (
        <ItemWrapper
          ref={ref as any}
          $canInteract={notificationResult.status === 'Success'}
          $isActive={!notification.read_at}
          onClick={() =>
            notificationResult.status === 'Success' ? onClickPhoneChangedNotification(notification.id) : null
          }
          {...rest}
        >
          <div style={{ display: 'flex', gap: theme.gapSm }}>
            <StyledIcon size={30} style={{ marginRight: theme.marginMd }} icon={MobileBulkIcon} />
            <div>
              <Text variant="bodyBold" style={{ display: 'block' }}>
                {t('actionRequired')}!
              </Text>
              <Text variant="bodyRegularSm" style={{ display: 'block' }}>
                {notificationResult.text}
              </Text>
            </div>
          </div>
          <Icon
            size={16}
            icon={TrashIcon}
            color="grayShade7Color"
            hover={{ color: theme['redColor'] }}
            style={{ cursor: 'pointer' }}
            onClick={(e) => onDelete(e, notification.id)}
          />
        </ItemWrapper>
      );
    }

    if (notificationType === 'CellExportReady') {
      return (
        <StyledPermissionHandler button="export-cell-data-enable" ref={ref as any} {...rest}>
          <ItemWrapper
            $canInteract={notificationResult.status === 'Success'}
            $isActive={!notification.read_at}
            onClick={() =>
              notificationResult.status === 'Success'
                ? onClickCellReportNotification(notificationResult.filename, notification.id)
                : null
            }
          >
            <div style={{ display: 'flex', gap: theme.gapSm }}>
              <StyledIcon size={30} style={{ marginRight: theme.marginMd }} icon={ArchiveDownloadSolidIcon} />
              <div>
                <Text variant="bodyBold" style={{ display: 'block' }}>
                  {notificationResult.filename}
                </Text>
                <Text
                  variant="bodyRegularSm"
                  color={notificationResult.status === 'Success' ? 'greenColor' : 'orangeColor'}
                  style={{ display: 'block' }}
                >
                  {t(`${toCamelCase(notificationResult.status)}`)}
                </Text>
                <Text color="textGrayColor" variant="bodyLightSm" style={{ display: 'block' }}>
                  {notificationResult.text}
                </Text>
              </div>
            </div>
            <Icon
              size={16}
              icon={TrashIcon}
              color="grayShade7Color"
              hover={{ color: theme['redColor'] }}
              style={{ cursor: 'pointer' }}
              onClick={(e) => onDelete(e, notification.id)}
            />
          </ItemWrapper>
        </StyledPermissionHandler>
      );
    }

    const canInteract = notificationResult?.teamId === user?.team_id;

    return (
      <ItemWrapper
        ref={ref as any}
        $isActive={!notification.read_at}
        $canInteract={canInteract}
        onClick={() => (canInteract ? onClickRobotNotification(notificationResult.robotId, notification.id) : null)}
        {...rest}
      >
        <div style={{ display: 'flex', gap: theme.gapSm }}>
          <StyledIcon size={30} style={{ marginRight: theme.marginMd }} icon={RobotsBulkIcon} />
          <div>
            <Text variant="bodyBold" style={{ display: 'block' }}>
              {notificationResult.robotName}
            </Text>
            <Text variant="bodyRegularSm" color={notificationResult.result === 'fail' ? 'orangeColor' : 'greenColor'}>
              {notificationResult.status}
            </Text>
            <Text color="textGrayColor" variant="bodyLightSx" style={{ display: 'block' }}>
              <ApplyUserTimezone date={notificationTime} />
            </Text>
            {!canInteract ? (
              <div style={{ opacity: 0.6, marginTop: theme.marginSm }}>
                <Text variant="bodyRegularSm">{t('pleaseSwitchTo')}</Text>{' '}
                {notificationResult.teamId ? (
                  <span>
                    <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                      {' '}
                      <Text variant="bodyBoldSm">{user?.teams?.data[notificationResult.teamId]?.name}</Text>
                    </span>{' '}
                    <Text variant="bodyRegularSm">{t('team').toLowerCase()}</Text>
                  </span>
                ) : (
                  <Text variant="bodyBoldSm">{t('personalView')}</Text>
                )}{' '}
                <Text variant="bodyRegularSm">{t('toSeeTheResult')}</Text>
              </div>
            ) : null}
          </div>
        </div>
        <Icon
          size={16}
          icon={TrashIcon}
          color="grayShade7Color"
          hover={{ color: theme['redColor'] }}
          style={{ cursor: 'pointer' }}
          onClick={(e) => onDelete(e, notification.id)}
        />
      </ItemWrapper>
    );
  },
);

const NotificationContent = ({ closePopover }: { closePopover: () => void }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const user = useAppSelector((store) => store.user);
  const notifications = user?.notifications?.data;
  const notificationsValues: INotification[] = Object.values(notifications || {});
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: notificationsValues.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 79,
  });

  const onClickRobotNotification = (robotId: number, notificationId: string) =>
    dispatch(markAsSeenNotification(notificationId)).then(() => {
      history.push(`/robots/${robotId}`);
      closePopover();
    });

  const onClickCellReportNotification = (fileName: string, notificationId: string) =>
    dispatch(downloadCellsReport(fileName)).then(() => {
      dispatch(markAsSeenNotification(notificationId));
      closePopover();
    });

  const onClickPhoneChangedNotification = (notificationId: string) =>
    dispatch(markAsSeenNotification(notificationId)).then(() => {
      closePopover();
    });

  const deleteNotificationAction = (e: MouseEvent, notificationId: string) => {
    e.stopPropagation();
    dispatch(deleteNotification(notificationId)).then(() => {
      rowVirtualizer.measure();
    });
  };

  const deleteAll = () => {
    if (notificationsValues.length) {
      dispatch(deleteAllNotifications()).then(() => {
        rowVirtualizer.measure();
      });
    }
  };

  const markAllAsSeen = () => (notificationsValues.length ? dispatch(markAllNotificationAsSeen()) : () => null);

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <NotificationWrapper>
      <HeaderWrapper>
        <Text variant="subtitleBold">{t('notifications')}</Text>
        <HeaderActionsWrapper>
          <Text variant="bodyRegularSm" underline onClick={markAllAsSeen} disabled={!notificationsValues.length}>
            {t('markAllAsRead')}
          </Text>
          <Text variant="bodyRegularSm" underline onClick={deleteAll} disabled={!notificationsValues.length}>
            {t('deleteAll')}
          </Text>
        </HeaderActionsWrapper>
      </HeaderWrapper>
      <ContentWrapper ref={parentRef}>
        {!notificationsValues.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />
        ) : (
          <div
            style={{
              height: rowVirtualizer.getTotalSize(),
              width: '100%',
              position: 'relative',
            }}
          >
            {virtualItems.map(({ key, index, start }) => (
              <NotificationItem
                key={key}
                ref={rowVirtualizer.measureElement}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${start}px)`,
                }}
                data-index={index}
                notification={notificationsValues[index]}
                onClickRobotNotification={onClickRobotNotification}
                onClickCellReportNotification={onClickCellReportNotification}
                onClickPhoneChangedNotification={onClickPhoneChangedNotification}
                onDelete={deleteNotificationAction}
                theme={theme}
                user={user}
              />
            ))}
          </div>
        )}
      </ContentWrapper>
    </NotificationWrapper>
  );
};

const NotificationWrapper = styled.div`
  position: relative;
  width: 470px;
  max-height: 490px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @media only screen and (max-width: 640px) {
    width: 100% !important;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.paddingMd} ${theme.paddingBg}`};

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.textLightGrayColor};
  gap: ${({ theme }) => theme.gapMd};

  @media only screen and (max-width: 640px) {
    padding: ${({ theme }) => `${theme.paddingMd} ${theme.paddingLg}`};
  }

  & > span {
    @media only screen and (max-width: 640px) {
      font-size: ${({ theme }) => theme.fontSizeLg};
    }
  }
`;

const HeaderActionsWrapper = styled.div`
  gap: ${({ theme }) => theme.gapMd};
  display: flex;
  flex-wrap: nowrap;

  span {
    cursor: pointer;
    color: ${({ theme }) => theme.textBlackColor} !important;

    &:nth-child(2) {
      &:hover {
        color: ${({ theme }) => theme.redColor} !important;
      }
    }
  }
`;

const ItemWrapper = styled.div<{ $isActive?: boolean; $canInteract?: boolean }>`
  padding: ${({ theme }) => `${theme.paddingLg} ${theme.paddingBg}`};
  border-bottom: 1px solid ${({ theme }) => theme.textLightGrayColor};

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 640px) {
    padding: ${({ theme }) => `${theme.paddingLg} ${theme.paddingLg}`};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${({ theme }) => theme.mainShade7Color};
    `}

  ${({ $canInteract }) =>
    $canInteract &&
    css`
      cursor: pointer;
    `}

  ${({ $canInteract }) =>
    !$canInteract &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-height: 435px;
  height: calc(100% - 52px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background: ${({ theme }) => theme.grayShade9Color};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: ${({ theme }) => theme.grayShade5Color};
  }
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) =>
    theme.isDarkMode &&
    css`
      filter: invert(70%);
    `}
`;

const StyledPermissionHandler = styled(PermissionHandler)`
  .handler-children-component {
    width: 100%;

    & > div {
      width: 100%;
    }
  }
`;

export default NotificationContent;
