import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AnimatePresence, motion } from 'framer-motion';

import { Tooltip } from 'antd';
import MenuPopover from './menuPopover';
import MenuStyles from 'components/menu/menuStyles';

import { Icon, Text } from 'components';
import { useTheme } from 'theme/useTheme';
import { useAppSelector } from 'hooks/useSelector';
import { BroadCastIcon, HelpIcon } from 'assets/icons';

const { MenuItemWrapper, MinimizedContent } = MenuStyles;

const ServiceStatusItem = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const serviceStatus = useAppSelector((store) => store.serviceStatus);
  const statusData = serviceStatus?.response?.data || {};
  const statusError = serviceStatus?.error || '';

  let errorMessage = '';
  try {
    const errorObject = JSON.parse(statusError);
    errorMessage = errorObject.error.detail;
  } catch (error) {
    // If parsing fails, statusError might be a simple string
    errorMessage = statusError;
  }

  const statusColor = useMemo(() => {
    switch (statusData.statusColour) {
      case 'GREEN':
        return 'greenColor';
      case 'YELLOW':
        return 'yellowColor';
      case 'ORANGE':
        return 'orangeColor';
      case 'RED':
        return 'redColor';
      default:
        return 'redColor';
    }
  }, [statusData?.statusColour]);

  const Status = ({ isPopup }: { isPopup?: boolean }) => (
    <ServiceStatusWrapper isPopup={isPopup}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: theme.marginSm }}>
        <Text variant="bodyBold">{t('serviceStatus')}</Text>
        <Icon size="middle" color={statusColor} icon={BroadCastIcon} style={{ marginLeft: '5px' }} />
      </div>
      <div style={{ display: 'grid', gap: theme.gapXs }}>
        <div style={{ display: 'flex' }}>
          <Text>{t('apiOperational')}:</Text>
          <Text variant="bodyBold" style={{ marginLeft: '5px' }}>
            {statusData?.apiOperational ? `${t('yes').toUpperCase()}` : `${t('no').toUpperCase()}`}
          </Text>
        </div>
        <div style={{ display: 'flex' }}>
          <Text>{t('serviceStatus')}:</Text>
          <Text variant="bodyBold" style={{ marginLeft: '5px' }}>
            {statusData?.serviceStatus ? statusData?.serviceStatus?.toUpperCase() : 'FAULT'}
          </Text>
        </div>
      </div>
      {errorMessage && (
        <StyledTooltip title={errorMessage} mouseEnterDelay={0.4}>
          <Icon color="redColor" style={{ fontSize: '16px', cursor: 'pointer' }} icon={HelpIcon} />
        </StyledTooltip>
      )}
    </ServiceStatusWrapper>
  );

  return (
    <MenuItemWrapper marginBottom={theme.marginMd} disableSelection={isExpanded} style={{ minHeight: '48px' }}>
      <AnimatePresence initial={false}>
        {isExpanded ? (
          <motion.div
            key="service-1"
            initial={{ opacity: 0, height: '48px' }}
            animate={{ opacity: 1, height: '78px' }}
            exit={{ opacity: 0, height: '48px' }}
            style={{ display: 'flex', alignItems: 'center', width: '100%', maxHeight: '78px' }}
            transition={{ duration: 0.15 }}
          >
            <Status />
          </motion.div>
        ) : (
          <motion.div
            key="service-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, height: '78px' }}
            style={{ position: 'absolute' }}
            transition={{ duration: 0.15 }}
          >
            <MenuPopover content={<Status isPopup />}>
              <Tooltip placement="right" title={t('serviceStatus')} mouseEnterDelay={0.5}>
                <MinimizedContent>
                  <Icon color={statusColor} icon={BroadCastIcon} />
                </MinimizedContent>
              </Tooltip>
            </MenuPopover>
          </motion.div>
        )}
      </AnimatePresence>
    </MenuItemWrapper>
  );
};

const ServiceStatusWrapper = styled.div<{ isPopup?: boolean }>`
  width: 100%;
  min-width: 175px;
  padding: ${({ isPopup }) => (isPopup ? '20px' : '5px')};
  border-radius: 5px;
  position: relative;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export default ServiceStatusItem;
