import React from 'react';

import styled from 'styled-components';

import { Radio, RadioGroupProps } from 'antd';

const RadioGroup = (props: RadioGroupProps) => <StyledRadioGroup {...props} />;

const StyledRadioGroup = styled(Radio.Group)`
  color: ${({ theme }) => theme.textBlackColor};
`;

export default RadioGroup;
