import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'theme/useTheme';
import { Icon } from 'components/index';
import { NotificationIcon, NotificationOnIcon } from 'assets/icons';

import { Tooltip } from 'antd';
import NotificationContent from 'components/menu/notificationItem/notificationContent';
import ExpandedContent from 'components/menu/expandedContent';
import MenuPopover from 'components/menu/menuPopover';
import MenuStyles from 'components/menu/menuStyles';
import { INotification } from 'types/storeTypes';
import { useAppSelector } from 'hooks/useSelector';

const { MenuItemWrapper, MinimizedContent, NameText } = MenuStyles;

const MenuNotificationItem = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [popoverVisible, setPopoverVisible] = useState(false);

  const user = useAppSelector((store) => store.user);
  const notifications = user?.notifications?.data;
  const notificationsValues: INotification[] = Object.values(notifications || {});
  const hasUnreadNotification = notificationsValues.some((notification) => notification.read_at === null);

  const handlePopoverVisibility = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <MenuPopover
      content={<NotificationContent closePopover={() => setPopoverVisible(false)} />}
      open={popoverVisible}
      onVisibleChange={handlePopoverVisibility}
    >
      <MenuItemWrapper marginBottom={theme['marginMd']}>
        {isExpanded ? (
          <MinimizedContent>
            <AnimatedIcon
              color="darkGrayColor"
              icon={hasUnreadNotification ? NotificationOnIcon : NotificationIcon}
              $hasUnreadNotification={hasUnreadNotification}
            />
          </MinimizedContent>
        ) : (
          <Tooltip placement="right" title={t('notifications')} mouseEnterDelay={0.5}>
            <MinimizedContent>
              <AnimatedIcon
                color="darkGrayColor"
                icon={hasUnreadNotification ? NotificationOnIcon : NotificationIcon}
                $hasUnreadNotification={hasUnreadNotification}
              />
            </MinimizedContent>
          </Tooltip>
        )}

        <ExpandedContent isExpanded={isExpanded}>
          <NameText>{t('notifications')}</NameText>
        </ExpandedContent>
      </MenuItemWrapper>
    </MenuPopover>
  );
};

const AnimatedIcon = styled(Icon)<{ $hasUnreadNotification: boolean }>`
  ${({ $hasUnreadNotification }) =>
    $hasUnreadNotification &&
    css`
      animation: animateGroup 4s infinite;

      @keyframes animateGroup {
        0%,
        100% {
          transform: rotate(-8deg);
        }
        17.542% {
          transform: rotate(0deg);
        }
        35.084% {
          transform: rotate(-20deg);
        }
        48.2405% {
          transform: rotate(20deg);
        }
        57.0115% {
          transform: rotate(-20deg);
        }
        64.9054% {
          transform: rotate(8deg);
        }
        74.5535% {
          transform: rotate(-15deg);
        }
        78.939% {
          transform: rotate(-7deg);
        }
        88.5535% {
          transform: rotate(2deg);
        }
      }
    `}
`;

export default MenuNotificationItem;
