import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks/useSelector';

import moment from 'moment-timezone';

interface IApplyUserTimezone {
  date: string | undefined | null;
  format?: string;
}

const ApplyUserTimezone: FC<IApplyUserTimezone> = ({ date, format = 'YYYY-MM-DD HH:mm' }) => {
  const { t } = useTranslation();

  const userTimezone = useAppSelector((store) => store.user.timezone);

  if (!date) return <>{t('noDate')}</>;

  if (userTimezone && date) {
    return <>{moment(date).tz(userTimezone).format(format)}</>;
  }

  return <>{moment(date).format(format)}</>;
};

export default memo(ApplyUserTimezone);
